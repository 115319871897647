import { gql } from '@apollo/client';

export const ParticipantFragment = gql`
  fragment ParticipantFields on ParticipantQL {
    id
    address
    displayName
    displayNameConfidence
    source
    avatarUrl
    comment
    firstName
    lastName
    spamStatus
    crmContacts {
      id
      firstName
      lastName
      companyName
      email
      phoneNumber
      contactType
    }
    latestCustomer {
      id
      firstName
      lastName
      displayName
      addresses {
        id
        address
        mediumType
        isPrimary
        isActive
        deletedAt
        sourceEntityId
        sourceEntityType
      }
    }
    customerCount
  }
`;
