import { gql } from '@apollo/client';
import { ChannelFieldsFragment } from '../../fragments/gql/channelFields.gql';

export const UPDATE_CHANNEL = gql`
  mutation updateChannel($input: UpdateChannelInput!) {
    updateChannel(input: $input) {
      channel {
        ...ChannelFields
      }
    }
  }
  ${ChannelFieldsFragment}
`;
