import { gql } from '@apollo/client';

export const ComplianceSettingsFragment = gql`
  fragment ComplianceSettings on ComplianceSettingsQL {
    onVoicemail
    onHangup
    onRevivedConversations
    onCompose
    guidelinesReminder
    detailedGuidelines
    sendOptOutInstructions
    optOutInstructions
    optOutConfirmation
    optOutResubscribe
    requireExplicitOptIn
    doubleOptInPrompt
    ongoingOptOutInstructions
    suppressOptOutInstructions
    requireExplicitOptIn
  }
`;
