import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const MOVE_AUTOMATION_NODES = gql`
  mutation moveAutomationNodes($input: UpdateAutomationUIHintsInput!) {
    updateAutomationUIHints(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
