import { GET_AWAY_MODE_STATUS } from '../../queries/gql/getAwayModeStatus.gql';
import type { ApolloContext } from '../../types';
import { safeRead } from '../../util/apollo/safeRead';

export const PubNubAwayModeChanged = async (
  obj: any,
  args: {
    user_id: string;
    is_away: boolean;
    is_schedule_enabled: boolean;
    status_board_view: boolean;
    next_shift_start: string | null;
    next_shift_end: string | null;
    can_manage_own_away_mode_settings: boolean;
    is_unassigning_inbound_messages: boolean;
    show_away_mode_banner_after: string | null | undefined;
  },

  { client }: ApolloContext,
) => {
  const cachedUserQuery = safeRead(client, {
    query: GET_AWAY_MODE_STATUS,
    variables: { userId: args.user_id },
  }) as getAwayModeStatus | null;

  if (!cachedUserQuery || !cachedUserQuery.user) {
    return;
  }

  await client.writeQuery<getAwayModeStatus>({
    query: GET_AWAY_MODE_STATUS,
    variables: { userId: args.user_id },
    data: {
      user: {
        ...cachedUserQuery.user,
        __typename: 'UserQL',
        id: args.user_id,
        isAway: args.is_away,
        isScheduleEnabled: args.is_schedule_enabled,
        nextShiftStart: args.next_shift_start,
        nextShiftEnd: args.next_shift_end,
        canManageOwnAwayModeSettings: args.can_manage_own_away_mode_settings,
        showAwayModeBannerAfter: args.show_away_mode_banner_after,
      },
    },
  });
};
