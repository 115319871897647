import axios from 'axios';

const apiPath = '/api/';
export const noop = () => {};

export function getResource(
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'actionType' implicitly has an 'any' typ... Remove this comment to see the full error message
  actionType,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'resourceName' implicitly has an 'any' t... Remove this comment to see the full error message
  resourceName,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'itemId' implicitly has an 'any' type.
  itemId,
  requestPath = undefined,
  onSuccess = noop,
  onFailure = noop,
) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return dispatch => {
    let path = `${apiPath}${resourceName}/${itemId}`;
    if (requestPath) {
      path = `${apiPath}${requestPath}`;
    }

    axios
      .get(path)
      .then(response => {
        dispatch({
          id: itemId,
          type: actionType,
          payload: response.data,
        });
      })
      .then(onSuccess)
      .catch(error => console.log(error))
      .then(onFailure);
  };
}

export function getResourceList(
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'actionType' implicitly has an 'any' typ... Remove this comment to see the full error message
  actionType,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'resourceName' implicitly has an 'any' t... Remove this comment to see the full error message
  resourceName,
  requestPath = undefined,
  onSuccess = noop,
  onFailure = noop,
) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return dispatch => {
    let path = `${apiPath}${resourceName}/`;
    if (requestPath) {
      path = `${apiPath}${requestPath}`;
    }

    axios
      .get(path)
      .then(response => {
        dispatch({
          type: actionType,
          payload: response.data,
        });
      })
      .then(onSuccess)
      .catch(error => console.log(error))
      .then(onFailure);
  };
}

export function postResource(
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'actionType' implicitly has an 'any' typ... Remove this comment to see the full error message
  actionType,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'resourceName' implicitly has an 'any' t... Remove this comment to see the full error message
  resourceName,
  requestPath = undefined,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  payload,
  onSuccess = noop,
  onFailure = noop,
) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return dispatch => {
    let path = `${apiPath}${resourceName}`;
    if (requestPath) {
      path = `${apiPath}${requestPath}`;
    }

    axios
      .post(path, payload)
      .then(response => {
        dispatch({
          type: actionType,
          payload: response.data,
        });
      })
      .then(onSuccess)
      .catch(error => console.log(error))
      .then(onFailure);
  };
}

export function patchResource(
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'actionType' implicitly has an 'any' typ... Remove this comment to see the full error message
  actionType,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'resourceName' implicitly has an 'any' t... Remove this comment to see the full error message
  resourceName,
  requestPath = undefined,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  payload,
  onSuccess = noop,
  onFailure = noop,
) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return dispatch => {
    let path = `${apiPath}${resourceName}/`;
    if (requestPath) {
      path = `${apiPath}${requestPath}`;
    }

    axios
      .patch(path, payload)
      .then(response => {
        dispatch({
          type: actionType,
          payload: response.data,
        });
      })
      .then(onSuccess)
      .catch(error => console.log(error))
      .then(onFailure);
  };
}
