import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import type { ApolloClient } from '@apollo/client';

import { EMPTY_CONNECTION, safeRead } from '../util';
import { LOAD_CHAT_HISTORY } from '../queries/gql/loadChatHistory.gql';
import { getChatHistoryQueryVariables } from '../queries/LoadChatHistory';
import { GET_CURRENT_USER } from '../queries/gql/getCurrentUser.gql';

type Args = {
  client: ApolloClient<any>;
  conversationId: string;
  message?: MessageItemFields;
  scheduledMessage?: ScheduledMessageFields;
};

export const addMessageToCache = async ({
  client,
  conversationId,
  message,
  scheduledMessage,
}: Args) => {
  const currentUserResponse = safeRead<getCurrentUser, getCurrentUserVariables>(
    client,
    {
      query: GET_CURRENT_USER,
    },
  );

  const chatHistoryQueryVariables = getChatHistoryQueryVariables({
    conversationId,
    withScheduledMessages: Boolean(scheduledMessage),
    includeSystemMessages:
      currentUserResponse?.currentUser?.viewPreferences.systemMessageSettings
        ?.showSystemMessages,
  });
  let cachedChatHistory = safeRead(client, {
    query: LOAD_CHAT_HISTORY,
    variables: chatHistoryQueryVariables,
  });

  if (
    !cachedChatHistory ||
    !cachedChatHistory.messages ||
    !cachedChatHistory.conversation ||
    !cachedChatHistory.activePhoneCallsWithoutMessages
  ) {
    cachedChatHistory = {
      messages: {
        __typename: 'MessageEdges',
        ...EMPTY_CONNECTION,
      },
      conversation: {
        __typename: 'ConversationQL',
        id: conversationId,
        isNextOutboundMessageSigned: null,
      },
      activePhoneCallsWithoutMessages: [],
    };
  }

  if (scheduledMessage && !cachedChatHistory.scheduledMessage) {
    cachedChatHistory.scheduledMessages = {
      __typename: 'ScheduledMessageEdges',
      ...EMPTY_CONNECTION,
    };
  }

  let newMessageEdges = [];
  let newScheduledMessageEdges = [];
  let newActivePhoneCallsWithoutMessages = [];

  if (message) {
    newMessageEdges = uniqBy(
      orderBy(
        [
          {
            __typename: 'MessageEdge',
            node: {
              ...message,
            },
          },
          ...cachedChatHistory.messages.edges,
        ],
        [
          edge =>
            edge.node.timestamp ? new Date(edge.node.timestamp) : new Date(),
        ],
        ['desc'],
      ),
      edge => edge.node.id,
    );
  }

  if (scheduledMessage) {
    newScheduledMessageEdges = uniqBy(
      orderBy(
        [
          {
            __typename: 'ScheduledMessageEdge',
            node: {
              ...scheduledMessage,
            },
          },
          ...cachedChatHistory.scheduledMessages.edges,
        ],
        [
          edge =>
            edge.node.createdOn ? new Date(edge.node.createdOn) : new Date(),
        ],
        ['desc'],
      ),
      edge => edge.node.id,
    );
  }

  const { activePhoneCallsWithoutMessages } = cachedChatHistory;

  if (message?.call) {
    newActivePhoneCallsWithoutMessages = activePhoneCallsWithoutMessages.filter(
      (call: loadChatHistory$activePhoneCallsWithoutMessages) =>
        call.id !== message.call?.id,
    );
  }

  await client.writeQuery({
    query: LOAD_CHAT_HISTORY,
    variables: chatHistoryQueryVariables,
    data: {
      activePhoneCallsWithoutMessages: newActivePhoneCallsWithoutMessages,
      conversation: {
        ...cachedChatHistory.conversation,
      },
      messages: {
        ...cachedChatHistory.messages,
        edges: message ? newMessageEdges : cachedChatHistory.messages.edges,
      },
      scheduledMessages: scheduledMessage
        ? {
            ...cachedChatHistory.scheduledMessages,
            edges: scheduledMessage
              ? newScheduledMessageEdges
              : cachedChatHistory.scheduledMessages.edges,
          }
        : null,
    },
  });
};
