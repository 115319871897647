import { gql } from '@apollo/client';

export const UPDATE_PLACE_DISRUPTION_SETTINGS = gql`
  mutation UpdatePlaceDisruptionSettings(
    $input: UpdatePlaceDisruptionSettingsInput!
  ) {
    updatePlaceDisruptionSettings(input: $input) {
      place {
        id
        answeringMode
        answeringModeUntil
      }
    }
  }
`;
