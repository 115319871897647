import { gql } from '@apollo/client';

export const AUTO_COMPLETE_SHORTCUTS = gql`
  query searchShortcuts(
    $search: String
    $searchTag: String
    $placeId: String!
    $after: String
  ) {
    autocompleteShortcuts(
      placeId: $placeId
      search: $search
      searchTag: $searchTag
      first: 25
      sort: name_asc
      after: $after
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          tag
          content
        }
      }
    }
  }
`;
