import { gql } from '@apollo/client';

export const UPDATE_DEFAULT_VIEW = gql`
  mutation UpdateDefaultView($input: UpdateDefaultViewInput!) {
    updateDefaultView(input: $input) {
      user {
        id
        defaultView
      }
    }
  }
`;
