import { FB_INIT, FB_LOGIN, FB_LOGIN_STATUS } from '~/actions/facebookLogin';

export const FACEBOOK_LOGIN_INITIAL_STATE = {
  authResponse: undefined,
  status: undefined,
  initialized: false,
};

export default function FacebookLoginReducer(
  state = FACEBOOK_LOGIN_INITIAL_STATE,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  action,
) {
  switch (action.type) {
    case FB_INIT:
      return {
        ...state,
        initialized: action.payload.initialized,
      };

    case FB_LOGIN_STATUS:
    case FB_LOGIN:
      return {
        ...state,
        authResponse: action.payload.authResponse,
        status: action.payload.status,
      };

    default:
      return state;
  }
}
