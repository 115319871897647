import { gql } from '@apollo/client';

export const UPDATE_ASSIGNMENT_ROUTING_SETTINGS = gql`
  mutation updateAssignmentRoutingSettings(
    $input: UpdateAssignmentRoutingSettingsInput!
  ) {
    updateAssignmentRoutingSettings(input: $input) {
      assignmentRoutingSettings {
        id
        enabled
        forwardingMdn
        enabledDuringBusinessHours
        enabledAfterBusinessHours
        enabledWhenAway
        callTimeout
        useNumaCallerId
      }
    }
  }
`;
