import {
  ENABLE_YELP,
  RECEIVE_YELP_REVIEW,
  SEND_TO_ASSISTANT,
  GET_TRANSACTIONS,
  GET_APP_DIRECTORY,
  GET_DEMO,
  RESET_DEMO_ACCOUNT,
  RESET_DEMO_ACCOUNT_FAILED,
} from '~/actions/demo';

export const DEMO_INITIAL_STATE = {
  didReset: false,
  resetInfo: null,
  active: false,
  assistant: {
    lastMessageId: null,
    lastQuestion: null,
    lastAnswer: null,
    assistantVisible: false,
    trained: false,
  },

  customers: [
    {
      name: 'andy',
      uuid: 'f123123123',
      location: {
        lat: '',
        long: '',
      },

      transactions: {
        averageBasket: 5,
      },
    },
  ],

  aggregates: {
    averageBasket: 2,
  },

  yelpEnabled: false,
  yelpConversation: null,
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const DemoReducer = (state = DEMO_INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_DEMO_ACCOUNT:
      return {
        ...state,
        didReset: true,
        resetFailed: false,
        resetInfo: action.payload.data,
      };

    case RESET_DEMO_ACCOUNT_FAILED:
      return {
        ...state,
        didReset: true,
        resetFailed: true,
        resetInfo: action.payload.response.statusText,
      };

    case SEND_TO_ASSISTANT:
      return {
        ...state,
        assistant: action.payload,
      };

    case GET_TRANSACTIONS:
      return {
        ...state,
        averageBasket: 5,
      };

    case ENABLE_YELP:
      return {
        ...state,
        yelpEnabled: true,
      };

    case RECEIVE_YELP_REVIEW:
      return {
        ...state,
        yelpConversation: {
          id: 'foo',
          kind: 'yelp',
          sender: {
            id: 'bar',
            address: 'Yelp',
          },

          last_message: {
            timestamp: new Date().toString(),
            content: 'You received a Yelp Review!',
            title: 'A new Yelp review was posted.',
          },
        },
      };

    case GET_DEMO:
      // Gets the intiial dummy data for the demo state
      return {
        ...state,
      };

    case GET_APP_DIRECTORY:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default DemoReducer;
