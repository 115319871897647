import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import type { PaginatedQuery, Connection } from '../../types';

export const concatEdges = <C extends Record<string, any>, S extends string>(
  previous: PaginatedQuery<C, S>,
  next: PaginatedQuery<C, S> | null | undefined,
  key: S,
  prepend = false,
): Connection<C> | null | undefined => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'edges' does not exist on type 'Paginated... Remove this comment to see the full error message
  if (!previous[key] || !next || !next[key] || !previous[key].edges) {
    return previous[key];
  }

  const edges = prepend
    ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'edges' does not exist on type 'Paginated... Remove this comment to see the full error message
      [...next[key].edges, ...previous[key].edges]
    : // @ts-expect-error ts-migrate(2339) FIXME: Property 'edges' does not exist on type 'Paginated... Remove this comment to see the full error message
      [...previous[key].edges, ...next[key].edges];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    ...previous[key],
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'pageInfo' does not exist on type 'Pagina... Remove this comment to see the full error message
    pageInfo: next[key].pageInfo,
    edges: uniqBy(compact(edges), edge => edge.node.id),
  };
};
