import { Components, Theme } from '@mui/material';

export const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      margin: 0,
    }),
  },
};
