import { gql } from '@apollo/client';

export const REQUEST_GOOGLE_MESSAGING_AGENT = gql`
  mutation requestGoogleMessagingAgent(
    $input: RequestGoogleMessagingAgentInput
  ) {
    requestGoogleMessagingAgent(input: $input) {
      googleAgent {
        id
        accountId
        agentId
        brandId
        contactEmail
        websiteUrl
        status
      }
    }
  }
`;
