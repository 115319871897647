import { gql } from '@apollo/client';

export const AdminAccountFragment = gql`
  fragment AdminAccountFields on AccountQL {
    __typename
    id
    name
    businessVertical
    billingIdentity {
      id
      signupMode
    }
  }
`;
