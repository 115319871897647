import { gql } from '@apollo/client';
import { MessageItemFragment } from '../../fragments/gql/messageItem.gql';

export const UPDATE_LOGGED_CALL = gql`
  mutation updateLoggedCall($input: UpdateLoggedCallInput!) {
    updateLoggedCall(input: $input) {
      message {
        ...MessageItemFields
      }
    }
  }
  ${MessageItemFragment}
`;
