import { gql } from '@apollo/client';

export const PaymentRequestStub = gql`
  fragment PaymentRequestStubFields on PaymentRequestQL {
    id
    encodedId
    status
    lastUpdated
    captureMethod
    amount
    amountCaptured
    currency
    description
    place {
      id
      name
    }
    sender {
      id
      displayName
    }
    customer {
      id
      displayName
      displayNameConfidence
      address
      source
    }
    canceledBy {
      id
      displayName
    }
    conversation {
      id
    }
    totalRefundedAmount
    netAmount
  }
`;

export const PaymentRequestFragment = gql`
  fragment PaymentRequestFields on PaymentRequestQL {
    id
    encodedId
    captureMethod
    amount
    amountCaptured
    currency

    applicationId
    description
    note
    orderId
    status
    lastUpdated
    createdOn
    lastOpened
    charged
    requested
    canceledAt
    cancellationReason
    holdExpiration

    billingName
    billingAddress
    billingEmail
    billingMethod
    billingLastFourDigits

    totalRefundedAmount
    netAmount

    failureCode
    failureMessage
    outcomeSellerMessage
    outcomeType
    outcomeReason

    stripeUrl
    receiptUrl

    attachmentUrl
    attachmentFilename

    refunds {
      id
      completedOn
      status
      amount
      netAmount
      type
      reason
      note
      issuer {
        id
        displayName
      }
    }

    disputes {
      id
      reason
      status
    }

    place {
      id
      name
    }
    sender {
      id
      displayName
    }
    customer {
      id
      displayName
      displayNameConfidence
      address
      source
    }
    canceledBy {
      id
      displayName
    }
    conversation {
      id
    }
  }
`;
