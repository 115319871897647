import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const UPDATE_AUTOMATION_ENROLLMENT_CRITERIA = gql`
  mutation updateAutomationEnrollmentCriteria(
    $input: UpdateAutomationEnrollmentCriteriaInput!
  ) {
    updateAutomationEnrollmentCriteria(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
