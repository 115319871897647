import { gql } from '@apollo/client';

export const PUBLISH_DISRUPTION_VOICE_SCRIPT = gql`
  mutation publishDisruptionVoiceScript(
    $input: PublishDisruptionVoiceScriptInput!
  ) {
    publishDisruptionVoiceScript(input: $input) {
      place {
        id
        answeringMode
        answeringModeUntil
        disruptions {
          id
          disruptionDepartmentVoiceScriptId
          draftDisruptionDepartmentVoiceScriptId
          disruptionStaffVoiceScriptId
          draftDisruptionStaffVoiceScriptId
          holidayDepartmentVoiceScriptId
          draftHolidayDepartmentVoiceScriptId
          holidayStaffVoiceScriptId
          draftHolidayStaffVoiceScriptId
        }
      }
    }
  }
`;
