import { gql } from '@apollo/client';

export const CREATE_APPOINTMENT_AGENT_SETTINGS = gql`
  mutation createAppointmentAgentSettings(
    $input: CreateAppointmentAgentSettingsInput!
  ) {
    createAppointmentAgentSettings(input: $input) {
      appointmentAgentSettings {
        id
        name
        settings
      }
    }
  }
`;
