import { gql } from '@apollo/client';
import { NOTE_TAKER_FRAGMENT } from '../../fragments/gql/noteTaker.gql';

export const GET_NOTE_TAKER = gql`
  query getNoteTaker($id: String!) {
    noteTaker(id: $id) {
      ...NoteTakerFields
    }
  }
  ${NOTE_TAKER_FRAGMENT}
`;
