import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import type {
  ApiCreateHostedSmsPortRequest,
  ApiCreateHostedSmsPortResponse,
  ApiFetchHostedSmsPortRequest,
  ApiFetchHostedSmsPortResponse,
  ApiUpdateHostedSmsPortRequest,
  ApiUpdateHostedSmsPortResponse,
} from '@numbox/services';
import { HostedSmsService } from '@numbox/services';

import type { Meta, ExtractReturn, ApiActionCreators } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const fetchHostedSmsPort: ApiActionCreators<
  'FETCH_HOSTED_SMS_PORT.REQUEST',
  ApiFetchHostedSmsPortRequest,
  'FETCH_HOSTED_SMS_PORT.SUCCESS',
  ApiFetchHostedSmsPortResponse,
  Meta
> = {
  request: (params: ApiFetchHostedSmsPortRequest, meta) => ({
    type: 'FETCH_HOSTED_SMS_PORT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_HOSTED_SMS_PORT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_HOSTED_SMS_PORT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchHostedSmsPort(
  action: ExtractReturn<typeof fetchHostedSmsPort.request>,
): Generator<any, void, void> {
  yield serviceCall(
    HostedSmsService.fetchHostedSmsPort,
    fetchHostedSmsPort,
    action.payload,
    action.meta,
  );
}
export const createHostedSmsPort: ApiActionCreators<
  'CREATE_HOSTED_SMS_PORT.REQUEST',
  ApiCreateHostedSmsPortRequest,
  'CREATE_HOSTED_SMS_PORT.SUCCESS',
  ApiCreateHostedSmsPortResponse,
  Meta
> = {
  request: (params: ApiCreateHostedSmsPortRequest, meta) => ({
    type: 'CREATE_HOSTED_SMS_PORT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'CREATE_HOSTED_SMS_PORT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'CREATE_HOSTED_SMS.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onCreateHostedSmsPortRequest(
  action: ExtractReturn<typeof createHostedSmsPort.request>,
): Generator<any, void, void> {
  yield serviceCall(
    HostedSmsService.createHostedSmsPort,
    createHostedSmsPort,
    action.payload,
    action.meta,
  );
}

export const updateHostedSmsPort: ApiActionCreators<
  'UPDATE_HOSTED_SMS_PORT.REQUEST',
  ApiUpdateHostedSmsPortRequest,
  'UPDATE_HOSTED_SMS_PORT.SUCCESS',
  ApiUpdateHostedSmsPortResponse,
  Meta
> = {
  request: (params: ApiUpdateHostedSmsPortRequest, meta) => ({
    type: 'UPDATE_HOSTED_SMS_PORT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'UPDATE_HOSTED_SMS_PORT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'UPDATE_HOSTED_SMS_PORT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onUpdateHostedSmsPortRequest(
  action: ExtractReturn<typeof updateHostedSmsPort.request>,
): Generator<any, void, void> {
  yield serviceCall(
    HostedSmsService.updateHostedSmsPort,
    updateHostedSmsPort,
    action.payload,
    action.meta,
  );
}

export function* HostedSmsSagas(): Saga<any> {
  yield all([
    takeEvery('CREATE_HOSTED_SMS_PORT.REQUEST', onCreateHostedSmsPortRequest),
    takeEvery('FETCH_HOSTED_SMS_PORT.REQUEST', onFetchHostedSmsPort),
    takeEvery('UPDATE_HOSTED_SMS_PORT.REQUEST', onUpdateHostedSmsPortRequest),
  ]);
}
