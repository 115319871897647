import { gql } from '@apollo/client';

export const UPDATE_DEFAULT_YOU_VIEW = gql`
  mutation UpdateDefaultYouView($input: UpdateDefaultYouViewInput!) {
    updateDefaultYouView(input: $input) {
      user {
        id
        defaultYouView
      }
    }
  }
`;
