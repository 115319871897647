import { gql } from '@apollo/client';

export const GET_STATUS_BOARD_USERS_FOR_LEADERBOARD = gql`
  query statusBoardUsersForLeaderboard($placeId: String!) {
    placesUsers(placeId: $placeId) {
      id
      placeId
      user {
        id
        displayName
        jobRole
        statusBoardView
        isAway
        statusLeaderboardColors(placeId: $placeId) {
          green
          yellow
          red
        }
      }
    }
  }
`;
