import { gql } from '@apollo/client';

export const GET_CURBSIDE_SETTINGS = gql`
  query getCurbsideSettings($placeId: String!) {
    place(id: $placeId) {
      id
      curbsideSettings {
        enabled
        orderIdentifierType
        orderIdentifierIntentResponse
        customHereIsAutoreply
        handoffMethod
        handoffMethodIntentResponse
        customHandoffIsAutoreply
      }
    }
  }
`;
