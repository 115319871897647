import { gql } from '@apollo/client';

import { ParticipantFragment } from './participant.gql';

export const ScheduledMessageFragment = gql`
  fragment ScheduledMessageFields on ScheduledMessageQL {
    id
    createdOn
    content
    sendAt
    status
    deliveryAttempted
    channelId
    conversationId
    sender {
      ...ParticipantFields
    }
  }
  ${ParticipantFragment}
`;
