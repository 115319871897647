/* global FB */

import { authorizeFacebook } from './numboxClient';

export const FB_INIT = 'FB_INIT';
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'appId' implicitly has an 'any' type.
export function initializeFacebook(appId) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return dispatch => {
    console.log('initializeFacebook', appId);
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'FB'.
    FB.init({
      appId,
      xfbml: false,
      version: 'v8.0',
    });

    dispatch({
      type: FB_INIT,
      payload: {
        initialized: true,
      },
    });
  };
}
export const FB_LOGIN_STATUS = 'FB_LOGIN_STATUS';
export function getLoginStatus() {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return dispatch => {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'FB'.
    FB.getLoginStatus(response => {
      dispatch({
        type: FB_LOGIN_STATUS,
        payload: response,
      });

      if (response.status === 'connected') {
        dispatch(authorizeFacebook(response.authResponse.accessToken));
      }
    });
  };
}

export const FB_LOGIN = 'FB_LOGIN';
export function login() {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return dispatch => {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'FB'.
    FB.login(
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      response => {
        dispatch({
          type: FB_LOGIN,
          payload: response,
        });

        if (response.status === 'connected') {
          dispatch(authorizeFacebook(response.authResponse.accessToken));
        }
      },
      {
        scope:
          'pages_read_engagement,pages_manage_metadata,pages_show_list,pages_messaging',
      },
    );
  };
}
