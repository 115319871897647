import { gql } from '@apollo/client';

export const GET_CURRENT_ACCOUNT = gql`
  query getCurrentAccount {
    currentAccount {
      id
      createdOn
      name
      useNewNumber
      billingIdentity {
        id
        brand
        billingOrderId
        paymentSource
      }
    }
  }
`;
