import { gql } from '@apollo/client';
import { NOTE_TAKER_FRAGMENT } from '../../fragments/gql/noteTaker.gql';

export const ADD_NOTE_TAKER = gql`
  mutation createNoteTaker($input: CreateNoteTakerInput!) {
    createNoteTaker(input: $input) {
      noteTaker {
        ...NoteTakerFields
      }
    }
  }
  ${NOTE_TAKER_FRAGMENT}
`;
