import { gql } from '@apollo/client';
import { AutopilotFragment } from '../../fragments/gql/Autopilot.gql';

export const GET_AUTOPILOT_FOR_CONVERSATION = gql`
  query getAutopilotForConversation($conversationId: String!) {
    autopilot(conversationId: $conversationId) {
      ...AutopilotFields
    }
  }
  ${AutopilotFragment}
`;
