import { gql } from '@apollo/client';

export const PUBNUB_STRIPE_INTEGRATION_UPDATED = gql`
  mutation PubNubStripeIntegrationUpdated(
    $stripe_integration: PubNubStripeIntegrationModel!
  ) {
    PubNubStripeIntegrationUpdated(stripe_integration: $stripe_integration)
      @client
  }
`;
