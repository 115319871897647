import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import type { Meta, ApiAction, ExtractReturn } from '@numbox/modules';
import type {
  ApiAuthResponse,
  ApiFetchAccountsResponse,
  ApiAccount,
  ApiPlace,
  ApiUser,
  ApiChannel,
} from '@numbox/services';

import { UserModule, PlaceModule } from '@numbox/modules';

import type {
  SelectAccountAction,
  AdminAccountSelectedAction,
  SelectPlaceAction,
  AdminPlaceSelectedAction,
  UpdatePlaceNameAction,
  UserAddedToTeamAction,
  UserRemovedFromTeamAction,
  TeamCreatedAction,
  SetPlacesAction,
} from '~/actions/inbox';

import {
  LOAD_FACEBOOK_CONFIG,
  AUTHORIZE_FACEBOOK,
  CREATE_FACEBOOK_CHANNEL,
  TOGGLE_CHANNEL,
} from '~/actions/numboxClient';

import type {
  SelectInboxAction,
  EnterInboxAction,
  LeaveInboxAction,
} from '../modules/inbox/inbox.actions';

type AuthPayload = {
  account: ApiAccount;
  teams: ApiPlace[];
  user: ApiUser;
};

type AuthUserAction = {
  type: 'AUTH_USER.SUCCESS';
  payload: AuthPayload;
};

type RefreshTokenAction = {
  type: 'REFRESH_TOKEN.SUCCESS';
  payload: AuthPayload;
};

export type StoreAuth0TokenAction = {
  type: 'STORE_AUTH0_TOKEN';
  payload: ApiAuthResponse;
};

export type RefreshAuth0TokenAction = {
  type: 'REFRESH_AUTH0_TOKEN';
  payload: ApiAuthResponse;
};

type UpdatePasswordAction = {
  type: 'UPDATE_PASSWORD.SUCCESS';
  payload: AuthPayload;
};

export type AccountReducerState = {
  list: ApiAccount[];
  selected: ApiAccount | null | undefined;
};

type AccountReducerActions =
  | ApiAction<'FETCH_ACCOUNT_LIST.SUCCESS', ApiFetchAccountsResponse, Meta>
  | AuthUserAction
  | RefreshTokenAction
  | SelectAccountAction
  | AdminAccountSelectedAction
  | UpdatePasswordAction
  | ExtractReturn<typeof UserModule.verifyUser.success>
  | StoreAuth0TokenAction
  | RefreshAuth0TokenAction;

export const ACCOUNTS_INITIAL_STATE = {
  list: [],
  selected: null,
};

export function AccountReducer(
  state: AccountReducerState = ACCOUNTS_INITIAL_STATE,
  action: AccountReducerActions,
) {
  switch (action.type) {
    case 'UPDATE_PASSWORD.SUCCESS':
    case 'REFRESH_TOKEN.SUCCESS':
    case 'VERIFY_USER.SUCCESS':
    case 'STORE_AUTH0_TOKEN':
    case 'REFRESH_AUTH0_TOKEN':
    case 'AUTH_USER.SUCCESS': {
      const { account } = action.payload;
      return {
        ...state,
        list: [account],
        selected: account,
      };
    }
    case 'FETCH_ACCOUNT_LIST.SUCCESS':
      return {
        ...state,
        list: action.payload,
        selected: action.payload[0],
      };

    case 'SELECT_ACCOUNT':
      return {
        ...state,
        selected: action.payload,
      };

    case 'SELECT_ACCOUNT_ADMIN':
      return {
        ...state,
        selected: { id: action.payload.accountId },
      };

    default: // eslint-disable-line no-unused-expressions
      action as never;
      return state;
  }
}

export type PlaceReducerState = {
  list: ApiPlace[];
  selected: ApiPlace | null | undefined;
  selectedBeforeModal: ApiPlace | null | undefined;
};

type PlaceReducerActions =
  | AuthUserAction
  | SelectPlaceAction
  | AdminPlaceSelectedAction
  | RefreshTokenAction
  | UpdatePasswordAction
  | SelectInboxAction
  | UpdatePlaceNameAction
  | UserAddedToTeamAction
  | UserRemovedFromTeamAction
  | TeamCreatedAction
  | ExtractReturn<typeof UserModule.verifyUser.success>
  | ExtractReturn<typeof PlaceModule.updatePlaceHours.request>
  | ExtractReturn<typeof PlaceModule.updatePlaceHours.success>
  | EnterInboxAction
  | LeaveInboxAction
  | StoreAuth0TokenAction
  | RefreshAuth0TokenAction
  | SetPlacesAction;

export const PLACE_INITIAL_STATE = {
  list: [],
  selected: null,
  selectedBeforeModal: null,
};

export function PlaceReducer(
  state: PlaceReducerState = PLACE_INITIAL_STATE,
  action: PlaceReducerActions,
) {
  switch (action.type) {
    case 'SET_PLACES':
    case 'UPDATE_PASSWORD.SUCCESS':
    case 'REFRESH_TOKEN.SUCCESS':
    case 'VERIFY_USER.SUCCESS':
    case 'STORE_AUTH0_TOKEN':
    case 'REFRESH_AUTH0_TOKEN':
    case 'AUTH_USER.SUCCESS': {
      const { teams } = action.payload;
      return {
        ...state,
        list: teams,
        selected: teams[0],
      };
    }
    case 'SELECT_INBOX': {
      // XXX - We do not have the place switcher in the
      // settings view at this time, so settings are always scoped to
      // a single place. We want to remove
      // the explicit place switcher from the global nav, but we need
      // a way to view a different place's settings. So, tie the
      // active place to the currently selected inbox. This
      // will stop working once we have collections, but it's an interim solution.
      const { inboxId } = action.payload;
      return {
        ...state,
        selected: state.list.find(t => t.id === inboxId),
      };
    }
    case 'SELECT_PLACE':
      return {
        ...state,
        selected: state.list.find(t => t.id === action.payload),
      };

    case 'SELECT_PLACE_ADMIN':
      return {
        ...state,
        selected: { id: action.payload.placeId },
      };

    case 'ENTER_INBOX':
      if (state.selectedBeforeModal) {
        return {
          ...state,
          selected: state.selectedBeforeModal,
          selectedBeforeModal: null,
        };
      }
      return state;

    case 'LEAVE_INBOX':
      return {
        ...state,
        selectedBeforeModal: state.selected,
      };

    case 'UPDATE_PLACE_HOURS.REQUEST': {
      const { placeId, hours } = action.payload;
      const newState = cloneDeep(state);

      if (hours) {
        if (newState.list) {
          newState.list = newState.list.map(place => {
            if (place.id !== placeId) {
              return place;
            }

            return {
              ...place,
              hours,
            };
          });
        }

        if (newState.selected && newState.selected.id === placeId) {
          newState.selected.hours = hours;
        }
      }

      return newState;
    }
    case 'UPDATE_PLACE_HOURS.SUCCESS': {
      const list = action.payload;
      if (!list) return state;

      let selected;
      const prevSelectedId = state.selected && state.selected.id;
      if (prevSelectedId) {
        selected = list.find(t => t.id === prevSelectedId);
      }

      return {
        list,
        selected,
      };
    }
    case 'UPDATE_PLACE_NAME': {
      const { placeId, name } = action.payload;
      const newState = cloneDeep(state);
      newState.list = newState.list.map(t => {
        if (t.id !== placeId) {
          return t;
        }
        return {
          ...t,
          name,
        };
      });
      if (newState.selected && newState.selected.id === placeId) {
        newState.selected.name = name;
      }
      return newState;
    }

    case 'USER_ADDED_TO_TEAM': {
      const { placeId, teamId } = action.payload;
      const newState = cloneDeep(state);
      const targetPlace = newState.list.find(place => place.id === placeId);
      const targetTeam = targetPlace?.teams.find(team => team.id === teamId);
      const updatedTeam = targetTeam && {
        ...targetTeam,
        user_count: targetTeam.user_count + 1,
      };

      if (!targetPlace || !updatedTeam) {
        return state;
      }

      newState.list = newState.list.map(place => {
        if (place.id !== placeId) {
          return place;
        }

        return {
          ...targetPlace,
          teams: sortBy(
            [
              ...targetPlace.teams.filter(team => team.id !== teamId),
              { ...updatedTeam },
            ],

            team => team.name,
          ),
        };
      });

      return newState;
    }
    case 'USER_REMOVED_FROM_TEAM': {
      const { placeId, teamId } = action.payload;
      const newState = cloneDeep(state);
      const targetPlace = newState.list.find(place => place.id === placeId);
      const targetTeam = targetPlace?.teams.find(team => team.id === teamId);
      const updatedTeam = targetTeam && {
        ...targetTeam,
        user_count: targetTeam.user_count - 1,
      };

      if (!targetPlace || !updatedTeam) {
        return state;
      }

      newState.list = newState.list.map(place => {
        if (place.id !== placeId) {
          return place;
        }
        return {
          ...targetPlace,
          teams: sortBy(
            [
              ...targetPlace.teams.filter(team => team.id !== teamId),
              { ...updatedTeam },
            ],

            team => team.name,
          ),
        };
      });

      return newState;
    }
    case 'TEAM_CREATED': {
      const { placeId, team } = action.payload;
      const newState = cloneDeep(state);
      const targetPlace = newState.list.find(place => place.id === placeId);

      if (!targetPlace) {
        return state;
      }

      newState.list = newState.list.map(place => {
        if (place.id !== placeId) {
          return place;
        }
        return {
          ...targetPlace,
          teams: sortBy(
            [...targetPlace.teams.filter(t => t.id !== team.id), team],
            t => t.name,
          ),
        };
      });

      return newState;
    }
    default: // eslint-disable-line no-unused-expressions
      action as never;
      return state;
  }
}

export const CHANNEL_INITIAL_STATE = {
  channelsByPlace: {},
  selectedPlaceId: null,
};

export type ChannelReducerState = {
  channelsByPlace: Record<string, Array<ApiChannel>>;
  selectedPlaceId: string | null | undefined;
};

type ChannelReducerActions = any;

export function ChannelReducer(
  state: ChannelReducerState = CHANNEL_INITIAL_STATE,
  action: ChannelReducerActions,
) {
  switch (action.type) {
    case 'UPDATE_PASSWORD.SUCCESS':
    case 'REFRESH_TOKEN.SUCCESS':
    case 'VERIFY_USER.SUCCESS':
    case 'STORE_AUTH0_TOKEN':
    case 'REFRESH_AUTH0_TOKEN':
    case 'AUTH_USER.SUCCESS': {
      const { teams } = action.payload;
      const channelsByPlace = {};
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 't' implicitly has an 'any' type.
      teams.forEach(t => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        channelsByPlace[t.id] = t.channels;
      });

      return {
        channelsByPlace,
        selectedPlaceId: teams[0].id,
      };
    }
    case 'SELECT_PLACE':
      return {
        ...state,
        selectedPlaceId: action.payload,
      };

    case CREATE_FACEBOOK_CHANNEL: {
      const newChannel = action.payload;
      const { selectedPlaceId } = state;
      if (!selectedPlaceId) {
        return state;
      }
      const channelsByPlace = cloneDeep(state.channelsByPlace);
      channelsByPlace[selectedPlaceId] = [
        ...channelsByPlace[selectedPlaceId],
        newChannel,
      ];

      return {
        ...state,
        channelsByPlace,
      };
    }
    case AUTHORIZE_FACEBOOK: {
      const { selectedPlaceId } = state;
      if (!selectedPlaceId) {
        return state;
      }
      const channelsUpdated = action.payload.channels;
      const channelsByPlace = cloneDeep(state.channelsByPlace);
      channelsByPlace[selectedPlaceId] = channelsByPlace[selectedPlaceId].map(
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'c' implicitly has an 'any' type.
        channel => channelsUpdated.find(c => c.id === channel.id) || channel,
      );

      return {
        ...state,
        channelsByPlace,
      };
    }
    case TOGGLE_CHANNEL: {
      const channelChanged: ApiChannel = action.payload;

      if (!state.selectedPlaceId) {
        return state;
      }
      const currentChannels = state.channelsByPlace[state.selectedPlaceId];
      const newEntries: Array<ApiChannel> = currentChannels.map(c => {
        if (c.id === channelChanged.id) {
          return channelChanged;
        }
        return c;
      });
      let selectedPlaceEntries = {};
      if (state.selectedPlaceId) {
        selectedPlaceEntries = { [state.selectedPlaceId]: newEntries };
      }
      return {
        ...state,
        channelsByPlace: {
          ...state.channelsByPlace,
          ...selectedPlaceEntries,
        },
      };
    }
    default:
      return state;
  }
}

export const FACEBOOK_INITIAL_STATE = {
  appId: null,
  pages: null,
};

export type FacebookIntegrationState = {
  appId: string | null | undefined;
  pages: Array<{ id: string; name: string; token: string }> | null | undefined;
};

export function FacebookIntegrationReducer(
  state: FacebookIntegrationState = FACEBOOK_INITIAL_STATE,
  action: any,
) {
  switch (action.type) {
    case LOAD_FACEBOOK_CONFIG:
      return {
        ...state,
        appId: action.payload.appId,
      };

    case AUTHORIZE_FACEBOOK:
      return {
        ...state,
        pages: action.payload.pages,
      };

    default:
      return state;
  }
}

export const DELIVERY_STATUS = {
  FAILED: 'failed',
  PENDING: 'pending',
  SENT: 'sent',
  DELIVERED: 'delivered',
  INPROGRESS: 'inprogress',
};
