import { gql } from '@apollo/client';

export const GET_USER_MFA_SETTINGS = gql`
  query getUserMfaSettings($userId: String!) {
    user(userId: $userId) {
      id
      enforceMfa
    }
  }
`;
