import { gql } from '@apollo/client';
import { ChannelFieldsFragment } from '../../fragments/gql/channelFields.gql';
import { PhoneLineFieldsFragment } from '../../fragments/gql/PhoneLineFields.gql';

export const PORT_IN_PHONELINE = gql`
  mutation portInPhoneLine($input: PortInPhoneLineInput!) {
    portInPhoneLine(input: $input) {
      phoneline {
        ...PhoneLineFields
      }
      channel {
        ...ChannelFields
      }
    }
  }
  ${PhoneLineFieldsFragment}
  ${ChannelFieldsFragment}
`;
