import { Components, Theme } from '@mui/material';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  defaultProps: {},
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
    }),
  },
};
