import { gql } from '@apollo/client';

export const GET_TYPING_INDICATORS_CONFIG = gql`
  query getTypingIndicatorTimeouts {
    pubnub {
      typingIndicator {
        sendStillTyping
        inactivityTimeout
        discardEventTimeout
        typingIndicatorChannel
      }
    }
  }
`;
