import { gql } from '@apollo/client';
import { StageStub } from '../../fragments/gql/Stage.gql';

export const UPDATE_STAGE = gql`
  mutation updateStage($input: UpdateStageInput!) {
    updateStage(input: $input) {
      stage {
        ...StageStub
      }
    }
  }
  ${StageStub}
`;
