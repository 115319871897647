import { createSelector } from 'reselect';

import type { WebReducerState } from '../reducers/types';

export const getPlaces = (state: WebReducerState) => state.places.list;

export const getSelectedPlace = (state: WebReducerState) =>
  state.places ? state.places.selected : null;

export const getSelectedPlaceId = createSelector([getSelectedPlace], place =>
  place ? place.id : null,
);

export const getPlaceById = (state: WebReducerState, id: string) =>
  getPlaces(state).find(t => t.id === id);
