import { gql } from '@apollo/client';

export const GET_ACTIVE_INBOX = gql`
  query getActiveInbox {
    activeInbox @client {
      inboxId
      state
      sort
      options {
        unreadOnly
      }
    }
  }
`;
