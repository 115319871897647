import { gql } from '@apollo/client';

export const GET_PLACE_MANAGERS = gql`
  query getPlaceManagers($placeId: String!) {
    place(id: $placeId) {
      id
      managers {
        id
        displayName
        isAway
      }
    }
  }
`;
