import axios from 'axios';
import cookie from 'react-cookie';

export const setApiAccessToken = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const NINETY_DAYS_IN_SECONDS = 60 * 60 * 24 * 90;

export const cacheTokens = (refreshToken: string, accessToken: string) => {
  cookie.save('refresh_token_cookie', refreshToken, {
    path: '/',
    // Set an explicit age on the token, otherwise it'll default to a session
    // token.
    maxAge: NINETY_DAYS_IN_SECONDS,
  });

  setApiAccessToken(accessToken);
};

export async function wipeCredentials() {
  await cookie.remove('refresh_token_cookie', { path: '/' });
}

export const userLoggedIn = (): string | null | undefined =>
  cookie.load('refresh_token_cookie');
