import { gql } from '@apollo/client';
import { ConversationItemStub } from '../../fragments/gql/conversationItem.gql';

export const RESET_CONVERSATION_LANGUAGE = gql`
  mutation resetConversationLanguage($input: ResetConversationLanguageInput!) {
    resetConversationLanguage(input: $input) {
      conversation {
        ...ConversationItemStub
      }
    }
  }
  ${ConversationItemStub}
`;
