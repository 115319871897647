import type {
  ApiAppliedIntent,
  ApiCustomIntent,
  ApiLibraryIntent,
} from '@numbox/services';

import type { ExtractReturn } from '@numbox/modules';
import { IntentsModule } from '@numbox/modules';
import get from 'lodash/get';

import type { WebReducerState } from '../reducers/types';

// Types
export type Intent = {
  placeId?: string;
  places?: string[];
  id: string;
  libraryId?: string;
  name: string;
  source: string;
  displayName: string;
  examples: string[];
  response?: string | null | undefined;
  renderedResponse?: string | null | undefined;
  isNumaResponse?: boolean;
  isAutoReply?: boolean;
  category?: string;
  isAppliedIntent: boolean | null | undefined;
  isCustomIntent: boolean;
  isLibraryIntent: boolean;
  isManaged?: boolean;
  managedBy?: string | null | undefined;
  isVisible?: boolean;
};

// Transforms

const hackyManagedBy = (intentName: string): string | null | undefined => {
  switch (intentName) {
    case 'core.hours':
      return 'Hours';
    default:
      return null;
  }
};

const fromApiAppliedIntent = (resource: ApiAppliedIntent): Intent => ({
  placeId: resource.place_id,
  id: resource.id,
  name: resource.name,
  source: resource.source,
  displayName: resource.display_name,
  examples: resource.display_examples || [],
  response: resource.response,
  renderedResponse: resource.rendered_response,
  isAutoReply:
    resource.handling === 'AUTO_SEND' &&
    Boolean(resource.response) &&
    resource.response !== '',
  isNumaResponse: resource.numa_generated_response,
  category: resource.category,
  isVisible: resource.is_visible,
  isAppliedIntent: true,
  isCustomIntent: resource.source === 'CUSTOM',
  // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
  isLibraryIntent: resource.source === 'SYSTEM',
  libraryId: resource.library_intent_id,
  isManaged: resource.is_managed,
  managedBy: hackyManagedBy(resource.name),
});

const fromApiLibraryIntent = (
  resource: ApiLibraryIntent | ApiCustomIntent,
): Intent => ({
  id: resource.id,
  source: resource.source,
  libraryId: resource.id,
  name: resource.name,
  category: resource.category,
  displayName: resource.display_name,
  examples:
    resource.source === 'CUSTOM'
      ? resource.training_examples
      : resource.display_examples,
  isAppliedIntent: undefined,
  isCustomIntent: resource.source === 'CUSTOM',
  isLibraryIntent: resource.source === 'SYSTEM',
  isVisible: resource.is_visible,
});

// Action Creators
export const resetAnswers = () => ({
  type: 'numbox-web/answers/RESET_ANSWERS',
});

// Reducer
export const ANSWERS_INITIAL_STATE = {
  byPlaceId: {},
};

export type AnswerReducerLists = {
  applied: {
    list: Array<Intent>;
    inProgress: boolean;
    error: boolean;
  };

  search: {
    list: Array<Intent>;
    inProgress: boolean;
    error: boolean;
    searchTerm: string | null | undefined;
  };
};

export type AnswersReducerState = {
  byPlaceId: Readonly<Record<string, AnswerReducerLists>>;
};

type AnswersReducerActions =
  | ExtractReturn<typeof IntentsModule.fetchAppliedIntents.request>
  | ExtractReturn<typeof IntentsModule.fetchAppliedIntents.success>
  | ExtractReturn<typeof IntentsModule.fetchAppliedIntents.error>
  | ExtractReturn<typeof IntentsModule.searchLibraryIntents.request>
  | ExtractReturn<typeof IntentsModule.searchLibraryIntents.success>
  | ExtractReturn<typeof IntentsModule.searchLibraryIntents.error>
  | ExtractReturn<typeof IntentsModule.updateAppliedIntent.success>
  | ExtractReturn<typeof IntentsModule.removeAppliedIntent.success>
  | ExtractReturn<typeof IntentsModule.createCustomIntent.success>
  | ExtractReturn<typeof IntentsModule.updateCustomIntent.success>
  | ExtractReturn<typeof IntentsModule.deleteCustomIntent.success>
  | ExtractReturn<typeof IntentsModule.applyCustomIntent.success>
  | ExtractReturn<typeof IntentsModule.applyLibraryIntent.success>
  | {
      type: 'numbox-web/answers/RESET_ANSWERS';
    };

// Reducer Utils
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const getIntentStateList = (state, placeId, listType) => {
  return get(state, `byPlaceId[${placeId}][${listType}].list`, []);
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const findIntent = (state, placeId, listType, matchProp, matchValue) =>
  getIntentStateList(state, placeId, listType).find(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
    i => i[matchProp] === matchValue,
  );

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const findAppliedIntent = (state, placeId, id) =>
  findIntent(state, placeId, 'applied', 'id', id);

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const removeIntent = (state, placeId, listType, matchProp, matchValue) => {
  const newList = [...getIntentStateList(state, placeId, listType)];
  return newList.filter(i => i[matchProp] !== matchValue);
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const addIntent = (state, placeId, listType, intent) => {
  const newList = [...getIntentStateList(state, placeId, listType)];
  newList.push(intent);
  return newList;
};

const updateIntent = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
  state,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'placeId' implicitly has an 'any' type.
  placeId,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'listType' implicitly has an 'any' type.
  listType,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'matchProp' implicitly has an 'any' type... Remove this comment to see the full error message
  matchProp,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'matchValue' implicitly has an 'any' typ... Remove this comment to see the full error message
  matchValue,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'attributes' implicitly has an 'any' typ... Remove this comment to see the full error message
  attributes,
) => {
  const newList = [...getIntentStateList(state, placeId, listType)];
  const match = newList.findIndex(i => i[matchProp] === matchValue);
  if (match >= 0) {
    let prevValue = newList[match];
    prevValue = {
      ...prevValue,
      ...attributes,
    };

    newList[match] = prevValue;
  }
  return newList;
};

const updateStateAnswersListProps = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
  state,
  placeId: string,
  listType: string,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'attributes' implicitly has an 'any' typ... Remove this comment to see the full error message
  attributes,
) => {
  const result = { ...state };

  if (!result.byPlaceId) {
    result.byPlaceId = {};
  }
  if (!result.byPlaceId[placeId]) {
    result.byPlaceId = {
      ...result.byPlaceId,
      [placeId]: {},
    };
  }
  if (!result.byPlaceId[placeId].search) {
    result.byPlaceId = {
      ...result.byPlaceId,
      [placeId]: {
        ...result.byPlaceId[placeId],
        search: {
          list: [],
          inProgress: false,
          error: false,
          searchTerm: null,
        },
      },
    };
  }
  if (!result.byPlaceId[placeId].applied) {
    result.byPlaceId = {
      ...result.byPlaceId,
      [placeId]: {
        ...result.byPlaceId[placeId],
        applied: {
          list: [],
          inProgress: false,
          error: false,
        },
      },
    };
  }

  result.byPlaceId[placeId][listType] = {
    ...result.byPlaceId[placeId][listType],
    ...attributes,
  };

  return result;
};

const AnswersReducer = (
  state: AnswersReducerState = ANSWERS_INITIAL_STATE,
  action: AnswersReducerActions,
) => {
  switch (action.type) {
    case 'numbox-web/answers/RESET_ANSWERS': {
      return ANSWERS_INITIAL_STATE;
    }
    case 'SEARCH_LIBRARY_INTENTS.REQUEST': {
      const { placeId, searchTerm } = action.payload;
      const newState = updateStateAnswersListProps(state, placeId, 'search', {
        searchTerm,
        inProgress: true,
        error: false,
      });

      return newState;
    }
    case 'FETCH_APPLIED_INTENTS.REQUEST': {
      const { placeId } = action.payload;
      const newState = updateStateAnswersListProps(state, placeId, 'applied', {
        list: [],
        inProgress: true,
        error: false,
      });

      return newState;
    }
    case 'GET_APPLIED_INTENT.SUCCESS': {
      const intent = fromApiAppliedIntent(action.payload);
      const { placeId, id } = intent;
      if (!placeId || !id) {
        return state;
      }
      const newState = updateStateAnswersListProps(state, placeId, 'applied', {
        list: updateIntent(state, placeId, 'applied', 'id', id, intent),
      });

      return newState;
    }
    case 'FETCH_APPLIED_INTENTS.SUCCESS': {
      if (!action.payload) return state;
      if (!Array.isArray(action.payload)) return state;
      if (action.payload.length === 0) return state;
      if (!action.payload[0].place_id) return state;
      const placeId = action.payload[0].place_id;
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
      const intents = action.payload.map(i => fromApiAppliedIntent(i));
      const newState = updateStateAnswersListProps(state, placeId, 'applied', {
        list: intents,
        inProgress: false,
        error: false,
      });

      return newState;
    }
    case 'SEARCH_LIBRARY_INTENTS.SUCCESS': {
      const { place_id: placeId, library_intents: libraryItents } =
        action.payload;
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
      let intents = libraryItents.map(i => fromApiLibraryIntent(i));
      // Look and see if they are in applied
      const applied =
        state.byPlaceId[placeId] &&
        state.byPlaceId[placeId].applied &&
        state.byPlaceId[placeId].applied.list;
      if (applied) {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
        intents = intents.map(i => ({
          ...i,
          isAppliedIntent: Boolean(applied.find(a => a.libraryId === i.id)),
        }));
      }

      const newState = updateStateAnswersListProps(state, placeId, 'search', {
        list: intents,
        inProgress: false,
        error: false,
      });

      return newState;
    }
    case 'UPDATE_APPLIED_INTENT.SUCCESS': {
      const intent = fromApiAppliedIntent(action.payload);
      const { placeId, id } = intent;
      if (!placeId || !id) {
        return state;
      }
      const newState = updateStateAnswersListProps(state, placeId, 'applied', {
        list: updateIntent(state, placeId, 'applied', 'id', id, intent),
      });

      return newState;
    }
    case 'UPDATE_APPLIED_INTENT.REQUEST': {
      const { id, placeId, ...attributes } = action.payload;
      const intent = findAppliedIntent(state, placeId, id);
      if (!intent) return state;

      const props = { ...attributes };

      if (props.handling) {
        props.isAutoReply = props.handling === 'AUTO_SEND';
        delete props.handling;
      }

      if (intent.isNumaResponse && props.response && props.response !== '') {
        if (intent.response !== props.response) {
          props.isNumaResponse = false;
        }
      }

      const newState = updateStateAnswersListProps(state, placeId, 'applied', {
        list: updateIntent(state, placeId, 'applied', 'id', id, { ...props }),
      });

      const finalState = updateStateAnswersListProps(
        newState,
        placeId,
        'search',
        {
          list: updateIntent(
            newState,
            placeId,
            'search',
            'libraryId',
            intent.libraryId,
            { ...props },
          ),
        },
      );

      return finalState;
    }
    case 'REMOVE_APPLIED_INTENT.REQUEST': {
      const { id, placeId, libraryId } = action.payload;

      let newState = updateStateAnswersListProps(state, placeId, 'applied', {
        list: removeIntent(state, placeId, 'applied', 'id', id),
      });

      newState = updateStateAnswersListProps(newState, placeId, 'search', {
        list: updateIntent(newState, placeId, 'search', 'id', libraryId, {
          isAppliedIntent: false,
        }),
      });

      return newState;
    }
    case 'UPDATE_CUSTOM_INTENT.REQUEST': {
      const { id, name, training_examples: trainingExamples } = action.payload;

      const props = {};
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type '{}'.
      if (name) props.name = name;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'examples' does not exist on type '{}'.
      if (trainingExamples) props.examples = trainingExamples;

      let newState = {};
      newState = { ...state };
      Object.keys(state.byPlaceId).forEach(placeId => {
        newState = updateStateAnswersListProps(newState, placeId, 'applied', {
          list: updateIntent(state, placeId, 'applied', 'libraryId', id, {
            ...props,
          }),
        });

        newState = updateStateAnswersListProps(newState, placeId, 'search', {
          list: updateIntent(newState, placeId, 'search', 'id', id, {
            ...props,
          }),
        });
      });

      return newState;
    }
    case 'DELETE_CUSTOM_INTENT.REQUEST': {
      const { id } = action.payload;

      let newState = {};
      newState = { ...state };
      Object.keys(state.byPlaceId).forEach(placeId => {
        newState = updateStateAnswersListProps(newState, placeId, 'search', {
          list: removeIntent(state, placeId, 'search', 'id', id),
        });

        newState = updateStateAnswersListProps(newState, placeId, 'applied', {
          list: removeIntent(state, placeId, 'applied', 'libraryId', id),
        });
      });

      return newState;
    }
    case 'APPLY_LIBRARY_INTENT.REQUEST':
    case 'APPLY_CUSTOM_INTENT.REQUEST': {
      const { id, placeId, ...attributes } = action.payload;
      const props = { ...attributes };

      if (!props.response || props.response === '') {
        props.isAutoReply = false;
        if (props.handling) props.handling = 'SUGGEST';
      }
      if (props.handling === 'AUTO_SEND') props.isAutoReply = true;
      delete props.handling;

      const newState = updateStateAnswersListProps(state, placeId, 'search', {
        list: updateIntent(state, placeId, 'search', 'id', id, { ...props }),
      });

      return newState;
    }
    case 'APPLY_LIBRARY_INTENT.SUCCESS':
    case 'APPLY_CUSTOM_INTENT.SUCCESS': {
      const intent = fromApiAppliedIntent(action.payload);
      const { placeId } = intent;
      if (!placeId || !intent) return state;
      const stateWithAppliedIntent = updateStateAnswersListProps(
        state,
        placeId,
        'applied',
        {
          list: addIntent(state, placeId, 'applied', intent),
        },
      );

      const newState = updateStateAnswersListProps(
        stateWithAppliedIntent,
        placeId,
        'search',
        {
          list: [],
        },
      );

      return newState;
    }
    default:
      return state;
  }
};

// Selectors
export const getPlaceIntents = (
  state: WebReducerState,
  placeId: string,
): AnswerReducerLists => {
  const result = state.answers.byPlaceId[placeId];
  return (
    result || {
      applied: {
        list: [],
        error: true,
        inProgress: false,
      },

      search: {
        list: [],
        error: true,
        searchTerm: null,
        inProgress: false,
      },
    }
  );
};

export const getAppliedIntents = (
  state: WebReducerState,
  placeId: string,
): Intent[] => getPlaceIntents(state, placeId).applied.list;

export const getAppliedIntentsProgress = (
  state: WebReducerState,
  placeId: string,
): boolean => getPlaceIntents(state, placeId).applied.inProgress;

export const getAppliedIntentsError = (
  state: WebReducerState,
  placeId: string,
): boolean => getPlaceIntents(state, placeId).applied.error;

export const getLibraryIntentResults = (
  state: WebReducerState,
  placeId: string,
): Intent[] => getPlaceIntents(state, placeId).search.list;

export const getSearchIntentsProgress = (
  state: WebReducerState,
  placeId: string,
): boolean => getPlaceIntents(state, placeId).search.inProgress;

export const getSearchIntentsError = (
  state: WebReducerState,
  placeId: string,
): boolean => getPlaceIntents(state, placeId).search.error;

export const getLibraryIntentSearchTerm = (
  state: WebReducerState,
  placeId: string,
): string | null | undefined =>
  getPlaceIntents(state, placeId).search.searchTerm;

export const getAppliedIntentById = (
  state: WebReducerState,
  placeId: string,
  appliedIntentId: string,
): Intent | null | undefined =>
  getAppliedIntents(state, placeId).find(i => i.id === appliedIntentId);

export const getAppliedIntentByName = (
  state: WebReducerState,
  placeId: string,
  appliedIntentName: string,
): Intent | null | undefined =>
  getAppliedIntents(state, placeId).find(i => i.name === appliedIntentName);

export const getAppliedIntentByLibraryId = (
  state: WebReducerState,
  placeId: string,
  libraryIntentId: string,
): Intent | null | undefined =>
  getAppliedIntents(state, placeId).find(i => i.libraryId === libraryIntentId);

export const getLibraryIntentById = (
  state: WebReducerState,
  placeId: string,
  libraryIntentId: string,
): Intent | null | undefined =>
  getLibraryIntentResults(state, placeId).find(
    i => i.libraryId === libraryIntentId,
  );

export const getLibraryIntentByName = (
  state: WebReducerState,
  placeId: string,
  name: string,
): Intent | null | undefined =>
  getLibraryIntentResults(state, placeId).find(i => i.name === name);

export default AnswersReducer;
