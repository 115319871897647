import { gql } from '@apollo/client';
import { ConversationItemStub } from '../../fragments/gql/conversationItem.gql';

export const GET_UNASSIGNED_CONVERSATIONS = gql`
  query unassignedConversations(
    $first: Int
    $after: String
    $assigneeId: String
    $teamId: String
    $state: String
    $newerThan: ArrowField
    $sort: [ConversationModelSortEnum] = last_updated_desc
    $isOverdue: Boolean
  ) {
    conversations(
      first: $first
      after: $after
      sort: $sort
      queryFilter: {
        state: { equal: $state }
        assigneeId: { equal: $assigneeId }
        teamId: { equal: $teamId }
        lastUpdated: { greaterThan: $newerThan }
        isOverdue: { equal: $isOverdue }
      }
    ) @connection(key: "conversations") {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ConversationItemStub
        }
      }
    }
  }
  ${ConversationItemStub}
`;
