import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import type { WebReducerState } from './types';
import './types';

type ToastState = {
  id: string;
  isVisible: boolean;
};

export type ToastReducerState = {
  toasts: Array<ToastState>;
};

export const showToast = (id: string) => ({
  type: 'SHOW_TOAST',
  id,
});

export const hideToast = (id: string) => ({
  type: 'HIDE_TOAST',
  id,
});

function findToastById(toasts: Array<ToastState>, toastId: string) {
  if (!toasts) {
    return false;
  }

  return toasts.find(toast => toast.id === toastId);
}

export function getToastById(state: WebReducerState, id: string) {
  return findToastById(state.toastNotifications.toasts, id);
}

export const getIsToastVisible: (
  arg0: WebReducerState,
  arg1: string,
) => boolean = createSelector([getToastById], toast =>
  toast ? toast.isVisible : false,
);

export const Toasts = {
  CUSTOMER_ADDED: 'CUSTOMER_ADDED',
};

function initialToastState(toastId: string): ToastState {
  return {
    id: toastId,
    isVisible: false,
  };
}

export const TOASTS_INITIAL_STATE: ToastReducerState = {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  toasts: Object.keys(Toasts).map(key => initialToastState(Toasts[key])),
};

type ToastReducerActions =
  | {
      type: 'SHOW_TOAST';
      id: string;
    }
  | {
      type: 'HIDE_TOAST';
      id: string;
    };

const ToastReducer = (
  state: ToastReducerState = TOASTS_INITIAL_STATE,
  action: ToastReducerActions,
) => {
  switch (action.type) {
    case 'SHOW_TOAST': {
      const match = findToastById(state.toasts, action.id);

      if (!match) {
        return state;
      }

      const toasts = cloneDeep(state.toasts);
      const toast = findToastById(toasts, action.id);

      if (toast) {
        toast.isVisible = true;
      }

      return {
        ...state,
        toasts,
      };
    }

    case 'HIDE_TOAST': {
      const match = findToastById(state.toasts, action.id);

      if (!match) {
        return state;
      }

      const toasts = cloneDeep(state.toasts);
      const toast = findToastById(toasts, action.id);

      if (toast) {
        toast.isVisible = false;
      }

      return {
        ...state,
        toasts,
      };
    }

    default: {
      return state;
    }
  }
};

export default ToastReducer;
