import { gql } from '@apollo/client';
import { AwayModeDetailsFragment } from '../../fragments/gql/AwayModeFragment.gql';

export const GET_AWAY_MODE_STATUS = gql`
  query getAwayModeStatus($userId: String!) {
    user(userId: $userId) {
      ...AwayModeDetailsFields
    }
  }
  ${AwayModeDetailsFragment}
`;
