import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
} & RouteComponentProps;

type State = {};

class ScrollToTop extends React.Component<Props, State> {
  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollToTop);
