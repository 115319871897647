import { PlaceDegradedInboxExperienceFragment } from '../../fragments/gql/PlaceDegradedInboxExperience.gql';
import type { ApolloContext } from '../../types';

export const PubNubDegradedInboxExperienceUpdated = async (
  obj: any,
  args: {
    account_id: string;
    place_id: string;
    has_degraded_inbox_experience: boolean;
  },

  { client }: ApolloContext,
) => {
  client.writeFragment({
    id: `PlaceQL:${args.place_id}`,
    fragment: PlaceDegradedInboxExperienceFragment,
    fragmentName: 'PlaceDegradedInboxExperienceFields',
    data: {
      __typename: 'PlaceQL',
      id: args.place_id,
      hasDegradedInboxExperience: args.has_degraded_inbox_experience,
    },
  });
};
