import { lazy } from 'react';
import { retry } from './promise';

// This will attempt to load the chunk 3 times before failing
const LOAD_RETRIES = 3;
// This will throw an error after refreshing this many times
/*
I've kept this intentially low, since we will attempt to load the chunk
LOAD_RETRIES * (REFRESH_RETRIES + 1) times
so even with only 1 refresh, we are attempting to load the chunk 6 times,
which means the chunk isnt available due to new deploy, or the servers are
down for any other reason
*/
const REFRESH_RETRIES = 1;

/*
Lazy load with retries and refreshes

We want to retry lazy loaded chunks if they fail

We have also seen that people with browsers open with old builds
will navigate and attempt to load chunks from bundles we aren't
hosting anymore.  So if we still cannot load the chunk after retrying,
attempt to refresh the browser to get a new version.

We also don't want to put the browser into a refresh loop if the servers
are unavailable to any other reason. So limit refresh retries.
*/
export const lazyLoad = (fn: () => Promise<any>, key: string): any => {
  const storageKey = `numaLazyLoadRefreshes:${key}`;
  return lazy(
    () =>
      new Promise((resolve, reject) => {
        retry(fn, LOAD_RETRIES)
          .then(val => {
            localStorage.removeItem(storageKey);
            resolve(val);
          })
          .catch(error => {
            const refreshes = localStorage.getItem(storageKey);
            const refreshCount = refreshes ? parseInt(refreshes, 10) : 0;
            if (refreshCount > REFRESH_RETRIES - 1) {
              localStorage.removeItem(storageKey);
              reject(error);
            } else {
              localStorage.setItem(storageKey, `${refreshCount + 1}`);
              window.location.reload();
            }
          });
      }),
  );
};
