import { gql } from '@apollo/client';
import { TeamWithUsersFragment } from '../../fragments/gql/TeamFragment.gql';

export const GET_TEAM_BY_ID = gql`
  query getTeamById($id: String!, $userCount: Int, $afterUser: String) {
    team(teamId: $id) {
      ...TeamWithUsers
      assignmentStyle
    }
  }
  ${TeamWithUsersFragment}
`;
