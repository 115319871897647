export const typography = {
  fontFamily: [
    'Inter',
    '-apple-system',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: 16,
  basefontSize: 16,

  // Base
  button: { fontSize: '1rem' },
  body1: { fontSize: '1rem' },

  // Smaller
  body2: { fontSize: '0.9rem' },
  caption: { fontSize: '0.85rem' },
  overline: { fontSize: '0.8rem' },

  // Larger
  subtitle1: { fontSize: '1.2rem' },
  subtitle2: { fontSize: '1.1rem' },

  // Headings
  h1: { fontSize: '2.5rem' },
  h2: { fontSize: '2rem' },
  h3: { fontSize: '1.75rem' },
  h4: { fontSize: '1.5rem' },
  h5: { fontSize: '1.25rem' },
  h6: { fontSize: '1.1rem' },
};
