import type { ExtractReturn } from '@numbox/modules';
import { SubscriptionsModule } from '@numbox/modules';
import type { ApiSubscription, ApiPaymentProvider } from '@numbox/services';
import '@numbox/services';

import type { WebReducerState } from '../reducers/types';

export type Subscription = {
  name: string;
  usedLicenses: Array<string>;
  quantity: number;
  paymentProvider: ApiPaymentProvider;
};

export type PaymentProvider = ApiPaymentProvider;

const fromSubscriptionApi = (subscription: ApiSubscription): Subscription => ({
  name: subscription.name,
  usedLicenses: subscription.used_licenses,
  quantity: subscription.quantity,
  paymentProvider: subscription.payment_provider,
});

export type SubscriptionReducerState = {
  subscriptions: Array<Subscription>;
  paymentProvider: ApiPaymentProvider | null | undefined;
};

export const SUBSCRIPTION_INITIAL_STATE: SubscriptionReducerState = {
  subscriptions: [],
  paymentProvider: null,
};

type SubscriptionActions = ExtractReturn<
  typeof SubscriptionsModule.fetchSubscriptions.success
>;

const SubscriptionReducer = (
  state: SubscriptionReducerState = SUBSCRIPTION_INITIAL_STATE,
  action: SubscriptionActions,
) => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTIONS.SUCCESS': {
      const subscriptions = action.payload;
      const paymentProvider =
        subscriptions.length > 0 ? subscriptions[0].payment_provider : null;
      return {
        ...state,
        subscriptions: subscriptions.map<Subscription>(fromSubscriptionApi),
        paymentProvider,
      };
    }
    default:
      return state;
  }
};

export default SubscriptionReducer;

export const getSubscriptions = (state: WebReducerState) =>
  state.subscriptions.subscriptions;
export const getSubscriptionPaymentProvider = (state: WebReducerState) =>
  state.subscriptions.paymentProvider;
