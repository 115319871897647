import { gql } from '@apollo/client';

export const ENABLE_NUDGES = gql`
  mutation EnableNudges($input: EnableNudgesInput!) {
    enableNudges(input: $input) {
      currentAccount {
        nudgesEnabled
        nudgesThreshold
      }
    }
  }
`;
