import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const DELETE_VOICE_STEP = gql`
  mutation deleteVoiceStep($input: DeleteVoiceStepInput!) {
    deleteVoiceStep(input: $input) {
      voiceScript {
        ...VoiceScriptFields
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
