import { gql } from '@apollo/client';

export const RENAME_PLACE = gql`
  mutation renamePlace($input: RenamePlaceInput) {
    renamePlace(input: $input) {
      place {
        id
      }
    }
  }
`;
