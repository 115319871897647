import { gql } from '@apollo/client';
import { ConversationItemStub } from '../../fragments/gql/conversationItem.gql';

export const GET_MENTIONED_CONVERSATIONS = gql`
  query mentionedConversations(
    $first: Int
    $after: String
    $unreadOnly: Boolean
  ) {
    mentionedConversations(
      first: $first
      after: $after
      unreadOnly: $unreadOnly
    ) @connection(key: "conversations") {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ConversationItemStub
          lastMentionedTimestamp
          amIMentioned
        }
      }
    }
  }
  ${ConversationItemStub}
`;
