import { gql } from '@apollo/client';

export const DELETE_USER_PHONE = gql`
  mutation deleteUserPhone($input: DeleteUserPhoneInput!) {
    deleteUserPhone(input: $input) {
      userPhone {
        id
      }
    }
  }
`;
