import { all, call, takeEvery } from 'redux-saga/effects';
import { cacheTokens, wipeCredentials } from '../util/auth';

export function* removeCookie() {
  yield call(wipeCredentials);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export function* storeCookie(action) {
  const refreshToken = action.payload.auth.refresh_token;
  const accessToken = action.payload.auth.access_token;
  yield call(cacheTokens, refreshToken, accessToken);
}

export function* CookieSagas() {
  yield all([
    takeEvery('AUTH_USER.SUCCESS', storeCookie),
    takeEvery('STORE_AUTH0_TOKEN', storeCookie),
    takeEvery('REFRESH_AUTH0_TOKEN', storeCookie),
    takeEvery('VERIFY_USER.SUCCESS', storeCookie),
    takeEvery('REFRESH_TOKEN.SUCCESS', storeCookie),
    takeEvery('UPDATE_PASSWORD.SUCCESS', storeCookie),
    takeEvery('AUTH_USER.FAILURE', removeCookie),
  ]);
}
