import { gql } from '@apollo/client';

export const LabelFragment = gql`
  fragment LabelFields on LabelQL {
    id
    placeId
    category
    name
    color
    icon
    iconType
    hidden
    expirationHours
    archived
  }
`;

export const ConversationLabelFragment = gql`
  fragment ConversationLabelFields on ConversationLabelQL {
    id
    createdOn
    expiresAt
    hidden
    label {
      ...LabelFields
    }
    participant {
      id
      displayName
    }
  }
  ${LabelFragment}
`;

export const LabelStub = gql`
  fragment LabelStubFields on LabelQL {
    id
    category
    name
  }
`;

export const ConversationLabelStub = gql`
  fragment ConversationLabelStubFields on ConversationLabelQL {
    id
    label {
      ...LabelStubFields
    }
  }
  ${LabelStub}
`;
