import { gql } from '@apollo/client';

export const GET_USER_STATUS_LEADERBOARD_COLORS = gql`
  query userStatusLeaderboardColors($userId: String!) {
    user(userId: $userId) {
      id
      statusLeaderboardColors(placeId: "") {
        green
        yellow
        red
      }
    }
  }
`;
