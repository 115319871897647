import { gql } from '@apollo/client';

export const UPDATE_PLACE_VOICE = gql`
  mutation UpdatePlaceVoice($input: UpdatePlaceVoiceInput!) {
    updatePlaceVoice(input: $input) {
      place {
        id
        voice
      }
    }
  }
`;
