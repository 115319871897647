import { gql } from '@apollo/client';
import { AwayModeFragment } from '../../fragments/gql/AwayModeFragment.gql';

export const UPDATE_AWAY_MODE_MANAGING_PERMISSION = gql`
  mutation updateAwayModeManagingPermission(
    $input: UpdateAwayModeManagingPermissionInput!
  ) {
    updateAwayModeManagingPermission(input: $input) {
      user {
        ...AwayModeFields
      }
    }
  }
  ${AwayModeFragment}
`;
