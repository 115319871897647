import { gql } from '@apollo/client';

export const GET_VOICE_APPOINTMENT_AGENT_SETTINGS_AUDIO = gql`
  query getVoiceAppointmentAgentSettingsAudio(
    $appointmentAgentSettingsId: String!
    $audioField: String!
  ) {
    voiceAppointmentAgentSettingsAudio(
      appointmentAgentSettingsId: $appointmentAgentSettingsId
      audioField: $audioField
    )
  }
`;
