import { gql } from '@apollo/client';

import { ScheduledMessageFragment } from '../../fragments/gql/ScheduledMessageFragment.gql';
import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';

export const START_CONVERSATION_WITH_SCHEDULED_MESSAGE = gql`
  mutation startConversationWithScheduledMessage(
    $input: StartConversationWithScheduledMessageInput!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    startConversationWithScheduledMessage(input: $input) {
      conversation {
        ...ConversationItemFields
      }
      scheduledMessage {
        ...ScheduledMessageFields
      }
    }
  }
  ${ConversationItemFragment}
  ${ScheduledMessageFragment}
`;
