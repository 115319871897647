import { gql } from '@apollo/client';

export const CHANGE_ALLOW_CUSTOM_AUDIO = gql`
  mutation changeAllowCustomAudio($input: ChangeAllowCustomAudioInput!) {
    changeAllowCustomAudio(input: $input) {
      place {
        id
        allowCustomAudio
      }
    }
  }
`;
