import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiCreatePlaceInfoRequest,
  ApiCreatePlaceInfoResponse,
} from './types';

class PlaceInfoService implements Service {
  static createPlaceInfo(
    params: ApiCreatePlaceInfoRequest,
  ): AxiosPromise<ApiCreatePlaceInfoResponse> {
    const { place_id: placeId, ...rest } = params;
    const path = `/api/places/${placeId}/place_info`;
    return axios.post(path, rest);
  }
}

export default PlaceInfoService;
