import { gql } from '@apollo/client';

export const UPDATE_PLACE_GROUP = gql`
  mutation updatePlaceGroup($input: UpdatePlaceGroupInput!) {
    updatePlaceGroup(input: $input) {
      placeGroup {
        id
        name
      }
    }
  }
`;
