import { gql } from '@apollo/client';

import { PhoneLineWithChannelsFieldsFragment } from '../../fragments/gql/PhoneLineFields.gql';

export const GET_PHONELINES = gql`
  query getPhonelines(
    $placeId: String!
    $withVoiceChannel: Boolean = false
    $withSMSChannel: Boolean = false
  ) {
    phonelines(placeId: $placeId) {
      ...PhoneLineWithChannelsFields
    }
    place(id: $placeId) {
      id
      departmentPhoneLinePolicy {
        id
      }
      staffPhoneLinePolicy {
        id
      }
    }
  }
  ${PhoneLineWithChannelsFieldsFragment}
`;
