import { gql } from '@apollo/client';

export const INCREASE_SHORTCUT_COUNT = gql`
  mutation increaseShortcutCount($input: IncreaseShortcutCountInput!) {
    increaseShortcutCount(input: $input) {
      shortcut {
        id
      }
    }
  }
`;
