import { Components, Theme } from '@mui/material';

export const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      fontSize: theme.typography.body2.fontSize,
      padding: theme.spacing(0.5, 1),
      gap: theme.spacing(1),
      minWidth: 'max-content',
      textWrap: 'nowrap',
    }),
  },
};
