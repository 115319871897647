import type { ApolloContext } from '../../types';
import { DialogFlowBotDataFragment } from '../../fragments/gql/dialogFlowBotDataFragment.gql';

export const convertDialogflowBotData = (
  dialog_flow_bot_data: PubNubDialogFlowBotDataModel,
): DialogFlowBotDataFields => {
  return {
    __typename: 'DialogFlowBotDataQL',
    id: dialog_flow_bot_data.id,
    conversationId: dialog_flow_bot_data.conversation_id,
    data: dialog_flow_bot_data.data
      ? JSON.stringify(dialog_flow_bot_data.data)
      : null,
    appointmentWasBooked: dialog_flow_bot_data.appointment_was_booked,
    outcome: dialog_flow_bot_data.outcome || null,
    agentReason: dialog_flow_bot_data.agent_reason || null,
    agentDuration: dialog_flow_bot_data.agent_duration,
    customerInDms: dialog_flow_bot_data.customer_in_dms || null,
  };
};

/* eslint-disable react/destructuring-assignment */
export const PubNubDialogFlowBotDataUpdated = (
  obj: any,
  args: { dialog_flow_bot_data: PubNubDialogFlowBotDataModel },
  { client }: ApolloContext,
) => {
  client.writeFragment({
    id: `DialogFlowBotDataQL:${args.dialog_flow_bot_data.id}`,
    fragment: DialogFlowBotDataFragment,
    fragmentName: 'DialogFlowBotDataFields',
    data: convertDialogflowBotData(args.dialog_flow_bot_data),
  });

  return null;
};

/* eslint-enable react/destructuring-assignment */
