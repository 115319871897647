import { gql } from '@apollo/client';
import { TeamSettingsFragment } from '../../../fragments/gql/TeamFragment.gql';

export const UPDATE_TEAM = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        ...TeamSettingsFields
      }
    }
  }
  ${TeamSettingsFragment}
`;
