import { gql } from '@apollo/client';
import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';

export const GET_CONVERSATION_BY_PHONE_NUMBER_AND_PLACE = gql`
  query getConversationByPhoneNumberAndPlace(
    $phoneNumber: String!
    $placeId: String!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    conversationByPhoneNumberAndPlace(
      phoneNumber: $phoneNumber
      placeId: $placeId
    ) {
      ...ConversationItemFields
    }
  }
  ${ConversationItemFragment}
`;
