import { gql } from '@apollo/client';

export const GET_USER_SCHEDULE = gql`
  query getUserSchedule($userId: String!) {
    user(userId: $userId) {
      id
      schedule
      isScheduleEnabled
    }
  }
`;
