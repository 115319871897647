import { gql } from '@apollo/client';

export const REQUEST_AVAILABLE_INTEGRATION = gql`
  mutation requestAvailableIntegration(
    $input: RequestAvailableIntegrationInput!
  ) {
    requestAvailableIntegration(input: $input) {
      activeIntegration {
        id
        integrationId
        status
        configuration
      }
    }
  }
`;
