import { Components, Theme } from '@mui/material';

export const MuiAccordionSummary: Components<Theme>['MuiAccordionSummary'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      // Removes the focus visible background added to the summary when focusing on an input
      // inside the summary
      backgroundColor: 'transparent !important',
      padding: 0,
      width: '100%',
      position: 'relative',
      '& .MuiAccordionSummary-expandIconWrapper': {
        position: 'absolute',
        right: 10,
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
  },
};
