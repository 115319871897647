import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const UPDATE_AUTOMATION_START_TRIGGER = gql`
  mutation updateAutomationStartTrigger(
    $input: UpdateAutomationStartTriggerInput!
  ) {
    updateAutomationStartTrigger(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
