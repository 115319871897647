import type { ApolloContext } from '../../types';
import { GET_MY_INBOXES } from '../../queries/gql/getMyInboxes.gql';

export const PubNubPlaceUpdated = (
  obj: any,
  args: any,
  { client }: ApolloContext,
) => {
  client.query({
    query: GET_MY_INBOXES,
    fetchPolicy: 'network-only',
  });
};
