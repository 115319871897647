import { Components, Palette, Theme } from '@mui/material';

export const MuiPaper: Components<Theme>['MuiPaper'] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const color =
        (ownerState.color &&
          (theme.palette[
            ownerState.color as keyof Palette
          ] as Palette['primary'])) ||
        undefined;
      return {
        backgroundColor: color?.background,
        color: color?.main,
        border: 'none',
      };
    },
  },
};
