import { gql } from '@apollo/client';

export const UPDATE_PLACE_MFA_SETTINGS = gql`
  mutation UpdatePlaceMFASettings($input: UpdatePlaceMFASettingsInput!) {
    updatePlaceMfaSettings(input: $input) {
      place {
        id
        enforceMfa
      }
    }
  }
`;
