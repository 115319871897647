import { Components, Theme } from '@mui/material';

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  defaultProps: {
    color: 'default',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(0, 2),
      zIndex: theme.zIndex.drawer + 1,
      height: theme.util?.customValues.navBarHeight,
      width: '100%',
    }),
  },
};
