import { gql } from '@apollo/client';

export const REMOVE_USER_FROM_PLACE = gql`
  mutation RemoveUserFromPlace($input: RemoveUserFromPlaceInput!) {
    removeUserFromPlace(input: $input) {
      user {
        id
        displayName
        placesUsers {
          id
          placeId
        }
      }
    }
  }
`;
