import { gql } from '@apollo/client';

export const TEACH_NUMA = gql`
  mutation teachNuma($input: TeachNumaInput) {
    teachNuma(input: $input) {
      appliedIntent {
        id
      }
    }
  }
`;
