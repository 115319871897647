import { gql } from '@apollo/client';
import { UserItemAndNotificationFragment } from '../../fragments/gql/userItem.gql';

export const CHANGE_SWIPE_ACTION_SETTINGS = gql`
  mutation changeSwipeActionSettings($input: ChangeSwipeActionSettingsInput!) {
    changeSwipeActionSettings(input: $input) {
      currentUser {
        ...UserItemAndNotificationFields
      }
    }
  }
  ${UserItemAndNotificationFragment}
`;
