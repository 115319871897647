import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import type {
  ApiCreateAccountRequest,
  ApiCreateAccountResponse,
  ApiFetchAccountsRequest,
  ApiFetchAccountsResponse,
} from '@numbox/services';
import { AccountService } from '@numbox/services';

import type { Meta, ApiActionCreators, ExtractReturn } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const fetchAccounts: ApiActionCreators<
  'FETCH_ACCOUNT_LIST.REQUEST',
  ApiFetchAccountsRequest,
  'FETCH_ACCOUNT_LIST.SUCCESS',
  ApiFetchAccountsResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'FETCH_ACCOUNT_LIST.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_ACCOUNT_LIST.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_ACCOUNT_LIST.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchAccounts(
  action: ExtractReturn<typeof fetchAccounts.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AccountService.fetchAccounts,
    fetchAccounts,
    action.payload,
    action.meta,
  );
}

export const createAccount: ApiActionCreators<
  'CREATE_ACCOUNT.REQUEST',
  ApiCreateAccountRequest,
  'CREATE_ACCOUNT.SUCCESS',
  ApiCreateAccountResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'CREATE_ACCOUNT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'CREATE_ACCOUNT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'CREATE_ACCOUNT.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onCreateAccount(
  action: ExtractReturn<typeof createAccount.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AccountService.createAccount,
    createAccount,
    action.payload,
    action.meta,
  );
}

export function* AccountSagas(): Saga<any> {
  yield all([
    takeEvery('CREATE_ACCOUNT.REQUEST', onCreateAccount),
    takeEvery('FETCH_ACCOUNT_LIST.REQUEST', onFetchAccounts),
  ]);
}
