import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const CREATE_AUTOMATION_ACTION = gql`
  mutation createAutomationAction($input: CreateAutomationActionInput!) {
    createAutomationAction(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
