import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation login($credential: Credentials!) {
    login(credential: $credential) {
      accessToken
      refreshToken
    }
  }
`;
