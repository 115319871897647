import { gql } from '@apollo/client';
import { MessageItemFragment } from '../../fragments/gql/messageItem.gql';

export const SEND_REVIEW_REQUEST = gql`
  mutation sendReviewRequest($input: SendReviewRequestInput!) {
    sendReviewRequest(input: $input) {
      message {
        ...MessageItemFields
      }
    }
  }
  ${MessageItemFragment}
`;
