import { gql } from '@apollo/client';

export const GET_ASSIGNMENT_ROUTING_SETTINGS = gql`
  query getAssignmentRoutingSettings($placeId: String!, $userId: String!) {
    assignmentRoutingSettings(placeId: $placeId, userId: $userId) {
      id
      forwardingMdn
      enabledDuringBusinessHours
      enabledAfterBusinessHours
      enabledWhenAway
      callTimeout
      useNumaCallerId
    }
  }
`;
