import { gql } from '@apollo/client';
import { HostedSmsPortFragment } from '../../fragments/gql/HostedSmsPortFragment.gql';

export const GET_HOSTED_SMS_PORTS = gql`
  query getHostedSmsPorts($placeId: String!) {
    hostedSmsPorts(placeId: $placeId) {
      ...HostedSmsPortFields
    }
  }
  ${HostedSmsPortFragment}
`;
