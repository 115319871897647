import { gql } from '@apollo/client';
import { AppointmentFragment } from '../../fragments/gql/appointmentFragment.gql';

export const UPDATE_APPOINTMENT = gql`
  mutation updateAppointment($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      appointment {
        ...AppointmentFields
      }
    }
  }
  ${AppointmentFragment}
`;
