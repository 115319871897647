import { Components, Theme } from '@mui/material';

export const MuiList: Components<Theme>['MuiList'] = {
  defaultProps: {
    dense: false,
    disablePadding: true,
  },
  styleOverrides: {
    root: {
      padding: 0,
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
      width: '100%',
    },

    padding: {
      padding: 0,
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
