import { Components, Theme } from '@mui/material';

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      marginTop: theme.spacing(2),
    }),
  },
};
