import { Components, Theme } from '@mui/material';

export const MuiListItem: Components<Theme>['MuiListItem'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      color: 'inherit',
    },
  },
};
