import { gql } from '@apollo/client';

export const GET_TEN_DLC_TRUST_PRODUCT_BY_ID = gql`
  query getTrustProductById($trustProductId: String!) {
    tenDlcProcessById(trustProductId: $trustProductId) {
      twilioTrustProduct {
        id
        status
        businessIdentity {
          id
          taxId
          legalName
          entityType
          industry
          websiteUrl
          street
          secondaryStreet
          city
          state
          zipCode
          country
          firstName
          lastName
          jobTitle
          email
          phone
          mobilePhone
          stockTickerSymbol
          stockExchange
          businessType
        }
      }
    }
  }
`;
