import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiCreateBillingIdentityRequest,
  ApiCreateBillingIdentityResponse,
  ApiFetchBillingIdentityRequest,
  ApiFetchBillingIdentityResponse,
  ApiUpdateBillingIdentityRequest,
  ApiUpdateBillingIdentityResponse,
} from './types';

class BillingIdentityService implements Service {
  static createBillingIdentity(
    params: ApiCreateBillingIdentityRequest,
  ): AxiosPromise<ApiCreateBillingIdentityResponse> {
    const path = `/api/billing_identity`;
    return axios.post(path, params);
  }

  static fetchBillingIdentity(
    params: ApiFetchBillingIdentityRequest,
  ): AxiosPromise<ApiFetchBillingIdentityResponse> {
    const path = `/api/billing_identity?order_id=${params.billingOrderId}`;
    return axios.get(path);
  }

  static updateBillingIdentity(
    params: ApiUpdateBillingIdentityRequest,
  ): AxiosPromise<ApiUpdateBillingIdentityResponse> {
    const path = `/api/billing_identity/${params.billing_identity_id}`;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { billing_identity_id: billingIdentityId, ...payload } = params;
    return axios.patch(path, payload);
  }
}

export default BillingIdentityService;
