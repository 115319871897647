import { gql } from '@apollo/client';

export const GET_PLACES = gql`
  query places($first: Int) {
    places(first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
