import { gql } from '@apollo/client';

export const UPDATE_PLACE_OUTBOUND_CALL_SETTINGS = gql`
  mutation UpdatePlaceOutboundCallSettings(
    $input: UpdatePlaceOutboundCallSettingsInput!
  ) {
    updatePlaceOutboundCallSettings(input: $input) {
      place {
        id
        outboundCallSettings {
          id
          mdn
        }
      }
    }
  }
`;
