import { gql } from '@apollo/client';

export const GET_ASSIGNMENT_RETENTION_CHOICES = gql`
  query getAssignmentRetentionChoices {
    currentAccount {
      id
      assignmentRetentionChoices {
        label
        value
      }
    }
  }
`;
