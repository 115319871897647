import { gql } from '@apollo/client';

export const GET_USER_PERSONALIZATION = gql`
  query getUserPersonalization($userId: String!) {
    user(userId: $userId) {
      id
      firstName
      namePronunciation
      namePronunciationUrl
      pronouns
    }
  }
`;
