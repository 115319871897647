import { gql } from '@apollo/client';
import { PaymentRequestFragment } from '../../fragments/gql/paymentRequestFragment.gql';

export const CAPTURE_PAYMENT_AMOUNT = gql`
  mutation capturePaymentAmount($input: CapturePaymentAmountInput!) {
    capturePaymentAmount(input: $input) {
      paymentRequest {
        ...PaymentRequestFields
      }
    }
  }
  ${PaymentRequestFragment}
`;
