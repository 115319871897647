import { gql } from '@apollo/client';

import { UserItemFragment } from './userItem.gql';
import { TeamFragment } from './TeamFragment.gql';

export const ChannelFieldsFragment = gql`
  fragment ChannelFields on Channel {
    __typename
    id
    isActive
    isBotEnabled
    identityPromptOption
    place {
      id
      name
    }
    ... on TwilioChannelQL {
      mdn
      sid
      outboundSmsMdn
      sendOutboundsWithShadowTollfree
      shadowTollfreePhonenumber
      shadowTollfreeSid
      accountSid
      respectExistingAssignments
      archiveMissedCalls
      autoSendHangupReplies
      hangupSuggestion
      assignee {
        ...UserItemFields
      }
      team {
        ...TeamFields
      }
      phoneLinePolicyId
    }
    ... on FacebookChannelQL {
      name
    }
  }
  ${UserItemFragment}
  ${TeamFragment}
`;
