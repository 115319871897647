import type { ApolloContext } from '../../../types';
import { removeTeamFromCache } from './utils';

export const PubNubTeamDeleted = async (
  obj: any,
  args: { team_id: string; place_id: string },
  { client }: ApolloContext,
) => {
  removeTeamFromCache(client, args.place_id, args.team_id);
};
