import { gql } from '@apollo/client';

export const SUBMIT_BUSINESS_IDENTITY = gql`
  mutation SubmitBusinessIdentityMutation(
    $input: BusinessIdentityInput!
    $trustProductId: String!
  ) {
    submitBusinessIdentity(input: $input, trustProductId: $trustProductId) {
      tenDlcProcess {
        twilioTrustProduct {
          friendlyName
          id
          status
        }
        twilioBrandRegistration {
          id
          status
        }
        errors {
          failureReason
          objectField
        }
      }
    }
  }
`;
