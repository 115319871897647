import {
  AUTH_INITIAL_STATE,
  AuthReducerState,
  AuthActions,
  authSuccessReducer,
} from './auth';

import type { WebReducerState } from './types';

export const getAdminAuth = (state: WebReducerState) => state.adminAuth;

export default function AdminAuthReducer(
  state: AuthReducerState = AUTH_INITIAL_STATE,
  action: AuthActions,
) {
  switch (action.type) {
    case 'ADMIN_AUTH_SUCCESS': {
      return authSuccessReducer(state, action);
    }
    default:
      return state;
  }
}
