import { Components, Theme } from '@mui/material';

export const MuiToolbar: Components<Theme>['MuiToolbar'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
};
