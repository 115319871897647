import type { ApolloContext } from '../../types';
import { addStatusItemToCache } from './AddStatusItemToCache';

export const PubNubStatusItemCreated = async (
  obj: any,
  args: { statusItem: PubNubStatusItemModel },
  client: ApolloContext,
) => {
  addStatusItemToCache(client, args.statusItem);
};
