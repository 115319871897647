import { gql } from '@apollo/client';

export const UPDATE_LOGOS = gql`
  mutation updateLogos($input: UpdateLogosInput!) {
    updateLogos(input: $input) {
      placeInfo {
        id
        logoUrl
        place {
          id
          brandTheme {
            id
            logoUrl
          }
        }
      }
    }
  }
`;
