import { gql } from '@apollo/client';

export const GET_STATUS_BOARD_USERS = gql`
  query statusBoardUsers($placeId: String!) {
    placesUsers(placeId: $placeId) {
      id
      placeId
      user {
        id
        displayName
        jobRole
        statusBoardView
      }
    }
  }
`;
