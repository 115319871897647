import debounce from 'lodash/debounce';
import { delay } from 'redux-saga';
import {
  all,
  call,
  put,
  select,
  race,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { GET_TYPING_INDICATORS_CONFIG, GraphQLClient } from '@numbox/apollo';
import {
  STARTED_TYPING,
  STOPPED_TYPING,
  USER_TYPING,
  KEPT_TYPING,
  DISCARD_USER,
  keptTyping,
  stoppedTyping,
  discardUser,
  getUsersTypingByConversation,
} from './module';
import { getOnTyping } from './pubnub';

const defaultTimeouts = {
  sendStillTyping: 500,
  inactivityTimeout: 5000,
  discardEventTimeout: 30000,
};
const timeouts = {};
let channel: string | null = null;
let currentlyTyping = false;

export function* onStopTyping(): Generator<any, void, void> {
  if (currentlyTyping) {
    currentlyTyping = false;
  }

  return yield currentlyTyping;
}
export function* keepSendingUpdates(
  conversationId: string,
): Generator<any, void, void> {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'sendStillTyping' does not exist on type ... Remove this comment to see the full error message
  const { sendStillTyping } = timeouts;
  yield delay(sendStillTyping);

  if (currentlyTyping) {
    yield put(keptTyping(conversationId));
    yield call(keepSendingUpdates, conversationId);
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* onStartTyping(action): Generator<any, void, void> {
  const { conversationId } = action.payload;

  if (conversationId && !currentlyTyping) {
    currentlyTyping = true;
    yield call(keepSendingUpdates, conversationId);
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* waitForInactivityTimeout(action): Generator<any, void, void> {
  const { conversationId } = action.payload;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'inactivityTimeout' does not exist on typ... Remove this comment to see the full error message
  const { inactivityTimeout } = timeouts;
  yield delay(inactivityTimeout);
  yield put(stoppedTyping(conversationId));
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* discardTimedOutUsers(action): Generator<any, void, Array<any>> {
  const { conversationId } = action.payload;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'discardEventTimeout' does not exist on t... Remove this comment to see the full error message
  const { discardEventTimeout } = timeouts;
  const usersChatting: Array<any> = yield select(
    getUsersTypingByConversation,
    conversationId,
  );
  const currentTime = new Date().getTime();

  for (let i = 0; i < usersChatting.length; i += 1) {
    const u = usersChatting[i];

    if (currentTime - u.updatedAt > discardEventTimeout) {
      yield put(discardUser(conversationId, u.user));
    }
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* waitForDiscardedTimeout(action): Generator<any, void, Array<any>> {
  const { conversationId } = action.payload;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'discardEventTimeout' does not exist on t... Remove this comment to see the full error message
  const { discardEventTimeout } = timeouts;
  const usersChatting: Array<any> = yield select(
    getUsersTypingByConversation,
    conversationId,
  );
  yield delay(discardEventTimeout);

  for (let i = 0; i < usersChatting.length; i += 1) {
    const u = usersChatting[i];
    yield put(discardUser(conversationId, u.user));
  }
}

function* setTimeouts(): Generator<any, void, void> {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'void'.
  const { data } = yield call([GraphQLClient, 'query'], {
    query: GET_TYPING_INDICATORS_CONFIG,
    fetchPolicy: 'network-only',
  });

  if (data && data.pubnub && data.pubnub.typingIndicator) {
    const {
      sendStillTyping,
      inactivityTimeout,
      discardEventTimeout,
      typingIndicatorChannel,
    } = data.pubnub.typingIndicator;

    Object.assign(timeouts, {
      sendStillTyping,
      inactivityTimeout,
      discardEventTimeout,
    });
    channel = typingIndicatorChannel;
  } else {
    Object.assign(timeouts, defaultTimeouts);
  }
}

export interface SelectPubnubPayload {
  pubnubClient: any;
  activeUserId: string;
  user: any;
}
export function getWatchActivelyTyping(
  selectPubnubPayload: Generator<any, SelectPubnubPayload, any>,
) {
  return function* watchActivelyTyping(): Generator<any, void, void> {
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    yield race([
      take('AUTH_USER.SUCCESS'),
      take('STORE_AUTH0_TOKEN'),
      take('REFRESH_AUTH0_TOKEN'),
      take('VERIFY_USER.SUCCESS'),
      take('REFRESH_TOKEN.SUCCESS'),
      take('UPDATE_PASSWORD.SUCCESS'),
      take('numbox-react/pubsub/STORE_PUBNUB_CLIENT'),
    ]);
    yield setTimeouts();
    const onTyping = getOnTyping(selectPubnubPayload, channel);
    const debouncedOnTyping = debounce(onTyping, 1000);
    yield all([
      takeEvery(STARTED_TYPING, debouncedOnTyping, true),
      takeEvery(KEPT_TYPING, onTyping, true),
      takeEvery(STOPPED_TYPING, onTyping, false),
      takeEvery(DISCARD_USER, onTyping, false),
      takeEvery(STARTED_TYPING, onStartTyping),
      takeEvery(STOPPED_TYPING, onStopTyping),
      takeLatest(STARTED_TYPING, waitForInactivityTimeout),
      takeEvery(USER_TYPING, discardTimedOutUsers),
      takeLatest(USER_TYPING, waitForDiscardedTimeout),
    ]);
  };
}
