import { gql } from '@apollo/client';
import { BrandThemeFragment } from '../../fragments/gql/BrandTheme.gql';

export const RESET_BRAND_THEME = gql`
  mutation ResetBrandTheme($input: ResetBrandThemeInput!) {
    resetBrandTheme(input: $input) {
      brandTheme {
        ...BrandThemeFields
      }
    }
  }
  ${BrandThemeFragment}
`;
