import { gql } from '@apollo/client';
import { ConversationItemStub } from '../../fragments/gql/conversationItem.gql';

export const ARCHIVE_CONVERSATIONS = gql`
  mutation ArchiveConversations($input: UpdateConversationsInput!) {
    updateConversations(input: $input) {
      conversations {
        ...ConversationItemStub
      }
    }
  }
  ${ConversationItemStub}
`;
