import { Components, Theme } from '@mui/material';

export const MuiIcon: Components<Theme>['MuiIcon'] = {
  styleOverrides: {
    fontSizeInherit: () => ({
      fontSize: 'inherit !important',
    }),
    fontSizeSmall: ({ theme }) => ({
      fontSize: `${theme.typography.h6.fontSize} !important`,
    }),
  },
};
