import { gql } from '@apollo/client';

export const GET_PLACE_GROUPS = gql`
  query getPlaceGroups(
    $first: Int
    $after: String
    $filter: PlaceGroupModelFilter
    $sort: [PlaceGroupModelSortEnum]
    $search: String
  ) {
    placeGroups(
      first: $first
      after: $after
      queryFilter: $filter
      sort: $sort
      search: $search
    ) @connection(key: "placeGroups", filter: ["queryFilter", "search"]) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          __typename
          id
          name
          account {
            id
            name
          }
        }
      }
    }
  }
`;
