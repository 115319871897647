export const retry = (
  fn: () => Promise<any>,
  retriesLeft = 3,
  interval = 250,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export async function handleUnmountGracefully(promise: Promise<any>) {
  try {
    return await promise;
  } catch (err) {
    if (!(err instanceof Error) || err.name !== 'Invariant Violation') {
      // Rethrow the error if it's not an Invariant Violation
      // which is assumed to be due to unmounting
      throw err;
    }
    return null;
  }
}
