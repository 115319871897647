import { gql } from '@apollo/client';

export const GET_USERS_QUERY = gql`
  query users($first: Int, $after: String, $filter: UserModelFilter) {
    users(first: $first, after: $after, queryFilter: $filter) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          firstName
          lastName
          email
          mdn
        }
      }
    }
  }
`;
