import {
  all,
  put,
  takeEvery,
  takeLatest,
  call,
  take,
  fork,
  race,
  throttle,
} from 'redux-saga/effects';

import { delay } from 'redux-saga';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import get from 'lodash/get';
import * as Sentry from '@sentry/browser';

import { UserService } from '@numbox/services';

let onInbox = false;
let isAnimating = true;
let inboxCount = 0;

function updateTitle() {
  if (onInbox && inboxCount) {
    document.title = `Numa (${inboxCount})`;
  } else {
    document.title = 'Numa';
  }
}

function* resetPageTitle(): Generator<any, void, any> {
  const reset = () => {
    document.title = 'Numa';
  };
  yield call(reset);
}

function* animate() {
  isAnimating = true;
  while (isAnimating) {
    if (document.title === 'New Message') {
      document.title = `Numa (${inboxCount})`;
    } else {
      document.title = 'New Message';
    }

    yield call(delay, 1000);
  }
}

function* resetAnimationTimeout(): Generator<any, void, void> {
  yield delay(60000); // one minute
  yield put({ type: 'ANIMATION_TIMEOUT' });
}

function* fetchInboxCount(): Generator<any, void, void> {
  try {
    const result = yield call(UserService.badgeCount);
    inboxCount = get(result, 'data.badge_count', 0);
    yield call(updateTitle);
  } catch (e: any) {
    if (!e.response || e.response.status !== 401) {
      Sentry.captureException(e);
    }
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* onReceivePushNotification(action): Generator<any, void, any> {
  const { shouldAnimateTitle } = action.payload;
  if (!shouldAnimateTitle) {
    return;
  }

  if (!isAnimating) {
    yield fork(animate);
  }

  const { newMessage } = yield race({
    foregrounded: take('TAB_FOREGROUNDED'),
    newMessage: take('RECEIVE_PUSH_NOTIFICATION'),
    timeout: take('ANIMATION_TIMEOUT'),
  });

  if (!newMessage) {
    // If it was a timeout or the browser brought to foreground, stop animating
    isAnimating = false;
  }
}

function* onEnterInbox(): Generator<any, void, void> {
  onInbox = true;
  isAnimating = false;
  yield call(updateTitle);
}

function* onLeaveInbox(): Generator<any, void, void> {
  onInbox = false;
  isAnimating = false;
  yield call(updateTitle);
}

function* watchPageTitleAnimation(): Saga<any> {
  yield all([
    takeEvery('LOGOUT_USER.SUCCESS', resetPageTitle),
    takeEvery('LEAVE_INBOX', onLeaveInbox),
    takeEvery('ENTER_INBOX', onEnterInbox),
    takeEvery('RECEIVE_PUSH_NOTIFICATION', onReceivePushNotification),
    takeLatest('RECEIVE_PUSH_NOTIFICATION', resetAnimationTimeout),
    takeEvery('AUTH_USER.SUCCESS', fetchInboxCount),
    takeEvery('STORE_AUTH0_TOKEN', fetchInboxCount),
    takeEvery('REFRESH_AUTH0_TOKEN', fetchInboxCount),
    takeEvery('VERIFY_USER.SUCCESS', fetchInboxCount),
    takeEvery('UPDATE_PASSWORD.SUCCESS', fetchInboxCount),
    takeEvery('REFRESH_TOKEN.SUCCESS', fetchInboxCount),
    throttle(2000, 'UPDATE_INBOX_COUNT', fetchInboxCount),
  ]);
}

export { watchPageTitleAnimation };
