import { gql } from '@apollo/client';
import { TeamWithUsersFragment } from '../../../fragments/gql/TeamFragment.gql';

export const ADD_USER_TO_TEAM = gql`
  mutation addUserToTeam(
    $input: AddUserToTeamInput!
    $userCount: Int
    $afterUser: String
  ) {
    addUserToTeam(input: $input) {
      team {
        ...TeamWithUsers
      }
    }
  }
  ${TeamWithUsersFragment}
`;
