import { gql } from '@apollo/client';
import { VideoUploadLinkFragment } from '../../fragments/gql/videoUploadLinkFragment.gql';

export const REMOVE_VIDEO_UPLOAD_LINK = gql`
  mutation removeVideoUploadLink($input: RemoveVideoUploadLinkInput!) {
    removeVideoUploadLink(input: $input) {
      videoUploadLink {
        ...VideoUploadLinkFields
      }
    }
  }
  ${VideoUploadLinkFragment}
`;
