import { gql } from '@apollo/client';

export const SET_RESET_OPTIN_DAYS = gql`
  mutation SetResetOptinDays($newResetOptinDays: Int!) {
    setResetOptinDays(input: { resetOptinDays: $newResetOptinDays }) {
      currentAccount {
        resetOptinDays
      }
    }
  }
`;
