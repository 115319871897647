import { gql } from '@apollo/client';

export const UPDATE_AUTOPILOT_JOB = gql`
  mutation updateAutopilotJob($input: UpdateAutopilotJobInput!) {
    updateAutopilotJob(input: $input) {
      autopilotJob {
        id
        welcome
        inspection
        service
        qualityControl
        pickup
      }
    }
  }
`;
