import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiFetchSubscriptionsRequest,
  ApiFetchSubscriptionsResponse,
} from './types';

class SubscriptionService implements Service {
  static fetchSubscriptions(
    params: ApiFetchSubscriptionsRequest,
  ): AxiosPromise<ApiFetchSubscriptionsResponse> {
    const path = `/api/subscriptions`;
    return axios.get(path, { params });
  }
}

export default SubscriptionService;
