import { gql } from '@apollo/client';

export const CHANGE_USER_VIDEO_CONFERENCE_URL = gql`
  mutation ChangeUserVideoConferenceUrl(
    $input: ChangeUserVideoConferenceUrlInput!
  ) {
    changeUserVideoConferenceUrl(input: $input) {
      currentUser {
        videoConferenceUrl
      }
    }
  }
`;
