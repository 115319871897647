import { all, call, put, select, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import { GraphQLClient, GET_CURRENT_USER } from '@numbox/apollo';
import { resetAuthHeader } from '@numbox/services';
import { testIsAuth0Token } from '@numbox/util';

import get from 'lodash/get';

import { OBJECTS, ACTIONS } from '@numbox/react';
import { trackEvent } from '../modules/analytics';
import { getAccessToken, signOutUserSuccess } from '../modules/auth';
import { getAuth0LogoutFunction } from '../selectors/auth0';
import { wipeCredentials } from '../util/auth';

export function* onLogoutAnalytics(): Generator<any, void, any> {
  yield put(trackEvent(OBJECTS.USER, ACTIONS.LOGGED_OUT, {}));
}

export function* auth0Logout(): Generator<any, void, any> {
  const accessToken = yield select(getAccessToken);
  const isAuth0Token = testIsAuth0Token(accessToken);

  if (isAuth0Token) {
    const logout = yield select(getAuth0LogoutFunction);
    yield call(logout);
  }
}

export function* onLogout(): Generator<any, void, any> {
  // There is a need to call bind https://github.com/apollographql/apollo-client/issues/4970
  yield call(GraphQLClient.stop.bind(GraphQLClient));
  yield call(GraphQLClient.clearStore.bind(GraphQLClient));
  yield call(onLogoutAnalytics);
  yield call(wipeCredentials);
  yield call(auth0Logout);
  yield put(signOutUserSuccess());
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* onAuthError(action): Generator<any, void, void> {
  yield put(
    trackEvent(OBJECTS.USER, ACTIONS.LOG_IN_FAILED, {
      memberEmail: action.meta.email,
    }),
  );
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* onApolloTokenRefresh(action): Generator<any, void, void> {
  const { refreshToken, authError } = action.payload;

  if (!refreshToken && authError) {
    // If we were unsuccessful in our reauth attempt, we want to cleanup
    // our internal state and drop the user at the login screen.
    yield call(onLogout);
  }
}

export function* watchApolloTokenRefresh(): Saga<any> {
  yield takeEvery('APOLLO_TOKEN_REFRESH', onApolloTokenRefresh);
}

export function* watchUserLogout(): Saga<any> {
  yield takeEvery('LOGOUT_USER.SUCCESS', onLogout);
}
export function* watchAuthError(): Saga<any> {
  yield takeEvery('AUTH_USER.FAILURE', onAuthError);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* updateAuthHeader(action): Generator<any, void, void> {
  const accessToken = get(action, 'payload.auth.access_token');

  if (accessToken) {
    yield call(resetAuthHeader, accessToken);
  }
}
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* updateAuthHeaderFromApollo(action): Generator<any, void, void> {
  const accessToken = get(action, 'payload.accessToken');

  if (accessToken) {
    yield call(resetAuthHeader, accessToken);
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function* setUserInApollo(action): Generator<any, void, void> {
  const { user } = action.payload;
  const {
    assignments,
    translation,
    escalations,
    away_mode: awayMode,
    assignment_routing: assignmentRouting,
  } = action.payload.sku_entitlements;
  let fullName = user.email;

  if (!fullName && (user.firstName || user.lastName)) {
    if (user.firstName) {
      fullName += user.firstName;
    }
    if (user.lastName) {
      fullName += ' ';
      fullName += user.lastName;
    }
  }

  yield call([GraphQLClient, GraphQLClient.writeQuery], {
    query: GET_CURRENT_USER,
    data: {
      currentUser: {
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        fullName,
        mdn: user.mdn,
        email: user.email,
        emailOnAssignment: user.email_on_assignment,
        pushNotificationSetting: user.push_notification_setting,
        browserNotificationSetting: user.browser_notification_setting,
        assignmentClaimSetting: user.assignment_claim_setting,
        swipeRightSetting: user.swipe_right_setting,
        swipeLeftSetting: user.swipe_left_setting,
        videoConferenceUrl: user.video_conference_url,
        isAway: user.is_away,
        isScheduleEnabled: user.is_schedule_enabled,
        nextShiftStart: user.next_shift_start,
        nextShiftEnd: user.next_shift_end,
        isUnassigningInboundMessages: user.is_unassigning_inbound_messages,
        showAwayModeBannerAfter: user.show_away_mode_banner_after,
        participantId: user.participant_id,
        defaultView: user.default_view,
        defaultYouView: user.default_you_view,
        emailNotificationSetting: user.email_notification_setting,
        hideSharedInboxViews: user.hide_shared_inbox_views,
        statusBoardView: user.status_board_view,
        signaturePreference: user.signature_preference,
        canManageOwnAwayModeSettings: user.can_manage_own_away_mode_settings,
        accountPermissions: user.account_permissions,
        placePermissions: user.place_permissions,
        jobRole: user.job_role,
        jobTitle: user.job_title,
        hasStatusItems: user.has_status_items,
        hasExternalIdentity: user.has_external_identity,
        viewPreferences: {
          __typename: 'UserViewPreferences',
          systemMessageSettings: {
            __typename: 'SystemMessageSettings',
            showSystemMessages:
              user.view_preferences.system_message_settings
                .show_system_messages,
          },
        },
        __typename: 'UserQL',
      },

      skuEntitlements: {
        __typename: 'SkuEntitlementsQL',
        assignments,
        translation,
        escalations,
        awayMode,
        assignmentRouting,
      },
    },
  });
}

export function* watchAuthHeaderUpdate(): Saga<any> {
  yield all([
    takeEvery('AUTH_USER.SUCCESS', updateAuthHeader),
    takeEvery('VERIFY_USER.SUCCESS', updateAuthHeader),
    takeEvery('REFRESH_TOKEN.SUCCESS', updateAuthHeader),
    takeEvery('UPDATE_PASSWORD.SUCCESS', updateAuthHeader),
    takeEvery('STORE_AUTH0_TOKEN', updateAuthHeader),
    takeEvery('REFRESH_AUTH0_TOKEN', updateAuthHeader),
    takeEvery('APOLLO_TOKEN_REFRESH', updateAuthHeaderFromApollo),
    takeEvery('AUTH_USER.SUCCESS', setUserInApollo),
    takeEvery('REFRESH_TOKEN.SUCCESS', setUserInApollo),
    takeEvery('UPDATE_PASSWORD.SUCCESS', setUserInApollo),
    takeEvery('STORE_AUTH0_TOKEN', setUserInApollo),
    takeEvery('REFRESH_AUTH0_TOKEN', setUserInApollo),
  ]);
}
