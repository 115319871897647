import { gql } from '@apollo/client';

export const PUBNUB_DIALOG_FLOW_BOT_DATA_UPDATED = gql`
  mutation PubNubDialogFlowBotDataUpdated(
    $dialog_flow_bot_data: PubNubDialogFlowBotDataModel!
  ) {
    PubNubDialogFlowBotDataUpdated(dialog_flow_bot_data: $dialog_flow_bot_data)
      @client
  }
`;
