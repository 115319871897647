import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type { ApiSignupRequest, ApiSignupResponse } from './types';

export class SignupService implements Service {
  static signup(params: ApiSignupRequest): AxiosPromise<ApiSignupResponse> {
    const path = `/api/signup`;
    return axios.post(path, params);
  }
}
