import { gql } from '@apollo/client';

import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';

export const SEARCH_CONVERSATION_BY_CONTACT = gql`
  query searchConversationByContact(
    $searchTerm: String!
    $first: Int!
    $after: String
    $withLastAppointment: Boolean = true
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = true
  ) {
    autocompleteConversations(
      search: $searchTerm
      first: $first
      after: $after
    ) @connection(key: "searchConversation", filter: []) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ConversationItemFields
          place {
            id
            name
          }
        }
      }
    }
  }
  ${ConversationItemFragment}
`;
