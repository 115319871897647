import { gql } from '@apollo/client';

export const UPDATE_PLACE_NAME_PRONUNCIATION = gql`
  mutation updatePlaceNamePronunciation($input: UpdateNamePronunciationInput!) {
    updateNamePronunciation(input: $input) {
      place {
        id
        namePronunciation
        namePronunciationUrl
      }
    }
  }
`;
