import { gql } from '@apollo/client';
import { AwayModeDetailsFragment } from '../../fragments/gql/AwayModeFragment.gql';

export const UPDATE_AWAY_MODE_ASSIGNEE = gql`
  mutation updateAwayModeAssignee($input: UpdateAwayModeAssigneeInput!) {
    updateAwayModeAssignee(input: $input) {
      user {
        ...AwayModeDetailsFields
      }
    }
  }
  ${AwayModeDetailsFragment}
`;
