import { gql } from '@apollo/client';

export const UPDATE_SUBSCRIPTION_MUTATION = gql`
  mutation updateSubscription(
    $billingIdentityId: String!
    $quantity: Int!
    $skuName: String!
  ) {
    updateSubscription(
      billingIdentityId: $billingIdentityId
      quantity: $quantity
      skuName: $skuName
    ) {
      subscription {
        id
        quantity
        sku {
          id
          name
        }
      }
    }
  }
`;
