import { gql } from '@apollo/client';

export const GET_PLACE_NAME_PRONUNCIATION = gql`
  query getPlaceNamePronunciation($placeId: String!) {
    place(id: $placeId) {
      id
      namePronunciation
      namePronunciationUrl
    }
  }
`;
