import { gql } from '@apollo/client';

import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';
import { MessageItemFragment } from '../../fragments/gql/messageItem.gql';

export const SEARCH_CONVERSATION_BY_MESSAGE = gql`
  query searchConversationByMessage(
    $searchTerm: String!
    $first: Int!
    $after: String
    $withLastAppointment: Boolean = true
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = true
  ) {
    searchMessagesByContent(
      search: $searchTerm
      first: $first
      after: $after
      sort: created_on_desc
    ) @connection(key: "searchContent", filter: []) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...MessageItemFields
          conversation {
            ...ConversationItemFields
          }
        }
      }
    }
  }
  ${MessageItemFragment}
  ${ConversationItemFragment}
`;
