import { gql } from '@apollo/client';

export const UPDATE_USE_NEW_NUMBER = gql`
  mutation updateUseNewNumber($input: UpdateUseNewNumberInput!) {
    updateUseNewNumber(input: $input) {
      account {
        id
        useNewNumber
      }
    }
  }
`;
