import { gql } from '@apollo/client';

export const TranscriptAnalysisFragment = gql`
  fragment TranscriptAnalysisFields on TranscriptAnalysisQL {
    id
    timestamp
    callType
    subject
    summary
    nextSteps
    coachingTip
    coachingSample
    sections {
      id
      name
      timestamp
    }
    scores {
      id
      name
      rating
      description
    }
  }
`;
