import { gql } from '@apollo/client';

export const BrandThemeFragment = gql`
  fragment BrandThemeFields on BrandThemeQL {
    id

    headerBackgroundColor
    headerBorderColor
    headerTextColor

    bodyBackgroundColor
    bodyBorderColor
    bodyTextColor

    buttonPrimaryBackgroundColor
    buttonPrimaryBorderColor
    buttonPrimaryTextColor

    buttonSecondaryBackgroundColor
    buttonSecondaryBorderColor
    buttonSecondaryTextColor

    logoUrl
    paletteType
    supportedPaletteTypes {
      name
      headerBackgroundColor
      headerBorderColor
      headerTextColor
      bodyBackgroundColor
      bodyBorderColor
      bodyTextColor
      buttonPrimaryBackgroundColor
      buttonPrimaryBorderColor
      buttonPrimaryTextColor
      buttonSecondaryBackgroundColor
      buttonSecondaryBorderColor
      buttonSecondaryTextColor
    }
  }
`;
