import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const DISCARD_VOICE_SCRIPT = gql`
  mutation discardVoiceScript($input: DiscardVoiceScriptInput!) {
    discardVoiceScript(input: $input) {
      answeringScript {
        id
        draftVoiceScript {
          ...VoiceScriptFields
        }
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
