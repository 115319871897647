import { gql } from '@apollo/client';

export const GET_BILLING_IDENTITIES_QUERY = gql`
  query billingIdentities(
    $first: Int
    $after: String
    $filter: BillingIdentityModelFilter
    $sort: [BillingIdentityModelSortEnum]
  ) {
    billingIdentities(
      first: $first
      after: $after
      queryFilter: $filter
      sort: $sort
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          billingOrderId
          eulaAcceptanceDate
          email
          name
          source
          subscriptions {
            id
            quantity
            available
            sku {
              id
              isActive
              name
              skuType
            }
          }
        }
      }
    }
  }
`;
