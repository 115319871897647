import { gql } from '@apollo/client';
import { LabelFragment } from '../../fragments/gql/labelFragment.gql';

export const GET_LABELS_FOR_PLACE = gql`
  query getLabelsForPlace($placeId: String!) {
    labels(placeId: $placeId) {
      ...LabelFields
    }
  }
  ${LabelFragment}
`;
