import { AppointmentFragment } from '../../fragments/gql/appointmentFragment.gql';
import type { ApolloContext } from '../../types';
import { readConversationFragment } from '../../util/conversations';

import { convertDialogflowBotData } from './PubNubDialogFlowBotDataUpdated';

export const PubNubAppointmentEnriched = async (
  obj: any,
  args: { conversation_id: string; appointment: PubNubAppointmentModel },
  { client }: ApolloContext,
) => {
  const existingConversation = readConversationFragment({
    client,
    conversationId: args.conversation_id,
  });
  if (!existingConversation) {
    return null;
  }
  const lastAppointment = {
    __typename: 'AppointmentQL',
    id: args.appointment.id,
    state: args.appointment.state,
    enrichedFrom: args.appointment.enriched_from,
    appointmentId: args.appointment.appointment_id,
    appointmentDate: args.appointment.appointment_date,
    externalServiceAdvisorId: args.appointment.external_service_advisor_id,
    externalServiceAdvisorName: args.appointment.external_service_advisor_name,
    createdOn: args.appointment.created_on,
    expiresAt: args.appointment.expires_at,
    dialogFlowBotData: args.appointment.dialog_flow_bot_data
      ? convertDialogflowBotData(args.appointment.dialog_flow_bot_data)
      : null,
  } as ConversationItemFields$lastAppointment;
  return client.writeFragment({
    id: `AppointmentQL:${args.appointment.id}`,
    fragment: AppointmentFragment,
    fragmentName: 'AppointmentFields',
    data: { ...lastAppointment },
  });
};
