import { gql } from '@apollo/client';

export const CREATE_A2P_CAMPAIGN_FOR_PLACE = gql`
  mutation CreateA2pCampaignForPlace(
    $placeId: String!
    $trustProductId: String!
  ) {
    createA2pCampaignForPlace(
      placeId: $placeId
      trustProductId: $trustProductId
    ) {
      tenDlcProcess {
        twilioA2pCampaign {
          id
          campaignStatus
        }
        twilioBrandRegistration {
          id
          status
        }
        twilioTrustProduct {
          friendlyName
          id
          status
        }
      }
    }
  }
`;
