import { gql } from '@apollo/client';

export const InboxBehaviourFragment = gql`
  fragment InboxBehaviourFields on UserQL {
    id
    hideSharedInboxViews
    statusBoardView
    defaultView
    defaultYouView
    emailNotificationSetting
    pushNotificationSetting
    browserNotificationSetting
  }
`;
