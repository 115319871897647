import { gql } from '@apollo/client';

export const GET_OUTBOUND_PROXY_CALL_STATUS = gql`
  query getOutboundProxyCallStatus($id: String!) {
    outboundProxyCall(id: $id) {
      id
      callStatus
      isBeingRecorded
      startTime
    }
  }
`;
