import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const UPDATE_AUTOMATION = gql`
  mutation updateAutomation($input: UpdateAutomationInput!) {
    updateAutomation(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
