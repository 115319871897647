import { gql } from '@apollo/client';

export const ReasonSentFragment = gql`
  fragment ReasonSentFields on ReasonSentQL {
    __typename
    ... on ReasonSentQL {
      kind
    }
    ... on ReasonSentGreetingQL {
      name
    }
    ... on ReasonSentIntentQL {
      intent {
        name
        displayName
      }
    }
    ... on ReasonSentHangupResponseQL {
      kind
    }
  }
`;
