import { gql } from '@apollo/client';

export const GET_AVAILABLE_VOICE_SCRIPTS_FOR_PLACE = gql`
  query getAvailableVoiceScriptsForPlace($placeId: String!) {
    availableVoiceScripts(placeId: $placeId) {
      scriptType
      previewScript
      scriptSteps {
        label
        canToggle
      }
    }
  }
`;
