import { gql } from '@apollo/client';

export const TeamFragment = gql`
  fragment TeamFields on TeamQL {
    id
    name
    avatar
  }
`;

export const TeamSettingsFragment = gql`
  fragment TeamSettingsFields on TeamQL {
    id
    name
    avatar
    assignmentStyle
    userCount
  }
`;

export const TeamWithUsersFragment = gql`
  fragment TeamWithUsers on TeamQL {
    ...TeamFields
    users(first: $userCount, after: $afterUser) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          displayName
          isAway
        }
      }
    }
  }
  ${TeamFragment}
`;
