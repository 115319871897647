import { gql } from '@apollo/client';

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String) {
    login(credential: { refreshToken: $refreshToken }) {
      refreshToken
      accessToken
      featureFlags
    }
  }
`;
