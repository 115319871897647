import { gql } from '@apollo/client';

import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';

export const DELETE_CONVERSATION = gql`
  mutation deleteConversation(
    $input: DeleteConversationInput!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    deleteConversation(input: $input) {
      conversation {
        ...ConversationItemFields
      }
    }
  }

  ${ConversationItemFragment}
`;
