import { gql } from '@apollo/client';

export const GLOBAL_SYNC_XTIME_SERVICES = gql`
  mutation globalSyncXtimeServices($input: GlobalSyncXtimeServicesInput!) {
    globalSyncXtimeServices(input: $input) {
      latestXtimeSync {
        id
        endDate
      }
      activeIntegration {
        id
        pendingOpcodeSync
      }
    }
  }
`;
