import type { ExtractReturn } from '@numbox/modules';
import { PhoneLineModule, PhoneNumberModule } from '@numbox/modules';

import type { WebReducerState } from '../reducers/types';

export type PhoneLineProvisionReducerState = {
  createLinks: Record<string, string>;

  availableNumbers: Array<{
    id: string;
    mdn: string;
  }>;
};

export const PHONELINE_PROVISION_INITIAL_STATE = {
  availableNumbers: [],
  createLinks: {},
};

type PhoneLineProvisionActions =
  | ExtractReturn<typeof PhoneNumberModule.fetchAvailablePhoneNumbers.success>
  | ExtractReturn<typeof PhoneLineModule.fetchPhoneLines.success>;

const PhonelineProvisionReducer = (
  state: PhoneLineProvisionReducerState = PHONELINE_PROVISION_INITIAL_STATE,
  action: PhoneLineProvisionActions,
) => {
  switch (action.type) {
    case 'FETCH_PHONE_LINES.SUCCESS': {
      return {
        ...state,
        createLinks: {
          ...state.createLinks,
          [action.payload.place_id]: action.payload.create_url,
        },
      };
    }
    case 'FETCH_AVAILABLE_PHONE_NUMBERS.SUCCESS': {
      return {
        ...state,
        availableNumbers: action.payload.mdns.map(availableNumber => ({
          id: availableNumber.id,
          mdn: availableNumber.mdn,
        })),

        createLinks: action.payload.create_phoneline_links,
      };
    }
    default:
      return state;
  }
};

export default PhonelineProvisionReducer;

export const getAvailableNumbers = (state: WebReducerState) =>
  state.phonelineProvision.availableNumbers;

export const getCreateUrlForTeam = (state: WebReducerState, teamId: string) =>
  state.phonelineProvision.createLinks[teamId];
