import type { ApiAuthRefreshRequest, ApiAuthResponse } from '@numbox/services';
import type { ApiActionCreators, Meta } from './sagas/callApi';

export const refreshTokenActionCreators: ApiActionCreators<
  'REFRESH_TOKEN.REQUEST',
  ApiAuthRefreshRequest,
  'REFRESH_TOKEN.SUCCESS',
  ApiAuthResponse,
  Meta
> = {
  request: (params: ApiAuthRefreshRequest, meta) => ({
    type: 'REFRESH_TOKEN.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'REFRESH_TOKEN.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'REFRESH_TOKEN.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};
