import { gql } from '@apollo/client';

export const GET_USER_AND_SERVICE_ADVISORS = gql`
  query usersWithServiceAdvisors(
    $placeId: String!
    $activeIntegrationId: String!
    $serviceAdvisorType: ServiceAdvisorType!
    $cdkClearCache: Boolean
    $skipRos: Boolean = false
  ) {
    placesUsers(placeId: $placeId) {
      id
      user {
        id
        fullName
        email
      }
      serviceAdvisors(serviceAdvisorType: $serviceAdvisorType) {
        ... on ServiceAdvisorQL {
          id
          serviceAdvisorId
          serviceAdvisorName
        }
        ... on CDKServiceAdvisorQL {
          id
          serviceAdvisorId
          serviceAdvisorName
        }
        ... on DealerTrackServiceAdvisorQL {
          id
          serviceAdvisorId
          serviceAdvisorName
        }
      }
    }
    employees(
      activeIntegrationId: $activeIntegrationId
      cdkClearCache: $cdkClearCache
      serviceAdvisorType: $serviceAdvisorType
      skipRos: $skipRos
    ) {
      advisorId
      name
      roCount
      serviceAdvisor {
        id
      }
    }
  }
`;
