import { gql } from '@apollo/client';
import { AdminAccountFragment } from '../../fragments/gql/AdminAccount.gql';

export const CHANGE_SIGNUP_MODE = gql`
  mutation changeSignupMode($input: ChangeSignupModeInput!) {
    changeSignupMode(input: $input) {
      account {
        ...AdminAccountFields
      }
    }
  }
  ${AdminAccountFragment}
`;
