import { gql } from '@apollo/client';
import { UserItemAndNotificationFragment } from '../../fragments/gql/userItem.gql';

export const CHANGE_USER_SETTINGS = gql`
  mutation changeUserSettings(
    $userId: String!
    $input: NotificationSettingsInput!
  ) {
    changeUserSettings(userId: $userId, input: $input) {
      user {
        ...UserItemAndNotificationFields
      }
    }
  }
  ${UserItemAndNotificationFragment}
`;
