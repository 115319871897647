import type { ApolloClient } from '@apollo/client';
import type { ApolloContext } from '../../types';
import { safeRead } from '../../util';

import { GET_CURRENT_USER } from '../../queries/gql/getCurrentUser.gql';
import { GET_STAGES } from '../../queries/gql/getStages.gql';
import { GET_STATUS_BOARD_USERS_FOR_LEADERBOARD } from '../../queries/gql/getStatusBoardUsersForLeaderboard.gql';
import { GET_STATUS_ITEMS } from '../../queries/gql/getStatusItems.gql';

export const convertAssignee = (
  assignee: PubNubStatusItemAssigneeModel,
): statusItem$statusItem$assignee => {
  return {
    __typename: 'UserQL',
    id: assignee.id,
    displayName: assignee.display_name || '',
  };
};

export const convertStage = (
  stage: PubNubStageModel,
): statusItem$statusItem$stage => {
  return {
    __typename: 'StageQL',
    id: stage.id,
    name: stage.name,
    placeId: stage.place_id,
  };
};

export const convertParticipant = (
  participant: PubNubStatusItemParticipantModel,
): statusItem$statusItem$participant => {
  return {
    __typename: 'ParticipantQL',
    id: participant.id,
    firstName: participant.first_name,
    lastName: participant.last_name,
    displayName: participant.display_name,
    displayNameConfidence: participant.display_name_confidence,
    address: participant.address,
  };
};

export const convertConversation = (
  conversation: PubNubStatusItemConversationModel,
): statusItem$statusItem$conversation => {
  return {
    __typename: 'ConversationQL',
    id: conversation.id,
    state: conversation.state,
    inboxId: conversation.inbox_id,
    lastUpdated: conversation.last_updated,
    waitingSince: conversation.waiting_since,
    escalated: conversation.escalated,
    conversationLabels: conversation.conversation_labels
      ? conversation.conversation_labels.map(conversationLabel => {
          return {
            __typename: 'ConversationLabelQL',
            id: conversationLabel.id,
            label: {
              __typename: 'LabelQL',
              id: conversationLabel.label.id,
              name: conversationLabel.label.name,
              category: conversationLabel.label.category,
            },
          };
        })
      : [],
  };
};

export const convertStatusItem = (
  statusItem: PubNubStatusItemModel | PubNubStatusItemUpdateModel,
): statusItem$statusItem => {
  return {
    __typename: 'StatusItemQL',
    id: statusItem.id,
    color: statusItem.color,
    eta: statusItem.eta,
    tag: statusItem.tag,
    orderNumber: statusItem.order_number,
    description: statusItem.description || '',
    createdOn: statusItem.created_on,
    lastUpdateTime: statusItem.last_update_time,
    stage: convertStage(statusItem.stage),
    assignee: convertAssignee(statusItem.assignee),
    participant: statusItem.participant
      ? convertParticipant(statusItem.participant)
      : null,
    conversation: statusItem.conversation
      ? convertConversation(statusItem.conversation)
      : null,
    status: statusItem.status,
    heldSince: statusItem.held_since,
  };
};

const convertColor = (
  color: StatusItemColor | PubNubStatusItemColor,
): 'green' | 'yellow' | 'red' => {
  return color.toLowerCase() as 'green' | 'yellow' | 'red';
};

const getEmptyLeaderboardColors =
  (): userStatusLeaderboardColors$user$statusLeaderboardColors => {
    return {
      __typename: 'LeaderboardColorsQL',
      green: 0,
      yellow: 0,
      red: 0,
    };
  };

const combineLeaderboardColors = (
  leaderboardA: userStatusLeaderboardColors$user$statusLeaderboardColors,
  leaderboardB: userStatusLeaderboardColors$user$statusLeaderboardColors,
): userStatusLeaderboardColors$user$statusLeaderboardColors => {
  return {
    __typename: 'LeaderboardColorsQL',
    green: leaderboardA.green + leaderboardB.green,
    yellow: leaderboardA.yellow + leaderboardB.yellow,
    red: leaderboardA.red + leaderboardB.red,
  };
};

const addStatusItemToStageQuery = (
  client: ApolloClient<any>,
  existingStages: Array<stages$stages>,
  statusItem: statusItem$statusItem,
) => {
  const newStages = existingStages.map(stage => {
    if (stage.id === statusItem.stage.id) {
      return {
        ...stage,
        statusItems: {
          ...stage.statusItems,
          edges: [
            ...(stage.statusItems?.edges.filter(
              edge => edge && edge.node && edge.node.id !== statusItem.id,
            ) ?? []),
            { __typename: 'StatusItemEdge', node: statusItem },
          ],
        },
      };
    }
    return {
      ...stage,
      statusItems: {
        ...stage.statusItems,
        edges: stage.statusItems?.edges.filter(
          edge => edge && edge.node && edge.node.id !== statusItem.id,
        ),
      },
    };
  });

  if (newStages.length > 0) {
    client.writeQuery({
      query: GET_STAGES,
      variables: {
        placeId: statusItem.stage.placeId,
        assigneeId: statusItem.assignee.id,
      },
      data: {
        stages: newStages,
      },
    });
  }
};

const removeStatusItemFromStageQuery = (
  client: ApolloClient<any>,
  existingStages: Array<stages$stages>,
  statusItemId: string,
  placeId: string,
  assigneeId: string,
) => {
  const newStages = existingStages.map(stage => {
    return {
      ...stage,
      statusItems: {
        ...stage.statusItems,
        edges: stage.statusItems?.edges.filter(
          edge => edge && edge.node && edge.node.id !== statusItemId,
        ),
      },
    };
  });

  if (newStages.length > 0) {
    client.writeQuery({
      query: GET_STAGES,
      variables: {
        placeId,
        assigneeId,
      },
      data: {
        stages: newStages,
      },
    });
  }
};

const updateStatusLeaderboardQuery = (
  client: ApolloClient<any>,
  placeId: string,
  leaderboardUpdates: {
    [key: string]: userStatusLeaderboardColors$user$statusLeaderboardColors;
  },
) => {
  const data = safeRead(client, {
    query: GET_STATUS_BOARD_USERS_FOR_LEADERBOARD,
    variables: {
      placeId,
    },
  });

  if (data?.placesUsers) {
    const placesUsers = data?.placesUsers.map(
      (placesUser: statusBoardUsersForLeaderboard$placesUsers) => {
        const leaderboardUpdate = leaderboardUpdates[placesUser.user.id];

        return {
          ...placesUser,
          user: {
            ...placesUser.user,
            statusLeaderboardColors: leaderboardUpdate
              ? combineLeaderboardColors(
                  placesUser.user.statusLeaderboardColors,
                  leaderboardUpdate,
                )
              : placesUser.user.statusLeaderboardColors,
          },
        };
      },
    );

    client.writeQuery({
      query: GET_STATUS_BOARD_USERS_FOR_LEADERBOARD,
      variables: {
        placeId,
      },
      data: {
        placesUsers,
      },
    });
  }
};

const addStatusItemToStatusItemsQuery = (
  client: ApolloClient<any>,
  statusItem: statusItem$statusItem,
) => {
  const statusItemData = safeRead(client, {
    query: GET_STATUS_ITEMS,
    variables: {
      assigneeId: statusItem.assignee.id,
    },
  });

  if (!statusItemData?.statusItems?.edges) {
    return;
  }

  const statusItemEdges: Array<statusItems$statusItems$edges> =
    statusItemData.statusItems.edges;

  const updatedStatusItems = {
    __typename: 'StatusItemConnection',
    pageInfo: statusItemData.statusItems.pageInfo,
    edges: [
      ...(statusItemEdges.filter(edge => edge?.node?.id !== statusItem.id) ??
        []),
      { __typename: 'StatusItemEdge', node: statusItem },
    ],
  };

  client.writeQuery({
    query: GET_STATUS_ITEMS,
    variables: {
      assigneeId: statusItem.assignee.id,
    },
    data: {
      statusItems: updatedStatusItems,
    },
  });
};

const removeStatusItemToStatusItemsQuery = (
  client: ApolloClient<any>,
  statusItem: statusItem$statusItem,
) => {
  const statusItemData = safeRead(client, {
    query: GET_STATUS_ITEMS,
    variables: {
      assigneeId: statusItem.assignee.id,
    },
  });

  if (!statusItemData?.statusItems?.edges) {
    return;
  }

  const statusItemEdges: Array<statusItems$statusItems$edges> =
    statusItemData.statusItems.edges;

  const updatedStatusItems = {
    __typename: 'StatusItemConnection',
    pageInfo: statusItemData.statusItems.pageInfo,
    edges: [
      ...(statusItemEdges.filter(edge => edge?.node?.id !== statusItem.id) ??
        []),
    ],
  };

  client.writeQuery({
    query: GET_STATUS_ITEMS,
    variables: {
      assigneeId: statusItem.assignee.id,
    },
    data: {
      statusItems: updatedStatusItems,
    },
  });
};

export const addStatusItemToCache = (
  { client }: ApolloContext,
  statusItem: PubNubStatusItemModel,
) => {
  const newStatusItem = convertStatusItem(statusItem);

  const currentUserQuery = safeRead(client, { query: GET_CURRENT_USER });
  const userId = currentUserQuery?.currentUser?.id;

  if (userId && newStatusItem.assignee.id === userId) {
    addStatusItemToStatusItemsQuery(client, newStatusItem);
  }

  const data = safeRead(client, {
    query: GET_STAGES,
    variables: {
      placeId: newStatusItem.stage.placeId,
      assigneeId: newStatusItem.assignee.id,
    },
  });

  if ((data?.stages?.length ?? 0) === 0) {
    return;
  }

  addStatusItemToStageQuery(client, data.stages, newStatusItem);

  if (newStatusItem.status === 'ACTIVE') {
    const leaderboard = getEmptyLeaderboardColors();
    leaderboard[convertColor(newStatusItem.color)] += 1;

    updateStatusLeaderboardQuery(client, newStatusItem.stage.placeId, {
      [newStatusItem.assignee.id]: leaderboard,
    });
  }
};

export const updateStatusItemInCache = (
  { client }: ApolloContext,
  statusItem: PubNubStatusItemUpdateModel,
) => {
  const prevColor = statusItem.prev_color;
  const prevAssigneeId = statusItem.prev_assignee_id;
  const prevStatus = statusItem.prev_status;
  const newStatusItem = convertStatusItem(statusItem);

  const currentUserQuery = safeRead(client, { query: GET_CURRENT_USER });
  const userId = currentUserQuery?.currentUser?.id;
  if (userId && newStatusItem.assignee.id === userId) {
    addStatusItemToStatusItemsQuery(client, newStatusItem);
  } else if (
    prevAssigneeId !== newStatusItem.assignee.id &&
    userId &&
    prevAssigneeId === userId
  ) {
    removeStatusItemToStatusItemsQuery(client, newStatusItem);
  }

  const data = safeRead(client, {
    query: GET_STAGES,
    variables: {
      placeId: newStatusItem.stage.placeId,
      assigneeId: newStatusItem.assignee.id,
    },
  });

  if ((data?.stages?.length ?? 0) > 0) {
    addStatusItemToStageQuery(client, data.stages, newStatusItem);
  }

  if (prevAssigneeId !== newStatusItem.assignee.id) {
    const previousAssigneeData = safeRead(client, {
      query: GET_STAGES,
      variables: {
        placeId: newStatusItem.stage.placeId,
        assigneeId: prevAssigneeId,
      },
    });

    if ((previousAssigneeData?.stages?.length ?? 0) > 0) {
      removeStatusItemFromStageQuery(
        client,
        previousAssigneeData.stages,
        newStatusItem.id,
        newStatusItem.stage.placeId,
        prevAssigneeId,
      );
    }
  }

  const isActive = newStatusItem.status === 'ACTIVE';
  const isHoldOrDone = ['HOLD', 'DONE'].includes(newStatusItem.status);

  const wasActive = prevStatus === 'ACTIVE';
  const wasHoldOrDone = ['HOLD', 'DONE'].includes(prevStatus);

  let leaderboardUpdates;
  if (wasActive && isHoldOrDone) {
    // If we went from ACTIVE -> HOLD or DONE, we need to decrement the previous color
    // since only ACTIVE statuses are counted in the leaderboard
    const leaderboard = getEmptyLeaderboardColors();
    leaderboard[convertColor(prevColor)] -= 1;

    leaderboardUpdates = {
      [prevAssigneeId]: leaderboard,
    };
  } else if (wasHoldOrDone && isActive) {
    // If we went from HOLD or DONE -> ACTIVE, we need to increment the color
    // and we dont need to do anything for the prevAssigneeId
    const leaderboard = getEmptyLeaderboardColors();
    leaderboard[convertColor(newStatusItem.color)] += 1;

    leaderboardUpdates = {
      [newStatusItem.assignee.id]: leaderboard,
    };
  } else if (isActive && wasActive) {
    const leaderboard = getEmptyLeaderboardColors();
    leaderboard[convertColor(newStatusItem.color)] += 1;

    let prevAssigneeLeaderboard;
    if (prevAssigneeId === newStatusItem.assignee.id) {
      leaderboard[convertColor(prevColor)] -= 1;
    } else {
      prevAssigneeLeaderboard = getEmptyLeaderboardColors();
      prevAssigneeLeaderboard[convertColor(prevColor)] -= 1;
    }

    if (prevAssigneeLeaderboard) {
      leaderboardUpdates = {
        [newStatusItem.assignee.id]: leaderboard,
        [prevAssigneeId]: prevAssigneeLeaderboard,
      };
    } else {
      leaderboardUpdates = {
        [newStatusItem.assignee.id]: leaderboard,
      };
    }
  }

  if (leaderboardUpdates) {
    updateStatusLeaderboardQuery(
      client,
      newStatusItem.stage.placeId,
      leaderboardUpdates,
    );
  }
};

export const deleteStatusItemFromCache = (
  { client }: ApolloContext,
  statusItem: PubNubStatusItemModel,
) => {
  const newStatusItem = convertStatusItem(statusItem);

  const currentUserQuery = safeRead(client, { query: GET_CURRENT_USER });
  const userId = currentUserQuery?.currentUser?.id;

  if (userId && newStatusItem.assignee.id === userId) {
    removeStatusItemToStatusItemsQuery(client, newStatusItem);
  }

  const data = safeRead(client, {
    query: GET_STAGES,
    variables: {
      placeId: newStatusItem.stage.placeId,
      assigneeId: newStatusItem.assignee.id,
    },
  });

  if ((data?.stages?.length ?? 0) > 0) {
    removeStatusItemFromStageQuery(
      client,
      data.stages,
      newStatusItem.id,
      newStatusItem.stage.placeId,
      newStatusItem.assignee.id,
    );
  }

  if (newStatusItem.status === 'ACTIVE') {
    const leaderboard = getEmptyLeaderboardColors();
    leaderboard[convertColor(newStatusItem.color)] -= 1;

    updateStatusLeaderboardQuery(client, newStatusItem.stage.placeId, {
      [newStatusItem.assignee.id]: leaderboard,
    });
  }
};
