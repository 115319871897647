import { gql } from '@apollo/client';

export const GET_TRANSPORTATION_SETS = gql`
  query getTransportationSets($appointmentAgentSettingsId: String!) {
    transportationSets(
      appointmentAgentSettingsId: $appointmentAgentSettingsId
    ) {
      id
      placeId
      kind
      name
      rules
      enabled
      speechUtterance
      transportationOptions {
        id
        kind
        enabled
        order
        speechShort
        speechLong
        minimumServiceItems
      }
    }
  }
`;
