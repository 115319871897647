import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const CONNECT_AUTOMATION_ACTION_EDGE = gql`
  mutation connectAutomationActionEdge(
    $input: ConnectAutomationActionEdgeInput!
  ) {
    connectAutomationActionEdge(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
