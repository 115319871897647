import { gql } from '@apollo/client';

export const CREATE_STRIPE_ACCOUNT = gql`
  mutation createStripeAccount($input: CreateStripeAccountInput) {
    createStripeAccount(input: $input) {
      accountLinkUrl
      refreshUrl
      returnUrl
    }
  }
`;
