import { isUnsupportedBrowser } from '~/util/browser';

export const layout = {
  elements: {
    messagesHeader: '64pt' /* Elements at top of the messages view */,
    messagesFooter: '48pt' /* Actions at the bottom of the messages view */,
    headerSizeSmall: 48,
    headerSizeLarge: 64,
    bannerHeight: 50,
    appTop: isUnsupportedBrowser ? 64 + 50 : 64,
    navigationSizeSmall: 48,
    navigationSizeLarge: 64,
  },
};

declare module '@mui/material/styles' {
  interface Theme {
    layout?: typeof layout;
  }
  interface ThemeOptions {
    layout?: typeof layout;
  }
}
