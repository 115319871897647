import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import { UserService } from '@numbox/services';
import type {
  ApiUserListResponse,
  ApiFetchUserRequest,
  ApiFetchUserResponse,
  ApiUpdateUserRequest,
  ApiUpdateUserResponse,
  ApiResendInviteRequest,
  ApiResendInviteResponse,
  ApiAddUserRequest,
  ApiAddUserResponse,
  ApiDeleteUserRequest,
  ApiDeleteUserResponse,
  ApiVerifyUserRequest,
  ApiVerifyUserResponse,
} from '@numbox/services';

import type { ApiActionCreators, Meta, ExtractReturn } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const fetchUsers: ApiActionCreators<
  'FETCH_USERS.REQUEST',
  void,
  'FETCH_USERS.SUCCESS',
  ApiUserListResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'FETCH_USERS.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_USERS.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_USERS.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchUsersRequest(
  action: ExtractReturn<typeof fetchUsers.request>,
): Generator<any, void, void> {
  yield serviceCall(
    UserService.fetchUsers,
    fetchUsers,
    action.payload,
    action.meta,
  );
}

export const fetchUser: ApiActionCreators<
  'FETCH_USER.REQUEST',
  ApiFetchUserRequest,
  'FETCH_USER.SUCCESS',
  ApiFetchUserResponse,
  Meta
> = {
  request: (params: ApiFetchUserRequest, meta) => ({
    type: 'FETCH_USER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_USER.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchUserRequest(
  action: ExtractReturn<typeof fetchUser.request>,
): Generator<any, void, void> {
  yield serviceCall(
    UserService.fetchUser,
    fetchUser,
    action.payload,
    action.meta,
  );
}

export const deleteUser: ApiActionCreators<
  'DELETE_USER.REQUEST',
  ApiDeleteUserRequest,
  'DELETE_USER.SUCCESS',
  ApiDeleteUserResponse,
  Meta
> = {
  request: (params: ApiDeleteUserRequest, meta) => ({
    type: 'DELETE_USER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'DELETE_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'DELETE_USER.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onDeleteUserRequest(
  action: ExtractReturn<typeof deleteUser.request>,
): Generator<any, void, void> {
  yield serviceCall(
    UserService.deleteUser,
    deleteUser,
    action.payload,
    action.meta,
  );
}

export const updateUser: ApiActionCreators<
  'UPDATE_USER.REQUEST',
  ApiUpdateUserRequest,
  'UPDATE_USER.SUCCESS',
  ApiUpdateUserResponse,
  Meta
> = {
  request: (params: ApiUpdateUserRequest, meta) => ({
    type: 'UPDATE_USER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'UPDATE_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'UPDATE_USER.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onUpdateUserRequest(
  action: ExtractReturn<typeof updateUser.request>,
): Generator<any, void, void> {
  yield serviceCall(
    UserService.updateUser,
    updateUser,
    action.payload,
    action.meta,
  );
}

export const addUser: ApiActionCreators<
  'ADD_USER.REQUEST',
  ApiAddUserRequest,
  'ADD_USER.SUCCESS',
  ApiAddUserResponse,
  Meta
> = {
  request: (params: ApiAddUserRequest, meta) => ({
    type: 'ADD_USER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'ADD_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'ADD_USER.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onAddUserRequest(
  action: ExtractReturn<typeof addUser.request>,
): Generator<any, void, void> {
  yield serviceCall(UserService.addUser, addUser, action.payload, action.meta);
}

export const resendInvite: ApiActionCreators<
  'RESEND_INVITE.REQUEST',
  ApiResendInviteRequest,
  'RESEND_INVITE.SUCCESS',
  ApiResendInviteResponse,
  Meta
> = {
  request: (params: ApiResendInviteRequest, meta) => ({
    type: 'RESEND_INVITE.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'RESEND_INVITE.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'RESEND_INVITE.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onResendInviteRequest(
  action: ExtractReturn<typeof resendInvite.request>,
): Generator<any, void, void> {
  yield serviceCall(
    UserService.resendInvite,
    resendInvite,
    action.payload,
    action.meta,
  );
}

export const verifyUser: ApiActionCreators<
  'VERIFY_USER.REQUEST',
  ApiVerifyUserRequest,
  'VERIFY_USER.SUCCESS',
  ApiVerifyUserResponse,
  Meta
> = {
  request: (params: ApiVerifyUserRequest, meta) => ({
    type: 'VERIFY_USER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'VERIFY_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'VERIFY_USER.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onVerifyUserRequest(
  action: ExtractReturn<typeof verifyUser.request>,
): Generator<any, void, void> {
  yield serviceCall(
    UserService.verifyUser,
    verifyUser,
    action.payload,
    action.meta,
  );
}

export function* UserSagas(): Saga<any> {
  yield all([
    takeEvery('FETCH_USERS.REQUEST', onFetchUsersRequest),
    takeEvery('DELETE_USER.REQUEST', onDeleteUserRequest),
    takeEvery('UPDATE_USER.REQUEST', onUpdateUserRequest),
    takeEvery('ADD_USER.REQUEST', onAddUserRequest),
    takeEvery('RESEND_INVITE.REQUEST', onResendInviteRequest),
    takeEvery('FETCH_USER.REQUEST', onFetchUserRequest),
    takeEvery('VERIFY_USER.REQUEST', onVerifyUserRequest),
  ]);
}
