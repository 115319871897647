import { gql } from '@apollo/client';
import { StageStub } from '../../fragments/gql/Stage.gql';
import { StatusItemStub } from '../../fragments/gql/StatusItem.gql';

export const GET_STAGES = gql`
  query stages($placeId: String!, $assigneeId: String!) {
    stages(placeId: $placeId) {
      ...StageStub
      statusItems(
        queryFilter: {
          status: { notEqual: "DONE" }
          assigneeId: { equal: $assigneeId }
        }
      ) @connection(key: "statusItems", filter: ["queryFilter"]) {
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...StatusItemStub
          }
        }
      }
    }
  }
  ${StageStub}
  ${StatusItemStub}
`;
