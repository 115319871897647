// snake_case --> Snake Case
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'word' implicitly has an 'any' type.
export const capitalizeWord = word => {
  if (word.length) {
    return word[0].toUpperCase() + word.slice(1);
  }
  return word;
};
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 's' implicitly has an 'any' type.
const toCapitalCase = s => s.split('_').map(capitalizeWord).join(' ');

export default toCapitalCase;
