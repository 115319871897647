import { gql } from '@apollo/client';
import { TeamWithUsersFragment } from '../../../fragments/gql/TeamFragment.gql';

export const REMOVE_USER_FROM_TEAM = gql`
  mutation removeUserFromTeam(
    $input: RemoveUserFromTeamInput!
    $userCount: Int
    $afterUser: String
  ) {
    removeUserFromTeam(input: $input) {
      team {
        ...TeamWithUsers
      }
    }
  }
  ${TeamWithUsersFragment}
`;
