import { gql } from '@apollo/client';

export const ADD_SERVICE_ADVISOR = gql`
  mutation addServiceAdvisor($input: GenericAddServiceAdvisorInput!) {
    addServiceAdvisor(input: $input) {
      serviceAdvisor {
        id
        serviceAdvisorId
        placesUsersId
        serviceAdvisorName
      }
    }
  }
`;

export const REMOVE_SERVICE_ADVISOR = gql`
  mutation removeServiceAdvisor($input: RemoveServiceAdvisorInput!) {
    removeServiceAdvisor(input: $input) {
      id
    }
  }
`;

export const UPDATE_SERVICE_ADVISOR = gql`
  mutation updateServiceAdvisor($input: UpdateServiceAdvisorInput!) {
    updateServiceAdvisor(input: $input) {
      serviceAdvisor {
        id
        serviceAdvisorId
        placesUsersId
        serviceAdvisorName
      }
    }
  }
`;
