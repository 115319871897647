import { gql } from '@apollo/client';

export const GET_ACCOUNT_SETTINGS = gql`
  query getAccountSettings {
    currentAccount {
      id
      assignmentRetention
      nudgesEnabled
      nudgesThreshold
      archiveMissedCalls
      escalationsEnabled
      escalationsThreshold
      hangupSuggestion
      resetOptinDays
      resetOngoingOptin
    }
  }
`;
