import { gql } from '@apollo/client';
import { UserItemAndNotificationFragment } from '../../fragments/gql/userItem.gql';

export const CHANGE_NOTIFICATION_SETTINGS = gql`
  mutation changeNotificationSettings($input: NotificationSettingsInput!) {
    changeNotificationSettings(input: $input) {
      currentUser {
        ...UserItemAndNotificationFields
      }
    }
  }
  ${UserItemAndNotificationFragment}
`;
