import { gql } from '@apollo/client';

// FIXME: This should be converted to a RepairOrder Query.
//        There's no history anymore, only LastData
export const GET_DMS_OBJECT_HISTORY = gql`
  query getDMSObjectHistory($numaObjectId: String!, $dmsType: DMSHistoryType!) {
    dmsObjectHistory(numaObjectId: $numaObjectId, dmsType: $dmsType) {
      lastData(numaObjectId: $numaObjectId, dmsType: $dmsType)
    }
  }
`;
