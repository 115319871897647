import { gql } from '@apollo/client';

export const DELETE_APPOINTMENT_AGENT_SETTINGS = gql`
  mutation deleteAppointmentAgentSettings(
    $input: DeleteAppointmentAgentSettingsInput!
  ) {
    deleteAppointmentAgentSettings(input: $input) {
      appointmentAgentSettings {
        id
      }
    }
  }
`;
