import intersection from 'lodash/intersection';
import type {
  ApiPlacePermissions,
  ApiAccountPermissions,
  ApiPossibleRoles,
  ApiUserPlacePermissions,
  ApiUserAccountPermissions,
} from '@numbox/services';

export const getRole = ({
  accountPermissions,
  placePermissions,
  rolePermissions,
}: {
  placePermissions: ApiUserPlacePermissions;
  accountPermissions: ApiUserAccountPermissions;
  rolePermissions: {
    // eslint-disable-next-line no-unused-vars
    [k in ApiPossibleRoles]: {
      account: Array<ApiAccountPermissions>;
      place: Array<ApiPlacePermissions>;
    };
  };
}): ApiPossibleRoles => {
  if (!accountPermissions || !placePermissions) {
    return 'CUSTOM';
  }
  const roleMatched = Object.keys(rolePermissions).reduce(
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    (bestMatch: ApiPossibleRoles, currentRole: ApiPossibleRoles) => {
      const definedRole = rolePermissions[currentRole];
      const accountsMatched = Object.keys(accountPermissions).filter(
        id =>
          intersection(definedRole.account, accountPermissions[id]).length ===
          definedRole.account.length,
      );
      const placesMatched = Object.keys(placePermissions).filter(
        id =>
          intersection(placePermissions[id], definedRole.place).length ===
          definedRole.place.length,
      );
      if (
        accountsMatched.length === Object.keys(accountPermissions).length &&
        placesMatched.length === Object.keys(placePermissions).length
      ) {
        return currentRole;
      }
      return bestMatch;
    },
    'CUSTOM',
  );
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'ApiPossib... Remove this comment to see the full error message
  return roleMatched;
};
