import get from 'lodash/get';
import { refreshTokenActionCreators } from './refreshTokenActionCreators';

export const getAccessToken = (state: any) =>
  get(state, ['auth', 'accessToken']);

export const getRefreshToken = (state: any) =>
  get(state, ['auth', 'refreshToken']);

export { refreshTokenActionCreators };
