import { gql } from '@apollo/client';

export const GET_CUBE_CONTEXT = gql`
  query getCubeToken {
    cube {
      cubeJwtToken
      cubeApiHost
    }
    currentAccount {
      id
    }
  }
`;
