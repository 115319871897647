import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const UPDATE_VOICE_STEP_UI_HINTS = gql`
  mutation updateVoiceStepUIHints($input: UpdateVoiceStepUIHintsInput!) {
    updateVoiceStepUIHints(input: $input) {
      voiceScript {
        ...VoiceScriptFields
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
