import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const UPDATE_VOICE_SCRIPT = gql`
  mutation updateVoiceScript($input: UpdateVoiceScriptInput!) {
    updateVoiceScript(input: $input) {
      voiceScript {
        ...VoiceScriptFields
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
