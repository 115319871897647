/* eslint-disable no-param-reassign */
import * as React from 'react';

import { ApolloError } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import { GET_ACTIVE_INBOX } from './gql/getActiveInbox.gql';
import {
  COLLECTION_NAMES,
  getActiveInboxOptionsGQL,
  getActiveInboxOptions,
} from '../types';

import type { ActiveInboxOptions } from '../types';
import { GraphQLClient } from '../client/GraphQLClient';

export type ActiveInboxParams = {
  activeInboxId: string;
  state: ConversationState;
  sort: InboxSort;
  options: ActiveInboxOptions;
};

const INITIAL_ACTIVE_INBOX = {
  __typename: 'ActiveInbox',
  inboxId: COLLECTION_NAMES.ALL,
  state: 'ACTIVE',
  sort: 'NEWEST',
  options: getActiveInboxOptionsGQL(),
};

const INITIAL_ACTIVE_INBOX_OPTIONS = getActiveInboxOptions();

type Props = {
  children: (params: ActiveInboxParams) => React.ReactElement | null;
};

export const initializeActiveInbox = (
  data:
    | {
        activeInbox: ActiveInboxParams;
      }
    | null
    | undefined,
) => {
  if (!data || !data.activeInbox) {
    console.log('Initializing GET_ACTIVE_INBOX');
    GraphQLClient.writeQuery({
      query: GET_ACTIVE_INBOX,
      data: { activeInbox: INITIAL_ACTIVE_INBOX },
      variables: {},
    });
  }
};

export const ActiveInbox = (props: Props) => {
  const { children } = props;
  return (
    <Query
      query={GET_ACTIVE_INBOX}
      onError={(error: ApolloError) => console.log(error)}
    >
      {/* @ts-expect-error ts-migrate(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message */}
      {({ data, loading }) => {
        if (!data || !data.activeInbox) {
          console.log(
            "Couldn't get active inbox, returning default. Loading === ",
            loading,
          );

          if (!loading) {
            // Set the initial state for the first read of activeInbox query, since this is
            // a client-only field.
            // Can't use Query.onComplete as those aren't called when fields aren't set.
            initializeActiveInbox(null);
          }

          return children({
            activeInboxId: INITIAL_ACTIVE_INBOX.inboxId,
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'InboxSort... Remove this comment to see the full error message
            sort: INITIAL_ACTIVE_INBOX.sort,
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Conversat... Remove this comment to see the full error message
            state: INITIAL_ACTIVE_INBOX.state,
            options: INITIAL_ACTIVE_INBOX_OPTIONS,
          });
        }

        return children({
          activeInboxId: data.activeInbox.inboxId,
          state: data.activeInbox.state,
          sort: data.activeInbox.sort || INITIAL_ACTIVE_INBOX.state,
          options: getActiveInboxOptions(data.activeInbox.options),
        });
      }}
    </Query>
  );
};
