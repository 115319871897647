import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const RESET_VOICE_SCRIPT = gql`
  mutation resetVoiceScript($input: ResetVoiceScriptInput!) {
    resetVoiceScript(input: $input) {
      voiceScript {
        ...VoiceScriptFields
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
