import { gql } from '@apollo/client';
import { TeamFragment } from '../../fragments/gql/TeamFragment.gql';

export const AutoCompleteTeamsFragment = gql`
  fragment AutoCompleteTeamsFields on TeamConnection {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...TeamFields
      }
    }
  }
  ${TeamFragment}
`;

export const AUTO_COMPLETE_TEAMS = gql`
  query searchTeams(
    $search: String!
    $conversationId: String
    $placeId: String
    $includeEmptyTeams: Boolean
    $first: Int
  ) {
    autocompleteTeams(
      search: $search
      conversationId: $conversationId
      placeId: $placeId
      first: $first
      includeEmptyTeams: $includeEmptyTeams
    ) {
      ...AutoCompleteTeamsFields
    }
  }
  ${AutoCompleteTeamsFragment}
`;
