import type { ApiUserAccountPermissions } from '@numbox/services';

import type { WebReducerState } from '../reducers/types';

export const PERMISSIONS = {
  ACCOUNT: {
    DEMO_ADMIN: 'DEMO_ADMIN',
    DEMO_USER: 'DEMO_USER',
    MANAGE_BILLING: 'MANAGE_BILLING',
    MANAGE_PLACES: 'MANAGE_PLACES',
  },
};

export const SIGN_OUT_USER_SUCCESS = 'SIGN_OUT_USER_SUCCESS';
export const USER_SETUP_IN_PROGRESS = 'USER_SETUP_IN_PROGRESS';
export const USER_SETUP_COMPLETE = 'USER_SETUP_COMPLETE';
export const USER_SETUP_FAILED = 'USER_SETUP_FAILED';

export const SET_BRAND = 'SET_BRAND';
export const SET_FORGOT_PASSWORD_INITIAL_EMAIL =
  'SET_FORGOT_PASSWORD_INITIAL_EMAIL';

export const storeTokens = (accessToken: string, refreshToken: string) => ({
  type: 'STORE_TOKENS',
  payload: {
    accessToken,
    refreshToken,
  },
});

export function setForgotPasswordInitialEmail(email: string) {
  return {
    type: SET_FORGOT_PASSWORD_INITIAL_EMAIL,
    payload: {
      email,
    },
  };
}

export const hasAccountPermission = (
  permissions: ApiUserAccountPermissions,
  permissionName: string,
) => {
  if (!permissions) {
    return false;
  }
  return Object.keys(permissions)
    .map(k => permissions[k])
    .some(accountPermissionSet =>
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      accountPermissionSet.includes(permissionName),
    );
};

export const getAccessToken = (state: WebReducerState) =>
  state.auth.accessToken;

export const getAccountPermissions = (state: WebReducerState) =>
  state.auth.accountPermissions;
export const getPlacePermissions = (state: WebReducerState) =>
  state.auth.placePermissions;

export const getIsDemoAdmin = (state: WebReducerState) =>
  state.auth.isDemoAdmin;

export const getIsDemoUser = (state: WebReducerState) => state.auth.isDemoUser;

export const getIsAuthenticated = (state: WebReducerState) =>
  state.auth.authenticated;
export const getIsAuthenticatedAdmin = (state: WebReducerState) =>
  state.adminAuth.authenticated;
export const getAuthError = (state: WebReducerState) => state.auth.error;
export const getForgotPasswordError = (state: WebReducerState) =>
  state.auth.forgotPasswordError;
export const getForgotPasswordInitialEmail = (state: WebReducerState) =>
  state.auth.forgotPasswordInitialEmail;
export const getUserEmail = (state: WebReducerState) => state.auth.userEmail;
export const getUserId = (state: WebReducerState) => state.auth.userId;
export const getAccountId = (state: WebReducerState) => state.auth.accountId;
export const getAccountName = (state: WebReducerState) =>
  state.auth.accountName;
export const getTeamId = (state: WebReducerState) => state.auth.teamId;

export const getUserSetupStatus = (state: WebReducerState) =>
  state.auth.userSetupInProgress;

export const getIsUserInMobileWebview = (state: WebReducerState) =>
  state.auth.isUserInMobileWebview;

export const signOutUserSuccess = () => ({
  type: SIGN_OUT_USER_SUCCESS,
  payload: {},
});

export const userSetupInProgress = () => ({
  type: USER_SETUP_IN_PROGRESS,
});

export const userSetupComplete = () => ({
  type: USER_SETUP_COMPLETE,
});

export const userSetupFailed = () => ({
  type: USER_SETUP_FAILED,
});
