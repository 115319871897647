import axios from 'axios';

type ApiGreeting = {
  id: string;
  team_id: string;
  kind: 'WELCOME' | 'PROSPECT' | 'OPT_IN';
  content: string;
  after_hours_content: string;
};

export const fetchGreetings = (teamId: string) =>
  axios.get<Array<ApiGreeting>>(`/api/teams/${teamId}/greetings`);

export const updateGreeting = (
  teamId: string,
  greetingId: string,
  attributes: any,
) => {
  const path = `/api/teams/${teamId}/greetings/${greetingId}`;
  return axios.patch<any, ApiGreeting>(path, attributes);
};
