import { gql } from '@apollo/client';
import { InboxBehaviourFragment } from '../../fragments/gql/InboxBehaviourFragment.gql';

export const UPDATE_STATUS_BOARD_VIEW = gql`
  mutation UpdateStatusBoardView($input: UpdateStatusBoardViewInput!) {
    updateStatusBoardView(input: $input) {
      user {
        ...InboxBehaviourFields
      }
    }
  }
  ${InboxBehaviourFragment}
`;
