import { gql } from '@apollo/client';
import { VideoUploadLinkFragment } from '../../fragments/gql/videoUploadLinkFragment.gql';

export const GET_VIDEO_UPLOAD_LINK = gql`
  query geVideoUploadLink($id: String!) {
    videoUploadLink(id: $id) {
      ...VideoUploadLinkFields
    }
  }
  ${VideoUploadLinkFragment}
`;
