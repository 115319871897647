import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiFetchWidgetRequest,
  ApiFetchWidgetResponse,
  ApiUpdateWidgetRequest,
  ApiUpdateWidgetResponse,
} from './types';

class WidgetService implements Service {
  static updateWidget(
    params: ApiUpdateWidgetRequest,
  ): AxiosPromise<ApiUpdateWidgetResponse> {
    const { placeId, fields } = params;
    const path = `/api/places/${placeId}/widget`;
    return axios.patch(path, fields);
  }

  static fetchWidget(
    params: ApiFetchWidgetRequest,
  ): AxiosPromise<ApiFetchWidgetResponse> {
    const { placeId } = params;
    const path = `/api/places/${placeId}/widget`;
    return axios.get(path);
  }
}

export default WidgetService;
