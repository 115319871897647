import { gql } from '@apollo/client';
import { UserItemFragment } from '../../fragments/gql/userItem.gql';

export const CHANGE_USER_PASSWORD = gql`
  mutation ChangeUserPassword($input: ChangeUserPasswordInput!) {
    changeUserPassword(input: $input) {
      currentUser {
        ...UserItemFields
      }
    }
  }
  ${UserItemFragment}
`;
