import { gql } from '@apollo/client';

export const PhoneLinePolicyFieldsFragment = gql`
  fragment PhoneLinePolicyFields on PhoneLinePolicyQL {
    id
    category
    autoOptin
    autoOptinMessage
    delayAnswerDuration
    answerWithKeys
    fixInternalDialing
    assistantPhrase
    archiveMissedCalls
    hangupSuggestion
    autoSendHangupReplies
    hasIdentityPrompt
    allowDisruptions
    answeringScripts {
      id
      scriptType
      order
    }
  }
`;
