import type { ApolloContext } from '../../types';
import { deleteStatusItemFromCache } from './AddStatusItemToCache';

export const PubNubStatusItemDeleted = async (
  obj: any,
  args: { statusItem: PubNubStatusItemModel },
  client: ApolloContext,
) => {
  deleteStatusItemFromCache(client, args.statusItem);
};
