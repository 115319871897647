import { gql } from '@apollo/client';
import { PhoneLineFieldsFragment } from '../../fragments/gql/PhoneLineFields.gql';

export const CREATE_PHONELINE = gql`
  mutation createPhoneLine($input: CreatePhoneLineInput!) {
    createPhoneLine(input: $input) {
      phoneline {
        ...PhoneLineFields
      }
    }
  }
  ${PhoneLineFieldsFragment}
`;
