import type { ExtractReturn } from '@numbox/modules';
import {
  BillingIdentityModule,
  BusinessSearchModule,
  PhoneNumberModule,
  SignupModule,
} from '@numbox/modules';

import type { WebReducerState } from '../reducers/types';

export const ACTIVATION_INITIAL_STATE = {
  accountClaimed: false,
  billingIdentityId: '',
  bizListings: [],
  bizName: '',
  bizPhone: '',
  workerName: '',
  workerPhone: '',
  email: '',
  orderId: '',
  password: '',
  signupMode: null,
  source: 'NumberAI',
};

type BizListing = {
  id: string;
  name: string;
  address: string;
};

export type ActivationReducerState = {
  accountClaimed: boolean;
  billingIdentityId: string;
  bizListings: Array<BizListing>;
  bizName: string;
  bizPhone: string;
  workerName: string;
  workerPhone: string;
  email: string;
  password: string;
  mdn?: string;
  link?: string;
  orderId: string;
  signupMode: string | null | undefined;
  source: string;
};

export type ActivationProps = {
  name?: string;
  phone?: string;
  workerName?: string;
  workerPhone?: string;
  email?: string;
  password?: string;
};

type AddOrderIdAction = {
  type: 'ACTIVATION_ADD_ORDER_ID';
  payload: {
    orderId: string;
  };
};

export type BusinessInfo = {
  name: string;
  phone: string;
};

type AddBusinessInfoAction = {
  type: 'ACTIVATION_ADD_BUSINESS_INFO';
  payload: BusinessInfo;
};

export type ContactInfo = {
  workerName: string;
  email: string;
  password: string;
  workerPhone?: string;
};

type AddBusinessContactInfoAction = {
  type: 'ACTIVATION_ADD_BUSINESS_CONTACT_INFO';
  payload: ContactInfo;
};

type ActivationActions =
  | AddBusinessInfoAction
  | AddBusinessContactInfoAction
  | AddOrderIdAction
  | ExtractReturn<typeof PhoneNumberModule.fetchAvailablePhoneNumbers.success>
  | ExtractReturn<typeof BusinessSearchModule.searchForBusiness.success>
  | ExtractReturn<typeof BillingIdentityModule.fetchBillingIdentity.success>
  | ExtractReturn<typeof BillingIdentityModule.createBillingIdentity.success>
  | ExtractReturn<typeof SignupModule.signup.success>;

export const addBusinessInfo = (info: BusinessInfo) => ({
  type: 'ACTIVATION_ADD_BUSINESS_INFO',
  payload: {
    name: info.name,
    phone: info.phone,
  },
});

export const addBusinessContactInfo = (info: ContactInfo) => ({
  type: 'ACTIVATION_ADD_BUSINESS_CONTACT_INFO',
  payload: {
    workerName: info.workerName,
    workerPhone: info.workerPhone,
    email: info.email,
    password: info.password,
  },
});

export const addOrderId = (orderId: string) => ({
  type: 'ACTIVATION_ADD_ORDER_ID',
  payload: {
    orderId,
  },
});

export const getBillingIdentityId = (state: WebReducerState) =>
  state.activation.billingIdentityId;

export const getAccountClaimed = (state: WebReducerState) =>
  state.activation.accountClaimed;

export const getBillingOrderId = (state: WebReducerState) =>
  state.activation.orderId;

const ActivationReducer = (
  state: ActivationReducerState = ACTIVATION_INITIAL_STATE,
  action: ActivationActions,
) => {
  switch (action.type) {
    case 'ACTIVATION_ADD_BUSINESS_INFO':
      return {
        ...state,
        bizPhone: action.payload.phone,
        bizName: action.payload.name,
      };

    case 'ACTIVATION_ADD_BUSINESS_CONTACT_INFO':
      return {
        ...state,
        workerName: action.payload.workerName,
        workerPhone: action.payload.workerPhone,
        email: action.payload.email,
        password: action.payload.password,
      };

    case 'ACTIVATION_ADD_ORDER_ID':
      return {
        ...state,
        orderId: action.payload.orderId,
      };

    case 'FETCH_BILLING_IDENTITY.SUCCESS':
      return {
        ...state,
        accountClaimed: action.payload.claimed,
        billingIdentityId: action.payload.billing_identity.id,
        orderId: action.payload.billing_identity.billing_order_id,
        signupMode: action.payload.billing_identity.signup_mode,
        source: action.payload.billing_identity.source,
      };

    case 'CREATE_BILLING_IDENTITY.SUCCESS':
      return {
        ...state,
        accountClaimed: action.payload.claimed,
        billingIdentityId: action.payload.billing_identity.id,
        orderId: action.payload.billing_identity.billing_order_id,
      };

    case 'SIGNUP.SUCCESS':
      return {
        ...state,
        accountClaimed: action.payload.claimed,
        billingIdentityId: action.payload.billing_identity.id,
        orderId: action.payload.billing_identity.billing_order_id,
      };

    case 'FETCH_AVAILABLE_PHONE_NUMBERS.SUCCESS': {
      const links = action.payload.create_links;
      const link = Object.keys(links).map(k => links[k])[0];
      return {
        ...state,
        mdn: action.payload.mdns[0].mdn,
        link,
      };
    }
    case 'SEARCH_FOR_BUSINESS.SUCCESS': {
      const businesses = action.payload;
      return {
        ...state,
        bizListings: businesses,
      };
    }
    default:
      return state;
  }
};

export default ActivationReducer;
