import { gql } from '@apollo/client';

export const GET_PLACES_DEGRADED_INBOX_EXPERIENCE_STATE = gql`
  query getPlacesDegradedInboxExperienceState($accountId: String!) {
    places(first: 100, queryFilter: { accountId: { equal: $accountId } }) {
      edges {
        node {
          id
          hasDegradedInboxExperience
        }
      }
    }
  }
`;
