/* eslint-disable no-param-reassign */
import { produce } from '@numbox/util';
import type { InboxActions } from './inbox.actions';
import './inbox.actions';
import { DEFAULT_INBOX_FILTER } from './inbox.constants';

export type InboxReducerState = {
  conversationListScrollTop: number;
  selectedInboxId: string | null | undefined;
  navDrawerOpen: false;
  participantDrawerOpen: false;
  conversationComposeMode: 'text' | 'note' | 'shortcuts';
};

export const INBOX_INITIAL_STATE = {
  conversationListScrollTop: 0,
  selectedInboxId: 'all',
  navDrawerOpen: false,
  participantDrawerOpen: false,
  conversationComposeMode: 'text',
};

type InboxReducerActions = InboxActions;

export const InboxReducer = (
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ conversationListScrollTop: number; selecte... Remove this comment to see the full error message
  state: InboxReducerState = INBOX_INITIAL_STATE,
  action: InboxReducerActions,
) => {
  switch (action.type) {
    case 'SELECT_INBOX': {
      return {
        ...state,
        selectedInboxId: action.payload.inboxId,
        // We always want the new inbox to start out on "active"
        activeFilter: DEFAULT_INBOX_FILTER,
      };
    }
    case 'TOGGLE_NAV_DRAWER': {
      return {
        ...state,
        navDrawerOpen: !state.navDrawerOpen,
      };
    }
    case 'TOGGLE_PARTICIPANT_DRAWER': {
      return {
        ...state,
        participantDrawerOpen: !state.participantDrawerOpen,
      };
    }

    case 'UPDATE_CONVERSATION_LIST_SCROLL_TOP': {
      const { scrollTop } = action.payload;
      return produce(state, old => {
        old.conversationListScrollTop = scrollTop;
      });
    }

    case 'SET_CONVERSATION_COMPOSE_MODE': {
      const { mode } = action.payload;
      return {
        ...state,
        conversationComposeMode: mode,
      };
    }

    default: {
      return state;
    }
  }
};
