import { createSelector } from 'reselect';
import 'react-router-dom';
import type { Location } from 'history';
import type { WebReducerState } from '../reducers/types';
import '../reducers/types';
import { urlWithSearch } from '../util/router';

export const MODAL_PATHS = ['jobs', 'guide', 'settings', 'contacts', 'people'];

export type StoreLastPathAction = {
  type: 'STORE_LAST_PATH';
  payload: {
    path: string;
  };
};

export const storeLastPath = (path: string) => ({
  type: 'STORE_LAST_PATH',
  payload: {
    path,
  },
});

export type LocationChangeAction = {
  type: '@@router/LOCATION_CHANGE';
  payload: {
    location: Location<{ isRedirect: boolean }>;
  };
};

type ModalActions = StoreLastPathAction | LocationChangeAction;

export const isInModal = (currentPath: string) => {
  if (!currentPath) {
    return false;
  }

  const leadingSlashMatch = /^\/*/;
  const sanitized = currentPath.replace(leadingSlashMatch, '');
  return (
    MODAL_PATHS.some(path => sanitized.startsWith(path)) ||
    (sanitized.includes('admin/dashboard/') && sanitized.includes('jobs/'))
  );
};

const isEnteringModal = (previousPath: string, currentPath: string) =>
  !isInModal(previousPath) && isInModal(currentPath);

const isExitingModal = (previousPath: string, currentPath: string) =>
  isInModal(previousPath) && !isInModal(currentPath);

export type ModalReducerState = {
  currentPath: string;
  lastPath: string;
  lastNonModalPath?: string;
  firstModalPath?: string;
  isRedirect?: boolean;
};

export const MODAL_INITIAL_STATE = {
  lastPath: '/inbox',
  currentPath: '/inbox',
};

export const ModalReducer = (
  state: ModalReducerState = MODAL_INITIAL_STATE,
  action: ModalActions,
) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      const { location } = action.payload;
      const currentPath = urlWithSearch(location.pathname, location.search);
      const lastPath = state.currentPath;
      let isRedirect = false;

      if (location && location.state && location.state.isRedirect) {
        // eslint-disable-next-line prefer-destructuring
        isRedirect = location.state.isRedirect;
      }

      let lastNonModalPath;
      let firstModalPath;
      if (isEnteringModal(lastPath, currentPath)) {
        lastNonModalPath = lastPath;
        firstModalPath = currentPath;
      } else if (isExitingModal(lastPath, currentPath)) {
        lastNonModalPath = null;
        firstModalPath = null;
      } else {
        ({ lastNonModalPath, firstModalPath } = state);
      }

      const resultState = {
        ...state,
        lastPath,
        currentPath,
        lastNonModalPath,
        firstModalPath,
        isRedirect,
      };

      return resultState;
    }
    case 'STORE_LAST_PATH':
      return {
        ...state,
        lastPath: action.payload.path,
      };

    default:
      return state;
  }
};

export const getCurrentPath = (state: WebReducerState) =>
  state.modal.currentPath;
export const getLastPath = (state: WebReducerState) => state.modal.lastPath;
export const getLastNonModalPath = (state: WebReducerState) =>
  state.modal.lastNonModalPath;
export const getFirstModalPath = (state: WebReducerState) =>
  state.modal.firstModalPath;
export const getRedirectState = (state: WebReducerState) =>
  state.modal.isRedirect;

export const getShowModalBackButton = createSelector(
  [getCurrentPath, getFirstModalPath, getRedirectState],
  (currentPath, firstModalPath, isRedirect) =>
    currentPath !== firstModalPath && !isRedirect,
);
