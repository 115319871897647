import { gql } from '@apollo/client';

export const StageStub = gql`
  fragment StageStub on StageQL {
    id
    placeId
    name
    icon
    sortOrder
    messageTemplate
    renderedMessageTemplate
    updateMessageTemplate
    renderedUpdateMessageTemplate
    speechUpdate
    speechUrl
  }
`;
