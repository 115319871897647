import axios from 'axios';
import type { Dispatch } from 'redux';
import 'redux';

const apiPath = '/integrations/facebook/';

export const AUTHORIZE_FACEBOOK = 'AUTHORIZE_FACEBOOK';
export function authorizeFacebook(token: string) {
  return (dispatch: Dispatch<any>) => {
    const path = `${apiPath}authorize`;
    axios
      .get(path, { params: { user_token: token } })
      .then(response => {
        dispatch({
          type: AUTHORIZE_FACEBOOK,
          payload: response.data,
        });
      })
      .catch(error => console.log(error));
  };
}

export const LOAD_FACEBOOK_CONFIG = 'LOAD_FACEBOOK_CONFIG';
export function loadFacebookConfig(
  onSuccess: (arg0?: any) => any = () => {},
  onFailure: (arg0?: any) => any = () => {},
) {
  return (dispatch: Dispatch<any>) => {
    const path = `${apiPath}config`;
    axios
      .get(path)
      .then(response => {
        dispatch({
          type: LOAD_FACEBOOK_CONFIG,
          payload: {
            appId: response.data.app_id,
          },
        });

        dispatch(onSuccess(response.data.app_id));
      })
      .catch(() => {
        onFailure();
      });
  };
}

export const CREATE_FACEBOOK_CHANNEL = 'CREATE_FACEBOOK_CHANNEL';

type FBOptions = {
  name: string;
  pageId: string;
  pageToken: string;
  fbUserId: string;
  link: string;
  username: string | null | undefined;
};

export function createFacebookChannel(
  options: FBOptions,
  accountId: string,
  teamId: string,
) {
  return (dispatch: Dispatch<any>) => {
    const path = '/api/channels';
    const payload = {
      team_id: teamId,
      account_id: accountId,
      name: options.name,
      type: 'facebook',
      facebook: {
        page: options.pageId,
        token: options.pageToken,
        fb_user_id: options.fbUserId,
        link: options.link,
        username: options.username,
      },
    };

    axios.post(path, payload).then(response =>
      dispatch({
        type: CREATE_FACEBOOK_CHANNEL,
        payload: response.data,
      }),
    );
  };
}
