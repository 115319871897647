import { gql } from '@apollo/client';
import { ChannelFieldsFragment } from '../../fragments/gql/channelFields.gql';

export const GET_CHANNELS = gql`
  query channels($placeId: String, $includeInactive: Boolean) {
    channels(placeId: $placeId, includeInactive: $includeInactive) {
      ...ChannelFields
    }
  }
  ${ChannelFieldsFragment}
`;
