import clone from 'lodash/clone';

// This is an api def, we use snake_case
type ApiUser = {
  email: string;
  id: string;
  // eslint-disable-next-line camelcase
  first_name: string;
  // eslint-disable-next-line camelcase
  last_name: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WebReducerState = any;
// Selectors
export const getUsersTypingByConversation = (
  state: WebReducerState,
  conversationId: string,
) => {
  const users = state.typing;
  return users[conversationId] || [];
};
// Actions
export const STARTED_TYPING = 'numbox-web/ui/STARTED_TYPING';
export const STOPPED_TYPING = 'numbox-web/ui/STOPPED_TYPING';
export const KEPT_TYPING = 'numbox-web/ui/KEPT_TYPING';
export const USER_TYPING = 'numbox-web/ui/USER_TYPING';
export const DISCARD_USER = 'numbox-web/ui/DISCARD_USER';
export const RESET_UI_STATES = 'numbox-web/ui/RESET_UI_STATES';
export const USERS_TYPING = 'numbox-web/ui/USERS_TYPING';

type ResetUIState = {
  type: 'numbox-web/ui/RESET_UI_STATES';
};

// Action creators
export const resetTypingIndicator = (): ResetUIState => ({
  type: RESET_UI_STATES,
});
export type UserTypingPayload = {
  conversationId: string;
  user: ApiUser;
  isTyping: boolean;
  updatedAt: number;
};
export type UserTypingAction = {
  type: 'numbox-web/ui/USER_TYPING';
  payload: UserTypingPayload;
};
export const userTyping = (
  user: ApiUser,
  conversationId: string,
  isTyping: boolean,
): UserTypingAction => ({
  type: USER_TYPING,

  payload: {
    conversationId,
    user,
    isTyping,
    updatedAt: new Date().getTime(),
  },
});
export type StartedTyping = {
  type: 'numbox-web/ui/STARTED_TYPING';
  payload: {
    conversationId: string;
  };
};
export const startedTyping = (conversationId: string): StartedTyping => ({
  type: STARTED_TYPING,

  payload: {
    conversationId,
  },
});
export type KeptTyping = {
  type: 'numbox-web/ui/KEPT_TYPING';
  payload: {
    conversationId: string;
  };
};
export const keptTyping = (conversationId: string): KeptTyping => ({
  type: KEPT_TYPING,

  payload: {
    conversationId,
  },
});
export type StoppedTyping = {
  type: 'numbox-web/ui/STOPPED_TYPING';
  payload: {
    conversationId: string;
  };
};
export const stoppedTyping = (conversationId: string): StoppedTyping => ({
  type: STOPPED_TYPING,

  payload: {
    conversationId,
  },
});
export type DiscardUser = {
  type: 'numbox-web/ui/DISCARD_USER';
  payload: {
    discardedUser: ApiUser;
    conversationId: string;
  };
};
export const discardUser = (
  conversationId: string,
  discardedUser: ApiUser,
): DiscardUser => ({
  type: DISCARD_USER,

  payload: {
    conversationId,
    discardedUser,
  },
});
export type UserTyping = {
  state?: {
    user: ApiUser;
    conversationId: string;
    isTyping: boolean;
  };
};
export type UsersTyping = {
  user: ApiUser;
  updatedAt: number;
}[];
export type TypingReducerState = Record<string, UsersTyping>;
export type UsersTypingAction = {
  type: 'numbox-web/ui/USERS_TYPING';
  payload: TypingReducerState;
};
export const usersTyping = (
  initialState: TypingReducerState,
): UsersTypingAction => ({
  type: USERS_TYPING,
  payload: initialState,
});
// Reducers
export const UI_REDUCER_INITIAL_STATE = {};
type Actions = ResetUIState | UserTypingAction | UsersTypingAction;
export const TypingReducer = (
  state: TypingReducerState = UI_REDUCER_INITIAL_STATE,
  action: Actions,
) => {
  switch (action.type) {
    case RESET_UI_STATES: {
      return UI_REDUCER_INITIAL_STATE;
    }

    case USER_TYPING: {
      const { conversationId, user, isTyping, updatedAt } = action.payload;
      const users = state[conversationId];
      let usersInConversation = clone(users);

      if (!usersInConversation) {
        usersInConversation = [];
      }

      if (isTyping) {
        const userExists = usersInConversation.find(u => u.user.id === user.id);

        if (userExists) {
          usersInConversation.filter(u => u.user.id === user.id)[0].updatedAt =
            updatedAt;
        } else {
          usersInConversation.push({
            user,
            updatedAt,
          });
        }

        return { ...state, [conversationId]: usersInConversation };
      }

      const withoutUser = usersInConversation.filter(
        u => u.user.id !== user.id,
      );
      return { ...state, [conversationId]: withoutUser };
    }

    case USERS_TYPING: {
      return action.payload;
    }
    default:
      return state;
  }
};
