import { gql } from '@apollo/client';

export const HostedSmsPortFragment = gql`
  fragment HostedSmsPortFields on HostedSmsPortQL {
    id
    mdn
    addressSid
    sid
    status
    verificationAttempts
    verificationCode
    loaSid
    customerName
    customerEmail
  }
`;
