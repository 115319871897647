import cloneDeep from 'lodash/cloneDeep';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as TranslationApi from '../api/translation';

// Actions
export const RESET_TRANSLATION = 'numbox-web/translation/RESET_TRANSLATION';
export const FETCH_TRANSLATION = 'numbox-web/translation/FETCH_TRANSLATION';
export const FETCH_TRANSLATION_SUCCESS =
  'numbox-web/translation/FETCH_TRANSLATION_SUCCESS';
export const FETCH_TRANSLATION_FAILURE =
  'numbox-web/translation/FETCH_TRANSLATION_FAILURE';

export const UPDATE_TRANSLATION = 'numbox-web/translation/UPDATE_TRANSLATION';
export const UPDATE_TRANSLATION_SUCCESS =
  'numbox-web/translation/FETCH_TRANSLATION_SUCCESS';
export const UPDATE_TRANSLATION_FAILURE =
  'numbox-web/translation/FETCH_TRANSLATION_FAILURE';

export const noop = () => {};

// Action creators
export const fetchTranslation = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
  teamId,
  onSuccess = noop,
  onFailure = noop,
) => ({
  type: FETCH_TRANSLATION,
  payload: { teamId, onSuccess, onFailure },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const fetchTranslationSuccess = (teamId, config) => ({
  type: FETCH_TRANSLATION_SUCCESS,
  payload: { teamId, config },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const fetchTranslationFailure = (teamId, error) => ({
  type: FETCH_TRANSLATION_FAILURE,
  payload: error,
  error: true,
});

export const updateTranslation = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
  teamId,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'enabled' implicitly has an 'any' type.
  enabled,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'language' implicitly has an 'any' type.
  language,
  onSuccess = noop,
  onFailure = noop,
) => ({
  type: UPDATE_TRANSLATION,
  payload: { teamId, enabled, language, onSuccess, onFailure },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const updateTranslationSuccess = (teamId, config) => ({
  type: UPDATE_TRANSLATION_SUCCESS,
  payload: { teamId, config },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const updateTranslationFailure = (teamId, error) => ({
  type: UPDATE_TRANSLATION_FAILURE,
  payload: { teamId, error },
  error: true,
});

// Sagas
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export function* loadTranslation(action) {
  const { teamId, onSuccess, onFailure } = action.payload;
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(TranslationApi.fetchTranslation, teamId);
    yield put(fetchTranslationSuccess(teamId, response.data));
    yield call(onSuccess);
  } catch (error: any) {
    yield put(fetchTranslationFailure(teamId, error.message));
    yield call(onFailure);
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export function* updateTranslationConfig(action) {
  const { teamId, enabled, language, onSuccess, onFailure } = action.payload;
  const payload = { enabled, language };
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(
      TranslationApi.updateTranslation,
      teamId,
      payload,
    );

    yield put(updateTranslationSuccess(teamId, response.data));
    yield call(onSuccess);
  } catch (error: any) {
    yield put(updateTranslationFailure(teamId, error.message));
    yield call(onFailure);
  }
}

export function* watchTranslation() {
  yield takeEvery(FETCH_TRANSLATION, loadTranslation);
  yield takeEvery(UPDATE_TRANSLATION, updateTranslationConfig);
}

// Reducer
export const TRANSLATION_REDUCER_INITIAL_STATE = {
  byTeamId: {},
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'translation' implicitly has an 'any' ty... Remove this comment to see the full error message
const fromTranslationApi = translation => ({
  name: translation.name,
  language: translation.language,
  supportedLanguages: translation.supported_languages
    ? Object.keys(translation.supported_languages).map(k => ({
        language: k,
        name: translation.supported_languages[k],
      }))
    : [],
  enabled: translation.enabled,
});

const TranslationReducer = (
  state = TRANSLATION_REDUCER_INITIAL_STATE,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  action,
) => {
  switch (action.type) {
    case RESET_TRANSLATION: {
      return TRANSLATION_REDUCER_INITIAL_STATE;
    }
    case FETCH_TRANSLATION_SUCCESS:
    case UPDATE_TRANSLATION_SUCCESS: {
      const { teamId, config } = action.payload;
      const newState = cloneDeep(state);
      const result = fromTranslationApi(config);
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newState.byTeamId[teamId] = result;
      return newState;
    }
    default:
      return state;
  }
};

export default TranslationReducer;

// Selectors
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getTranslationConfig = (state, teamId) =>
  state.translation.byTeamId[teamId];
