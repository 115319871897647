import type { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ ownerState, theme }) => ({
      ...(ownerState.flipped === true && {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        color: theme.palette.text.primary,
      }),
    }),
  },
};
