import type { ApolloClient } from '@apollo/client';

import { safeRead } from '../../../util';
import { GET_TEAMS_BY_PLACE_ID } from '../../../queries/gql/getTeamsByPlaceId.gql';
import { TeamFragment } from '../../../fragments/gql/TeamFragment.gql';

export const convertTeam = (team: PubNubTeamModel): TeamFields => {
  return {
    __typename: 'TeamQL',
    id: team.id,
    name: team.name,
    avatar: team.avatar,
  };
};

export const convertTeamSettings = (
  team: PubNubTeamSettingsModel,
): TeamSettingsFields => {
  return {
    __typename: 'TeamQL',
    id: team.id,
    name: team.name,
    avatar: team.avatar,
    assignmentStyle: team.assignment_style,
    userCount: team.user_count,
  };
};

export const upsertTeamInCache = (
  client: ApolloClient<any>,
  team: PubNubTeamSettingsModel,
) => {
  const newTeam = convertTeamSettings(team);

  client.writeFragment({
    id: `TeamQL:${newTeam.id}`,
    fragment: TeamFragment,
    fragmentName: 'TeamFields',
    data: newTeam,
  });

  const data = safeRead(client, {
    query: GET_TEAMS_BY_PLACE_ID,
    variables: { id: team.place_id },
  });

  const existingTeams = data?.teams ?? [];

  client.writeQuery({
    query: GET_TEAMS_BY_PLACE_ID,
    variables: { id: team.place_id },
    data: {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'l' implicitly has an 'any' type.
      teams: [newTeam, ...existingTeams.filter(l => l.id !== newTeam.id)],
    },
  });
};

export const removeTeamFromCache = (
  client: ApolloClient<any>,
  placeId: string,
  teamId: string,
) => {
  const data = safeRead(client, {
    query: GET_TEAMS_BY_PLACE_ID,
    variables: { id: placeId },
  });

  const existingTeams = data?.teams ?? [];

  client.writeQuery({
    query: GET_TEAMS_BY_PLACE_ID,
    variables: { id: placeId },
    data: {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'l' implicitly has an 'any' type.
      teams: existingTeams.filter(l => l.id !== teamId),
    },
  });
};
