import { gql } from '@apollo/client';

export const GET_REPAIR_SERVICES = gql`
  query getRepairServices($activeIntegrationId: String!) {
    repairServices(activeIntegrationId: $activeIntegrationId) {
      id
      name
      enabledSupportedVehiclesCount
      disabledSupportedVehiclesCount
    }
  }
`;
