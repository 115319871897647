export const EMPTY_CONNECTION = {
  pageInfo: {
    __typename: 'PageInfo',
    startCursor: null,
    endCursor: null,
    hasNextPage: false,
  },

  edges: [],
};
