import uniqBy from 'lodash/uniqBy';
import type { ApolloClient } from '@apollo/client';
import get from 'lodash/get';

import { EMPTY_CONNECTION, safeRead, safeReadFragment } from '../../util';
import { buildGetPaymentsQueryFilter } from '../../queries/GetPaymentRequests';
import type { PaymentsFilterType } from '../../queries/GetPaymentRequests';

import { GET_ACTIVE_PAYMENTS_COLLECTION } from '../../queries/gql/getActivePaymentsCollection.gql';
import { GET_CURRENT_USER } from '../../queries/gql/getCurrentUser.gql';
import { GET_PAYMENT_REQUEST } from '../../queries/gql/getPaymentRequest.gql';
import { GET_PAYMENT_REQUESTS } from '../../queries/gql/getPaymentRequests.gql';

import {
  PaymentRequestFragment,
  PaymentRequestStub,
} from '../../fragments/gql/paymentRequestFragment.gql';
import { COLLECTION_NAMES } from '../../types';

const convertPaymentRequestPlace = (place: PubNubPaymentRequestPlaceModel) => ({
  __typename: 'PlaceQL',
  id: place.id,
  name: place.name,
});

const convertPaymentRequestParticiapnt = (
  participant: PubnubPaymentRequestParticipantModel,
) => ({
  __typename: 'ParticipantQL',
  id: participant.id,
  displayName: participant.display_name,
});

const convertPaymentRequestCustomerParticiapnt = (
  participant: PubnubPaymentRequestCustomerParticipantModel,
) => ({
  __typename: 'ParticipantQL',
  id: participant.id,
  displayName: participant.display_name,
  displayNameConfidence: participant.display_name_confidence,
  address: participant.address,
  source: participant.source,
});

const convertPaymentRequestRefunds = (
  refunds: Array<PubNubPaymentRequestRefund>,
): Array<PaymentRequestFields$refunds> => {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "RefundQL"; id: string; comple... Remove this comment to see the full error message
  return refunds.map(refund => ({
    __typename: 'RefundQL',
    id: refund.id,
    completedOn: refund.completed_on,
    status: refund.status,
    netAmount: refund.net_amount,
    amount: refund.amount,
    type: refund.type,
    reason: refund.reason,
    note: refund.note,
    issuer: convertPaymentRequestParticiapnt(refund.issuer),
  }));
};

const convertPaymentRequestDisputes = (
  disputes: Array<PubNubPaymentRequestDispute>,
): Array<PaymentRequestFields$disputes> => {
  return disputes.map(dispute => ({
    __typename: 'DisputeQL',
    id: dispute.id,
    status: dispute.status,
    reason: dispute.reason,
  }));
};

export const convertPubNubPaymentRequestToPaymentRequestFields = (
  paymentRequest: PubNubPaymentRequestModel,
): PaymentRequestFields => ({
  __typename: 'PaymentRequestQL',
  id: paymentRequest.id,
  encodedId: paymentRequest.encoded_id,
  amount: paymentRequest.amount,
  currency: paymentRequest.currency,
  amountCaptured: paymentRequest.amount_captured,
  captureMethod: paymentRequest.capture_method,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  description: paymentRequest.description,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  note: paymentRequest.note,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  orderId: paymentRequest.order_id,
  status: paymentRequest.status,
  createdOn: paymentRequest.created_on,
  lastUpdated: paymentRequest.last_updated,
  lastOpened: paymentRequest.last_opened,
  charged: paymentRequest.charged,
  requested: paymentRequest.requested,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  billingName: paymentRequest.billing_name,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  billingAddress: paymentRequest.billing_address,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  billingEmail: paymentRequest.billing_email,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  billingMethod: paymentRequest.billing_method,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  billingLastFourDigits: paymentRequest.billing_last_four_digits,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: string; id: string; name: stri... Remove this comment to see the full error message
  place: convertPaymentRequestPlace(paymentRequest.place),
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: string; id: string; displayNam... Remove this comment to see the full error message
  sender: convertPaymentRequestParticiapnt(paymentRequest.sender),
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: string; id: string; displayNam... Remove this comment to see the full error message
  customer: convertPaymentRequestCustomerParticiapnt(paymentRequest.customer),
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: string; id: string; displayNam... Remove this comment to see the full error message
  canceledBy: paymentRequest.canceled_by
    ? convertPaymentRequestParticiapnt(paymentRequest.canceled_by)
    : null,
  canceledAt: paymentRequest.canceled_at,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'PubNubPaymentRequestCancellationReason | nul... Remove this comment to see the full error message
  cancellationReason: paymentRequest.cancellation_reason,
  holdExpiration: paymentRequest.hold_expiration,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  applicationId: paymentRequest.application_id,
  conversation: paymentRequest.conversation
    ? {
        __typename: 'ConversationQL',
        id: paymentRequest.conversation.id,
      }
    : null,
  totalRefundedAmount: paymentRequest.total_refunded_amount,
  netAmount: paymentRequest.net_amount,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  stripeUrl: paymentRequest.stripe_url,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  failureCode: paymentRequest.failure_code,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  failureMessage: paymentRequest.failure_message,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  outcomeSellerMessage: paymentRequest.outcome_seller_message,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  outcomeType: paymentRequest.outcome_type,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  outcomeReason: paymentRequest.outcome_reason,
  refunds: paymentRequest.refunds
    ? convertPaymentRequestRefunds(paymentRequest.refunds)
    : [],
  disputes: paymentRequest.disputes
    ? convertPaymentRequestDisputes(paymentRequest.disputes)
    : [],
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  receiptUrl: paymentRequest.receipt_url,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  attachmentUrl: paymentRequest.attachment_url,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  attachmentFilename: paymentRequest.attachment_filename,
});

const convertPubNubPaymentRequestToPaymentRequestStubFields = (
  paymentRequest: PubNubPaymentRequestModel,
): PaymentRequestStubFields => ({
  __typename: 'PaymentRequestQL',
  id: paymentRequest.id,
  encodedId: paymentRequest.encoded_id,
  amount: paymentRequest.amount,
  currency: paymentRequest.currency,
  amountCaptured: paymentRequest.amount_captured,
  captureMethod: paymentRequest.capture_method,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  description: paymentRequest.description,
  status: paymentRequest.status,
  lastUpdated: paymentRequest.last_updated,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: string; id: string; name: stri... Remove this comment to see the full error message
  place: convertPaymentRequestPlace(paymentRequest.place),
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: string; id: string; displayNam... Remove this comment to see the full error message
  sender: convertPaymentRequestParticiapnt(paymentRequest.sender),
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: string; id: string; displayNam... Remove this comment to see the full error message
  customer: convertPaymentRequestCustomerParticiapnt(paymentRequest.customer),
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: string; id: string; displayNam... Remove this comment to see the full error message
  canceledBy: paymentRequest.canceled_by
    ? convertPaymentRequestParticiapnt(paymentRequest.canceled_by)
    : null,
  conversation: paymentRequest.conversation
    ? {
        __typename: 'ConversationQL',
        id: paymentRequest.conversation.id,
      }
    : null,
  totalRefundedAmount: paymentRequest.total_refunded_amount,
  netAmount: paymentRequest.net_amount,
});

const addToPaymentRequestCache = async (
  client: ApolloClient<any>,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'cache' implicitly has an 'any' type.
  cache,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newEdge' implicitly has an 'any' type.
  newEdge,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'variables' implicitly has an 'any' type... Remove this comment to see the full error message
  variables,
) => {
  const cacheNonNull =
    !cache || !cache.paymentRequests
      ? {
          paymentRequests: {
            __typename: 'PaymentRequestConnection',
            ...EMPTY_CONNECTION,
          },
        }
      : cache;

  const newEdges = uniqBy(
    [newEdge, ...cacheNonNull.paymentRequests.edges],
    e => e.node.id,
  );

  await client.writeQuery({
    query: GET_PAYMENT_REQUESTS,
    variables,
    data: {
      paymentRequests: {
        ...cacheNonNull.paymentRequests,
        edges: newEdges,
      },
    },
  });
};

const removeFromPaymentRequestCache = async (
  client: ApolloClient<any>,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'cache' implicitly has an 'any' type.
  cache,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'oldId' implicitly has an 'any' type.
  oldId,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'variables' implicitly has an 'any' type... Remove this comment to see the full error message
  variables,
) => {
  const cacheNonNull =
    !cache || !cache.paymentRequests
      ? {
          paymentRequests: {
            __typename: 'PaymentRequestConnection',
            ...EMPTY_CONNECTION,
          },
        }
      : cache;

  const newActiveEdges = cacheNonNull.paymentRequests.edges.filter(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'edge' implicitly has an 'any' type.
    edge => edge.node.id !== oldId,
  );

  await client.writeQuery({
    query: GET_PAYMENT_REQUESTS,
    variables,
    data: {
      paymentRequests: {
        ...cacheNonNull.paymentRequests,
        edges: newActiveEdges,
      },
    },
  });
};

// Condense backend statuses to match frontend cache groupings
function condenseStatuses(status: PaymentRequestStatus): PaymentsFilterType {
  if (['REFUND', 'PARTIAL_REFUND'].includes(status)) {
    return 'REFUNDS';
  }
  if (['FAILED', 'DISPUTED'].includes(status)) {
    return 'ATTENTION';
  }
  if (status === 'WAITING') {
    return 'REQUESTS';
  }
  if (status === 'SUCCESS') {
    return 'SUCCESS';
  }
  if (status === 'HOLD') {
    return 'HOLDS';
  }

  return 'ALL';
}

function statusInFilter(
  status: PaymentRequestStatus,
  filter: PaymentsFilterType,
) {
  if (filter === 'ALL') {
    return true;
  }
  if (filter === 'REQUESTS') {
    return status === 'WAITING';
  }
  if (filter === 'HOLDS') {
    return status === 'HOLD';
  }
  if (filter === 'ATTENTION') {
    return ['FAILED', 'DISPUTED'].includes(status);
  }
  if (filter === 'REFUNDS') {
    return ['REFUND', 'PARTIAL_REFUND'].includes(status);
  }

  return filter === status;
}

function paymentRequestInFilters(
  paymentRequest: PaymentRequestStubFields,
  status: PaymentsFilterType,
  collectionId: string | null | undefined,
  userParticipantId: string,
) {
  if (
    collectionId &&
    [COLLECTION_NAMES.ASSIGNED, COLLECTION_NAMES.ADVISOR].includes(
      collectionId,
    ) &&
    userParticipantId !== get(paymentRequest, 'sender.id')
  ) {
    return false;
  }

  if (
    collectionId &&
    ![
      COLLECTION_NAMES.ALL,
      COLLECTION_NAMES.ASSIGNED,
      COLLECTION_NAMES.ADVISOR,
    ].includes(collectionId) &&
    collectionId !== get(paymentRequest, 'place.id')
  ) {
    return false;
  }

  return statusInFilter(paymentRequest.status, status);
}

export const addPaymentRequestToCache = async (
  client: ApolloClient<any>,
  paymentRequest: PubNubPaymentRequestModel,
) => {
  const existingPaymentRequestStub = safeReadFragment<PaymentRequestStubFields>(
    client,
    {
      id: `PaymentRequestQL:${paymentRequest.id}`,
      fragment: PaymentRequestStub,
      fragmentName: 'PaymentRequestStubFields',
    },
  );

  // Update PaymentRequestFragment and GET_PAYMENT_REQUEST

  const apolloPaymentRequest =
    convertPubNubPaymentRequestToPaymentRequestFields(paymentRequest);

  await client.writeFragment({
    id: `PaymentRequestQL:${paymentRequest.id}`,
    fragment: PaymentRequestFragment,
    fragmentName: 'PaymentRequestFields',
    data: apolloPaymentRequest,
  });

  await client.writeQuery({
    query: GET_PAYMENT_REQUEST,
    variables: { id: paymentRequest.id },
    data: {
      paymentRequest: apolloPaymentRequest,
    },
  });

  // Update GET_PAYMENT_REQUESTS

  const currentUserQuery = safeRead(client, { query: GET_CURRENT_USER });
  const activePaymentsCollectionQuery = safeRead(client, {
    query: GET_ACTIVE_PAYMENTS_COLLECTION,
  });

  const userParticipantId = get(currentUserQuery, 'currentUser.participantId');
  const activeStatus = get(
    activePaymentsCollectionQuery,
    'activePaymentsCollection.status',
  );

  if (!userParticipantId || !activeStatus) {
    return;
  }

  const activeCollectionId = get(
    activePaymentsCollectionQuery,
    'activePaymentsCollection.collectionId',
  );

  const apolloPaymentRequestStub =
    convertPubNubPaymentRequestToPaymentRequestStubFields(paymentRequest);

  if (
    paymentRequestInFilters(
      apolloPaymentRequestStub,
      activeStatus,
      activeCollectionId,
      userParticipantId,
    )
  ) {
    // Payments should appear in active inbox
    // Add/update payments cache
    const newEdge = {
      __typename: 'PaymentRequestEdge',
      node: apolloPaymentRequestStub,
    };

    const existingFilter = condenseStatuses(apolloPaymentRequestStub.status);

    const newQueryFilter = buildGetPaymentsQueryFilter(
      existingFilter,
      activeCollectionId,
      userParticipantId,
    );

    const newStatusVariables = {
      status: activeStatus,
      placeId: get(newQueryFilter, 'placeId.equal'),
      senderId: get(newQueryFilter, 'senderId.equal'),
    };

    const newStatusPaymentRequests = safeRead(client, {
      query: GET_PAYMENT_REQUESTS,
      variables: newStatusVariables,
    });

    await addToPaymentRequestCache(
      client,
      newStatusPaymentRequests,
      newEdge,
      newStatusVariables,
    );
  } else if (
    existingPaymentRequestStub &&
    paymentRequestInFilters(
      existingPaymentRequestStub,
      activeStatus,
      activeCollectionId,
      userParticipantId,
    )
  ) {
    // Payment was in current inbox, shouldn't be after update
    // Remove from payments cache
    const existingFilter = condenseStatuses(existingPaymentRequestStub.status);

    const oldQueryFilter = buildGetPaymentsQueryFilter(
      existingFilter,
      activeCollectionId,
      userParticipantId,
    );

    const oldStatusVariables = {
      status: activeStatus,
      placeId: get(oldQueryFilter, 'placeId.equal'),
      senderId: get(oldQueryFilter, 'senderId.equal'),
    };

    const oldStatusPaymentRequests = safeRead(client, {
      query: GET_PAYMENT_REQUESTS,
      variables: oldStatusVariables,
    });

    await removeFromPaymentRequestCache(
      client,
      oldStatusPaymentRequests,
      paymentRequest.id,
      oldStatusVariables,
    );
  }
};
