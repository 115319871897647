import { getResourceList, patchResource } from '../util';

const resourceName = 'channels';

export const GET_CHANNEL_LIST = 'GET_CHANNEL_LIST';
export function getChannelList() {
  return getResourceList(GET_CHANNEL_LIST, resourceName);
}

export const TOGGLE_CHANNEL = 'TOGGLE_CHANNEL';
export function toggleChannel(
  channelId: string,
  teamId: string,
  active: boolean,
  options: any,
) {
  const path = `channels/${channelId}`;
  const payload = {
    team_id: teamId,
    is_active: active,
    ...options,
  };

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  return patchResource(TOGGLE_CHANNEL, resourceName, path, payload);
}
