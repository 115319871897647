import { gql } from '@apollo/client';

export const PUBNUB_DEGRADED_INBOX_EXPERIENCE_UPDATED = gql`
  mutation PubNubDegradedInboxExperienceUpdated(
    $account_id: String!
    $place_id: String!
    $has_degraded_inbox_experience: Boolean!
  ) {
    PubNubDegradedInboxExperienceUpdated(
      account_id: $account_id
      place_id: $place_id
      has_degraded_inbox_experience: $has_degraded_inbox_experience
    ) @client
  }
`;
