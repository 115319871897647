import { gql } from '@apollo/client';

export const PUBNUB_OUTBOUND_CONFERENCE_CALL_STATUS_UPDATED = gql`
  mutation PubNubOutboundConferenceCallStatusUpdated(
    $outbound_conference_call: PubNubOutboundConferenceCallModel!
  ) {
    PubNubOutboundConferenceCallStatusUpdated(
      outbound_conference_call: $outbound_conference_call
    ) @client
  }
`;
