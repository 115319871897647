import { gql } from '@apollo/client';
import { ComplianceSettingsFragment } from '../../fragments/gql/ComplianceSettings.gql';

export const GET_COMPLIANCE_SETTINGS = gql`
  query getComplianceSettings {
    complianceSettings {
      ...ComplianceSettings
    }
  }
  ${ComplianceSettingsFragment}
`;
