import { gql } from '@apollo/client';

import { PaymentRequestFragment } from '../../fragments/gql/paymentRequestFragment.gql';

export const REFUND_PAYMENT = gql`
  mutation RefundPayment($input: CreateStripeRefundInput!) {
    createStripeRefund(input: $input) {
      paymentRequest {
        ...PaymentRequestFields
      }
    }
  }
  ${PaymentRequestFragment}
`;
