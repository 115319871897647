import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import type {
  ApiFetchPlacesResponse,
  ApiUpdatePlaceHoursRequest,
  ApiUpdatePlaceHoursResponse,
} from '@numbox/services';
import { PlaceService } from '@numbox/services';

import type { Meta, ExtractReturn, ApiActionCreators } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const fetchPlaces: ApiActionCreators<
  'FETCH_PLACES.REQUEST',
  void,
  'FETCH_PLACES.SUCCESS',
  ApiFetchPlacesResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'FETCH_PLACES.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_PLACES.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_PLACES.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchPlacesRequest(
  action: ExtractReturn<typeof fetchPlaces.request>,
): Generator<any, void, void> {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  yield serviceCall(PlaceService.fetchPlaces, fetchPlaces, action.payload);
}

export const updatePlaceHours: ApiActionCreators<
  'UPDATE_PLACE_HOURS.REQUEST',
  ApiUpdatePlaceHoursRequest,
  'UPDATE_PLACE_HOURS.SUCCESS',
  ApiUpdatePlaceHoursResponse,
  Meta
> = {
  request: (params: ApiUpdatePlaceHoursRequest, meta) => ({
    type: 'UPDATE_PLACE_HOURS.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'UPDATE_PLACE_HOURS.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'UPDATE_PLACE_HOURS.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onUpdatePlaceHoursRequest(
  action: ExtractReturn<typeof updatePlaceHours.request>,
): Generator<any, void, void> {
  yield serviceCall(
    PlaceService.updatePlaceHours,
    updatePlaceHours,
    action.payload,
    action.meta,
  );
}

export function* PlaceSagas(): Saga<any> {
  yield all([
    takeEvery('FETCH_PLACES.REQUEST', onFetchPlacesRequest),
    takeEvery('UPDATE_PLACE_HOURS.REQUEST', onUpdatePlaceHoursRequest),
  ]);
}
