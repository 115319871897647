import { gql } from '@apollo/client';

export const UPDATE_SHORTCUT = gql`
  mutation updateShortcut($input: UpdateShortcutInput!) {
    updateShortcut(input: $input) {
      shortcut {
        id
      }
    }
  }
`;
