import { gql } from '@apollo/client';
import { NOTE_TAKER_FRAGMENT } from '../../fragments/gql/noteTaker.gql';

export const GET_NOTE_TAKERS = gql`
  query getNoteTakers($first: Int!, $placeId: String!) {
    noteTakers(first: $first, placeId: $placeId) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...NoteTakerFields
        }
      }
    }
  }
  ${NOTE_TAKER_FRAGMENT}
`;
