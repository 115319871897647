import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const PUBLISH_VOICE_SCRIPT = gql`
  mutation publishVoiceScript($input: PublishVoiceScriptInput!) {
    publishVoiceScript(input: $input) {
      answeringScript {
        id
        voiceScript {
          ...VoiceScriptFields
        }
        draftVoiceScript {
          ...VoiceScriptFields
        }
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
