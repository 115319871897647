import { gql } from '@apollo/client';

export const UPDATE_SHOW_BANNER_AFTER = gql`
  mutation updateShowBannerAfter {
    updateShowBannerAfter {
      currentUser {
        showAwayModeBannerAfter
      }
    }
  }
`;
