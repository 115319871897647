import { gql } from '@apollo/client';

export const GET_GUIDE_CHECKLIST = gql`
  query getGuideChecklist($placeId: String!) {
    guideChecklist(placeId: $placeId) {
      textingPhonelines {
        id
        mdn
        lineType
        carrierName
        hostedSmsPort {
          status
        }
      }
      hasCallForwarding
      hasDismissedGuideChecklist
    }
  }
`;
