import { gql } from '@apollo/client';

export const GET_PLACE_GROUP_DETAILS = gql`
  query getPlaceGroupDetails($placeGroupId: String!) {
    placeGroup(id: $placeGroupId) {
      __typename
      id
      name
      account {
        id
        name
      }
      places {
        edges {
          node {
            id
            name
            status
          }
        }
      }
    }
  }
`;
