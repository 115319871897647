import { gql } from '@apollo/client';

export const GET_PLACES_USERS = gql`
  query placesUsers($placeId: String!) {
    placesUsers(placeId: $placeId) {
      id
      placeId
      receivesEscalations
      userId
      role
      user {
        email
        displayName
      }
    }
  }
`;
