import { gql } from '@apollo/client';
import { UserItemFragment } from './userItem.gql';
import { TeamFragment } from './TeamFragment.gql';

export const AwayModeFragment = gql`
  fragment AwayModeFields on UserQL {
    id
    isAway
    isScheduleEnabled
    nextShiftStart
    nextShiftEnd
    showAwayModeBannerAfter
    canManageOwnAwayModeSettings
  }
`;

export const AwayModeDetailsFragment = gql`
  fragment AwayModeDetailsFields on UserQL {
    id
    ...AwayModeFields
    placesUsers {
      place {
        id
      }
      awayModeAssigneeUser {
        ...UserItemFields
      }
      awayModeAssigneeTeam {
        ...TeamFields
      }
    }
  }
  ${AwayModeFragment}
  ${UserItemFragment}
  ${TeamFragment}
`;
