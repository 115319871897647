import { gql } from '@apollo/client';

export const UPDATE_RECEIVES_ESCALATIONS = gql`
  mutation UpdateReceivesEscalations($input: UpdateReceivesEscalationsInput!) {
    updateReceivesEscalations(input: $input) {
      placesUser {
        userId
        receivesEscalations
      }
    }
  }
`;
