import * as React from 'react';
import { Banner } from '~/components/library/Banner';

type BannerVariant = 'success' | 'error' | 'warning' | 'task';

type Props = {
  children: React.ReactNode;
  onClose?: () => void;
  variant?: BannerVariant;
  iconName?: string;
  includeIcon?: boolean;
};

export const TopBanner = ({
  children,
  onClose,
  variant = 'success',
  iconName,
  includeIcon = true,
}: Props) => (
  <Banner
    sticky
    includeIcon={includeIcon}
    iconName={iconName}
    color={variant}
    onClose={onClose}
    size="large"
  >
    {children}
  </Banner>
);
