import { gql } from '@apollo/client';

export const GET_QUESTION_AGENT_SETTINGS = gql`
  query getquestionAgentSettings($id: String!) {
    questionAgentSettings(id: $id) {
      id
      name
      settings
    }
  }
`;
