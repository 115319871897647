import { gql } from '@apollo/client';

export const GET_APPOINTMENT_AGENT_SETTINGS = gql`
  query getAppointmentAgentSettings($id: String!, $first: Int, $after: String) {
    appointmentAgentSettings(id: $id) {
      id
      name
      settings
      agentProfile
      optionalPrompts
      placeId
      textingCanWeTextSpeech
      textingOnYesSpeechCanWeText
      textingOnNoSpeechCanWeText
      textingMessageToSend
      activeIntegration {
        id
        pendingOpcodeSync
        xtimeSyncs(first: $first, after: $after) {
          edges {
            node {
              id
              createdOn
              xtimeLogs {
                id
                supportedVehicles {
                  id
                  make
                  model
                  supportedYears
                  repairService {
                    id
                    name
                  }
                }
                opCode {
                  description
                }
                notes
              }
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
      latestXtimeSync {
        id
        endDate
      }
      place {
        id
      }
    }
  }
`;
