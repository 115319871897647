import { gql } from '@apollo/client';

export const GET_USER_CONVERSATION_COUNTS = gql`
  query getUserConversationCounts($userId: String!, $placeId: String) {
    user(userId: $userId) {
      id
      openConversationCount(placeId: $placeId)
      closedConversationCount(placeId: $placeId)
    }
  }
`;
