import { gql } from '@apollo/client';

export const GET_REVIEW_JOB_CONFIG = gql`
  query getReviewJob($placeId: String!) {
    reviewJob(placeId: $placeId) {
      id
      placeId
      place {
        id
        externalName
      }
      fallbackChannelId
      buttonTextColor
      buttonColor
      headerColor
      headerTextColor
      backgroundColor
      textColor
      logoUrl
      messageBody
      messageImageUrl
      renderedMessageBody
      skipRatingsScreen
      reviewMode
      reviewSites {
        serviceName
        url
        ... on FacebookReviewSite {
          facebookPageId
        }
      }
    }
  }
`;
