import { gql } from '@apollo/client';
import { UserViewPreferencesFragment } from '../../fragments/gql/UserViewPreferences.gql';

export const UPDATE_USER_VIEW_PREFERENCES = gql`
  mutation updateUserViewPreferences($input: UpdateUserViewPreferencesInput!) {
    updateUserViewPreferences(input: $input) {
      user {
        id
        viewPreferences {
          ...UserViewPreferencesFields
        }
      }
    }
  }
  ${UserViewPreferencesFragment}
`;
