import { gql } from '@apollo/client';

export const UserItemFragment = gql`
  fragment UserItemFields on UserQL {
    id
    email
    fullName
    firstName
    lastName
  }
`;

export const UserItemAndNotificationFragment = gql`
  fragment UserItemAndNotificationFields on UserQL {
    id
    email
    fullName
    firstName
    lastName
    mdn
    emailOnAssignment
    emailNotificationSetting
    pushNotificationSetting
    browserNotificationSetting
    assignmentClaimSetting
    swipeRightSetting
    swipeLeftSetting
  }
`;

export const UserDetailAdminFragment = gql`
  fragment UserDetailFields on UserQL {
    id
    firstName
    lastName
    email
    accountStatus
    placesUsers {
      role
    }
  }
`;
