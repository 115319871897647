import axios from 'axios';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const fetchTranslation = teamId => {
  const path = `/api/teams/${teamId}/jobs/translation`;
  return axios.get(path);
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const updateTranslation = (teamId, payload) =>
  axios.patch(`/api/teams/${teamId}/jobs/translation`, payload);
