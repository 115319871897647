import * as React from 'react';
import type { Store } from 'redux';
import { ApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { AUTH_LINK, REFRESH_TOKEN_LINK } from '@numbox/apollo';

import { useNumaAuth0 } from './public/forms/NewLogin/auth0Util';

type Props = {
  client: ApolloClient<any>;
  store: Store<any, any>;
  connection: 'web' | 'demo' | 'admin';
  children: React.ReactNode;
};

export const ApolloLinksWrapper = ({
  store,
  client,
  connection,
  children,
}: Props) => {
  const { attemptSilentAuth0Login } = useNumaAuth0();
  const { loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    const getRedirectUrl = () => {
      if (connection === 'demo') {
        return '/demo/auth0redirect';
      }
      if (connection === 'admin') {
        return '/admin/auth0redirect';
      }
      return '/login/auth0redirect';
    };

    REFRESH_TOKEN_LINK.injectConfig({
      client,
      refreshTokenPath: 'auth.refreshToken',
      store,
      attemptSilentAuth0Login,
      onAuth0LoginFailure: () => {
        const redirectUrl = getRedirectUrl();
        loginWithRedirect({
          authorizationParams: {
            prompt: 'login',
            redirect_uri: `${window.location.origin}${redirectUrl}`,
          },
          appState: {
            redirectUri: redirectUrl,
          },
        });
      },
    });

    AUTH_LINK.injectConfig({
      authTokenPath: 'auth.accessToken',
      store,
    });
  }, [client, store, connection, attemptSilentAuth0Login, loginWithRedirect]);

  return <>{children}</>;
};
