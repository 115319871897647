import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import type {
  ApiCreateBillingIdentityRequest,
  ApiCreateBillingIdentityResponse,
  ApiFetchBillingIdentityRequest,
  ApiFetchBillingIdentityResponse,
  ApiUpdateBillingIdentityRequest,
  ApiUpdateBillingIdentityResponse,
} from '@numbox/services';
import { BillingIdentityService } from '@numbox/services';

import type { Meta, ApiActionCreators, ExtractReturn } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const createBillingIdentity: ApiActionCreators<
  'CREATE_BILLING_IDENTITY.REQUEST',
  ApiCreateBillingIdentityRequest,
  'CREATE_BILLING_IDENTITY.SUCCESS',
  ApiCreateBillingIdentityResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'CREATE_BILLING_IDENTITY.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'CREATE_BILLING_IDENTITY.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'CREATE_BILLING_IDENTITY.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onCreateBillingIdentity(
  action: ExtractReturn<typeof createBillingIdentity.request>,
): Generator<any, void, void> {
  yield serviceCall(
    BillingIdentityService.createBillingIdentity,
    createBillingIdentity,
    action.payload,
    action.meta,
  );
}
export const fetchBillingIdentity: ApiActionCreators<
  'FETCH_BILLING_IDENTITY.REQUEST',
  ApiFetchBillingIdentityRequest,
  'FETCH_BILLING_IDENTITY.SUCCESS',
  ApiFetchBillingIdentityResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'FETCH_BILLING_IDENTITY.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_BILLING_IDENTITY.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_BILLING_IDENTITY.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchBillingIdentity(
  action: ExtractReturn<typeof fetchBillingIdentity.request>,
): Generator<any, void, void> {
  yield serviceCall(
    BillingIdentityService.fetchBillingIdentity,
    fetchBillingIdentity,
    action.payload,
    action.meta,
  );
}

export const updateBillingIdentity: ApiActionCreators<
  'UPDATE_BILLING_IDENTITY.REQUEST',
  ApiUpdateBillingIdentityRequest,
  'UPDATE_BILLING_IDENTITY.SUCCESS',
  ApiUpdateBillingIdentityResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'UPDATE_BILLING_IDENTITY.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'UPDATE_BILLING_IDENTITY.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'UPDATE_BILLING_IDENTITY.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onUpdateBillingIdentity(
  action: ExtractReturn<typeof updateBillingIdentity.request>,
): Generator<any, void, void> {
  yield serviceCall(
    BillingIdentityService.updateBillingIdentity,
    updateBillingIdentity,
    action.payload,
    action.meta,
  );
}
export function* BillingIdentitySagas(): Saga<any> {
  yield all([
    takeEvery('CREATE_BILLING_IDENTITY.REQUEST', onCreateBillingIdentity),
    takeEvery('FETCH_BILLING_IDENTITY.REQUEST', onFetchBillingIdentity),
    takeEvery('UPDATE_BILLING_IDENTITY.REQUEST', onUpdateBillingIdentity),
  ]);
}
