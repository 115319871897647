import { useState, useEffect } from 'react';
import { AuthModule } from '@numbox/modules';
import { useDispatch } from 'react-redux';
import { useLocation, Redirect, Route, Switch } from 'react-router-dom';

import { selectPlace } from '~/actions/inbox';
import { useQuery, useTrackPageView } from '../effects';
import CenteredSpinner from './shared/settings/components/CenteredSpinner';

import { AdminAppRoutes } from './AdminAppRoutes';
import { DemoAppRoutes } from './DemoAppRoutes';
import { MainAppRoutes } from './MainAppRoutes';

const App = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { pathname } = useLocation();

  const { exchangeToken, placeId } = query;

  const [refreshInProgress, setRefreshInProgress] = useState(true);

  useTrackPageView();

  useEffect(() => {
    if (!exchangeToken) {
      setRefreshInProgress(false);
      return;
    }

    dispatch(
      AuthModule.exchangeToken.request(
        {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | true | (string | boolean | null)[]'... Remove this comment to see the full error message
          token: exchangeToken,
          token_type: 'MOBILE_WEBVIEW',
        },

        {
          onSuccess: () => {
            if (placeId) {
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | true | (string | boolea... Remove this comment to see the full error message
              dispatch(selectPlace(placeId));
            }
            setRefreshInProgress(false);
          },
          onFailure: () => {
            setRefreshInProgress(false);
          },
          tokenType: 'MOBILE_WEBVIEW',
        },
      ),
    );
  }, [dispatch, exchangeToken, placeId]);

  if (refreshInProgress) {
    return <CenteredSpinner />;
  }

  return (
    <Switch>
      {/* TODO: we can remove this when we upgrade to v6 react-router */}
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route key="demo" path="/demo" component={DemoAppRoutes} />
      <Route key="admin" path="/admin" component={AdminAppRoutes} />
      <Route component={MainAppRoutes} />
    </Switch>
  );
};

export default App;
