import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiCreateAccountRequest,
  ApiCreateAccountResponse,
  ApiFetchAccountsRequest,
  ApiFetchAccountsResponse,
} from './types';

const path = `/api/accounts`;
class AccountService implements Service {
  static fetchAccounts(
    params: ApiFetchAccountsRequest,
  ): AxiosPromise<ApiFetchAccountsResponse> {
    return axios.post(path, params);
  }

  static createAccount(
    params: ApiCreateAccountRequest,
  ): AxiosPromise<ApiCreateAccountResponse> {
    return axios.post(path, params);
  }
}

export default AccountService;
