import { gql } from '@apollo/client';

export const DELETE_AUTOMATION = gql`
  mutation deleteAutomation($input: DeleteAutomationInput!) {
    deleteAutomation(input: $input) {
      automation {
        id
        state
      }
    }
  }
`;
