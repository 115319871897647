import isEmpty from 'lodash/isEmpty';

import { SUPPORTED_SIGNATURE_MESSAGE_FORMATS } from '@numbox/util';

import { GET_CURRENT_USER } from '../../queries/gql/getCurrentUser.gql';
import { GET_MY_INBOXES } from '../../queries/gql/getMyInboxes.gql';
import { convertTeam } from './teams/utils';

import type { ApolloContext } from '../../types';

import {
  readConversationFragment,
  safeRead,
  updateActiveInbox,
  writeConversationFragment,
} from '../../util';

import { addMessageToCache } from '../../mutations/helpers';

import { ConversationSignatureStateFragment } from '../../fragments/gql/ConversationSignatureStateFragment.gql';
import { convertDialogflowBotData } from './PubNubDialogFlowBotDataUpdated';
import { convertLabel } from './AddLabelToCache';
import { convertPubNubPaymentRequestToPaymentRequestFields } from './convertPaymentRequest';
import { convertPhonecall } from './PubNubInboundCallStatusUpdated';
import { convertOutboundConferenceCall } from './PubNubOutboundConferenceCallStatusUpdated';

export const convertPubNubAttachmentToAttachmentQL = (
  a: PubNubAttachmentType,
): MessageItemFields$attachments | null | undefined => {
  // all of the FlowFixMe here are required because
  // graphql input types don't support unions yet
  // Once they do, we can remove these
  // https://github.com/graphql/graphql-spec/issues/488
  // Our PubNubAttachmentType should be a union type
  // Similar to the MessageItemFields$attachments
  switch (a.attachment_type) {
    case 'image': {
      return {
        __typename: 'ImageAttachment',
        id: a.id,
        attachmentType: a.attachment_type,
        // @ts-expect-error 2322
        width: a.width,
        // @ts-expect-error 2322
        height: a.height,
        // @ts-expect-error 2322
        url: a.url,
      };
    }
    case 'file': {
      return {
        __typename: 'FileAttachment',
        id: a.id,
        attachmentType: a.attachment_type,
        // @ts-expect-error 2322
        filename: a.file_name,
        // @ts-expect-error 2322
        sizeBytes: a.size_bytes,
        // @ts-expect-error 2322
        url: a.url,
        words: '',
      };
    }
    case 'qualify': {
      return {
        __typename: 'OpenVoiceQuestionAttachment',
        id: a.id,
        attachmentType: a.attachment_type,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'shorthand_prompt' does not exist on type... Remove this comment to see the full error message
        shorthandPrompt: a.shorthand_prompt,
        // @ts-expect-error 2322
        url: a.url,
        words: '',
      };
    }
    case 'translation': {
      return {
        __typename: 'TranslatedMessage',
        attachmentType: a.attachment_type,
        id: a.id,
        // @ts-expect-error 2322
        language: a.language,
        // @ts-expect-error 2322
        text: a.text,
      };
    }
    case 'yelp': {
      return {
        __typename: 'YelpAttachment',
        id: a.id,
        attachmentType: a.attachment_type,
        // @ts-expect-error 2322
        url: a.url,
      };
    }
    case 'vcard': {
      return {
        __typename: 'VCardAttachment',
        id: a.id,
        attachmentType: a.attachment_type,
        // @ts-expect-error 2322
        filename: a.file_name,
        // @ts-expect-error 2322
        sizeBytes: a.size_bytes,
        message: null,
        // @ts-expect-error 2322
        url: a.url,
      };
    }
    case 'review': {
      return {
        __typename: 'ReviewAttachment',
        id: a.id,
        attachmentType: a.attachment_type,
        review: null,
      };
    }
    case 'video_upload_link': {
      return {
        __typename: 'VideoUploadLinkAttachment',
        id: a.id,
        attachmentType: a.attachment_type,
        // @ts-expect-error 2322
        videoUploadLinkId: a.video_upload_link_id,
        // @ts-expect-error 2322
        videoMicrositeLinkId: a.video_microsite_link_id,
      };
    }
    default: {
      return null;
    }
  }
};

export const convertPubNubReasonSentToReasonSentQL = (
  reasonSent: PubNubReasonSentModel,
): MessageItemFields$reasonSent | null | undefined => {
  switch (reasonSent.kind) {
    case 'GREETING': {
      if (reasonSent.name) {
        return {
          __typename: 'ReasonSentGreetingQL',
          kind: reasonSent.kind,
          name: reasonSent.name,
        };
      }

      return null;
    }
    case 'INTENT': {
      if (
        reasonSent.intent &&
        reasonSent.intent.name &&
        reasonSent.intent.display_name
      ) {
        return {
          __typename: 'ReasonSentIntentQL',
          kind: reasonSent.kind,
          intent: {
            __typename: 'AppliedIntentQL',
            name: reasonSent.intent.name,
            displayName: reasonSent.intent.display_name,
          },
        };
      }

      return null;
    }
    case 'HANGUP_RESPONSE': {
      return {
        __typename: 'ReasonSentHangupResponseQL',
        kind: reasonSent.kind,
      };
    }
    default: {
      return null;
    }
  }
};

const convertPubNubCrmContact = (
  numaContact: PubNubCrmContactModel,
): ConversationItemFields$participant$crmContacts => {
  return {
    __typename: 'CrmContactQL',
    id: numaContact.id,
    firstName: numaContact.first_name ?? null,
    lastName: numaContact.last_name ?? null,
    companyName: numaContact.company_name ?? null,
    email: numaContact.email ?? null,
    phoneNumber: numaContact.phone_number ?? null,
    contactType: numaContact.contact_type as CrmContactType | null,
  };
};

const convertPubNubAddress = (
  address: PubNubAddressModel,
): ConversationItemFields$participant$latestCustomer$addresses => {
  return {
    __typename: 'AddressQL',
    id: address.id,
    mediumType: address.medium_type ?? null,
    address: address.address ?? null,
    isPrimary: address.is_primary ?? null,
    isActive: address.is_active ?? null,
    deletedAt: address.deleted_at ?? null,
    sourceEntityId: address.source_entity_id ?? null,
    sourceEntityType: address.source_entity_type ?? null,
  };
};

const convertPubNubRepairOrderStatusItem = (
  statusItem?: PubNubRepairOrderStatusItem | null,
): ConversationItemFields$lastRepairOrder$statusItem | null => {
  if (!statusItem) {
    return null;
  }

  return {
    __typename: 'StatusItemQL',
    id: statusItem.id,
    status: statusItem.status,
    color: statusItem.color,
    lastUpdateTime: statusItem.last_update_time,
  };
};

const convertAppointment = (
  appointment: PubNubAppointmentModel,
): ConversationItemFields$lastAppointment => {
  return {
    __typename: 'AppointmentQL',
    id: appointment.id,
    state: appointment.state,
    createdOn: appointment.created_on,
    expiresAt: appointment.expires_at,
    dialogFlowBotData: appointment.dialog_flow_bot_data
      ? convertDialogflowBotData(appointment.dialog_flow_bot_data)
      : null,
    appointmentId: appointment.appointment_id ?? null,
    appointmentDate: appointment.appointment_date ?? null,
    externalServiceAdvisorId: appointment.external_service_advisor_id ?? null,
    externalServiceAdvisorName:
      appointment.external_service_advisor_name ?? null,
    enrichedFrom: appointment.enriched_from ?? null,
  };
};
const convertRepairOrder = (repairOrder: PubNubRepairOrder) => {
  switch (repairOrder.repair_order_type) {
    case 'CDK': {
      return {
        __typename: 'BaseRepairOrder' as const,
        id: repairOrder.id,
        repairOrderType: repairOrder.repair_order_type,
        status: repairOrder.status,
        createdOn: repairOrder.created_on,
        orderNumber: repairOrder.order_number,
        tagNumber: repairOrder.tag_number ?? null,
        completedTime: repairOrder.completed_time,
        openedTime: repairOrder.opened_time,
        statusItem: convertPubNubRepairOrderStatusItem(repairOrder.status_item),
        promisedTime: repairOrder.promised_time,
        serviceAdvisor: null,
        dmsStatus: repairOrder.dms_status ?? null,
        lastDmsUpdate: repairOrder.last_dms_update,
        transportationFlags: repairOrder.transportation_flags ?? '{}',
        serviceAdvisorName: repairOrder.service_advisor_name ?? null,
        vehicleMakeFriendly: repairOrder.vehicle_make_friendly ?? null,
        vehicleVin: repairOrder.vehicle_vin ?? null,
        vehicleYear: repairOrder.vehicle_year ?? null,
      };
    }
    case 'DEALERTRACK': {
      return {
        __typename: 'BaseRepairOrder' as const,
        id: repairOrder.id,
        repairOrderType: repairOrder.repair_order_type,
        status: repairOrder.status,
        createdOn: repairOrder.created_on,
        orderNumber: repairOrder.order_number,
        tagNumber: repairOrder.tag_number ?? null,
        completedTime: repairOrder.completed_time,
        openedTime: repairOrder.opened_time,
        statusItem: convertPubNubRepairOrderStatusItem(repairOrder.status_item),
        promisedTime: repairOrder.promised_time,
        serviceAdvisor: null,
        dmsStatus: repairOrder.dms_status ?? null,
        lastDmsUpdate: repairOrder.last_dms_update,
        transportationFlags: repairOrder.transportation_flags ?? '{}',
        serviceAdvisorName: repairOrder.service_advisor_name ?? null,
        vehicleMakeFriendly: repairOrder.vehicle_make_friendly ?? null,
        vehicleVin: repairOrder.vehicle_vin ?? null,
        vehicleYear: repairOrder.vehicle_year ?? null,
      };
    }
    case 'PBS':
    case 'REYNOLDS':
    case 'DEALERBUILT':
    case 'TEKION': {
      return {
        __typename: 'BaseRepairOrder' as const,
        id: repairOrder.id,
        repairOrderType: repairOrder.repair_order_type,
        status: repairOrder.status,
        createdOn: repairOrder.created_on,
        orderNumber: repairOrder.order_number,
        tagNumber: repairOrder.tag_number ?? null,
        completedTime: repairOrder.completed_time,
        openedTime: repairOrder.opened_time,
        statusItem: convertPubNubRepairOrderStatusItem(repairOrder.status_item),
        promisedTime: repairOrder.promised_time,
        serviceAdvisor: null,
        dmsStatus: repairOrder.dms_status ?? null,
        lastDmsUpdate: repairOrder.last_dms_update,
        transportationFlags: repairOrder.transportation_flags ?? '{}',
        serviceAdvisorName: repairOrder.service_advisor_name ?? null,
        vehicleMakeFriendly: repairOrder.vehicle_make_friendly ?? null,
        vehicleVin: repairOrder.vehicle_vin ?? null,
        vehicleYear: repairOrder.vehicle_year ?? null,
      };
    }
    default: {
      return null;
    }
  }
};

const convertPubNubMentionsToMentionsFields = (mentions: PubNubMention[]) => {
  return mentions
    ? mentions.map(
        mention =>
          mention && {
            __typename: 'MentionQL' as const,
            userId: mention.user_id,
            tag: mention.tag,
          },
      )
    : [];
};

const convertPubNubPreviewDatatoPreviewDataFields = (
  previewData: PubNubConversationPreviewDataModel,
): ConversationItemFields$previewData => {
  return {
    __typename: 'ConversationPreviewContentQL' as const,
    preview: previewData.preview,
    previewFormat: previewData.preview_format,
    previewIsInbound: previewData.preview_is_inbound,
    previewMentions: convertPubNubMentionsToMentionsFields(
      previewData.preview_mentions,
    ),
    previewDisplayName: previewData.preview_display_name,
    previewSource: previewData.preview_source,
    previewServerGenerated: previewData.preview_server_generated,
    subPreview: previewData.sub_preview,
    subPreviewFormat: previewData.sub_preview_format,
    subPreviewDisplayName: previewData.sub_preview_display_name,
    subPreviewMentions: convertPubNubMentionsToMentionsFields(
      previewData.sub_preview_mentions,
    ),
  };
};

const convertPubNubMessageToMessageItemFields = (
  message: PubNubMessageModel,
  conversation: PubNubConversationModel,
): MessageItemFields => {
  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'attachments' implicitly has type 'any[]'... Remove this comment to see the full error message
  const attachments = [];
  message.attachments.forEach(a => {
    if (a) {
      const result = convertPubNubAttachmentToAttachmentQL(a);
      if (result) {
        attachments.push(result);
      }
    }
  });

  return {
    __typename: 'MessageQL',
    id: message.id,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | null | undefined' is not assignabl... Remove this comment to see the full error message
    canTeachNuma: message.can_teach_numa,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    intentDisplayName: message.intent_display_name,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    intentId: message.intent_id,
    channelId: message.channel_id,
    title: message.title,
    deliveryStatus: message.delivery_status,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
    deliveryError: message.delivery_error,
    isInbound: message.is_inbound,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | null | undefined' is not assignabl... Remove this comment to see the full error message
    isSuggestion: message.is_suggestion,
    messageFormat: message.message_format,
    content: message.content,
    timestamp: message.timestamp,
    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'attachments' implicitly has an 'any[]' t... Remove this comment to see the full error message
    attachments,
    mentions: convertPubNubMentionsToMentionsFields(message.mentions),
    sender: {
      __typename: 'ParticipantQL',
      id: message.sender.id,
      address: message.sender.address,
      displayName: message.sender.display_name,
      displayNameConfidence: message.sender.display_name_confidence,
      firstName: null,
      lastName: null,
      // @ts-expect-error 2322
      source: message.sender.source,
    },

    conversation: {
      __typename: 'ConversationQL',
      id: conversation.id,
    },

    dialogFlowBotData: message.dialog_flow_bot_data
      ? convertDialogflowBotData(message.dialog_flow_bot_data)
      : null,

    // @ts-expect-error 2322
    reasonSent: message.reason_sent
      ? convertPubNubReasonSentToReasonSentQL(message.reason_sent)
      : null,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "OutboundProxyCallQL"; id: str... Remove this comment to see the full error message
    outboundProxyCall: message.outbound_proxy_call
      ? {
          __typename: 'OutboundProxyCallQL',
          id: message.outbound_proxy_call.id,
          endtime: message.outbound_proxy_call.endtime,
          callStatus: message.outbound_proxy_call.call_status,
          callDuration: message.outbound_proxy_call.call_duration,
          callRecording: null,
        }
      : null,
    outboundConferenceCall: message.outbound_conference_call
      ? convertOutboundConferenceCall(message.outbound_conference_call)
      : null,
    call: message.call ? convertPhonecall(message.call) : null,
    loggedCallInteraction: message.logged_call_interaction ?? null,
    repairOrder: message.repair_order
      ? convertRepairOrder(message.repair_order)
      : null,
    paymentRequest: message.payment_request
      ? convertPubNubPaymentRequestToPaymentRequestFields(
          message.payment_request,
        )
      : null,
    capsLockFixed: message.caps_lock_fixed,
    isHeatCase: message.is_heat_case,
  };
};

const convertPubNubPlaceToPlaceFields = (
  place: PubNubPlaceModel,
): ConversationItemFields$place => {
  return {
    id: place.id,
    primaryMdn: place.primary_mdn,
    name: place.name,
    __typename: 'PlaceQL',
  };
};
const convertConversationOptInStatus = (
  optInStatus: PubNubConversationOptInStatus | null | undefined,
): ConversationOptInStatus => {
  if (!optInStatus) {
    return 'UNKNOWN';
  }
  if (optInStatus.startsWith('ConversationOptInStatus.')) {
    return optInStatus.replace(
      'ConversationOptInStatus.',
      '',
    ) as ConversationOptInStatus;
  }
  return optInStatus;
};

const convertPubNubEngagmentToLastEngagement = (
  engagement: PubNubConversationEngagement | null | undefined,
): ConversationItemFields$lastEngagement | null => {
  if (!engagement) {
    return null;
  }

  return {
    __typename: 'EngagementQL',
    id: engagement.id,
    summary: engagement.summary ?? null,
    summaryId: engagement.summary_id ?? null,
    // @ts-expect-error PN does not return ratings so we should omit so it merges
    mySummaryRating: undefined,
  };
};

export const convertPubNubConversationToConversationItemFields = (
  conversation: PubNubConversationModel,
): ConversationItemFields => ({
  __typename: 'ConversationQL',
  place: convertPubNubPlaceToPlaceFields(conversation.place),
  inboxId: conversation.inbox_id,
  lastMessage: !isEmpty(conversation.last_message)
    ? convertPubNubMessageToMessageItemFields(
        conversation.last_message,
        conversation,
      )
    : null,
  language: conversation.language,
  lastAppointment:
    !isEmpty(conversation.last_appointment) && conversation.last_appointment
      ? convertAppointment(conversation.last_appointment)
      : null,
  lastChannel: conversation.last_channel
    ? {
        __typename: 'BaseChannel',
        id: conversation.last_channel.id,
      }
    : null,
  lastRepairOrder: !isEmpty(conversation.last_repair_order)
    ? convertRepairOrder(conversation.last_repair_order)
    : null,
  participant: {
    id: conversation.participant.id,
    address: conversation.participant.address,
    displayName: conversation.participant.display_name,
    displayNameConfidence: conversation.participant.display_name_confidence,
    source: conversation.participant.source as source_types,
    avatarUrl: conversation.participant.avatar_url ?? null,
    firstName: conversation.participant.first_name ?? null,
    lastName: conversation.participant.last_name ?? null,
    comment: conversation.participant.comment ?? null,
    spamStatus: conversation.participant.spam_status,
    customerCount: conversation.participant.customerCount ?? null,
    latestCustomer: conversation.participant.latestCustomer
      ? {
          __typename: 'CustomerQL',
          id: conversation.participant.id,
          firstName: conversation.participant.latestCustomer.first_name ?? null,
          lastName: conversation.participant.latestCustomer.last_name ?? null,
          displayName:
            conversation.participant.latestCustomer.display_name ?? null,
          // addresses:
          //   conversation.participant.latestCustomer.addresses != null
          //     ? conversation.participant.latestCustomer.addresses.map(address =>
          //         convertPubNubAddress(address),
          //       )
          //     : null,
          addresses:
            conversation.participant.latestCustomer.addresses != null
              ? conversation.participant.latestCustomer.addresses
                  .filter(
                    (address): address is PubNubAddressModel =>
                      address !== null,
                  )
                  .map(address => convertPubNubAddress(address))
              : null,
        }
      : null,
    crmContacts:
      conversation.participant.crm_contacts != null
        ? conversation.participant.crm_contacts.map(contact =>
            convertPubNubCrmContact(contact),
          )
        : null,
    __typename: 'ParticipantQL',
  },
  previewData: convertPubNubPreviewDatatoPreviewDataFields(
    conversation.preview_data || {
      preview: '',
      preview_display_name: '',
      preview_format: 'text',
      preview_is_inbound: false,
      preview_mentions: [],
      preview_server_generated: false,
      preview_source: '',
      sub_preview: '',
      sub_preview_format: 'text',
      sub_preview_mentions: [],
      sub_preview_display_name: '',
    },
  ),
  serverGeneratedPreview: conversation.server_generated_preview,
  id: conversation.id,
  assignee: conversation.assignee
    ? {
        __typename: 'UserQL',
        id: conversation.assignee.id,
        fullName: conversation.assignee.full_name ?? null,
        firstName: conversation.assignee.first_name ?? null,
        lastName: conversation.assignee.last_name ?? null,
        email: conversation.assignee.email ?? null,
      }
    : null,
  lastUpdated: conversation.last_updated,
  waitingSince: conversation.waiting_since,
  snoozedUntil: conversation.snoozed_until,
  state: conversation.state,
  amIMentioned: conversation.am_i_mentioned,
  isMentionedConversation: conversation.is_mentioned_conversation,
  escalated: conversation.escalated,
  lastMentionedTimestamp: conversation.last_mentioned_timestamp,
  mentionedPreview: '',
  mentionedDisplayName: '',
  source: conversation.source || 'sms',
  optInStatus: convertConversationOptInStatus(conversation.opt_in_status),
  team: conversation.team ? convertTeam(conversation.team) : null,
  conversationLabels: conversation.conversation_labels
    ? conversation.conversation_labels.map<ConversationItemFields$conversationLabels>(
        conversationLabel => ({
          __typename: 'ConversationLabelQL',
          id: conversationLabel.id,
          createdOn: conversationLabel.created_on,
          expiresAt: conversationLabel.expires_at,
          hidden: conversationLabel.hidden,
          label: convertLabel(conversationLabel.label),
          participant: {
            __typename: 'ParticipantQL',
            id: conversationLabel.participant.id,
            displayName: conversationLabel.participant.display_name,
          },
        }),
      )
    : [],
  lastEngagement: convertPubNubEngagmentToLastEngagement(
    conversation.last_engagement,
  ),
  hasRecentHeatCase: conversation.has_recent_heat_case,
});

export const PubNubMessageReceived = async (
  obj: any,
  args: { conversation: PubNubConversationModel; message: PubNubMessageModel },
  { client }: ApolloContext,
) => {
  const existingConversation = readConversationFragment({
    client,
    conversationId: args.conversation.id,
    withLastAppointment: true,
    withLastChannel: true,
    withLastRepairOrder: true,
  });

  const apolloConversation = convertPubNubConversationToConversationItemFields(
    args.conversation,
  );

  const currentUser = safeRead(client, { query: GET_CURRENT_USER });

  const updateIsNextOutboundMessageSigned = (updatedValue: boolean) => {
    return client.writeFragment({
      id: `ConversationQL:${args.conversation.id}`,
      fragment: ConversationSignatureStateFragment,
      fragmentName: 'ConversationSignatureStateFields',
      data: {
        __typename: 'ConversationQL',
        id: args.conversation.id,
        isNextOutboundMessageSigned: updatedValue,
      },
    });
  };

  const lastMessageWasSentByCurrentUser =
    apolloConversation?.lastMessage?.sender.id ===
    currentUser?.currentUser.participantId;

  if (
    SUPPORTED_SIGNATURE_MESSAGE_FORMATS.includes(args.message.message_format)
  ) {
    if (currentUser?.currentUser.signaturePreference === 'ONLY_FIRST') {
      if (lastMessageWasSentByCurrentUser) {
        updateIsNextOutboundMessageSigned(false);
      } else {
        updateIsNextOutboundMessageSigned(true);
      }
    }
  }

  // The conversation.am_i_mentioned field will always be false since
  // the pubnub event is not user aware. Rather than requery for that field
  // every time we get a MESSAGE_RECEIVED event, use the cached value if
  // available.
  if (existingConversation) {
    apolloConversation.isMentionedConversation =
      existingConversation.isMentionedConversation;
    apolloConversation.amIMentioned = existingConversation.amIMentioned;
  }

  // However if this specific message mentions the current user, we can
  // locally consider this user mentioned
  if (
    currentUser &&
    args.message.mentions.find(
      mention => mention?.user_id === currentUser.currentUser.id,
    )
  ) {
    apolloConversation.amIMentioned = true;
    apolloConversation.isMentionedConversation = true;
  }

  writeConversationFragment({
    client,
    conversation: apolloConversation,
    withLastAppointment: true,
    withLastChannel: true,
    withLastRepairOrder: true,
  });

  updateActiveInbox(client, [
    {
      id: apolloConversation.id,
      state: apolloConversation.state,
      lastUpdated: apolloConversation.lastUpdated,
      assigneeId: apolloConversation.assignee
        ? apolloConversation.assignee.id
        : null,
      inboxId: apolloConversation.inboxId,
      amIMentioned: apolloConversation.amIMentioned,
      isMentionedConversation: apolloConversation.isMentionedConversation,
      waitingSince: apolloConversation.waitingSince,
    },
  ]);

  const newMessage = convertPubNubMessageToMessageItemFields(
    args.message,
    args.conversation,
  );

  addMessageToCache({
    client,
    conversationId: args.conversation.id,
    message: newMessage,
  });

  if (process.env.NODE_ENV === 'test') {
    return;
  }

  if (
    // If conversation was known locally and changed between open/closed
    (existingConversation &&
      existingConversation?.state !== apolloConversation.state) ||
    // if the conversation is now active, and a relevant attribute changed
    (apolloConversation.state === 'ACTIVE' &&
      // if user is now mentioned but wasn't before
      (existingConversation?.amIMentioned !== apolloConversation.amIMentioned ||
        // if assignee changed
        existingConversation?.assignee?.id !==
          apolloConversation.assignee?.id ||
        // if team changed
        existingConversation?.team?.id !== apolloConversation.team?.id))
  ) {
    // query inboxes and get updated badge count
    client.query({
      query: GET_MY_INBOXES,
      fetchPolicy: 'network-only',
    });
  }
};
