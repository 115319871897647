import { gql } from '@apollo/client';
import { AutopilotFragment } from '../../fragments/gql/Autopilot.gql';

export const COMPLETE_AUTOPILOT = gql`
  mutation completeAutopilot($input: CompleteAutopilotInput!) {
    completeAutopilot(input: $input) {
      autopilot {
        ...AutopilotFields
      }
    }
  }
  ${AutopilotFragment}
`;
