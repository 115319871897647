import { detect } from 'detect-browser';

const browser = detect();

export const isInternetExplorer = browser?.name === 'ie';
export const isEdge = browser?.name === 'edge';
export const isSafari = browser?.name === 'safari';
export const isFirefox = browser?.name === 'firefox';
export const isIosSafari = browser?.name === 'ios';

export const isUnsupportedBrowser = isInternetExplorer;

export const isiOS = browser?.os === 'iOS';
export const isAndroid = browser?.os === 'Android OS';

export const isSupportedMobileOS = isiOS || isAndroid;

export const getWindowDimensions = () => ({
  innerWidth: window.innerWidth,
  innerHeight: window.innerHeight,
  screenWidth: window.screen.width,
  screenHeight: window.screen.height,
  screenAvailHeight: window.screen.availHeight,
  screenAvailWidth: window.screen.availWidth,
});
