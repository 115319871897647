import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiCreateHostedSmsPortRequest,
  ApiCreateHostedSmsPortResponse,
  ApiFetchHostedSmsPortRequest,
  ApiFetchHostedSmsPortResponse,
  ApiUpdateHostedSmsPortRequest,
  ApiUpdateHostedSmsPortResponse,
} from './types';

class HostedSmsService implements Service {
  static createHostedSmsPort(
    params: ApiCreateHostedSmsPortRequest,
  ): AxiosPromise<ApiCreateHostedSmsPortResponse> {
    const { url, ...payload } = params;
    return axios.post(url, payload);
  }

  static fetchHostedSmsPort(
    params: ApiFetchHostedSmsPortRequest,
  ): AxiosPromise<ApiFetchHostedSmsPortResponse> {
    const { url } = params;
    return axios.get(url);
  }

  static updateHostedSmsPort(
    params: ApiUpdateHostedSmsPortRequest,
  ): AxiosPromise<ApiUpdateHostedSmsPortResponse> {
    const { url, ...payload } = params;
    return axios.patch(url, payload);
  }
}

export default HostedSmsService;
