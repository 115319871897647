import { gql } from '@apollo/client';
import { ConversationLabelFragment } from '../../fragments/gql/labelFragment.gql';

export const UPDATE_CONVERSATION_LABEL = gql`
  mutation updateConversationLabel($input: UpdateConversationLabelInput!) {
    updateConversationLabel(input: $input) {
      conversationLabel {
        ...ConversationLabelFields
      }
    }
  }
  ${ConversationLabelFragment}
`;
