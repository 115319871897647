import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const DISCARD_AUTOMATION_EDITS = gql`
  mutation discardAutomationEdits($input: DiscardAutomationEditsInput!) {
    discardAutomationEdits(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
