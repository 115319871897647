import { gql } from '@apollo/client';

export const UPDATE_ESCALATION_SETTINGS = gql`
  mutation UpdateEscalationSettings($input: UpdateEscalationSettingsInput!) {
    updateEscalationSettings(input: $input) {
      currentAccount {
        escalationsEnabled
        escalationsThreshold
      }
    }
  }
`;
