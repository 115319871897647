import { gql } from '@apollo/client';

export const AutopilotFragment = gql`
  fragment AutopilotFields on AutopilotQL {
    __typename
    id
    eta
    identifier
    customerName
    autopilotStatus
    conversation {
      id
    }
    assignee {
      id
      displayName
    }
    previewWelcome
    previewInspection
    previewService
    previewQualityControl
    previewPickup
    autopilotScheduleMessages {
      id
      milestoneName
      scheduled {
        id
        sendAt
        status
      }
    }
  }
`;
