import { gql } from '@apollo/client';
import { VoiceStepFieldsFragment } from '../../fragments/gql/VoiceStepFieldsFragment.gql';

export const UPDATE_VOICE_STEP = gql`
  mutation updateVoiceStep($input: UpdateVoiceStepInput!) {
    updateVoiceStep(input: $input) {
      step {
        ...VoiceStepFields
      }
      script {
        id
        dirty
      }
    }
  }
  ${VoiceStepFieldsFragment}
`;
