import { all, takeEvery, put } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import type { ApiSignupRequest, ApiSignupResponse } from '@numbox/services';
import { SignupService } from '@numbox/services';

import type { Meta, ApiActionCreators, ExtractReturn } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export type SignupMeta = {
  fromAdminTool?: boolean;
} & Meta;

export const signup: ApiActionCreators<
  'SIGNUP.REQUEST',
  ApiSignupRequest,
  'SIGNUP.SUCCESS',
  ApiSignupResponse,
  SignupMeta
> = {
  request: (params: ApiSignupRequest, meta) => ({
    type: 'SIGNUP.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'SIGNUP.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'SIGNUP.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onSignupRequest(
  action: ExtractReturn<typeof signup.request>,
): Generator<any, void, void> {
  return yield serviceCall(
    SignupService.signup,
    signup,
    action.payload,
    action.meta,
  );
}

function* onSignupSuccess(
  action: ExtractReturn<typeof signup.success>,
): Generator<any, void, void> {
  if (!action.payload.auth || action.payload.claimed) {
    return;
  }
  if (action.meta && action.meta.fromAdminTool) {
    return;
  }
  yield put({
    type: 'AUTH_USER.SUCCESS',
    payload: action.payload.auth,
  });
}

export function* SignupSagas(): Saga<any> {
  yield all([
    takeEvery('SIGNUP.REQUEST', onSignupRequest),
    takeEvery('SIGNUP.SUCCESS', onSignupSuccess),
  ]);
}
