import { gql } from '@apollo/client';

export const PUBNUB_MESSAGE_RECEIVED = gql`
  mutation PubNubMessageReceived(
    $conversation: PubNubConversationModel!
    $message: PubNubMessageModel
  ) {
    PubNubMessageReceived(conversation: $conversation, message: $message)
      @client
  }
`;
