import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import * as amplitude from '@amplitude/analytics-browser';

import {
  ACTIONS,
  AnalyticsTrackEvent,
  createEvent,
  OBJECTS,
} from '@numbox/react';
import { getAccountId, getAccountName, getTeamId, getUserId } from './auth';
import { getUserById } from './users';

// Actions
export const TRACK_EVENT = 'numbox-web/analytics/TRACK_EVENT';

interface EventPayload {
  object: string;
  action: string;
  properties?: any;
}

// Action creators
export const trackEvent = (
  object: string,
  action: string,
  properties?: any,
) => {
  const payload: EventPayload = { object, action };
  if (properties) {
    payload.properties = properties;
  }

  return {
    type: TRACK_EVENT,
    payload,
  };
};

function setEnvironment(props: { [key: string]: unknown }) {
  let env = 'dev';
  switch (import.meta.env.MODE) {
    case 'production': {
      env = 'prod';
      break;
    }
    case 'test': {
      env = 'test';
      break;
    }
    default:
      break;
  }

  return { ...props, env };
}

declare global {
  interface Window {
    analytics: any;
    Cypress: any;
  }
}

const trackEventInSegment = (event: AnalyticsTrackEvent) => {
  if (import.meta.env.MODE !== 'test') {
    window.analytics.track(
      `${event.object} ${event.action}`,
      event.properties ? event.properties : {},
    );
  }
};

const trackEventInAmplitude = (event: AnalyticsTrackEvent) => {
  if (import.meta.env.MODE !== 'test') {
    amplitude.track(
      `[Amplitude Event] ${event.object} ${event.action}`,
      event.properties ? event.properties : {},
    );
  }
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'incomingAction' implicitly has an 'any'... Remove this comment to see the full error message
export function* onTrackEvent(incomingAction) {
  try {
    const { action, object, properties } = incomingAction.payload;
    const event = createEvent(object, action, properties);

    if (event.properties) {
      event.properties = setEnvironment(event.properties);
    }

    yield call(trackEventInSegment, event);
    yield call(trackEventInAmplitude, event);
  } catch (error) {
    console.warn(error);
  }
}

const trackPageViewInSegment = () => {
  if (import.meta.env.MODE !== 'test') {
    const propertiesWithEnvironment = setEnvironment({});
    window.analytics.page(undefined, undefined, propertiesWithEnvironment);
  }
};

export const trackPageView = () => {
  // amplitude automatically tracks page views for us
  trackPageViewInSegment();
};

export function* watchEventTracks() {
  yield takeEvery(TRACK_EVENT, onTrackEvent);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'accountId' implicitly has an 'any' type... Remove this comment to see the full error message
const registerInSegment = (accountId, accountName, teamId, memberId) => {
  if (import.meta.env.MODE !== 'test') {
    window.analytics.identify(memberId, { accountName, teamId, accountId });
  }
};

const registerInAmplitude = (
  accountId: string,
  accountName: string,
  teamId: string,
  memberId: string,
) => {
  // Skip initializing Amplitude in test mode.
  // Without initialization amplitude client will silently drop all events.
  if (import.meta.env.MODE !== 'test' && !window.Cypress) {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_KEY, memberId, {
      // Amplitude tracks page view events by default.
      // The event type for this event is [Amplitude] Page Viewed.
      defaultTracking: true,
    });
  } else {
    console.log('Amplitude not initialized in test mode.');
  }
};

function* registerInSentry(accountId: string, memberId: string) {
  if (import.meta.env.MODE !== 'test') {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const user = yield select(getUserById, memberId);

    if (!user) {
      return;
    }
    Sentry.setUser({
      email: user.email,
      userID: memberId,
      extra: {
        accountId,
      },
    });
  }
}

// @ts-expect-error ts-migrate(7010) FIXME: 'registerIdentity', which lacks return-type annota... Remove this comment to see the full error message
function* registerIdentity() {
  try {
    const [memberId, teamId, accountId, accountName] = yield all([
      select(getUserId),
      select(getTeamId),
      select(getAccountId),
      select(getAccountName),
    ]);

    yield call(registerInSegment, accountId, accountName, teamId, memberId);

    yield call(registerInSentry, accountId, memberId);

    yield call(registerInAmplitude, accountId, accountName, teamId, memberId);

    yield put(trackEvent(OBJECTS.USER, ACTIONS.LOGGED_IN));
  } catch (error) {
    console.warn(error);
  }
}

export function* watchUserLogin() {
  yield all([
    takeEvery('AUTH_USER.SUCCESS', registerIdentity),
    takeEvery('STORE_AUTH0_TOKEN', registerIdentity),
    takeEvery('REFRESH_AUTH0_TOKEN', registerIdentity),
    takeEvery('VERIFY_USER.SUCCESS', registerIdentity),
    takeEvery('REFRESH_TOKEN.SUCCESS', registerIdentity),
    takeEvery('UPDATE_PASSWORD.SUCCESS', registerIdentity),
  ]);
}
