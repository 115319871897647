import type { ApolloContext } from '../../types';
import { addLabelToCache } from './AddLabelToCache';

export const PubNubLabelUpdated = async (
  obj: any,
  args: { label: PubNubLabelModel },
  client: ApolloContext,
) => {
  addLabelToCache(client, args.label);
};
