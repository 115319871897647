import { gql } from '@apollo/client';
import { UserDetailAdminFragment } from '../../fragments/gql/userItem.gql';

export const GET_ACCOUNT_DETAILS_QUERY = gql`
  query account(
    $accountId: String!
    $placesAfter: String
    $usersAfter: String
  ) {
    account(id: $accountId) {
      id
      name
      status
      businessVertical
      useNewNumber
      places(after: $placesAfter) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            name
            newConversationsPerDay
            status
            placeGroup {
              id
              name
            }
          }
        }
      }
      users(first: 200, after: $usersAfter) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...UserDetailFields
          }
        }
      }
      owners {
        id
        email
        firstName
        lastName
      }
      billingIdentity {
        id
        signupMode
        subscriptions {
          id
          available
          quantity
          sku {
            id
            skuType
            isActive
            name
          }
        }
      }
    }
  }
  ${UserDetailAdminFragment}
`;
