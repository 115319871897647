import { gql } from '@apollo/client';

export const SET_HANGUP_SUGGESTION = gql`
  mutation setHangupSuggestion($input: SetHangupSuggestionInput!) {
    setHangupSuggestion(input: $input) {
      phoneLinePolicy {
        id
        hangupSuggestion
      }
    }
  }
`;
