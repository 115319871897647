import { gql } from '@apollo/client';

export const SET_ASSIGNMENT_RETENTION = gql`
  mutation setAssignmentRetention($newRetention: Int!) {
    setAssignmentRetention(input: { assignmentRetention: $newRetention }) {
      currentAccount {
        assignmentRetention
      }
    }
  }
`;
