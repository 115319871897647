import { gql } from '@apollo/client';

export const UPDATE_ANSWERING_SCRIPT_ORDER = gql`
  mutation updateAnsweringScriptOrder(
    $input: [UpdateAnsweringScriptOrderInput!]!
  ) {
    updateAnsweringScriptOrder(input: $input) {
      answeringScripts {
        id
        order
      }
    }
  }
`;
