import { all, throttle } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import type { ApiBadgeResponse } from '@numbox/services';
import { UserService } from '@numbox/services';
import type { ApiActionCreators, Meta } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const fetchBadgeCount: ApiActionCreators<
  'FETCH_BADGE_COUNT.REQUEST',
  void,
  'FETCH_BADGE_COUNT.SUCCESS',
  ApiBadgeResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'FETCH_BADGE_COUNT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_BADGE_COUNT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_BADGE_COUNT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchBadgeCountRequest(): Generator<any, void, void> {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 2.
  yield serviceCall(UserService.badgeCount, fetchBadgeCount);
}

export function* BadgeSagas(): Saga<any> {
  yield all([
    throttle(2000, 'FETCH_BADGE_COUNT.REQUEST', onFetchBadgeCountRequest),
  ]);
}
