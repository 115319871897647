import { gql } from '@apollo/client';

export const UPDATE_ACTIVE_INTEGRATION = gql`
  mutation updateActiveIntegration($input: UpdateActiveIntegrationInput!) {
    updateActiveIntegration(input: $input) {
      activeIntegration {
        id
        status
        configuration
        pendingOpcodeSync
        appointmentAgentSettings {
          id
          placeId
          name
          settings
        }
      }
    }
  }
`;
