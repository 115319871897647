import { gql } from '@apollo/client';

export const UPDATE_QUESTION_AGENT_SETTINGS = gql`
  mutation updateQuestionAgentSettings(
    $input: UpdateQuestionAgentSettingsInput!
  ) {
    updateQuestionAgentSettings(input: $input) {
      questionAgentSettings {
        id
        name
        settings
      }
    }
  }
`;
