import axios from 'axios';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'axio... Remove this comment to see the full error message
import createError from 'axios/lib/core/createError';
import some from 'lodash/some';
import { getBaseUrl } from '@numbox/util';

const _ = { some };

/* eslint-disable no-undef */
if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
  axios.defaults.baseURL = getBaseUrl();
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'token' implicitly has an 'any' type.
export const resetAuthHeader = token => {
  const header = `Bearer ${token}`;
  axios.defaults.headers.common.Authorization = header;
};

/* esline-enable no-undef */
const NO_AUTH_URLS = [
  '/api/accounts',
  '/api/billing_identity',
  '/api/signup',
  '/token/auth',
  '/token/refresh',
  '/token/reset',
  '/token/request',
  '/token/exchange',
  '/app_versions',
];

const NO_AUTH_ENDPOINTS_TO_HTTP_METHOD = {
  '/api/business_search': ['post'],
  '/api/users/invite': ['patch'],
};

axios.interceptors.request.use(
  config => {
    if (config.headers.common.Authorization) {
      return config;
    }

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (_.some(NO_AUTH_URLS, url => config.url.includes(url))) {
      return config;
    }

    if (
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      config.url in NO_AUTH_ENDPOINTS_TO_HTTP_METHOD &&
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      NO_AUTH_ENDPOINTS_TO_HTTP_METHOD[config.url].includes(config.method)
    ) {
      return config;
    }
    throw createError('Request requires an auth token', config, '401');
  },
  error => Promise.reject(error),
);

export const initializeAxios = () => {
  /* eslint-disable no-undef */
  if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
    axios.defaults.baseURL = getBaseUrl();
  }
  axios.interceptors.request.use(
    config => {
      if (config.headers.common.Authorization) {
        return config;
      }

      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (_.some(NO_AUTH_URLS, url => config.url.includes(url))) {
        return config;
      }

      if (
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        config.url in NO_AUTH_ENDPOINTS_TO_HTTP_METHOD &&
        // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
        NO_AUTH_ENDPOINTS_TO_HTTP_METHOD[config.url].includes(config.method)
      ) {
        return config;
      }
      throw createError('Request requires an auth token', config, '401');
    },
    error => Promise.reject(error),
  );
};
