import { gql } from '@apollo/client';

export const REQUEST_GOOGLE_MESSAGING_AGENT_VERIFICATION = gql`
  mutation requestGoogleMessagingAgentVerification(
    $input: RequestGoogleMessagingAgentVerificationInput
  ) {
    requestGoogleMessagingAgentVerification(input: $input) {
      googleAgent {
        id
        accountId
        agentId
        brandId
        contactEmail
        websiteUrl
        status
      }
    }
  }
`;
