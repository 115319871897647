import { gql } from '@apollo/client';
import { InboxBehaviourFragment } from '../../fragments/gql/InboxBehaviourFragment.gql';

export const UPDATE_HIDE_SHARED_INBOX_VIEWS = gql`
  mutation UpdateHideSharedInboxViews(
    $input: UpdateHideSharedInboxViewsInput!
  ) {
    updateHideSharedInboxViews(input: $input) {
      user {
        ...InboxBehaviourFields
      }
    }
  }
  ${InboxBehaviourFragment}
`;
