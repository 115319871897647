import { gql } from '@apollo/client';

export const RESET_APPOINTMENT_AGENT_SETTINGS = gql`
  mutation resetAppointmentAgentSettings(
    $input: ResetAppointmentAgentSettingsInput!
  ) {
    resetAppointmentAgentSettings(input: $input) {
      appointmentAgentSettings {
        id
      }
    }
  }
`;
