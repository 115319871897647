import { gql } from '@apollo/client';

export const GET_PLACE_OUTBOUND_CALL_SETTINGS = gql`
  query getPlaceOutboundCallSettings($placeId: String!) {
    place(id: $placeId) {
      id
      outboundCallSettings {
        id
        mdn
      }
    }
  }
`;
