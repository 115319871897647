import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const REMOVE_AUTOMATION_ACTION = gql`
  mutation removeAutomationAction($input: RemoveAutomationActionInput!) {
    removeAutomationAction(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
