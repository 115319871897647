import { gql } from '@apollo/client';
import { UserItemFragment } from '../../fragments/gql/userItem.gql';

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...UserItemFields
        signature
        signaturePreference
      }
    }
  }
  ${UserItemFragment}
`;
