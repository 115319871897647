import { gql } from '@apollo/client';

export const AUTO_COMPLETE_PARTICIPANTS = gql`
  query searchParticipants($search: String!, $first: Int!) {
    autocompleteParticipants(search: $search, first: $first)
      @connection(key: "autocompleteParticipants", filter: []) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          displayName
          address
          source
          conversations {
            pageInfo {
              startCursor
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                place {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
