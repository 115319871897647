import { gql } from '@apollo/client';

export const REQUEST_GOOGLE_MESSAGING_AGENT_LAUNCH = gql`
  mutation requestGoogleMessagingAgentLaunch(
    $input: RequestGoogleMessagingAgentLaunchInput
  ) {
    requestGoogleMessagingAgentLaunch(input: $input) {
      googleAgent {
        id
        accountId
        agentId
        brandId
        contactEmail
        websiteUrl
        status
      }
    }
  }
`;
