import { gql } from '@apollo/client';

import { PhoneCallFragment } from '../../fragments/gql/phoneCall.gql';

export const GET_ACTIVE_INBOUND_PHONE_CALLS = gql`
  query getActiveInboundPhoneCalls($userId: String!) {
    activeInboundPhoneCalls(userId: $userId) {
      ...PhoneCallFields
    }
  }
  ${PhoneCallFragment}
`;
