import en from './en.json';
import es from './es-ES.json';

export default {
  en: {
    locales: ['en-US'],
    messages: en,
    formats: {
      number: {
        USD: {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'symbol',
        },
      },
    },
  },

  es: {
    locales: ['es-ES'],
    messages: es,
  },
};
