import { gql } from '@apollo/client';

export const CREATE_SHORTCUT = gql`
  mutation createShortcut($input: CreateShortcutInput!) {
    createShortcut(input: $input) {
      shortcut {
        id
      }
    }
  }
`;
