import { gql } from '@apollo/client';

export const UPDATE_GREETING = gql`
  mutation updateGreeting($input: UpdateGreetingInput!) {
    updateGreeting(input: $input) {
      greeting {
        id
        content
        contentTemplate
        afterHoursContent
        afterHoursContentTemplate
      }
    }
  }
`;
