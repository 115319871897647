import { gql } from '@apollo/client';
import { AwayModeFragment } from '../../fragments/gql/AwayModeFragment.gql';

export const UPDATE_AWAY_MODE_WITH_TIME = gql`
  mutation updateAwayModeWithTime($input: UpdateAwayModeWithTimeInput!) {
    updateAwayModeWithTime(input: $input) {
      user {
        ...AwayModeFields
      }
    }
  }
  ${AwayModeFragment}
`;
