import { gql } from '@apollo/client';
import { VoiceStepFieldsFragment } from './VoiceStepFieldsFragment.gql';

export const VoiceScriptFieldsFragment = gql`
  fragment VoiceScriptFields on VoiceScriptQL {
    __typename
    id
    createdOn
    afterHoursEnabled
    scriptType
    placeId
    voice
    allowCustomAudio
    firstStepId
    dirty
    steps {
      ...VoiceStepFields
    }
  }
  ${VoiceStepFieldsFragment}
`;
