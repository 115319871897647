import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const UPDATE_AUTOMATION_ACTION = gql`
  mutation updateAutomationAction($input: UpdateAutomationActionInput!) {
    updateAutomationAction(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
