import { gql } from '@apollo/client';
import { UserItemFragment } from '../../fragments/gql/userItem.gql';

export const AutoCompleteUsersFragment = gql`
  fragment AutoCompleteUsersFields on UserConnection {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...UserItemFields
      }
    }
  }
  ${UserItemFragment}
`;

export const AUTO_COMPLETE_USERS = gql`
  query searchUsers(
    $search: String!
    $conversationId: String
    $placeId: String
    $first: Int
  ) {
    autocompleteUsers(
      search: $search
      conversationId: $conversationId
      placeId: $placeId
      first: $first
    ) {
      ...AutoCompleteUsersFields
    }
  }
  ${AutoCompleteUsersFragment}
`;
