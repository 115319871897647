import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiGetAppliedIntentRequest,
  ApiGetAppliedIntentResponse,
  ApiFetchAppliedIntentsRequest,
  ApiFetchAppliedIntentsResponse,
  ApiUpdateAppliedIntentRequest,
  ApiUpdateAppliedIntentResponse,
  ApiRemoveAppliedIntentRequest,
  ApiRemoveAppliedIntentResponse,
  ApiCreateCustomIntentRequest,
  ApiCreateCustomIntentResponse,
  ApiUpdateCustomIntentRequest,
  ApiUpdateCustomIntentResponse,
  ApiDeleteCustomIntentRequest,
  ApiDeleteCustomIntentResponse,
  ApiApplyCustomIntentRequest,
  ApiApplyCustomIntentResponse,
  ApiSearchLibraryIntentsRequest,
  ApiSearchLibraryIntentsResponse,
  ApiApplyLibraryIntentRequest,
  ApiApplyLibraryIntentResponse,
} from './types';

class IntentService implements Service {
  // Applied
  static getAppliedIntent(
    params: ApiGetAppliedIntentRequest,
  ): AxiosPromise<ApiGetAppliedIntentResponse> {
    const { placeId, id } = params;
    const path = `/api/places/${placeId}/intents/${id}`;
    return axios.get(path);
  }

  static fetchAppliedIntents(
    params: ApiFetchAppliedIntentsRequest,
  ): AxiosPromise<ApiFetchAppliedIntentsResponse> {
    const path = `/api/places/${params.placeId}/intents`;
    return axios.get(path);
  }

  static updateAppliedIntent(
    params: ApiUpdateAppliedIntentRequest,
  ): AxiosPromise<ApiUpdateAppliedIntentResponse> {
    const { placeId, id, ...attributes } = params;
    const path = `/api/places/${placeId}/intents/${id}`;
    return axios.patch(path, attributes);
  }

  static removeAppliedIntent(
    params: ApiRemoveAppliedIntentRequest,
  ): AxiosPromise<ApiRemoveAppliedIntentResponse> {
    const { placeId, id } = params;
    const path = `/api/places/${placeId}/intents/${id}`;
    return axios.delete(path);
  }

  // Custom
  static createCustomIntent(
    params: ApiCreateCustomIntentRequest,
  ): AxiosPromise<ApiCreateCustomIntentResponse> {
    const path = `/api/custom_intent`;
    return axios.put(path, params);
  }

  static updateCustomIntent(
    params: ApiUpdateCustomIntentRequest,
  ): AxiosPromise<ApiUpdateCustomIntentResponse> {
    const { id, ...attributes } = params;
    const path = `/api/custom_intent/${id}`;
    return axios.patch(path, attributes);
  }

  static deleteCustomIntent(
    params: ApiDeleteCustomIntentRequest,
  ): AxiosPromise<ApiDeleteCustomIntentResponse> {
    const { id } = params;
    const path = `/api/custom_intent/${id}`;
    return axios.delete(path);
  }

  static applyCustomIntent(
    params: ApiApplyCustomIntentRequest,
  ): AxiosPromise<ApiApplyCustomIntentResponse> {
    const { placeId, id, ...otherParams } = params;
    const path = `/api/places/${placeId}/intents`;
    const attributes = {
      ...otherParams,
      library_intent_id: id,
    };

    return axios.post(path, attributes);
  }

  // Library
  static searchLibraryIntents(
    params: ApiSearchLibraryIntentsRequest,
  ): AxiosPromise<ApiSearchLibraryIntentsResponse> {
    const { searchTerm, allCategories, placeId } = params;
    let path = `/api/intent_library/${placeId}?all_categories=${
      allCategories ? '1' : '0'
    }`;
    // if (placeId) path= `${path}&place_id=${placeId}`
    if (searchTerm)
      path = `${path}&search_term=${encodeURIComponent(searchTerm)}`;
    return axios.get(path);
  }

  static applyLibraryIntent(
    params: ApiApplyLibraryIntentRequest,
  ): AxiosPromise<ApiApplyLibraryIntentResponse> {
    const { placeId, id, ...otherParams } = params;
    const path = `/api/places/${placeId}/intents`;
    const attributes = {
      ...otherParams,
      library_intent_id: id,
    };

    return axios.post(path, attributes);
  }
}

export default IntentService;
