import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as GreetingsApi from '../api/greetings';

import toCapitalCase from '../util/strings';

// Actions
export const RESET_GREETINGS = 'numbox-web/greetings/RESET_GREETINGS';

export const FETCH_GREETINGS = 'numbox-web/greetings/FETCH_GREETINGS';
export const FETCH_GREETINGS_SUCCESS = `${FETCH_GREETINGS}_SUCCESS`;
export const FETCH_GREETINGS_FAILURE = `${FETCH_GREETINGS}_FAILURE`;

export const UPDATE_GREETING = 'numbox-web/greetings/UPDATE_GREETING';
export const UPDATE_GREETING_SUCCESS = `${UPDATE_GREETING}_SUCCESS`;
export const UPDATE_GREETING_FAILURE = `${UPDATE_GREETING}_FAILURE`;

export const noop = () => {};

// Action Creators
export const resetGreetings = () => ({ type: RESET_GREETINGS });

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const fetchGreetings = (teamId, onSuccess = noop, onFailure = noop) => ({
  type: FETCH_GREETINGS,
  payload: { teamId, onSuccess, onFailure },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const fetchGreetingsSuccess = (teamId, greetings) => ({
  type: FETCH_GREETINGS_SUCCESS,
  payload: { teamId, greetings },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const fetchGreetingsFailure = (teamId, error) => ({
  type: FETCH_GREETINGS_FAILURE,
  payload: { teamId, error },
  error: true,
});

export const updateGreeting = (
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'greeting' implicitly has an 'any' type.
  greeting,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'attributes' implicitly has an 'any' typ... Remove this comment to see the full error message
  attributes,
  onSuccess = noop,
  onFailure = noop,
) => ({
  type: UPDATE_GREETING,
  payload: {
    teamId: greeting.teamId,
    greetingId: greeting.id,
    attributes,
    onSuccess,
    onFailure,
  },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const updateGreetingSuccess = (teamId, greeting) => ({
  type: UPDATE_GREETING_SUCCESS,
  payload: { teamId, greeting },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const updateGreetingFailure = (teamId, error) => ({
  type: UPDATE_GREETING_FAILURE,
  payload: { teamId, error },
  error: true,
});

// Sagas
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export function* doFetchGreetings(action) {
  const { teamId, onSuccess, onFailure } = action.payload;
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(GreetingsApi.fetchGreetings, teamId);
    yield put(fetchGreetingsSuccess(teamId, response.data));
    yield call(onSuccess);
  } catch (error: any) {
    yield put(fetchGreetingsFailure(teamId, error.message));
    yield call(onFailure);
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export function* doUpdateGreetings(action) {
  const { teamId, greetingId, attributes, onSuccess, onFailure } =
    action.payload;
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(
      GreetingsApi.updateGreeting,
      teamId,
      greetingId,
      attributes,
    );

    yield put(updateGreetingSuccess(teamId, response.data));
    yield call(onSuccess);
  } catch (error: any) {
    yield put(updateGreetingFailure(teamId, error.message));
    yield call(onFailure);
  }
}

export function* watchGreetings() {
  yield takeEvery(FETCH_GREETINGS, doFetchGreetings);
  yield takeEvery(UPDATE_GREETING, doUpdateGreetings);
}

// Reducer
export const GREETINGS_INITIAL_STATE = {
  byTeamId: {},
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'greeting' implicitly has an 'any' type.
const fromGreetingAPI = greeting => {
  const greetingName = greeting.kind;
  const greetingTitle = toCapitalCase(greetingName.toLowerCase());
  const formattedGreeting = {
    content: greeting.content,
    contentTemplate: greeting.content_template,
    id: greeting.id,
    name: greetingTitle,
    kind: greeting.kind,
    teamId: greeting.team_id,
    title: greetingTitle,
    afterHoursContent: greeting.after_hours_content,
    afterHoursContentTemplate: greeting.after_hours_content_template,
  };

  return formattedGreeting;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const GreetingReducer = (state = GREETINGS_INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_GREETINGS: {
      return GREETINGS_INITIAL_STATE;
    }
    case FETCH_GREETINGS_SUCCESS: {
      const { teamId, greetings } = action.payload;
      const newState = cloneDeep(state);
      const result = Object.keys(greetings).map(i =>
        fromGreetingAPI(greetings[i]),
      );

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newState.byTeamId[teamId] = result;
      return newState;
    }
    case UPDATE_GREETING_SUCCESS: {
      const { teamId, greeting } = action.payload;
      const newGreeting = fromGreetingAPI(greeting);
      const newState = cloneDeep(state);
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!newState.byTeamId[teamId]) newState.byTeamId[teamId] = [];
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const matchIndex = newState.byTeamId[teamId].findIndex(
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
        i => i.id === newGreeting.id,
      );

      if (matchIndex > -1) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState.byTeamId[teamId][matchIndex] = newGreeting;
      }
      return newState;
    }
    default:
      return state;
  }
};

export default GreetingReducer;

// Selectors
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getGreetings = (state, teamId, sortBy = 'kind') => {
  let result = state.greetings.byTeamId[teamId] || [];
  if (sortBy) result = orderBy(result, [sortBy], ['desc']);
  return result;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getGreetingById = (state, teamId, greetingId) => {
  const greetings = getGreetings(state, teamId);
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'g' implicitly has an 'any' type.
  return greetings ? greetings.find(g => g.id === greetingId) : null;
};
