import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import type {
  ApiFetchWidgetRequest,
  ApiFetchWidgetResponse,
  ApiUpdateWidgetRequest,
  ApiUpdateWidgetResponse,
} from '@numbox/services';
import { WidgetService } from '@numbox/services';

import type { Meta, ExtractReturn, ApiActionCreators } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const fetchWidget: ApiActionCreators<
  'FETCH_WIDGET.REQUEST',
  ApiFetchWidgetRequest,
  'FETCH_WIDGET.SUCCESS',
  ApiFetchWidgetResponse,
  Meta
> = {
  request: (params: ApiFetchWidgetRequest, meta) => ({
    type: 'FETCH_WIDGET.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_WIDGET.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_WIDGET.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchWidgetRequest(
  action: ExtractReturn<typeof fetchWidget.request>,
): Generator<any, any, any> {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  yield serviceCall(WidgetService.fetchWidget, fetchWidget, action.payload);
}

export const updateWidget: ApiActionCreators<
  'UPDATE_WIDGET.REQUEST',
  ApiUpdateWidgetRequest,
  'UPDATE_WIDGET.SUCCESS',
  ApiUpdateWidgetResponse,
  Meta
> = {
  request: (params: ApiUpdateWidgetRequest, meta) => ({
    type: 'UPDATE_WIDGET.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'UPDATE_WIDGET.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'UPDATE_WIDGET.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onUpdateWidgetRequest(
  action: ExtractReturn<typeof updateWidget.request>,
): Generator<any, any, any> {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  yield serviceCall(WidgetService.updateWidget, updateWidget, action.payload);
}

export function* WidgetSagas(): Saga<any> {
  yield all([
    takeEvery('FETCH_WIDGET.REQUEST', onFetchWidgetRequest),
    takeEvery('UPDATE_WIDGET.REQUEST', onUpdateWidgetRequest),
  ]);
}
