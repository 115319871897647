import { gql } from '@apollo/client';

import { PhoneLinePolicyFieldsFragment } from '../../fragments/gql/PhoneLinePolicy.gql';

export const UPDATE_PHONE_LINE_POLICY = gql`
  mutation UpdatePhoneLinePolicy($input: UpdatePhoneLinePolicyInput!) {
    updatePhoneLinePolicy(input: $input) {
      phoneLinePolicy {
        ...PhoneLinePolicyFields
      }
    }
  }
  ${PhoneLinePolicyFieldsFragment}
`;
