import { gql } from '@apollo/client';
import { PaymentRequestFragment } from '../../fragments/gql/paymentRequestFragment.gql';

export const CREATE_PARTICIPANT_PAYMENT_REQUEST = gql`
  mutation createParticipantPaymentRequest(
    $input: CreateParticipantPaymentRequestInput!
  ) {
    createParticipantPaymentRequest(input: $input) {
      paymentRequest {
        ...PaymentRequestFields
      }
    }
  }
  ${PaymentRequestFragment}
`;
