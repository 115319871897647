import { gql } from '@apollo/client';

export const GET_PLACE_NEW_CONVERSATION_LIMIT = gql`
  query getPlaceNewConversationLimit($placeId: String!) {
    place(id: $placeId) {
      id
      newConversationsPerDay
      outboundConversationsToday
    }
  }
`;
