export const LAUNCH_DARKLY_INITIAL_STATE = {};

export type LaunchDarklyReducerState = Record<string, string | boolean>;

type LaunchDarklyActions = {
  type:
    | 'FEATUREFLAG_REFRESH'
    | 'UPDATE_PASSWORD.SUCCESS'
    | 'REFRESH_TOKEN.SUCCESS'
    | 'AUTH_USER.SUCCESS'
    | 'VERIFY_USER.SUCCESS'
    | 'STORE_AUTH0_TOKEN'
    | 'REFRESH_AUTH0_TOKEN'
    | 'ADMIN_AUTH_SUCCESS';

  payload: {
    flags: Record<string, string | boolean>;
  };
};

export default function LaunchDarklyReducer(
  state: LaunchDarklyReducerState = LAUNCH_DARKLY_INITIAL_STATE,
  action: LaunchDarklyActions,
) {
  switch (action.type) {
    case 'FEATUREFLAG_REFRESH':
    case 'UPDATE_PASSWORD.SUCCESS':
    case 'REFRESH_TOKEN.SUCCESS':
    case 'AUTH_USER.SUCCESS':
    case 'STORE_AUTH0_TOKEN':
    case 'REFRESH_AUTH0_TOKEN':
    case 'VERIFY_USER.SUCCESS':
    case 'ADMIN_AUTH_SUCCESS':
      return {
        ...action.payload.flags,
      };

    default:
      return state;
  }
}
