import { gql } from '@apollo/client';

export const GET_SHORTCUT = gql`
  query getShortcut($shortcutId: String!) {
    shortcut(shortcutId: $shortcutId) {
      id
      name
      tag
      content
      place {
        name
      }
    }
  }
`;
