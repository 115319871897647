import { gql } from '@apollo/client';

export const GET_PAYMENT_RECEIPT_MESSAGE = gql`
  query getPaymentReceiptMessage($id: String!) {
    paymentRequest(id: $id) {
      id
      receiptMessage
    }
  }
`;
