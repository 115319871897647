import { gql } from '@apollo/client';
import { AwayModeFragment } from '../../fragments/gql/AwayModeFragment.gql';

export const UPDATE_USER_SCHEDULE = gql`
  mutation updateUserSchedule($input: UpdateUserScheduleInput!) {
    updateUserSchedule(input: $input) {
      user {
        ...AwayModeFields
        schedule
        isScheduleEnabled
      }
    }
  }
  ${AwayModeFragment}
`;
