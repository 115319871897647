/* eslint-disable no-param-reassign */
import type { ApolloContext } from '../../types';
import { updateActiveInbox, writeConversationFragment } from '../../util';
import { convertPubNubConversationToConversationItemFields } from './PubNubMessageReceived';

export const PubNubConversationDeleted = async (
  obj: any,
  args: { conversation: PubNubConversationModel },
  { client }: ApolloContext,
) => {
  const apolloConversation = convertPubNubConversationToConversationItemFields(
    args.conversation,
  );

  writeConversationFragment({
    client,
    conversation: apolloConversation,
    withLastAppointment: true,
    withLastChannel: false,
    withLastRepairOrder: true,
  });

  // Remove from Active conversations
  updateActiveInbox(client, [
    {
      id: args.conversation.id,
      inboxId: args.conversation.inbox_id,
      lastUpdated: args.conversation.last_updated,
      waitingSince: args.conversation.waiting_since,
      state: args.conversation.state,
      assigneeId: args.conversation.assignee && args.conversation.assignee.id,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; inboxId: string; lastUpdated: ... Remove this comment to see the full error message
      allMessagesDeleted: true,
    },
  ]);
};
