import { gql } from '@apollo/client';

// FIXME: Remove the usePamV3 parameter once the switch to V3 is complete.
export const GET_PUBNUB_CONFIG = gql`
  query getPubnubConfig {
    pubnub(usePamV3: true) {
      userId
      userParticipantId
      pamKey
      pamToken
      channels
      channelGroups
      pushChannels
      browserPushChannels
      subscribeKey
      publishKey
      deviceToken
    }
  }
`;
