import { gql } from '@apollo/client';

export const GET_PAYMENT_REQUEST_CUSTOMIZATION = gql`
  query getPaymentRequestCustomization($placeId: String!) {
    paymentRequestCustomization(placeId: $placeId) {
      id
      placeId

      createTemplate
      renderedCreateTemplate
      createAutoSend

      createHoldTemplate
      renderedCreateHoldTemplate
      createHoldAutoSend

      successTemplate
      renderedSuccessTemplate
      successAutoSend

      refundTemplate
      renderedRefundTemplate
      refundAutoSend

      partialRefundTemplate
      renderedPartialRefundTemplate
      partialRefundAutoSend

      canceledTemplate
      renderedCanceledTemplate
      canceledAutoSend
    }
  }
`;
