import { Components, Theme } from '@mui/material';

export const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.divider,
    }),
  },
};
