import { gql } from '@apollo/client';

import { UserItemAndNotificationFragment } from '../../fragments/gql/userItem.gql';
import { SkuEntitlementsFragment } from '../../fragments/gql/skuEntitlements.gql';
import { AwayModeFragment } from '../../fragments/gql/AwayModeFragment.gql';
import { InboxBehaviourFragment } from '../../fragments/gql/InboxBehaviourFragment.gql';
import { UserViewPreferencesFragment } from '../../fragments/gql/UserViewPreferences.gql';

export const GET_CURRENT_USER = gql`
  query getCurrentUser($withPermissions: Boolean = false) {
    currentUser {
      accountPermissions @include(if: $withPermissions) {
        accountId
        permissions
      }
      placePermissions @include(if: $withPermissions) {
        placeId
        permissions
      }
      ...UserItemAndNotificationFields
      mdn
      jobRole
      jobTitle
      participantId
      videoConferenceUrl
      ...AwayModeFields
      ...InboxBehaviourFields
      signaturePreference
      hasStatusItems
      hasExternalIdentity
      viewPreferences {
        ...UserViewPreferencesFields
      }
    }
    skuEntitlements {
      ...SkuEntitlementsFields
    }
  }
  ${InboxBehaviourFragment}
  ${UserItemAndNotificationFragment}
  ${SkuEntitlementsFragment}
  ${AwayModeFragment}
  ${UserViewPreferencesFragment}
`;
