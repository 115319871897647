import { gql } from '@apollo/client';
import { HostedSmsPortFragment } from '../../fragments/gql/HostedSmsPortFragment.gql';

export const UPDATE_HOSTED_SMS = gql`
  mutation updateHostedSms($input: UpdateHostedSmsInput!) {
    updateHostedSms(input: $input) {
      hostedSmsPort {
        ...HostedSmsPortFields
      }
    }
  }
  ${HostedSmsPortFragment}
`;
