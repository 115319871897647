import { ApolloClient, DataProxy } from '@apollo/client';

export const safeRead = <TData = any, TVariables = any>(
  client: ApolloClient<any>,
  query: DataProxy.Query<TVariables, TData>,
  optimistic = false,
) => {
  // Apollo Cache `readQuery` will throw if the query has not previously been fetched.
  // We don't need to distinguish between "never been fetched"
  // and "fetched, but was null".
  // So wrap the read with a try/catch that returns null for future readability.
  try {
    return client.readQuery<TData, TVariables>(query, optimistic);
  } catch (e) {
    return null;
  }
};

export const safeReadFragment = <T>(
  client: ApolloClient<any>,
  options: {
    id: string;
    fragment: any;
    fragmentName: string;
    variables?: any;
  },
  optimistic = false,
): T | null => {
  try {
    return client.readFragment<T>(
      {
        ...options,
      },
      optimistic,
    );
  } catch (e) {
    return null;
  }
};
