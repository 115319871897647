import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const PUBLISH_AUTOMATION = gql`
  mutation publishAutomation($input: PublishAutomationInput!) {
    publishAutomation(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
