import { gql } from '@apollo/client';
import { AwayModeFragment } from '../../fragments/gql/AwayModeFragment.gql';

export const UPDATE_AWAY_MODE = gql`
  mutation updateAwayMode($input: UpdateAwayModeInput!) {
    updateAwayMode(input: $input) {
      user {
        ...AwayModeFields
      }
    }
  }
  ${AwayModeFragment}
`;
