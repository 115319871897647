import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import type {
  ApiBusinessSearchRequest,
  ApiBusinessSearchResponse,
} from '@numbox/services';
import { BusinessSearchService } from '@numbox/services';

import type { ApiActionCreators, Meta, ExtractReturn } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const searchForBusiness: ApiActionCreators<
  'SEARCH_FOR_BUSINESS.REQUEST',
  ApiBusinessSearchRequest,
  'SEARCH_FOR_BUSINESS.SUCCESS',
  ApiBusinessSearchResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'SEARCH_FOR_BUSINESS.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'SEARCH_FOR_BUSINESS.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'SEARCH_FOR_BUSINESS.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onSearchBusinessRequest(
  action: ExtractReturn<typeof searchForBusiness.request>,
): Generator<any, void, void> {
  yield serviceCall(
    BusinessSearchService.searchForBusiness,
    searchForBusiness,
    action.payload,
    action.meta,
  );
}

export function* BusinessSearchSagas(): Saga<any> {
  yield all([
    takeEvery('SEARCH_FOR_BUSINESS.REQUEST', onSearchBusinessRequest),
  ]);
}
