import { gql } from '@apollo/client';

export const ARCHIVE_MISSED_CALLS = gql`
  mutation ArchiveMissedCalls($input: ArchiveMissedCallsInput!) {
    archiveMissedCalls(input: $input) {
      phoneLinePolicy {
        id
        archiveMissedCalls
      }
    }
  }
`;
