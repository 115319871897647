import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import type {
  ApiGetAppliedIntentRequest,
  ApiGetAppliedIntentResponse,
  ApiFetchAppliedIntentsRequest,
  ApiFetchAppliedIntentsResponse,
  ApiUpdateAppliedIntentRequest,
  ApiUpdateAppliedIntentResponse,
  ApiRemoveAppliedIntentRequest,
  ApiRemoveAppliedIntentResponse,
  ApiCreateCustomIntentRequest,
  ApiCreateCustomIntentResponse,
  ApiUpdateCustomIntentRequest,
  ApiUpdateCustomIntentResponse,
  ApiDeleteCustomIntentRequest,
  ApiDeleteCustomIntentResponse,
  ApiApplyCustomIntentRequest,
  ApiApplyCustomIntentResponse,
  ApiApplyLibraryIntentRequest,
  ApiApplyLibraryIntentResponse,
  ApiSearchLibraryIntentsRequest,
  ApiSearchLibraryIntentsResponse,
} from '@numbox/services';
import { IntentService } from '@numbox/services';

import type { Meta, ExtractReturn, ApiActionCreators } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

type IntentsMeta = {
  redirect?: string | null | undefined;
  placeId?: string | null | undefined;
} & Meta;

export const getAppliedIntent: ApiActionCreators<
  'GET_APPLIED_INTENT.REQUEST',
  ApiGetAppliedIntentRequest,
  'GET_APPLIED_INTENT.SUCCESS',
  ApiGetAppliedIntentResponse,
  IntentsMeta
> = {
  request: (
    params: ApiGetAppliedIntentRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'GET_APPLIED_INTENT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'GET_APPLIED_INTENT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'GET_APPLIED_INTENT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onGetAppliedIntentRequest(
  action: ExtractReturn<typeof getAppliedIntent.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.getAppliedIntent,
    getAppliedIntent,
    action.payload,
    action.meta,
  );
}

export const fetchAppliedIntents: ApiActionCreators<
  'FETCH_APPLIED_INTENTS.REQUEST',
  ApiFetchAppliedIntentsRequest,
  'FETCH_APPLIED_INTENTS.SUCCESS',
  ApiFetchAppliedIntentsResponse,
  IntentsMeta
> = {
  request: (
    params: ApiFetchAppliedIntentsRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'FETCH_APPLIED_INTENTS.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'FETCH_APPLIED_INTENTS.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'FETCH_APPLIED_INTENTS.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchAppliedIntentsRequest(
  action: ExtractReturn<typeof fetchAppliedIntents.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.fetchAppliedIntents,
    fetchAppliedIntents,
    action.payload,
    action.meta,
  );
}

export const updateAppliedIntent: ApiActionCreators<
  'UPDATE_APPLIED_INTENT.REQUEST',
  ApiUpdateAppliedIntentRequest,
  'UPDATE_APPLIED_INTENT.SUCCESS',
  ApiUpdateAppliedIntentResponse,
  IntentsMeta
> = {
  request: (
    params: ApiUpdateAppliedIntentRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'UPDATE_APPLIED_INTENT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'UPDATE_APPLIED_INTENT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'UPDATE_APPLIED_INTENT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onUpdateAppliedIntentRequest(
  action: ExtractReturn<typeof updateAppliedIntent.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.updateAppliedIntent,
    updateAppliedIntent,
    action.payload,
    action.meta,
  );
}

export const removeAppliedIntent: ApiActionCreators<
  'REMOVE_APPLIED_INTENT.REQUEST',
  ApiRemoveAppliedIntentRequest,
  'REMOVE_APPLIED_INTENT.SUCCESS',
  ApiRemoveAppliedIntentResponse,
  IntentsMeta
> = {
  request: (
    params: ApiRemoveAppliedIntentRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'REMOVE_APPLIED_INTENT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'REMOVE_APPLIED_INTENT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'REMOVE_APPLIED_INTENT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onRemoveAppliedIntentRequest(
  action: ExtractReturn<typeof removeAppliedIntent.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.removeAppliedIntent,
    removeAppliedIntent,
    action.payload,
    action.meta,
  );
}

export const createCustomIntent: ApiActionCreators<
  'CREATE_CUSTOM_INTENT.REQUEST',
  ApiCreateCustomIntentRequest,
  'CREATE_CUSTOM_INTENT.SUCCESS',
  ApiCreateCustomIntentResponse,
  IntentsMeta
> = {
  request: (
    params: ApiCreateCustomIntentRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'CREATE_CUSTOM_INTENT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'CREATE_CUSTOM_INTENT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'CREATE_CUSTOM_INTENT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onCreateCustomIntentRequest(
  action: ExtractReturn<typeof createCustomIntent.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.createCustomIntent,
    createCustomIntent,
    action.payload,
    action.meta,
  );
}

export const updateCustomIntent: ApiActionCreators<
  'UPDATE_CUSTOM_INTENT.REQUEST',
  ApiUpdateCustomIntentRequest,
  'UPDATE_CUSTOM_INTENT.SUCCESS',
  ApiUpdateCustomIntentResponse,
  IntentsMeta
> = {
  request: (
    params: ApiUpdateCustomIntentRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'UPDATE_CUSTOM_INTENT.REQUEST',
    payload: params,
    meta: {
      ...meta,
      placeId: params.placeId,
    },
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'UPDATE_CUSTOM_INTENT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'UPDATE_CUSTOM_INTENT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onUpdateCustomIntentRequest(
  action: ExtractReturn<typeof updateCustomIntent.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.updateCustomIntent,
    updateCustomIntent,
    action.payload,
    action.meta,
  );
}

export const deleteCustomIntent: ApiActionCreators<
  'DELETE_CUSTOM_INTENT.REQUEST',
  ApiDeleteCustomIntentRequest,
  'DELETE_CUSTOM_INTENT.SUCCESS',
  ApiDeleteCustomIntentResponse,
  IntentsMeta
> = {
  request: (
    params: ApiDeleteCustomIntentRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'DELETE_CUSTOM_INTENT.REQUEST',
    payload: params,
    meta: {
      ...meta,
      placeId: params.placeId,
    },
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'DELETE_CUSTOM_INTENT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'DELETE_CUSTOM_INTENT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onDeleteCustomIntentRequest(
  action: ExtractReturn<typeof deleteCustomIntent.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.deleteCustomIntent,
    deleteCustomIntent,
    action.payload,
    action.meta,
  );
}

export const applyCustomIntent: ApiActionCreators<
  'APPLY_CUSTOM_INTENT.REQUEST',
  ApiApplyCustomIntentRequest,
  'APPLY_CUSTOM_INTENT.SUCCESS',
  ApiApplyCustomIntentResponse,
  IntentsMeta
> = {
  request: (
    params: ApiApplyCustomIntentRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'APPLY_CUSTOM_INTENT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'APPLY_CUSTOM_INTENT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'APPLY_CUSTOM_INTENT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onApplyCustomIntentRequest(
  action: ExtractReturn<typeof applyCustomIntent.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.applyCustomIntent,
    applyCustomIntent,
    action.payload,
    action.meta,
  );
}

export const searchLibraryIntents: ApiActionCreators<
  'SEARCH_LIBRARY_INTENTS.REQUEST',
  ApiSearchLibraryIntentsRequest,
  'SEARCH_LIBRARY_INTENTS.SUCCESS',
  ApiSearchLibraryIntentsResponse,
  IntentsMeta
> = {
  request: (
    params: ApiSearchLibraryIntentsRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'SEARCH_LIBRARY_INTENTS.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'SEARCH_LIBRARY_INTENTS.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'SEARCH_LIBRARY_INTENTS.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onSearchLibraryIntentsRequest(
  action: ExtractReturn<typeof searchLibraryIntents.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.searchLibraryIntents,
    searchLibraryIntents,
    action.payload,
    action.meta,
  );
}

export const applyLibraryIntent: ApiActionCreators<
  'APPLY_LIBRARY_INTENT.REQUEST',
  ApiApplyLibraryIntentRequest,
  'APPLY_LIBRARY_INTENT.SUCCESS',
  ApiApplyLibraryIntentResponse,
  IntentsMeta
> = {
  request: (
    params: ApiApplyLibraryIntentRequest,
    meta: IntentsMeta | null | undefined,
  ) => ({
    type: 'APPLY_LIBRARY_INTENT.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta: IntentsMeta | null | undefined) => ({
    type: 'APPLY_LIBRARY_INTENT.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta: IntentsMeta | null | undefined) => ({
    type: 'APPLY_LIBRARY_INTENT.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onApplyLibraryIntentRequest(
  action: ExtractReturn<typeof applyLibraryIntent.request>,
): Generator<any, void, void> {
  yield serviceCall(
    IntentService.applyLibraryIntent,
    applyLibraryIntent,
    action.payload,
    action.meta,
  );
}

// Sagas --------------------------------------------------------

export function* IntentsSagas(): Saga<any> {
  yield all([
    takeEvery('GET_APPLIED_INTENT.REQUEST', onGetAppliedIntentRequest),
    takeEvery('FETCH_APPLIED_INTENTS.REQUEST', onFetchAppliedIntentsRequest),
    takeEvery('UPDATE_APPLIED_INTENT.REQUEST', onUpdateAppliedIntentRequest),
    takeEvery('REMOVE_APPLIED_INTENT.REQUEST', onRemoveAppliedIntentRequest),
    takeEvery('CREATE_CUSTOM_INTENT.REQUEST', onCreateCustomIntentRequest),
    takeEvery('UPDATE_CUSTOM_INTENT.REQUEST', onUpdateCustomIntentRequest),
    takeEvery('DELETE_CUSTOM_INTENT.REQUEST', onDeleteCustomIntentRequest),
    takeEvery('APPLY_CUSTOM_INTENT.REQUEST', onApplyCustomIntentRequest),
    takeEvery('SEARCH_LIBRARY_INTENTS.REQUEST', onSearchLibraryIntentsRequest),
    takeEvery('APPLY_LIBRARY_INTENT.REQUEST', onApplyLibraryIntentRequest),
  ]);
}
