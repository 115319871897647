import { Components, Theme } from '@mui/material';

export const MuiListSubheader: Components<Theme>['MuiListSubheader'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      lineHeight: '1em',
    }),
  },
};
