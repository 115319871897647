import { gql } from '@apollo/client';
import { UserItemFragment } from '../../fragments/gql/userItem.gql';

export const UPDATE_USER_INFORMATION = gql`
  mutation updateUserInformation($input: UpdateUserInformationInput!) {
    updateUserInformation(input: $input) {
      user {
        ...UserItemFields
        jobRole
        jobTitle
        mdn
      }
    }
  }
  ${UserItemFragment}
`;
