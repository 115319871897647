import { gql } from '@apollo/client';
import { ChannelFieldsFragment } from '../../fragments/gql/channelFields.gql';

export const GET_OUTBOUND_PHONE_CHANNELS = gql`
  query getOutboundPhoneChannels($placeId: String) {
    outboundPhoneChannels(placeId: $placeId) {
      ...ChannelFields
    }
  }
  ${ChannelFieldsFragment}
`;
