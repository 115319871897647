import { gql } from '@apollo/client';

export const GET_MESSAGE_METADATA = gql`
  query getMessageMetadata($id: String!) {
    messageMetadata(messageId: $id) {
      name
      mdn
    }
  }
`;
