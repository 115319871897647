import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import type {
  ApiAdminCreateDemoUserRequest,
  ApiAdminCreateDemoUserResponse,
  ApiAdminDeleteUserRequest,
  ApiAdminDeleteUserResponse,
  ApiAdminFetchDemoUsersRequest,
  ApiAdminFetchDemoUsersResponse,
  ApiDemoResetRequest,
  ApiDemoResetResponse,
  ApiDemoStatusRequest,
  ApiDemoStatusResponse,
} from '@numbox/services';
import { AdminService } from '@numbox/services';

import type { Meta, ApiActionCreators, ExtractReturn } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const demoReset: ApiActionCreators<
  'DEMO_RESET.REQUEST',
  ApiDemoResetRequest,
  'DEMO_RESET.SUCCESS',
  ApiDemoResetResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'DEMO_RESET.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'DEMO_RESET.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'DEMO_RESET.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onDemoReset(
  action: ExtractReturn<typeof demoReset.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AdminService.demoReset,
    demoReset,
    action.payload,
    action.meta,
  );
}

export const demoStatus: ApiActionCreators<
  'DEMO_STATUS.REQUEST',
  ApiDemoStatusRequest,
  'DEMO_STATUS.SUCCESS',
  ApiDemoStatusResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'DEMO_STATUS.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'DEMO_STATUS.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'DEMO_STATUS.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onDemoStatus(
  action: ExtractReturn<typeof demoStatus.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AdminService.demoStatus,
    demoStatus,
    action.payload,
    action.meta,
  );
}

export const createDemoUser: ApiActionCreators<
  'CREATE_DEMO_USER.REQUEST',
  ApiAdminCreateDemoUserRequest,
  'CREATE_DEMO_USER.SUCCESS',
  ApiAdminCreateDemoUserResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'CREATE_DEMO_USER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'CREATE_DEMO_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'CREATE_DEMO_USER.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onCreateDemoUser(
  action: ExtractReturn<typeof createDemoUser.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AdminService.createDemoUser,
    createDemoUser,
    action.payload,
    action.meta,
  );
}

export const deleteDemoUser: ApiActionCreators<
  'DELETE_DEMO_USER.REQUEST',
  ApiAdminDeleteUserRequest,
  'DELETE_DEMO_USER.SUCCESS',
  ApiAdminDeleteUserResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'DELETE_DEMO_USER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'DELETE_DEMO_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'DELETE_DEMO_USER.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onDeleteDemoUser(
  action: ExtractReturn<typeof deleteDemoUser.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AdminService.deleteUser,
    deleteDemoUser,
    action.payload,
    action.meta,
  );
}

export const fetchDemoUsers: ApiActionCreators<
  'FETCH_DEMO_USERS.REQUEST',
  ApiAdminFetchDemoUsersRequest,
  'FETCH_DEMO_USERS.SUCCESS',
  ApiAdminFetchDemoUsersResponse,
  Meta
> = {
  request: (params, meta) => ({
    type: 'FETCH_DEMO_USERS.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_DEMO_USERS.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_DEMO_USERS.ERROR',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchDemoUsers(
  action: ExtractReturn<typeof fetchDemoUsers.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AdminService.fetchDemoUsers,
    fetchDemoUsers,
    action.payload,
    action.meta,
  );
}

export function* AdminSagas(): Saga<any> {
  yield all([
    takeEvery('CREATE_DEMO_USER.REQUEST', onCreateDemoUser),
    takeEvery('DELETE_DEMO_USER.REQUEST', onDeleteDemoUser),
    takeEvery('FETCH_DEMO_USERS.REQUEST', onFetchDemoUsers),
    takeEvery('DEMO_RESET.REQUEST', onDemoReset),
    takeEvery('DEMO_STATUS.REQUEST', onDemoStatus),
  ]);
}
