import { gql } from '@apollo/client';

export const ADD_PLACE_GROUP = gql`
  mutation addPlaceGroup($input: AddPlaceGroupInput!) {
    addPlaceGroup(input: $input) {
      placeGroup {
        __typename
        id
        name
        account {
          id
          name
        }
      }
    }
  }
`;
