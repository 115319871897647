import { gql } from '@apollo/client';
import { AutopilotFragment } from '../../fragments/gql/Autopilot.gql';

export const DISABLE_AUTOPILOT = gql`
  mutation disableAutopilot($input: DisableAutopilotInput!) {
    disableAutopilot(input: $input) {
      autopilot {
        ...AutopilotFields
      }
    }
  }
  ${AutopilotFragment}
`;
