import { gql } from '@apollo/client';
import { PaymentRequestFragment } from '../../fragments/gql/paymentRequestFragment.gql';

export const CREATE_PAYMENT_REQUEST = gql`
  mutation createPaymentRequest($input: CreatePaymentRequestInput!) {
    createPaymentRequest(input: $input) {
      paymentRequest {
        ...PaymentRequestFields
      }
    }
  }
  ${PaymentRequestFragment}
`;
