import { gql } from '@apollo/client';

export const UPDATE_CURBSIDE_SETTINGS = gql`
  mutation updateCurbsideSettings($input: UpdateCurbsideSettingsInput!) {
    updateCurbsideSettings(input: $input) {
      curbsideSettings {
        enabled
        orderIdentifierType
        orderIdentifierIntentResponse
        customHereIsAutoreply
        handoffMethod
        handoffMethodIntentResponse
        customHandoffIsAutoreply
      }
    }
  }
`;
