import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import type {
  ApiCreatePhoneLineRequest,
  ApiCreatePhoneLineResponse,
  ApiFetchPhoneLinesRequest,
  ApiFetchPhoneLinesResponse,
  ApiUpdatePhoneLineRequest,
  ApiUpdatePhoneLineResponse,
} from '@numbox/services';
import { PhoneLineService } from '@numbox/services';

import type { Meta, ExtractReturn, ApiActionCreators } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const createPhoneLine: ApiActionCreators<
  'CREATE_NEW_PHONE_LINE.REQUEST',
  ApiCreatePhoneLineRequest,
  'CREATE_NEW_PHONE_LINE.SUCCESS',
  ApiCreatePhoneLineResponse,
  Meta
> = {
  request: (params: ApiCreatePhoneLineRequest, meta) => ({
    type: 'CREATE_NEW_PHONE_LINE.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'CREATE_NEW_PHONE_LINE.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'CREATE_NEW_PHONELINE.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onCreatePhoneLineRequest(
  action: ExtractReturn<typeof createPhoneLine.request>,
): Generator<any, void, void> {
  yield serviceCall(
    PhoneLineService.createPhoneLine,
    createPhoneLine,
    action.payload,
    action.meta,
  );
}

export const fetchPhoneLines: ApiActionCreators<
  'FETCH_PHONE_LINES.REQUEST',
  ApiFetchPhoneLinesRequest,
  'FETCH_PHONE_LINES.SUCCESS',
  ApiFetchPhoneLinesResponse,
  Meta
> = {
  request: (params: ApiFetchPhoneLinesRequest, meta) => ({
    type: 'FETCH_PHONE_LINES.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_PHONE_LINES.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_PHONE_LINES.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchPhoneLinesRequest(
  action: ExtractReturn<typeof fetchPhoneLines.request>,
): Generator<any, void, void> {
  yield serviceCall(
    PhoneLineService.fetchPhoneLines,
    fetchPhoneLines,
    action.payload,
    action.meta,
  );
}

export const updatePhoneLine: ApiActionCreators<
  'UPDATE_PHONE_LINE.REQUEST',
  ApiUpdatePhoneLineRequest,
  'UPDATE_PHONE_LINE.SUCCESS',
  ApiUpdatePhoneLineResponse,
  Meta
> = {
  request: (params: ApiUpdatePhoneLineRequest, meta = {}) => ({
    type: 'UPDATE_PHONE_LINE.REQUEST',
    payload: params,
    meta: { ...meta, phonelineId: params.phone_line_id },
  }),

  success: (data, meta) => ({
    type: 'UPDATE_PHONE_LINE.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'UPDATE_PHONE_LINE.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onUpdatePhoneLineRequest(
  action: ExtractReturn<typeof updatePhoneLine.request>,
): Generator<any, void, void> {
  yield serviceCall(
    PhoneLineService.updatePhoneLine,
    updatePhoneLine,
    action.payload,
    action.meta,
  );
}

export function* PhoneLineSagas(): Saga<any> {
  yield all([
    takeEvery('CREATE_NEW_PHONE_LINE.REQUEST', onCreatePhoneLineRequest),
    takeEvery('FETCH_PHONE_LINES.REQUEST', onFetchPhoneLinesRequest),
    takeEvery('UPDATE_PHONE_LINE.REQUEST', onUpdatePhoneLineRequest),
  ]);
}
