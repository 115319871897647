import { gql } from '@apollo/client';

export const UPDATE_BILLING_METADATA = gql`
  mutation UpdateBillingMetadata($input: UpdateBillingMetadataInput!) {
    updateBillingMetadata(input: $input) {
      place {
        id
        billingMetadata {
          id
          hubspotDealId
          hubspotCompanyId
          chargebeeSubscriptionId
          chargebeeCustomerId
        }
      }
    }
  }
`;
