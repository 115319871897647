import { Components, Theme } from '@mui/material';
import { Color, getMainColor } from '../../util';

export const MuiRating: Components<Theme>['MuiRating'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      color:
        (ownerState.color && getMainColor(theme, ownerState.color as Color)) ||
        theme.palette.primary.main,
      gap: theme.spacing(0.5),
    }),
    iconEmpty: ({ theme, ownerState }) => ({
      color:
        (ownerState.color && getMainColor(theme, ownerState.color as Color)) ||
        theme.palette.primary.main,
    }),
  },
};
