import { gql } from '@apollo/client';
import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';

export const SET_OPT_IN_STATUS = gql`
  mutation setOptInStatus(
    $input: SetOptInStatusInput!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    setOptInStatus(input: $input) {
      conversation {
        ...ConversationItemFields
      }
    }
  }
  ${ConversationItemFragment}
`;
