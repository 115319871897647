import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from '../modules/analytics';

export const useTrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location]);

  return null;
};
