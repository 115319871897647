import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const GET_VOICE_SCRIPTS_FOR_ANSWERING_SCRIPT = gql`
  query getVoiceScriptsForAnsweringScript($id: String!) {
    answeringScript(answeringScriptId: $id) {
      id

      voiceScript {
        ...VoiceScriptFields
      }
      draftVoiceScript {
        ...VoiceScriptFields
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
