import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const GET_VOICE_SCRIPT = gql`
  query getVoiceScript($id: String!) {
    voiceScript(id: $id) {
      ...VoiceScriptFields
    }
  }
  ${VoiceScriptFieldsFragment}
`;
