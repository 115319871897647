import { gql } from '@apollo/client';

export const UPDATE_USER_MFA_SETTINGS = gql`
  mutation UpdateUserMFASettings($input: UpdateUserMFASettingsInput!) {
    updateUserMfaSettings(input: $input) {
      user {
        id
        enforceMfa
      }
    }
  }
`;
