import { gql } from '@apollo/client';

import { PaymentRequestFragment } from '../../fragments/gql/paymentRequestFragment.gql';

export const CANCEL_PAYMENT_REQUEST = gql`
  mutation CancelPaymentRequest($input: CancelPaymentRequestInput) {
    cancelPaymentRequest(input: $input) {
      paymentRequest {
        ...PaymentRequestFields
      }
    }
  }
  ${PaymentRequestFragment}
`;
