import type { ApolloContext } from '../../types';
import { safeRead } from '../../util';
import { GET_STRIPE_ACCOUNT } from '../../queries/gql/getStripeAccount.gql';

const convertStripeIntegration = (
  stripeIntegration: PubNubStripeIntegrationModel,
): getStripeAccount$stripeIntegration => {
  return {
    __typename: 'StripeIntegrationQL',
    id: stripeIntegration.id,
    placeId: stripeIntegration.place_id,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    stripeAccountId: stripeIntegration.stripe_account_id,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'PubNubStripeAccountType | null | undefined' ... Remove this comment to see the full error message
    stripeAccountType: stripeIntegration.stripe_account_type,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    stripeAccountStatus: stripeIntegration.stripe_account_status,
  };
};

export const PubNubStripeIntegrationUpdated = async (
  obj: any,
  args: { stripe_integration: PubNubStripeIntegrationModel },
  { client }: ApolloContext,
) => {
  const newStripeAccount = convertStripeIntegration(args.stripe_integration);

  const stripeAccount = safeRead(client, {
    query: GET_STRIPE_ACCOUNT,
    variables: { placeId: newStripeAccount.placeId },
  });

  const stripeIntegration = {
    ...stripeAccount,
    ...newStripeAccount,
  };

  await client.writeQuery({
    query: GET_STRIPE_ACCOUNT,
    variables: { placeId: stripeIntegration.placeId },
    data: { stripeIntegration },
  });
};
