import { gql } from '@apollo/client';
import { TeamSettingsFragment } from '../../fragments/gql/TeamFragment.gql';

export const GET_TEAMS_BY_PLACE_ID = gql`
  query getTeamsByPlaceId($id: String!) {
    teams(placeId: $id) {
      ...TeamSettingsFields
    }
  }
  ${TeamSettingsFragment}
`;
