import { gql } from '@apollo/client';
import { StatusItemDetails } from '../../fragments/gql/StatusItem.gql';

export const GET_STATUS_ITEM = gql`
  query statusItem($statusItemId: String!) {
    statusItem(statusItemId: $statusItemId) {
      ...StatusItemDetails
    }
  }
  ${StatusItemDetails}
`;
