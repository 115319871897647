import { gql } from '@apollo/client';
import { ComplianceSettingsFragment } from '../../fragments/gql/ComplianceSettings.gql';

export const UPDATE_COMPLIANCE_SETTINGS = gql`
  mutation UpdateComplianceSettings($input: UpdateComplianceSettingsInput!) {
    updateComplianceSettings(input: $input) {
      complianceSettings {
        ...ComplianceSettings
      }
    }
  }
  ${ComplianceSettingsFragment}
`;
