import { gql } from '@apollo/client';

export const POKE_USER = gql`
  mutation pokeUser($input: PokeUserInput!) {
    pokeUser(input: $input) {
      user {
        id
      }
    }
  }
`;
