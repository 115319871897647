import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiBusinessSearchRequest,
  ApiBusinessSearchResponse,
} from './types';

const path = `/api/business_search`;

class BusinessSearchService implements Service {
  static searchForBusiness(
    params: ApiBusinessSearchRequest,
  ): AxiosPromise<ApiBusinessSearchResponse> {
    return axios.post(path, params);
  }
}

export default BusinessSearchService;
