import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const GET_AUTOMATION_BY_ID = gql`
  query getAutomationById($automationId: String!, $version: String) {
    automation(automationId: $automationId, version: $version) {
      ...AutomationFields
    }
  }
  ${AutomationFragment}
`;
