import { gql } from '@apollo/client';

import { ChannelFieldsFragment } from '../../fragments/gql/channelFields.gql';

export const UPDATE_TWILIO_CHANNEL = gql`
  mutation UpdateTwilioChannel($input: UpdateTwilioChannelInput!) {
    updateTwilioChannel(input: $input) {
      channel {
        ...ChannelFields
      }
    }
  }
  ${ChannelFieldsFragment}
`;
