import { gql } from '@apollo/client';

export const UPDATE_TRANSPORTATION_SET = gql`
  mutation updateTransportationSet($input: UpdateTransportationSetInput!) {
    updateTransportationSet(input: $input) {
      transportationSet {
        id
        kind
        name
        placeId
        rules
        enabled
        speechUtterance
        transportationOptions {
          id
          enabled
          kind
          order
          speechShort
          speechLong
          minimumServiceItems
        }
      }
    }
  }
`;
