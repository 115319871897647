import * as React from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { KeyboardContext } from './KeyboardContext';

import type { RegisterKeyDownListener } from './types';
import './types';

type State = {
  keyDownListeners: Record<
    string,
    Array<{
      keyCode: number;
      callback: (...args: Array<any>) => any;
      preventDefault?: boolean;
    }>
  >;
};

type Props = {
  children: React.ReactNode;
};

export class KeyboardListener extends React.Component<Props, State> {
  state = {
    keyDownListeners: {},
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  addKeyDownListeners = (
    componentId: string,
    listeners: Array<RegisterKeyDownListener>,
  ) => {
    const { keyDownListeners } = this.state;

    if (componentId in keyDownListeners) {
      // Previously registered, no-op
      return;
    }

    this.setState({
      keyDownListeners: {
        ...keyDownListeners,
        [componentId]: listeners,
      },
    });
  };

  onKeyDown = (e: KeyboardEvent) => {
    const { keyDownListeners }: { keyDownListeners: { [key: string]: any[] } } =
      this.state;

    if (isEmpty(keyDownListeners)) {
      return;
    }

    Object.keys(keyDownListeners).forEach(componentId => {
      const listeners = keyDownListeners[componentId];
      listeners.forEach(listener => {
        if (listener.keyCode === e.keyCode) {
          if (listener.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
          }
          listener.callback(e);
        }
      });
    });
  };

  removeKeyDownListeners = (componentId: string) => {
    const { keyDownListeners } = this.state;
    const cloned = cloneDeep(keyDownListeners);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    delete cloned[componentId];

    this.setState({
      keyDownListeners: cloned,
    });
  };

  render() {
    const { children } = this.props;
    return (
      <KeyboardContext.Provider
        value={{
          addKeyDownListeners: this.addKeyDownListeners,
          removeKeyDownListeners: this.removeKeyDownListeners,
        }}
      >
        {children}
      </KeyboardContext.Provider>
    );
  }
}
