import { gql } from '@apollo/client';
import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';
import { MessageItemFragment } from '../../fragments/gql/messageItem.gql';

export const START_CONVERSATION = gql`
  mutation startConversation(
    $input: StartConversationInput!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    startConversation(input: $input) {
      conversation {
        ...ConversationItemFields
      }
      message {
        ...MessageItemFields
      }
    }
  }
  ${ConversationItemFragment}
  ${MessageItemFragment}
`;
