import { gql } from '@apollo/client';
import { VideoUploadLinkFragment } from '../../fragments/gql/videoUploadLinkFragment.gql';

export const UPDATE_VIDEO_UPLOAD_LINK = gql`
  mutation updateVideoUploadLink($input: UpdateVideoUploadLinkInput!) {
    updateVideoUploadLink(input: $input) {
      videoUploadLink {
        ...VideoUploadLinkFields
      }
    }
  }
  ${VideoUploadLinkFragment}
`;
