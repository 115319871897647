import { gql } from '@apollo/client';

export const CREATE_CONVERSATION_TEXT_EXPANSION = gql`
  mutation createConversationTextExpansion(
    $input: CreateConversationTextExpansionInput!
  ) {
    createConversationTextExpansion(input: $input) {
      textExpansion {
        responses
        messages {
          role
          content
        }
        data
      }
    }
  }
`;
