import { gql } from '@apollo/client';
import { PhoneLineFieldsFragment } from '../../fragments/gql/PhoneLineFields.gql';

export const REFRESH_CARRIER_INFO = gql`
  mutation refreshCarrierInfo($input: RefreshCarrierInfoInput!) {
    refreshCarrierInfo(input: $input) {
      phoneline {
        ...PhoneLineFields
      }
    }
  }
  ${PhoneLineFieldsFragment}
`;
