import { gql } from '@apollo/client';

export const GET_TEN_DLC_PROCESS_BY_ACCOUNT = gql`
  query getTenDLCProcessByAccount($accountId: String!) {
    tenDlcProcesses(accountId: $accountId) {
      place {
        id
        name
      }
      twilioA2pCampaign {
        id
        campaignStatus
      }
      twilioBrandRegistration {
        id
        status
      }
      twilioTrustProduct {
        id
        friendlyName
        status
      }
      registrationOptions
      errors {
        friendlyName
      }
    }
  }
`;
