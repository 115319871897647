import { gql } from '@apollo/client';
import { AutopilotFragment } from '../../fragments/gql/Autopilot.gql';

export const UPDATE_AUTOPILOT_SCHEDULED_MESSAGE = gql`
  mutation updateAutopilotScheduledMessage(
    $input: UpdateAutopilotScheduledMessageInput!
  ) {
    updateAutopilotScheduledMessage(input: $input) {
      autopilot {
        ...AutopilotFields
      }
    }
  }
  ${AutopilotFragment}
`;
