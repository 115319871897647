import { gql } from '@apollo/client';

import { ParticipantFragment } from '../../fragments/gql/participant.gql';

export const UPDATE_PARTICIPANT = gql`
  mutation updateParticipant($input: UpdateParticipantInput!) {
    updateParticipant(input: $input) {
      participant {
        ...ParticipantFields
      }
    }
  }
  ${ParticipantFragment}
`;
