import { gql } from '@apollo/client';
import { VideoUploadLinkFragment } from '../../fragments/gql/videoUploadLinkFragment.gql';

export const GET_PARTICIPANT_VIDEO_UPLOAD_LINKS = gql`
  query getParticipantVideoUploadLinks($participantId: String!) {
    participantVideoUploadLinks(participantId: $participantId) {
      ...VideoUploadLinkFields
    }
  }
  ${VideoUploadLinkFragment}
`;
