import { gql } from '@apollo/client';

export const DialogFlowBotDataFragment = gql`
  fragment DialogFlowBotDataFields on DialogFlowBotDataQL {
    id
    conversationId
    data
    appointmentWasBooked
    outcome
    agentReason
    agentDuration
    customerInDms
  }
`;
