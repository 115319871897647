import { gql } from '@apollo/client';
import { ChannelFieldsFragment } from '../../fragments/gql/channelFields.gql';

export const GET_CHANNEL_BY_ID = gql`
  query getChannelById($id: String!) {
    channel(id: $id) {
      ...ChannelFields
    }
  }
  ${ChannelFieldsFragment}
`;
