import { gql } from '@apollo/client';

export const DEACTIVATE_ACCOUNT = gql`
  mutation DeactivateAccount($input: DeactivateAccountInput!) {
    deactivateAccount(input: $input) {
      account {
        id
        status
      }
    }
  }
`;
