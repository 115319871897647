import type { Components, Theme } from '@mui/material';
import { getContrastColor, getMainColor } from '../../util';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      if (ownerState.disabled || ownerState.variant !== 'filled') {
        return false;
      }
      const colors = {
        backgroundColor: getMainColor(theme, ownerState.color),
        color: getContrastColor(theme, ownerState.color),
      };
      return {
        ...colors,
        '&:hover': {
          ...colors,
        },
      };
    },
  },
};
