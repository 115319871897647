import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiCreatePhoneLineRequest,
  ApiCreatePhoneLineResponse,
  ApiFetchPhoneLinesRequest,
  ApiFetchPhoneLinesResponse,
  ApiUpdatePhoneLineRequest,
  ApiUpdatePhoneLineResponse,
} from './types';

class PhonelineService implements Service {
  static createPhoneLine(
    params: ApiCreatePhoneLineRequest,
  ): AxiosPromise<ApiCreatePhoneLineResponse> {
    const { url, ...payload } = params;
    return axios.post(url, payload);
  }

  static fetchPhoneLines(
    params: ApiFetchPhoneLinesRequest,
  ): AxiosPromise<ApiFetchPhoneLinesResponse> {
    const { place_id: placeId } = params;
    const path = `/api/places/${placeId}/phoneline`;
    return axios.get(path);
  }

  static updatePhoneLine(
    params: ApiUpdatePhoneLineRequest,
  ): AxiosPromise<ApiUpdatePhoneLineResponse> {
    const {
      place_id: placeId,
      phone_line_id: phoneLineId,
      ...payload
    } = params;
    const path = `/api/places/${placeId}/phoneline/${phoneLineId}`;
    return axios.patch(path, payload);
  }
}

export default PhonelineService;
