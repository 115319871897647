import { useRef, useEffect } from 'react';

// base on https://usehooks.com/usePrevious/
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export default function usePrevious(value, initialValue?) {
  const ref = useRef(initialValue);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
