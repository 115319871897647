import type { ApolloClient } from '@apollo/client';
import { ConversationItemFragment } from '../fragments/gql/conversationItem.gql';
import { safeReadFragment } from './apollo';

/*

Cache reading/writing semantics with include variables from fragments:
( these were determined by the tests in conversations.spec.js )

If a conversation fragment is written with includes, we can still read
that fragment out of the cache without includes, they just wont be in the result.

If a conversation fragment is written with includes, then written again without includes
the original cache values for the includes are maintained and can be read out.

If a conversation fragment is written with includes, then written again with includes, but
null values, those will overwrite the cache value for those included fields.

Conclusion:

We should always try to write fragments with includes, but its safe to update without inlcudes.
It is safe to always read with includes

*/

export const readConversationFragment = ({
  client,
  conversationId,
  withLastAppointment = true,
  withLastChannel = true,
  withLastRepairOrder = true,
}: {
  client: ApolloClient<any>;
  conversationId: string;
  withLastAppointment?: boolean;
  withLastChannel?: boolean;
  withLastRepairOrder?: boolean;
}): ConversationItemFields | null | undefined => {
  const existingConversation = safeReadFragment<ConversationItemFields>(
    client,
    {
      id: `ConversationQL:${conversationId}`,
      fragment: ConversationItemFragment,
      fragmentName: 'ConversationItemFields',
      variables: {
        withLastAppointment,
        withLastChannel,
        withLastRepairOrder,
      },
    },
  );

  return existingConversation;
};

export const writeConversationFragment = ({
  client,
  conversation,
  withLastAppointment = false,
  withLastChannel = false,
  withLastRepairOrder = false,
}: {
  client: ApolloClient<any>;
  conversation: ConversationItemFields;
  withLastAppointment?: boolean;
  withLastChannel?: boolean;
  withLastRepairOrder?: boolean;
}) => {
  client.writeFragment({
    id: `ConversationQL:${conversation.id}`,
    fragment: ConversationItemFragment,
    fragmentName: 'ConversationItemFields',
    variables: {
      withLastAppointment,
      withLastChannel,
      withLastRepairOrder,
    },

    data: conversation,
  });
};
