import { gql } from '@apollo/client';
import { PaymentRequestStub } from '../../fragments/gql/paymentRequestFragment.gql';

export const GET_PAYMENT_REQUESTS = gql`
  query getPaymentRequests(
    $first: Int
    $after: String
    $status: String
    $placeId: String
    $senderId: String
    $filter: PaymentRequestModelFilter
  ) {
    paymentRequests(
      first: $first
      after: $after
      status: $status
      placeId: $placeId
      senderId: $senderId
      queryFilter: $filter
      sort: last_updated_desc
    )
      @connection(
        key: "paymentRequests"
        filter: ["status", "placeId", "senderId"]
      ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PaymentRequestStubFields
        }
      }
    }
  }
  ${PaymentRequestStub}
`;
