import { call, put } from 'redux-saga/effects';
import { AuthService } from '@numbox/services';
import CLIENT_TYPE from '../clientType';
import { refreshTokenActionCreators } from '../refreshToken';

function* refreshToken(token: string): Generator<any, any, any> {
  try {
    const response = yield call(AuthService.refresh, {
      refreshToken: token,
      clientType: CLIENT_TYPE,
    });

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    return yield put(refreshTokenActionCreators.success(response.data));
  } catch (error) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    return yield put(refreshTokenActionCreators.error(error));
  }
}

export default refreshToken;
