import { createContext } from 'react';

import type {
  AddKeyDownListenersCallback,
  RemoveKeyDownListenersCallback,
} from './types';

export type KeyboardContextType = {
  addKeyDownListeners: AddKeyDownListenersCallback;
  removeKeyDownListeners: RemoveKeyDownListenersCallback;
};

export const KeyboardContext = createContext<KeyboardContextType>({
  addKeyDownListeners: () => {},
  removeKeyDownListeners: () => {},
});
