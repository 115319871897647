import type { ApolloContext } from '../../types';
import { safeRead } from '../../util';
import {
  GetActiveInboundPhoneCallsQuery,
  GetActiveInboundPhoneCallsQueryVariables,
} from '../../graphql';
import { LOAD_CHAT_HISTORY } from '../../queries/gql/loadChatHistory.gql';
import { GET_ACTIVE_INBOUND_PHONE_CALLS } from '../../queries/gql/getActiveInboundPhoneCalls.gql';
import {
  getChatHistoryQueryVariables,
  LOAD_CHAT_HISTORY_NUMBER_OF_MESSAGES,
} from '../../queries/LoadChatHistory';
import { PhoneCallFragment } from '../../fragments/gql/phoneCall.gql';
import { GET_CURRENT_USER } from '../../queries/gql/getCurrentUser.gql';

export const convertTranscript = (
  transcript: PubNubTranscriptModel | null | undefined,
): PhoneCallFields$transcript | null => {
  if (!transcript || !transcript.id) {
    return null;
  }

  return {
    __typename: 'TranscriptQL',
    id: transcript.id,
    events:
      transcript.events?.map(event => ({
        __typename: 'TranscriptEventQL',
        id: event.id,
        type: event.type,
      })) ?? [],
    subject: transcript?.analysis?.subject ?? null,
    summary: transcript?.analysis?.summary ?? null,
    analysis: transcript?.analysis
      ? {
          __typename: 'TranscriptAnalysisQL',
          id: transcript.analysis.id,
          timestamp: transcript.analysis.timestamp,
          callType: transcript.analysis.call_type ?? null,
          subject: transcript.analysis.subject ?? null,
          summary: transcript.analysis.summary ?? null,
          nextSteps: transcript.analysis.next_steps ?? null,
          coachingTip: transcript.analysis.coaching_tip ?? null,
          coachingSample: transcript.analysis.coaching_sample ?? null,
          sections:
            transcript.analysis.sections?.map(section => ({
              __typename: 'TranscriptSectionQL',
              id: section.id,
              name: section.name,
              timestamp: section.timestamp,
            })) ?? null,
          scores:
            transcript.analysis.scores?.map(score => ({
              __typename: 'TranscriptScoreQL',
              id: score.id,
              name: score.name,
              rating: score.rating,
              description: score.description,
            })) ?? null,
        }
      : null,
  };
};

export const convertPhonecall = (
  call: PubNubInboundPhoneCallModel,
): PhoneCallFields => {
  return {
    __typename: 'PhoneCallQL',
    id: call.id,
    callDuration: call.call_duration || null,
    isActive: call.is_active,
    startTime: call.start_time,
    endtime: call.endtime,
    conversationId: call.conversation_id,
    transcript: convertTranscript(call.transcript),
    hasReturnCallsRouting: call.has_return_calls_routing || false,
    returnCallsRoutingOutcome: call.return_calls_routing_outcome || null,
    returnCallsRoutedTo: call.return_calls_routed_to || null,
    returnCallsRoutedUserId: call.return_calls_routed_user_id || null,
    participant: {
      __typename: 'ParticipantQL',
      id: call.participant.id,
      address: call.participant.address,
      displayName: call.participant.display_name,
    },
    redirectedFrom: call.redirected_from
      ? {
          __typename: 'ParticipantQL',
          id: call.redirected_from.id,
          address: call.redirected_from.address,
          displayName: call.participant.display_name,
        }
      : null,
    callRecording: call.call_recording
      ? {
          __typename: 'CallRecordingQL',
          id: call.call_recording.id,
          url: call.call_recording.url ?? null,
        }
      : null,
  };
};
/* eslint-disable react/destructuring-assignment */
export const PubNubInboundCallStatusUpdated = (
  obj: any,
  args: { call: PubNubInboundPhoneCallModel; conversation_id: string },
  { client }: ApolloContext,
) => {
  const conversationId = args.conversation_id;

  const currentUser = safeRead<getCurrentUser, getCurrentUserVariables>(
    client,
    { query: GET_CURRENT_USER },
  );

  const chatHistoryQueryVariables = getChatHistoryQueryVariables({
    conversationId,
    withScheduledMessages: true,
    numMessagesToLoad: LOAD_CHAT_HISTORY_NUMBER_OF_MESSAGES,
    includeSystemMessages:
      currentUser?.currentUser?.viewPreferences.systemMessageSettings
        ?.showSystemMessages,
  });

  const data = safeRead<loadChatHistory, loadChatHistoryVariables>(client, {
    query: LOAD_CHAT_HISTORY,
    variables: chatHistoryQueryVariables,
  });

  if (!data) {
    return null;
  }

  const phonecall = convertPhonecall(args.call);

  const activePhoneCallsWithoutMessages = [
    ...data?.activePhoneCallsWithoutMessages.filter(
      (call: loadChatHistory$activePhoneCallsWithoutMessages) =>
        call.id !== phonecall.id,
    ),
    phonecall,
  ];

  client.writeQuery<loadChatHistory, loadChatHistoryVariables>({
    query: LOAD_CHAT_HISTORY,
    variables: chatHistoryQueryVariables,
    data: {
      ...data,
      activePhoneCallsWithoutMessages,
    },
  });

  const { returnCallsRoutedUserId } = phonecall;
  if (
    returnCallsRoutedUserId &&
    returnCallsRoutedUserId === currentUser?.currentUser?.id
  ) {
    const inboundCallData = safeRead<
      GetActiveInboundPhoneCallsQuery,
      GetActiveInboundPhoneCallsQueryVariables
    >(client, {
      query: GET_ACTIVE_INBOUND_PHONE_CALLS,
      variables: { userId: returnCallsRoutedUserId },
    });

    const activeInboundPhoneCalls = [
      ...(inboundCallData?.activeInboundPhoneCalls ?? []).filter(
        call => call.id !== phonecall.id,
      ),
      phonecall,
    ] as GetActiveInboundPhoneCallsQuery['activeInboundPhoneCalls'];

    client.writeQuery<
      GetActiveInboundPhoneCallsQuery,
      GetActiveInboundPhoneCallsQueryVariables
    >({
      query: GET_ACTIVE_INBOUND_PHONE_CALLS,
      variables: { userId: returnCallsRoutedUserId },
      data: {
        ...inboundCallData,
        activeInboundPhoneCalls,
      },
    });
  }

  client.writeFragment({
    id: `PhoneCallQL:${phonecall.id}`,
    fragment: PhoneCallFragment,
    fragmentName: 'PhoneCallFields',
    data: phonecall,
  });

  return null;
};

/* eslint-enable */
