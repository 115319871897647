import { gql } from '@apollo/client';

import { MessageItemFragment } from '../../fragments/gql/messageItem.gql';
import { PhoneCallFragment } from '../../fragments/gql/phoneCall.gql';
import { ConversationSignatureStateFragment } from '../../fragments/gql/ConversationSignatureStateFragment.gql';
import { ScheduledMessageFragment } from '../../fragments/gql/ScheduledMessageFragment.gql';

export const LOAD_CHAT_HISTORY = gql`
  query loadChatHistory(
    $id: String!
    $first: Int
    $after: String
    $messageFormatsToExclude: [String!]
    $withScheduledMessages: Boolean = false
  ) {
    messages(
      queryFilter: {
        conversationId: { equal: $id }
        messageFormat: { notIn: $messageFormatsToExclude }
      }
      first: $first
      after: $after
      sort: created_on_desc
    ) @connection(key: "messages", filter: ["queryFilter", "sort"]) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...MessageItemFields
        }
      }
    }
    conversation(conversationId: $id) {
      ...ConversationSignatureStateFields
    }
    activePhoneCallsWithoutMessages(conversationId: $id) {
      ...PhoneCallFields
    }
    scheduledMessages(
      queryFilter: { conversationId: { equal: $id } }
      first: $first
      after: $after
      sort: created_on_desc
    )
      @connection(key: "scheduledMessages", filter: ["queryFilter", "sort"])
      @include(if: $withScheduledMessages) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ScheduledMessageFields
        }
      }
    }
  }
  ${MessageItemFragment}
  ${PhoneCallFragment}
  ${ConversationSignatureStateFragment}
  ${ScheduledMessageFragment}
`;
