import { gql } from '@apollo/client';
import { AutopilotFragment } from '../../fragments/gql/Autopilot.gql';

export const CREATE_AUTOPILOT = gql`
  mutation createAutopilot($input: CreateAutopilotInput!) {
    createAutopilot(input: $input) {
      autopilot {
        ...AutopilotFields
      }
    }
  }
  ${AutopilotFragment}
`;
