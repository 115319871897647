import { gql } from '@apollo/client';

export const UPDATE_REVIEW_JOB = gql`
  mutation updateReviewJob($input: UpdateReviewJobInput!) {
    updateReviewJob(input: $input) {
      reviewJob {
        id
        placeId
        fallbackChannelId
        messageBody
        messageImageUrl
        reviewSites {
          serviceName
          url
          ... on FacebookReviewSite {
            facebookPageId
          }
        }
        headerColor
        headerTextColor
        backgroundColor
        textColor
        buttonColor
        buttonTextColor
        skipRatingsScreen
      }
    }
  }
`;
