import type { Connection } from '../../types';

export const collapseEdges = <C extends Record<string, any>, N>(
  connection: Connection<C>,
): Array<N> => {
  const result = [];
  for (const edge of connection.edges) {
    if (edge && edge.node) {
      result.push(edge.node);
    }
  }
  return result;
};
