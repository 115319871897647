import { gql } from '@apollo/client';

export const DELETE_IDENTITY_PROMPT_ADDRESS = gql`
  mutation deleteIdentityPromptAddress(
    $input: DeleteIdentityPromptAddressInput!
  ) {
    deleteIdentityPromptAddress(input: $input) {
      identityPromptAddress {
        id
      }
    }
  }
`;
