import { gql } from '@apollo/client';

export const DELETE_STATUS_ITEM = gql`
  mutation deleteStatusItem($input: DeleteStatusItemInput) {
    deleteStatusItem(input: $input) {
      statusItem {
        id
        stageId
      }
    }
  }
`;
