import { gql } from '@apollo/client';

export const CREATE_IDENTITY_PROMPT_ADDRESS = gql`
  mutation createIdentityPromptAddress(
    $input: CreateIdentityPromptAddressInput!
  ) {
    createIdentityPromptAddress(input: $input) {
      identityPromptAddress {
        id
        createdOn
        address
        channelId
        createdBy {
          id
          displayName
        }
      }
    }
  }
`;
