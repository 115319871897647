import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const UPDATE_EDGES_VOICE_STEP = gql`
  mutation updateEdgesVoiceStep($input: UpdateEdgesVoiceStepInput!) {
    updateEdgesVoiceStep(input: $input) {
      voiceScript {
        ...VoiceScriptFields
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
