import { gql } from '@apollo/client';

export const VideoUploadLinkFragment = gql`
  fragment VideoUploadLinkFields on VideoUploadLinkQL {
    id
    createdOn
    status
    placeId
    externalId
    uploadUrl
    fileName
    fileSize
    note
    readyToStream
    duration
    thumbnailUrl
    previewUrl
    playbackHls
    hasBeenSent
    uploader {
      id
      displayName
    }
    participant {
      id
      displayName
      displayNameConfidence
      address
      source
    }
  }
`;
