import { gql } from '@apollo/client';
import { SkuEntitlementsFragment } from '../../fragments/gql/skuEntitlements.gql';

export const GET_SKU_ENTITLEMENTS = gql`
  query getSkuEntitlements {
    skuEntitlements {
      ...SkuEntitlementsFields
    }
  }
  ${SkuEntitlementsFragment}
`;
