import omit from 'lodash/omit';

export type ActiveInboxOptions = {
  unreadOnly: boolean;
};

const DEFAULT_ACTIVE_INBOX_OPTIONS: getActiveInbox$activeInbox$options = {
  __typename: 'ActiveInboxOptions',
  unreadOnly: false,
};

export const getActiveInboxOptionsGQL = (
  options:
    | ActiveInboxOptions
    | getActiveInbox$activeInbox$options = DEFAULT_ACTIVE_INBOX_OPTIONS,
): getActiveInbox$activeInbox$options => {
  return {
    ...DEFAULT_ACTIVE_INBOX_OPTIONS,
    ...options,
  };
};

export const getActiveInboxOptions = (
  options?: ActiveInboxOptions | getActiveInbox$activeInbox$options,
): ActiveInboxOptions =>
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Omit<getActiveInbox$activeInbox$options, "__... Remove this comment to see the full error message
  omit(getActiveInboxOptionsGQL(options), ['__typename']);
