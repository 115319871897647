import type { ApolloContext } from '../../types';
import { markMentionedConversationsAsRead } from '../../util';

export const PubNubLeaveConversations = async (
  obj: any,
  args: { conversation_ids: Array<string> },
  { client }: ApolloContext,
) => {
  const { conversation_ids: conversationIds } = args;
  markMentionedConversationsAsRead(client, conversationIds);
};
