import { gql } from '@apollo/client';
import { LabelFragment } from '../../fragments/gql/labelFragment.gql';

export const CREATE_LABEL = gql`
  mutation createLabel($input: CreateLabelInput!) {
    createLabel(input: $input) {
      label {
        ...LabelFields
      }
    }
  }
  ${LabelFragment}
`;
