import type { AxiosPromise } from 'axios';
import axios from 'axios';
import { Service } from '../Service';
import type {
  ApiBadgeResponse,
  ApiDeleteUserRequest,
  ApiDeleteUserResponse,
  ApiFetchUserRequest,
  ApiFetchUserResponse,
  ApiUpdateUserRequest,
  ApiUpdateUserResponse,
  ApiUserListResponse,
  ApiAddUserRequest,
  ApiAddUserResponse,
  ApiResendInviteRequest,
  ApiResendInviteResponse,
  ApiVerifyUserResponse,
  ApiVerifyUserRequest,
} from './types';

class UserService implements Service {
  static badgeCount(): AxiosPromise<ApiBadgeResponse> {
    const path = '/api/badge';
    return axios.get(path);
  }

  static fetchUsers(): AxiosPromise<ApiUserListResponse> {
    const path = '/api/users';
    return axios.get(path);
  }

  static fetchUser(
    params: ApiFetchUserRequest,
  ): AxiosPromise<ApiFetchUserResponse> {
    const { id } = params;
    const path = `api/users/${id}`;
    return axios.get(path);
  }

  static updateUser(
    params: ApiUpdateUserRequest,
  ): AxiosPromise<ApiUpdateUserResponse> {
    const { id, ...payload } = params;
    const path = `/api/users/${id}`;
    return axios.patch(path, payload);
  }

  static deleteUser(
    params: ApiDeleteUserRequest,
  ): AxiosPromise<ApiDeleteUserResponse> {
    const { id, reassignmentList } = params;
    const path = `/api/users/${id}`;
    return axios.delete(path, {
      data: {
        reassignment_list: reassignmentList,
      },

      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static addUser(params: ApiAddUserRequest): AxiosPromise<ApiAddUserResponse> {
    const path = `/api/users`;
    return axios.post(path, params);
  }

  static resendInvite(
    params: ApiResendInviteRequest,
  ): AxiosPromise<ApiResendInviteResponse> {
    const path = `/api/users/invite`;
    return axios.post(path, params);
  }

  static verifyUser(
    params: ApiVerifyUserRequest,
  ): AxiosPromise<ApiVerifyUserResponse> {
    const path = `/api/users/invite`;
    return axios.patch(path, params);
  }
}

export default UserService;
