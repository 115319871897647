import { gql } from '@apollo/client';
import { HostedSmsPortFragment } from '../../fragments/gql/HostedSmsPortFragment.gql';

export const SEND_LOA = gql`
  mutation sendLoa($input: SendLoaInput!) {
    sendLoa(input: $input) {
      hostedSmsPort {
        ...HostedSmsPortFields
      }
    }
  }
  ${HostedSmsPortFragment}
`;
