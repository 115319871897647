import { gql } from '@apollo/client';

export const GET_TEN_DLC_TRUST_PRODUCTS_BY_ACCOUNT = gql`
  query getTenDLCTrustProductsByAccount($accountId: String!) {
    trustProducts(accountId: $accountId) {
      id
      friendlyName
      productType
      status
      twilioSid
    }
  }
`;
