import { gql } from '@apollo/client';
import { AttachmentItemFragment } from '../../fragments/gql/attachment.gql';

export const GET_ATTACHMENT_BY_ID = gql`
  query getAttachmentById($id: String!) {
    attachment(id: $id) {
      ...AttachmentItemFields
    }
  }
  ${AttachmentItemFragment}
`;
