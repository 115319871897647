import { gql } from '@apollo/client';

export const GET_COMPLIANCE_REMINDER = gql`
  query getComplianceReminder(
    $isNewConversation: Boolean
    $conversationId: String
  ) {
    complianceReminder(
      isNewConversation: $isNewConversation
      conversationId: $conversationId
    ) {
      guidelinesReminder
    }
  }
`;
