import { gql } from '@apollo/client';

export const GET_SKUS_QUERY = gql`
  query skus {
    skus(queryFilter: { isActive: { equal: true } }) {
      edges {
        node {
          id
          isActive
          name
          skuType
        }
      }
    }
  }
`;
