import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const RESET_OUTBOUND_CHANNELS = gql`
  mutation resetOutboundChannels($input: ResetOutboundChannelsInput!) {
    resetOutboundChannels(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
