import { gql } from '@apollo/client';
import { AdminPlaceFragment } from '../../fragments/gql/AdminPlace.gql';

export const CHANGE_PLACE_GROUP = gql`
  mutation changePlaceGroup($input: ChangePlaceGroupInput!) {
    changePlaceGroup(input: $input) {
      place {
        ...AdminPlaceFields
      }
    }
  }
  ${AdminPlaceFragment}
`;
