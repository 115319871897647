import { gql } from '@apollo/client';
import { ScheduledMessageFragment } from '../../fragments/gql/ScheduledMessageFragment.gql';

export const CREATE_SCHEDULED_MESSAGE = gql`
  mutation createScheduledMessage($input: CreateScheduledMessageInput!) {
    createScheduledMessage(input: $input) {
      scheduledMessage {
        ...ScheduledMessageFields
      }
    }
  }
  ${ScheduledMessageFragment}
`;
