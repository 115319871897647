import { gql } from '@apollo/client';

export const ManageHangupsFragment = gql`
  fragment ManageHangupsFields on PhoneLinePolicyQL {
    id
    archiveMissedCalls
    autoSendHangupReplies
    hangupSuggestion
  }
`;
