import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const CREATE_AUTOMATION_FROM_BLUEPRINT = gql`
  mutation createAutomationFromBlueprint(
    $input: CreateAutomationFromBlueprintInput!
  ) {
    createAutomationFromBlueprint(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
  ${AutomationFragment}
`;
