import { gql } from '@apollo/client';
import { AutomationFragment } from '../../fragments/gql/Automation.gql';

export const GET_AUTOMATIONS = gql`
  query getAutomations($placeId: String!) {
    automations(placeId: $placeId) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...AutomationFields
        }
      }
    }
  }
  ${AutomationFragment}
`;
