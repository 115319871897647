import get from 'lodash/get';
import { AuthModule, UserModule } from '@numbox/modules';
import type {
  ApiUserAccountPermissions,
  ApiUserPlacePermissions,
} from '@numbox/services';

import type { ExtractReturn } from '@numbox/modules';
import {
  SET_FORGOT_PASSWORD_INITIAL_EMAIL,
  USER_SETUP_IN_PROGRESS,
  USER_SETUP_COMPLETE,
  USER_SETUP_FAILED,
  PERMISSIONS,
  hasAccountPermission,
} from '../modules/auth';

export const AUTH_INITIAL_STATE = {
  accessToken: null,
  refreshToken: null,
  accountPermissions: {},
  placePermissions: {},
  isDemoAdmin: false,
  isDemoUser: false,
  isNumberAIEmployee: false,
  isUserInMobileWebview: false,
  isAuth0: false,
  userSetupInProgress: false,
  authenticated: false,
  error: null,
  accountId: null,
  teamId: null,
  userEmail: null,
  userId: null,
  accountName: null,
  defaultTeamId: null,
  forgotPasswordError: null,
  forgotPasswordInitialEmail: null,
};

export type AuthReducerState = {
  accountPermissions: ApiUserAccountPermissions;
  placePermissions: ApiUserPlacePermissions;
  accessToken: string | null | undefined;
  refreshToken: string | null | undefined;
  isDemoAdmin: boolean;
  isDemoUser: boolean;
  isNumberAIEmployee: boolean;
  isUserInMobileWebview: boolean;
  isAuth0: boolean;
  userSetupInProgress: boolean;
  authenticated: boolean;
  error: any;
  accountId: string | null | undefined;
  teamId: string | null | undefined;
  userEmail: string | null | undefined;
  userId: string | null | undefined;
  accountName: string | null | undefined;
  defaultTeamId: string | null | undefined;
  forgotPasswordError: any | null | undefined;
  forgotPasswordInitialEmail: string | null | undefined;
};

export type AuthActions =
  | ExtractReturn<typeof AuthModule.auth.success>
  | ExtractReturn<typeof AuthModule.auth.error>
  | ExtractReturn<typeof AuthModule.updatePassword.success>
  | ExtractReturn<typeof UserModule.verifyUser.success>;

export const authSuccessReducer = (
  state: AuthReducerState,
  action: AuthActions,
) => {
  const { auth, account, teams, user } = action.payload;
  const { access_token: accessToken, refresh_token: refreshToken } = auth;
  const tokenType = get(action, 'meta.tokenType');

  const isAuth0 = action.type === 'STORE_AUTH0_TOKEN';

  const accountId = account.id;
  const accountName = account.name;
  // The or check here allows a brand provided in query string before auth
  // to override the returned one post auth
  const teamId = teams[0].id; // Legacy Team Id
  const defaultTeamId = teams[0].id;
  const userId = user.id;
  const userEmail = user.email;
  return {
    ...state,
    accessToken,
    refreshToken,
    accountPermissions: user.account_permissions,
    placePermissions: user.place_permissions,
    isDemoAdmin: hasAccountPermission(
      user.account_permissions,
      PERMISSIONS.ACCOUNT.DEMO_ADMIN,
    ),

    isDemoUser: hasAccountPermission(
      user.account_permissions,
      PERMISSIONS.ACCOUNT.DEMO_USER,
    ),

    isNumberAIEmployee: user.is_numberai_employee,
    authenticated: true,
    error: null,
    accountId,
    accountName,
    teamId,
    defaultTeamId,
    userId,
    userEmail,
    isUserInMobileWebview: tokenType === 'MOBILE_WEBVIEW',
    isAuth0,
  };
};

export default function AuthReducer(
  state: AuthReducerState = AUTH_INITIAL_STATE,
  action: AuthActions,
) {
  switch (action.type) {
    case 'UPDATE_PASSWORD.SUCCESS':
    case 'REFRESH_TOKEN.SUCCESS':
    case 'VERIFY_USER.SUCCESS':
    case 'STORE_AUTH0_TOKEN':
    case 'REFRESH_AUTH0_TOKEN':
    case 'AUTH_USER.SUCCESS': {
      return authSuccessReducer(state, action);
    }
    case 'AUTH_USER.FAILURE':
      return {
        ...state,
        error: action.payload.response.data.msg,
      };

    case 'EXCHANGE_TOKEN.FAILURE': {
      return {
        ...state,
        error: 'Sorry, we need to send you another sign in email.',
      };
    }
    case 'SIGNUP.FAILURE': {
      return {
        ...state,
        error: 'Sign in to finish setting up your new account!',
      };
    }
    case 'UPDATE_PASSWORD.FAILURE':
    case 'REQUEST_PASSWORD_RESET.FAILURE':
      return {
        ...state,
        forgotPasswordError: action.payload.message,
      };

    case USER_SETUP_IN_PROGRESS:
      return {
        ...state,
        userSetupInProgress: true,
      };

    case USER_SETUP_COMPLETE:
    case USER_SETUP_FAILED:
      return {
        ...state,
        userSetupInProgress: false,
      };

    case SET_FORGOT_PASSWORD_INITIAL_EMAIL:
      return {
        ...state,
        forgotPasswordInitialEmail: action.payload.email,
      };

    case 'APOLLO_TOKEN_REFRESH': {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    }
    case 'STORE_TOKENS': {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    }
    default:
      return state;
  }
}
