import { gql } from '@apollo/client';

export const GET_CHARGEBEE_SUBSCRIPTIONS = gql`
  query getChargeBeeSubscriptions {
    chargebeeSubscriptions {
      trialDaysLeft
      dueSince
      billingStatus
      cardInfo {
        type
        last4
        cardStatus
      }
    }
  }
`;
