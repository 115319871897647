/* eslint-disable no-param-reassign */
import type { ApolloContext } from '../../types';
import { writeConversationFragment } from '../../util';
import { convertPubNubConversationToConversationItemFields } from './PubNubMessageReceived';

export const PubNubConversationLanguageReset = async (
  obj: any,
  args: { conversation: PubNubConversationModel },
  { client }: ApolloContext,
) => {
  const apolloConversation = convertPubNubConversationToConversationItemFields(
    args.conversation,
  );

  writeConversationFragment({
    client,
    conversation: apolloConversation,
    withLastAppointment: true,
    withLastChannel: false,
    withLastRepairOrder: true,
  });
};
