import { gql } from '@apollo/client';

export const CHANGE_INBOX_TYPE = gql`
  mutation changeInboxType($input: ChangeInboxTypeInput!) {
    changeInboxType(input: $input) {
      place {
        id
        name
        inboxType
      }
    }
  }
`;
