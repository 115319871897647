import { gql } from '@apollo/client';

export const SET_ONGOING_OPT_IN_RESET = gql`
  mutation SetOngoingOptInReset($newOngoingResetOptinDays: Int!) {
    setResetOngoingOptin(
      input: { resetOngoingOptin: $newOngoingResetOptinDays }
    ) {
      currentAccount {
        resetOngoingOptin
      }
    }
  }
`;
