import { gql } from '@apollo/client';

export const AdminPlaceFragment = gql`
  fragment AdminPlaceFields on PlaceQL {
    __typename
    id
    name
    newConversationsPerDay
    inboxType
    status
    hasDegradedInboxExperience
    allowCustomAudio
    account {
      id
      name
    }
    placeGroup {
      id
      name
    }
  }
`;
