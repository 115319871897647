import { gql } from '@apollo/client';

export const GET_IDENTITY_PROMPT_ADDRESSES = gql`
  query getIdentityPromptAddresses($channelId: String!) {
    identityPromptAddresses(channelId: $channelId) {
      id
      createdOn
      address
      channelId
      createdBy {
        id
        displayName
      }
    }
  }
`;
