export type Auth0LoginFunction = (options: {
  onSuccess?: (
    accessToken: string,
    refreshToken: string | undefined,
    userData: any,
  ) => void;
  onFailure?: () => void;
  storeToken?: boolean;
  cacheMode?: 'on' | 'off' | 'cache-only';
  authorizationParams?: {
    redirect_uri?: string;
  };
}) => void;

export type Auth0LogoutFunction = (options?: { redirectTo?: string }) => void;

export type StoreAuth0LoginAction = {
  type: 'SET_AUTH0_AUTH_FUNCTIONS';
  payload: {
    login: Auth0LoginFunction;
    logout: Auth0LogoutFunction;
  };
};

export const storeAuth0Login = ({
  login,
  logout,
}: {
  login: Auth0LoginFunction;
  logout: Auth0LogoutFunction;
}): StoreAuth0LoginAction => ({
  type: 'SET_AUTH0_AUTH_FUNCTIONS',
  payload: {
    login,
    logout,
  },
});
