import { gql } from '@apollo/client';

import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';

export const ASSIGN_CONVERSATIONS = gql`
  mutation AssignConversations(
    $input: AssignConversationsInput!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    assignConversations(input: $input) {
      conversations {
        ...ConversationItemFields
      }
    }
  }
  ${ConversationItemFragment}
`;
