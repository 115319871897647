import { gql } from '@apollo/client';
import { StatusItemStub } from '../../fragments/gql/StatusItem.gql';

export const GET_STATUS_ITEMS = gql`
  query statusItems($assigneeId: String!, $first: Int!) {
    statusItems(
      queryFilter: {
        status: { notEqual: "DONE" }
        assigneeId: { equal: $assigneeId }
      }
      sort: last_update_time_desc
      first: $first
    ) @connection(key: "statusItems", filter: ["assigneeId"]) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...StatusItemStub
        }
      }
    }
  }
  ${StatusItemStub}
`;
