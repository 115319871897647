import cloneDeep from 'lodash/cloneDeep';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as SpeechApi from '../api/speech';

// Actions
export const RESET_SPEECH = 'numbox-web/speech/RESET_SPEECH';
export const TEXT_TO_SPEECH = 'numbox-web/speech/TEXT_TO_SPEECH';
export const TEXT_TO_SPEECH_SUCCESS =
  'numbox-web/speech/TEXT_TO_SPEECH_SUCCESS';
export const TEXT_TO_SPEECH_FAILURE =
  'numbox-web/speech/TEXT_TO_SPEECH_FAILURE';

// Action Creators
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'teamId' implicitly has an 'any' type.
export const textToSpeech = (teamId, text) => ({
  type: TEXT_TO_SPEECH,
  payload: { teamId, text },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const textToSpeechSuccess = data => ({
  type: TEXT_TO_SPEECH_SUCCESS,
  payload: data,
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
export const textToSpeechFailure = error => ({
  type: TEXT_TO_SPEECH_FAILURE,
  payload: error,
  error: true,
});

// Sagas
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export function* doTextToSpeech(action) {
  try {
    const { teamId, text } = action.payload;
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(SpeechApi.textToSpeech, teamId, text);
    yield put(textToSpeechSuccess(response.data));
  } catch (error: any) {
    yield put(textToSpeechFailure(error.message));
  }
}

export function* watchTextToSpeech() {
  yield takeEvery(TEXT_TO_SPEECH, doTextToSpeech);
}

// Reducer
export const TEXT_TO_SPEECH_REDUCER_INITIAL_STATE = {
  previous: {},
  latestUrl: null,
  isFetching: false,
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
const fromSpeechApi = data => ({
  text: data.text,
  url: data.url,
  isFetching: false,
});

const SpeechReducer = (
  state = TEXT_TO_SPEECH_REDUCER_INITIAL_STATE,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  action,
) => {
  switch (action.type) {
    case RESET_SPEECH: {
      return TEXT_TO_SPEECH_REDUCER_INITIAL_STATE;
    }
    case TEXT_TO_SPEECH: {
      const newState = cloneDeep(state);
      newState.isFetching = true;
      return newState;
    }
    case TEXT_TO_SPEECH_SUCCESS: {
      const { text, url } = fromSpeechApi(action.payload);
      return {
        previous: {
          ...state.previous,
          [text]: url,
        },

        latestUrl: url,
        isFetching: false,
      };
    }
    case TEXT_TO_SPEECH_FAILURE: {
      const newState = cloneDeep(state);
      newState.isFetching = false;
      return newState;
    }
    default:
      return state;
  }
};

export default SpeechReducer;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getCachedSpeechUrl = (state, string) =>
  state.speech.previous[string];

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const getLatestSpeechPreviewUrl = state => state.speech.latestUrl;
