import { Components, Theme } from '@mui/material';

export const MuiListItemText: Components<Theme>['MuiListItemText'] = {
  defaultProps: {},
  styleOverrides: {
    root: {},
    primary: ({ theme }) => ({
      fontSize: theme.typography.body1.fontSize,
    }),
    secondary: ({ theme }) => ({
      fontSize: theme.typography.body2.fontSize,
    }),
  },
};
