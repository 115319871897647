import { pureWithStyle } from '~/styles';
import { DelayedLoadingIndicator } from '../../DelayedLoadingIndicator';

type Props = {
  classes: Readonly<Record<string, string>>;
};

/**
 * A spinner that appears in the center of its container.
 */
// @ts-expect-error ts-migrate(2709) FIXME: Cannot use namespace '_' as a type.
export default pureWithStyle<Props, _>(
  'CenteredSpinner',
  {
    root: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  ({ classes }: Props) => {
    return (
      <div className={classes.root}>
        <DelayedLoadingIndicator />
      </div>
    );
  },
);
