import { gql } from '@apollo/client';

export const GET_TEN_DLC_STATUS_BY_ACCOUNT = gql`
  query getTenDLCStatusByAccount($accountId: String!) {
    tenDlcStatus(accountId: $accountId) {
      a2pStatus
      businessVerificationStatus
    }
  }
`;
