import { ApolloLink, Operation, NextLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import type { Store } from 'redux';

import get from 'lodash/get';

import { ACCESS_TOKEN_KEY } from './constants';

type AuthLinkConfig = {
  authTokenPath: string;
  store: Store<any, any>;
  storage?: any;
};

export class AuthLink extends ApolloLink {
  request = (operation: Operation, forward?: NextLink) => {
    return this.link.request(operation, forward);
  };

  injectConfig = (config: AuthLinkConfig): void => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'config' does not exist on type 'AuthLink... Remove this comment to see the full error message
    this.config = config;
  };

  authHeaders = (
    headers: Record<string, any>,
    accessToken: string,
  ): Record<string, any> => ({
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  });

  link = setContext((operation, context) => {
    const { headers } = context;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'config' does not exist on type 'AuthLink... Remove this comment to see the full error message
    const { authTokenPath, store, storage } = this.config;
    const accessToken = get(store.getState(), authTokenPath);

    if (accessToken) {
      return this.authHeaders(headers, accessToken);
    }

    if (!storage) {
      return {};
    }

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'token' implicitly has an 'any' type.
    return storage.getItem(ACCESS_TOKEN_KEY).then(token => {
      if (token) {
        return this.authHeaders(headers, token);
      }
      return {};
    });
  });
}
