import { put, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import { OBJECTS, ACTIONS } from '@numbox/react';
import { trackEvent } from './analytics';

export type TrackSuggestedReplyAction = {
  type: 'TRACK_SUGGESTED_REPLY';
  payload: {
    suggestionId: string;
    conversationId: string;
  };
};

export function trackSuggestedReply(
  suggestionId: string,
  conversationId: string,
): TrackSuggestedReplyAction {
  return {
    type: 'TRACK_SUGGESTED_REPLY',
    payload: {
      suggestionId,
      conversationId,
    },
  };
}

export function* onSuggestedReplyUse(
  action: TrackSuggestedReplyAction,
): Generator<any, void, void> {
  const { suggestionId, conversationId } = action.payload;
  yield put(
    trackEvent(OBJECTS.SUGGESTION, ACTIONS.SELECTED, {
      suggestionId,
      conversationId,
    }),
  );
}

export function* watchSuggestedReply(): Saga<void> {
  yield takeEvery('TRACK_SUGGESTED_REPLY', onSuggestedReplyUse);
}
