import { gql } from '@apollo/client';
import { MessageItemFragment } from '../../fragments/gql/messageItem.gql';

export const SEND_MESSAGE = gql`
  mutation sendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      message {
        ...MessageItemFields
      }
    }
  }
  ${MessageItemFragment}
`;
