import { gql } from '@apollo/client';

export const NOTE_TAKER_FRAGMENT = gql`
  fragment NoteTakerFields on NoteTakerQL {
    id
    name
    settings
    placeId
  }
`;
