import { gql } from '@apollo/client';

export const PUBNUB_OUTBOUND_PROXY_CALL_STATUS_UPDATED = gql`
  mutation PubNubOutboundProxyCallStatusUpdated(
    $id: String!
    $call_status: String!
    $start_time: String
    $is_being_recorded: Boolean!
  ) {
    PubNubOutboundProxyCallStatusUpdated(
      id: $id
      call_status: $call_status
      start_time: $start_time
      is_being_recorded: $is_being_recorded
    ) @client
  }
`;
