import { updateActiveInbox, writeConversationFragment } from '../../util';
import type { ApolloContext } from '../../types';
import { convertPubNubConversationToConversationItemFields } from './PubNubMessageReceived';

export const PubNubAssignConversation = (
  obj: any,
  args: { conversation: PubNubConversationModel },
  { client }: ApolloContext,
) => {
  const { conversation } = args;

  const apolloConversation =
    convertPubNubConversationToConversationItemFields(conversation);

  writeConversationFragment({
    client,
    conversation: apolloConversation,
    withLastAppointment: true,
    withLastChannel: false,
    withLastRepairOrder: true,
  });

  updateActiveInbox(client, [
    {
      ...apolloConversation,
      assigneeId: apolloConversation.assignee && apolloConversation.assignee.id,
    },
  ]);
};
