import { gql } from '@apollo/client';

export const CHANGE_BUSINESS_VERTICAL = gql`
  mutation changeBusinessVertical($input: ChangeBusinessVerticalInput!) {
    changeBusinessVertical(input: $input) {
      account {
        id
        name
        businessVertical
      }
    }
  }
`;
