import { gql } from '@apollo/client';

import { ConversationItemStub } from '../../fragments/gql/conversationItem.gql';

export const SNOOZE_CONVERSATION = gql`
  mutation snoozeConversation($input: SnoozeConversationInput!) {
    snoozeConversation(input: $input) {
      conversation {
        ...ConversationItemStub
      }
    }
  }
  ${ConversationItemStub}
`;
