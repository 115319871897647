import { QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import * as React from 'react';
import get from 'lodash/get';
import takeWhile from 'lodash/takeWhile';

import { LOAD_CHAT_HISTORY } from './gql/loadChatHistory.gql';

type MessageFn = (arg0: {
  conversationSignatureState: loadChatHistory$conversation | null | undefined;
  queryResult: QueryResult<loadChatHistory, loadChatHistoryVariables>;
  messages: Array<loadChatHistory$messages$edges$node>;
  suggestions: Array<loadChatHistory$messages$edges$node>;
  activePhoneCallsWithoutMessages: Array<loadChatHistory$activePhoneCallsWithoutMessages>;
  pageInfo: loadChatHistory$messages$pageInfo | null | undefined;
  scheduledMessages: Array<loadChatHistory$scheduledMessages$edges$node>;
}) => React.ReactElement | null;

type OwnProps = {
  conversationId: string;
  withScheduledMessages?: boolean;
  includeSystemMessages?: boolean | null;
  children: MessageFn;
};

type Props = OwnProps;

export const LOAD_CHAT_HISTORY_NUMBER_OF_MESSAGES = 18;

// N.B. Keep a constant reference so that the same object can be used in the
// GraphQL filter (and avoid unnecessary cache misses).
const MESSAGE_FORMAT_EXCLUDING_SYSTEM_MESSAGES = ['system'];

export const getChatHistoryQueryVariables = ({
  conversationId,
  withScheduledMessages,
  numMessagesToLoad,
  includeSystemMessages,
}: {
  conversationId: string;
  withScheduledMessages?: boolean | null;
  numMessagesToLoad?: number | null;
  includeSystemMessages?: boolean | null;
}): loadChatHistoryVariables => {
  const variables: loadChatHistoryVariables = {
    id: conversationId,
    withScheduledMessages,
  };

  if (includeSystemMessages === false) {
    variables.messageFormatsToExclude =
      MESSAGE_FORMAT_EXCLUDING_SYSTEM_MESSAGES;
  }

  // NOTE: Sets null as it could be intentional by the caller.
  if (typeof withScheduledMessages !== 'undefined') {
    variables.withScheduledMessages = withScheduledMessages;
  }

  // NOTE: Sets null as it could be intentional by the caller.
  if (typeof numMessagesToLoad !== 'undefined') {
    variables.first = numMessagesToLoad;
  }

  return variables;
};

const LoadChatHistory = ({
  conversationId,
  withScheduledMessages = false,
  includeSystemMessages = true,
  children,
}: Props) => {
  const queryVariables = getChatHistoryQueryVariables({
    conversationId,
    withScheduledMessages,
    numMessagesToLoad: LOAD_CHAT_HISTORY_NUMBER_OF_MESSAGES,
    includeSystemMessages,
  });

  return (
    <Query<loadChatHistory, loadChatHistoryVariables>
      query={LOAD_CHAT_HISTORY}
      variables={queryVariables}
      fetchPolicy="cache-and-network"
    >
      {queryResult => {
        const { data } = queryResult;
        const allMessages = get(data, 'messages.edges', []).map(
          (messageEdge: loadChatHistory$messages$edges) => messageEdge.node,
        );
        const pageInfo = get(data, 'messages.pageInfo', null);
        const suggestions = takeWhile(
          allMessages,
          (message: loadChatHistory$messages$edges$node) =>
            message?.isSuggestion,
        );
        const messages = allMessages.filter(
          (message: loadChatHistory$messages$edges$node) =>
            message && !message.isSuggestion,
        );
        const conversationSignatureState = get(data, 'conversation');
        const activePhoneCallsWithoutMessages = get(
          data,
          'activePhoneCallsWithoutMessages',
          [],
        );
        const scheduledMessages = get(data, 'scheduledMessages.edges', []).map(
          (edge: loadChatHistory$scheduledMessages$edges) => edge.node,
        );

        return children({
          conversationSignatureState,
          queryResult,
          messages,
          suggestions,
          activePhoneCallsWithoutMessages,
          pageInfo,
          scheduledMessages,
        });
      }}
    </Query>
  );
};

export { LoadChatHistory };
