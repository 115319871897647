import type { ApiPlace, ApiPlacePermissions, ApiTeam } from '@numbox/services';
import '@numbox/services';

type Account = {
  id: string;
  name: string;
};

export type SelectAccountAction = {
  type: 'SELECT_ACCOUNT';
  payload?: Account;
};

export function selectAccount(account?: {
  id: string;
  name: string;
}): SelectAccountAction {
  return {
    type: 'SELECT_ACCOUNT',
    payload: account,
  };
}

export function adminAccountSelected(payload: { accountId: string }) {
  return {
    type: 'SELECT_ACCOUNT_ADMIN' as const,
    payload,
  };
}

export type AdminAccountSelectedAction = ReturnType<
  typeof adminAccountSelected
>;

export type SelectPlaceAction = {
  type: 'SELECT_PLACE';
  payload: string;
};

export function selectPlace(placeId: string): SelectPlaceAction {
  return {
    type: 'SELECT_PLACE',
    payload: placeId,
  };
}

export function adminPlaceSelected(payload: { placeId: string }) {
  return {
    type: 'SELECT_PLACE_ADMIN' as const,
    payload,
  };
}

export type AdminPlaceSelectedAction = ReturnType<typeof adminPlaceSelected>;

export type UpdatePlaceNameAction = {
  type: 'UPDATE_PLACE_NAME';
  payload: {
    placeId: string;
    name: string;
  };
};

export function updatePlaceName(
  placeId: string,
  name: string,
): UpdatePlaceNameAction {
  return {
    type: 'UPDATE_PLACE_NAME',
    payload: {
      placeId,
      name,
    },
  };
}

export type TeamTogglePayload = {
  placeId: string;
  teamId: string;
};

export type PlaceTogglePayload = {
  userId: string;
  placeIds: Array<string>;
  placePermissions: Record<string, Array<ApiPlacePermissions>>;
};

export type TeamCreatedPayload = {
  placeId: string;
  team: ApiTeam;
};

export type UserAddedToTeamAction = {
  type: 'USER_ADDED_TO_TEAM';
  payload: TeamTogglePayload;
};

export type UserRemovedFromTeamAction = {
  type: 'USER_REMOVED_FROM_TEAM';
  payload: TeamTogglePayload;
};

export type TeamCreatedAction = {
  type: 'TEAM_CREATED';
  payload: TeamCreatedPayload;
};

export function UserAddedToTeam(
  placeId: string,
  teamId: string,
): UserAddedToTeamAction {
  return { type: 'USER_ADDED_TO_TEAM', payload: { placeId, teamId } };
}

export function UserRemovedFromTeam(
  placeId: string,
  teamId: string,
): UserRemovedFromTeamAction {
  return { type: 'USER_REMOVED_FROM_TEAM', payload: { placeId, teamId } };
}

export type UserRemovedFromPlaceAction = {
  type: 'USER_REMOVED_FROM_PLACE';
  payload: PlaceTogglePayload;
};

export function UserRemovedFromPlace(
  userId: string,
  placeIds: Array<string>,
  placePermissions: Record<string, Array<ApiPlacePermissions>>,
): UserRemovedFromPlaceAction {
  return {
    type: 'USER_REMOVED_FROM_PLACE',
    payload: { userId, placeIds, placePermissions },
  };
}

export function TeamCreated(placeId: string, team: ApiTeam): TeamCreatedAction {
  return {
    type: 'TEAM_CREATED',
    payload: { placeId, team },
  };
}

export type SetPlacesAction = {
  type: 'SET_PLACES';
  payload: { teams: ApiPlace[] };
};

export function setPlaces(teams: ApiPlace[]): SetPlacesAction {
  return {
    type: 'SET_PLACES',
    payload: { teams },
  };
}
