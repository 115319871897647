import { gql } from '@apollo/client';

export const SubscriptionFieldsFragment = gql`
  fragment SubscriptionFields on SubscriptionQL {
    id
    sku {
      id
      name
    }
    quantity
  }
`;
