import { gql } from '@apollo/client';
import { MessageItemFragment } from '../../fragments/gql/messageItem.gql';
import { ScheduledMessageFragment } from '../../fragments/gql/ScheduledMessageFragment.gql';

export const SEND_SCHEDULED_MESSAGE_NOW = gql`
  mutation sendScheduledMessageNow($input: SendScheduledMessageNowInput!) {
    sendScheduledMessageNow(input: $input) {
      message {
        ...MessageItemFields
      }
      scheduledMessage {
        ...ScheduledMessageFields
      }
    }
  }
  ${MessageItemFragment}
  ${ScheduledMessageFragment}
`;
