import { gql } from '@apollo/client';

export const SkuEntitlementsFragment = gql`
  fragment SkuEntitlementsFields on SkuEntitlementsQL {
    assignments
    translation
    escalations
    assignmentRouting
    awayMode
  }
`;
