import { gql } from '@apollo/client';

export const REMOVE_PLACE_GROUP = gql`
  mutation removePlaceGroup($input: RemovePlaceGroupInput!) {
    removePlaceGroup(input: $input) {
      account {
        id
      }
    }
  }
`;
