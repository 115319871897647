import { gql } from '@apollo/client';

export const CHANGE_ACCOUNT_SUBSCRIPTION = gql`
  mutation changeAccountSubscription($input: ChangeAccountSubscriptionInput!) {
    changeAccountSubscription(input: $input) {
      account {
        billingIdentity {
          id
          subscriptions {
            id
            available
            sku {
              id
              skuType
              isActive
              name
            }
            quantity
          }
        }
      }
    }
  }
`;
