import * as React from 'react';

import { connect } from 'react-redux';
import type { Location } from 'history';
import { Redirect } from 'react-router-dom';
import {
  getIsAuthenticated,
  getIsDemoAdmin,
  getIsDemoUser,
} from '../modules/auth';

import type { WebReducerState } from '../reducers/types';

type Props = {
  authenticated: boolean;
  isDemoSession: boolean;
  location: Location;
};

export default (WrappedComponent: React.ComponentType<any>) => {
  const Auth = (props: Props) => {
    const { authenticated, location, isDemoSession } = props;
    const [pathname, setPathname] = React.useState('/login');
    React.useEffect(() => {
      if (authenticated) {
        if (isDemoSession) {
          setPathname('/demo/login');
        } else {
          setPathname('/login');
        }
      }
    }, [authenticated, isDemoSession]);

    return !authenticated ? (
      <Redirect to={{ pathname, state: { from: location } }} />
    ) : (
      <WrappedComponent {...props} />
    );
  };

  function mapStateToProps(state: WebReducerState) {
    return {
      authenticated: getIsAuthenticated(state),
      isDemoSession: getIsDemoUser(state) || getIsDemoAdmin(state),
    };
  }

  return connect(mapStateToProps)(Auth);
};
