import { gql } from '@apollo/client';

import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';

export const REMOVE_CONVERSATION_LABEL = gql`
  mutation RemoveLabelFromConversation(
    $input: RemoveLabelFromConversationInput!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    removeLabelFromConversation(input: $input) {
      conversation {
        ...ConversationItemFields
      }
    }
  }
  ${ConversationItemFragment}
`;
