import { gql } from '@apollo/client';
import { BrandThemeFragment } from '../../fragments/gql/BrandTheme.gql';

export const UPDATE_BRAND_THEME = gql`
  mutation updateBrandTheme($input: UpdateBrandThemeInput!) {
    updateBrandTheme(input: $input) {
      brandTheme {
        ...BrandThemeFields
      }
    }
  }
  ${BrandThemeFragment}
`;
