import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import type {
  ApiCreatePhoneNumberRequest,
  ApiCreatePhoneNumberResponse,
  ApiFetchAvailablePhoneNumbersRequest,
  ApiFetchAvailablePhoneNumbersResponse,
} from '@numbox/services';
import { PhoneNumberService } from '@numbox/services';

import type { Meta, ExtractReturn, ApiActionCreators } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const createPhoneNumber: ApiActionCreators<
  'CREATE_PHONE_NUMBER.REQUEST',
  ApiCreatePhoneNumberRequest,
  'CREATE_PHONE_NUMBER.SUCCESS',
  ApiCreatePhoneNumberResponse,
  Meta
> = {
  request: (params: ApiCreatePhoneNumberRequest, meta) => ({
    type: 'CREATE_PHONE_NUMBER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'CREATE_PHONE_NUMBER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'CREATE_PHONE_NUMBER.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onCreatePhoneNumberRequest(
  action: ExtractReturn<typeof createPhoneNumber.request>,
): Generator<any, void, void> {
  yield serviceCall(
    PhoneNumberService.createPhoneNumber,
    createPhoneNumber,
    action.payload,
    action.meta,
  );
}

export const fetchAvailablePhoneNumbers: ApiActionCreators<
  'FETCH_AVAILABLE_PHONE_NUMBERS.REQUEST',
  ApiFetchAvailablePhoneNumbersRequest,
  'FETCH_AVAILABLE_PHONE_NUMBERS.SUCCESS',
  ApiFetchAvailablePhoneNumbersResponse,
  Meta
> = {
  request: (params: ApiFetchAvailablePhoneNumbersRequest, meta) => ({
    type: 'FETCH_AVAILABLE_PHONE_NUMBERS.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'FETCH_AVAILABLE_PHONE_NUMBERS.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'FETCH_AVAILABLE_PHONE_NUMBERS.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onFetchAvailablePhoneNumbersRequest(
  action: ExtractReturn<typeof fetchAvailablePhoneNumbers.request>,
): Generator<any, void, void> {
  yield serviceCall(
    PhoneNumberService.fetchAvailablePhoneNumbers,
    fetchAvailablePhoneNumbers,
    action.payload,
    action.meta,
  );
}
export function* PhoneNumberSagas(): Saga<any> {
  yield all([
    takeEvery(
      'FETCH_AVAILABLE_PHONE_NUMBERS.REQUEST',
      onFetchAvailablePhoneNumbersRequest,
    ),

    takeEvery('CREATE_PHONE_NUMBER.REQUEST', onCreatePhoneNumberRequest),
  ]);
}
