import type { ApolloContext } from '../../types';
import { safeRead } from '../../util';
import { GET_PARTICIPANT_VIDEO_UPLOAD_LINKS } from '../../queries/gql/getParticipantVideoUploadLinks.gql';

const convertVideoUploadLink = (
  videoUploadLink: PubNubVideoUploadLinkModel,
): VideoUploadLinkFields => {
  return {
    __typename: 'VideoUploadLinkQL',
    id: videoUploadLink.id,
    createdOn: videoUploadLink.created_on,
    status: videoUploadLink.status,
    placeId: videoUploadLink.place_id,
    uploadUrl: videoUploadLink.upload_url,
    externalId: videoUploadLink.external_id,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    fileName: videoUploadLink.file_name,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
    fileSize: videoUploadLink.file_size,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    note: videoUploadLink.note,
    readyToStream: videoUploadLink.ready_to_stream,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
    duration: videoUploadLink.duration,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    thumbnailUrl: videoUploadLink.thumbnail_url,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    previewUrl: videoUploadLink.preview_url,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    playbackHls: videoUploadLink.playback_hls,
    hasBeenSent: videoUploadLink.has_been_sent,
    uploader: videoUploadLink.uploader
      ? {
          __typename: 'ParticipantQL',
          id: videoUploadLink.uploader.id,
          displayName: videoUploadLink.uploader.display_name,
        }
      : null,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "ParticipantQL"; id: string; d... Remove this comment to see the full error message
    participant: videoUploadLink.participant
      ? {
          __typename: 'ParticipantQL',
          id: videoUploadLink.participant.id,
          displayName: videoUploadLink.participant.display_name,
          displayNameConfidence:
            videoUploadLink.participant.display_name_confidence,
          address: videoUploadLink.participant.address,
          source: videoUploadLink.participant.source,
        }
      : null,
  };
};

/* eslint-disable react/destructuring-assignment */

export const PubNubVideoUploadLinkUpdated = (
  obj: any,
  args: { video_upload_link: PubNubVideoUploadLinkModel },
  { client }: ApolloContext,
) => {
  const newVideoUploadLink = convertVideoUploadLink(args.video_upload_link);

  const participantId = newVideoUploadLink.participant?.id;
  if (!participantId) {
    return null;
  }

  const data = safeRead(client, {
    query: GET_PARTICIPANT_VIDEO_UPLOAD_LINKS,
    variables: { participantId },
  });

  const existingVideoUploadLinks = data?.participantVideoUploadLinks ?? [];

  client.writeQuery({
    query: GET_PARTICIPANT_VIDEO_UPLOAD_LINKS,
    variables: { participantId },
    data: {
      participantVideoUploadLinks: [
        newVideoUploadLink,
        ...existingVideoUploadLinks.filter(
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
          v => v.id !== newVideoUploadLink.id,
        ),
      ],
    },
  });

  return null;
};

/* eslint-enable react/destructuring-assignment */
