import axios from 'axios';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"axios"' has no exported member '$AxiosXHR... Remove this comment to see the full error message
import type { AxiosPromise, $AxiosXHR } from 'axios';
import { Service } from '../Service';
import type {
  ApiAuthRequest,
  ApiAuthRefreshRequest,
  ApiAuthResponse,
  ApiLogoutRequest,
  ApiLogoutResponse,
  ApiPasswordResetRequest,
  ApiPasswordResetResponse,
  ApiUpdatePasswordRequest,
  ApiUpdatePasswordResponse,
  ApiExchangeTokenRequest,
  ApiExchangeTokenResponse,
  ApiMagicLinkRequest,
  ApiMagicLinkResponse,
  ApiSignupCodeRequest,
  ApiSignupCodeResponse,
} from './types';

// FIXME: Remove this once the switch to V3 is complete.
const USE_PAM_V3 = true;

class AuthService implements Service {
  static auth(params: ApiAuthRequest): AxiosPromise<ApiAuthResponse> {
    const path = `/token/auth`;
    return axios
      .post(path, { ...params, use_pam_v3: USE_PAM_V3 })
      .then((response: $AxiosXHR<ApiAuthRequest, ApiAuthResponse>) => {
        const header = `Bearer ${response.data.auth.access_token}`;
        axios.defaults.headers.common.Authorization = header;
        return response;
      });
  }

  static refresh(params: ApiAuthRefreshRequest): AxiosPromise<ApiAuthResponse> {
    const path = '/token/refresh';
    return axios
      .post(
        path,
        { clientType: params.clientType, use_pam_v3: USE_PAM_V3 },
        {
          headers: { Authorization: `Bearer ${params.refreshToken}` },
        },
      )
      .then((response: $AxiosXHR<any, ApiAuthResponse>) => {
        const header = `Bearer ${response.data.auth.access_token}`;
        axios.defaults.headers.common.Authorization = header;
        return response;
      });
  }

  static requestPasswordReset(
    params: ApiPasswordResetRequest,
  ): AxiosPromise<ApiPasswordResetResponse> {
    const path = `/token/reset`;
    // attributes in the 'params' object will be as URL parameters in the path
    return axios.get(path, {
      params: {
        email: params.email,
      },
    });
  }

  static updatePassword(
    params: ApiUpdatePasswordRequest,
  ): AxiosPromise<ApiUpdatePasswordResponse> {
    const path = '/token/reset';
    return axios
      .post(path, params)
      .then((response: $AxiosXHR<any, ApiAuthResponse>) => {
        const header = `Bearer ${response.data.auth.access_token}`;
        axios.defaults.headers.common.Authorization = header;
        return response;
      });
  }

  static logout(
    params: ApiLogoutRequest = {},
  ): AxiosPromise<ApiLogoutResponse> {
    const path = '/token/remove';
    return axios.post(path, params);
  }

  static requestMagicLink(
    params: ApiMagicLinkRequest,
  ): AxiosPromise<ApiMagicLinkResponse> {
    const path = '/token/request';
    return axios.post(path, params);
  }

  static requestSignupCode({
    email,
  }: ApiSignupCodeRequest): AxiosPromise<ApiSignupCodeResponse> {
    const path = '/token/request';
    return axios.post(path, { email, token_type: 'SIGNUP_TOKEN' });
  }

  static exchangeToken(
    params: ApiExchangeTokenRequest,
  ): AxiosPromise<ApiExchangeTokenResponse> {
    const path = '/token/exchange';
    return axios.post(path, params);
  }
}

export default AuthService;
