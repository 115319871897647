export type ThemeReducerState = {
  mode: 'light' | 'dark';
};

const ThemeInitialState: ThemeReducerState = {
  mode: 'light',
};

type ThemeReducerActions = {
  type: 'SET_THEME';
  mode: 'light' | 'dark';
};

export const ThemeReducer = (
  state: ThemeReducerState = ThemeInitialState,
  action: ThemeReducerActions,
) => {
  if (action.type === 'SET_THEME') {
    return {
      mode: action.mode,
    };
  }
  return state;
};
