import { takeLatest, race, put, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { AdminModule } from '@numbox/modules';
import { demoTimedOut } from '../modules/admin';

function* onDemoReset(): Generator<any, void, any> {
  // Make sure the interval is log enough to not fail prematurely
  // due to a permission changing and having the demoStatus request fail
  // But not too long that demoers have to wait forever for feedback
  const INTERVAL_MILLIS = 5000;
  const THRESHOLD_MILLIS = 180000;
  let timeDelayed = 0;
  while (timeDelayed < THRESHOLD_MILLIS) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    yield put(AdminModule.demoStatus.request({}));
    const raceResult = yield race({
      success: take('DEMO_STATUS.SUCCESS'),
      error: take('DEMO_STATUS.ERROR'),
    });

    if (!raceResult) {
      // Something unexpected happened, abort the retry loop
      return;
    }
    if (raceResult.error) {
      // There was an error checking the demo reset status
      if (raceResult.error?.payload?.response?.status !== 401) {
        // The error wasn't a 401 so something bad happened
        return;
      }
    } else if (raceResult.success.payload.status === 'SUCCESS') {
      // it wasn't error and we see the reset is no longer pending
      return;
    }

    // the reset is still in progress
    timeDelayed += INTERVAL_MILLIS;
    yield delay(INTERVAL_MILLIS);
  }

  yield put(demoTimedOut());
}

export function* watchDemoReset(): Generator<any, void, void> {
  yield takeLatest('DEMO_RESET.SUCCESS', onDemoReset);
}
