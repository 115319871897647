import { PubNubType } from '@numbox/apollo';
import type { StorePubNubClientAction } from '~/actions/pubnub';

export const PUBNUB_INITIAL_STATE = {
  pubnubClient: null,
};

export type PubNubReducerState = {
  pubnubClient?: PubNubType | null;
};

type Actions = StorePubNubClientAction;

export default function PubNubReducer(
  state: PubNubReducerState = PUBNUB_INITIAL_STATE,
  action: Actions,
) {
  switch (action.type) {
    case 'STORE_PUBNUB_CLIENT': {
      return {
        ...state,
        pubnubClient: action.payload.pubnubClient,
      };
    }
    default:
      return state;
  }
}
