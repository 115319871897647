import { gql } from '@apollo/client';

export const SYNC_XTIME_SERVICES = gql`
  mutation syncXtimeServices($input: SyncXtimeServicesInput!) {
    syncXtimeServices(input: $input) {
      supportedVehicles {
        id
      }
    }
  }
`;
