import type { ApolloContext } from '../../types';
import { updateStatusItemInCache } from './AddStatusItemToCache';

export const PubNubStatusItemUpdated = async (
  obj: any,
  args: { statusItem: PubNubStatusItemUpdateModel },
  client: ApolloContext,
) => {
  updateStatusItemInCache(client, args.statusItem);
};
