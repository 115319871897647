import { gql } from '@apollo/client';

export const CANCEL_SCHEDULED_MESSAGE = gql`
  mutation cancelScheduledMessage($input: CancelScheduledMessageInput!) {
    cancelScheduledMessage(input: $input) {
      scheduledMessage {
        id
        status
      }
    }
  }
`;
