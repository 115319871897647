import axios from 'axios';

export const DEMO_ACCOUNT = 'test1@numberai.com';

export const RESET_DEMO_ACCOUNT = 'RESET_DEMO_ACCOUNT';
export const RESET_DEMO_ACCOUNT_FAILED = 'RESET_DEMO_ACCOUNT_FAILED';
export function resetDemoAccount(
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'emailAddress' implicitly has an 'any' t... Remove this comment to see the full error message
  emailAddress,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'businessName' implicitly has an 'any' t... Remove this comment to see the full error message
  businessName,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'businessPhone' implicitly has an 'any' ... Remove this comment to see the full error message
  businessPhone,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'verifiedBusiness' implicitly has an 'an... Remove this comment to see the full error message
  verifiedBusiness,
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'branding' implicitly has an 'any' type.
  branding,
) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return dispatch => {
    const email = encodeURIComponent(emailAddress);
    const b = encodeURIComponent(businessName);
    const mdn = encodeURIComponent(businessPhone);
    const verified = verifiedBusiness ? 1 : 0;
    const brand = encodeURIComponent(branding);
    const path = `/resetDemo?b=${b}&mdn=${mdn}&email=${email}&verified=${verified}&brand=${brand}`;

    axios
      .get(path)
      .then(response => {
        dispatch({
          type: RESET_DEMO_ACCOUNT,
          payload: response,
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({
          type: RESET_DEMO_ACCOUNT_FAILED,
          payload: error,
          error: true,
        });
      });
  };
}

export const ENABLE_YELP = 'ENABLE_YELP';
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
export function enableYelp(payload) {
  return {
    type: ENABLE_YELP,
    payload,
  };
}

export const RECEIVE_YELP_REVIEW = 'RECEIVE_YELP_REVIEW';
export function receiveYelpReview() {
  return {
    type: RECEIVE_YELP_REVIEW,
  };
}

export const GET_DEMO = 'GET_DEMO';
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
export function getDemo(payload) {
  return {
    type: GET_DEMO,
    payload,
  };
}

export const GET_APP_DIRECTORY = 'GET_APP_DIRECTORY';
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
export function getAppDirectory(payload) {
  return {
    type: GET_APP_DIRECTORY,
    payload,
  };
}

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
export function getTransactions(payload) {
  return {
    type: GET_TRANSACTIONS,
    payload,
  };
}

export const SEND_TO_ASSISTANT = 'SEND_TO_ASSISTANT';
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
export function sendToAssistant(payload) {
  return {
    type: SEND_TO_ASSISTANT,
    payload,
  };
}
