import { gql } from '@apollo/client';

export const UPDATE_PAYMENT_REQUEST_CUSTOMIZATION = gql`
  mutation updatePaymentRequestCustomization(
    $input: UpdatePaymentRequestCustomizationInput!
  ) {
    updatePaymentRequestCustomization(input: $input) {
      paymentRequestCustomization {
        id
        placeId

        createTemplate
        renderedCreateTemplate
        createAutoSend

        createHoldTemplate
        renderedCreateHoldTemplate
        createHoldAutoSend

        successTemplate
        renderedSuccessTemplate
        successAutoSend

        refundTemplate
        renderedRefundTemplate
        refundAutoSend

        partialRefundTemplate
        renderedPartialRefundTemplate
        partialRefundAutoSend

        canceledTemplate
        renderedCanceledTemplate
        canceledAutoSend
      }
    }
  }
`;
