import { gql } from '@apollo/client';

export const GET_MY_INBOXES = gql`
  query myInboxes {
    myInboxes {
      totalConversations
      totalUnassigned
      conversationsAssignedToMe
      conversationMentions
      hideSharedInboxViews
      statusBoardView
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          conversationCount
          place {
            id
            name
          }
        }
      }
    }
  }
`;
