import { Components, Theme } from '@mui/material';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },

      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    },
  },
  styleOverrides: {},
};
