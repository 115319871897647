import { gql } from '@apollo/client';

export const REQUEST_GOOGLE_MESSAGING_LOCATION_VERIFICATION = gql`
  mutation requestGoogleMessagingLocationVerification(
    $input: RequestGoogleMessagingLocationVerificationInput
  ) {
    requestGoogleMessagingLocationVerification(input: $input) {
      googleAgent {
        id
        accountId
        agentId
        brandId
        contactEmail
        websiteUrl
        status
      }
    }
  }
`;
