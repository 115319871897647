import { writeConversationFragment } from '../../util';
import type { ApolloContext } from '../../types';
import { convertPubNubConversationToConversationItemFields } from './PubNubMessageReceived';

export const PubNubConversationEngagementUpdated = (
  obj: any,
  args: { conversation: PubNubConversationModel },
  { client }: ApolloContext,
) => {
  const { conversation } = args;

  const apolloConversation =
    convertPubNubConversationToConversationItemFields(conversation);

  // PubNub event only triggers when latest engagement or summary changes, therefore rating becomes
  // moot for the latest presented state, so clear the rating.
  if (apolloConversation.lastEngagement) {
    apolloConversation.lastEngagement = {
      ...apolloConversation.lastEngagement,
      mySummaryRating: null,
    };
  }

  writeConversationFragment({
    client,
    conversation: apolloConversation,
    withLastAppointment: true,
    withLastChannel: false,
    withLastRepairOrder: true,
  });
};
