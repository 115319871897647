import { gql } from '@apollo/client';

export const PUBNUB_RESTORE_CONVERSATIONS = gql`
  mutation PubNubRestoreConversations(
    $inbox_id: String!
    $conversations: [PubNubConversationTimestamp!]
  ) {
    PubNubRestoreConversations(
      inbox_id: $inbox_id
      conversations: $conversations
    ) @client
  }
`;
