import { gql } from '@apollo/client';

export const CHANGE_TWILIO_CHANNEL_MDN = gql`
  mutation changeTwilioChannelMdn($input: ChangeTwilioChannelMDNInput!) {
    changeTwilioChannelMdn(input: $input) {
      channel {
        id
        mdn
        sid
        outboundSmsMdn
      }
    }
  }
`;
