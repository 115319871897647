export const urlWithSearch = (
  pathname: string,
  search: string | null | undefined,
) => {
  if (!search) {
    return pathname;
  }

  return `${pathname}${search}`;
};

/*
 * This function is used to format a URL from a common sub path so we can use the same router
 * for both the main and admin apps.
 *
 * On web we have:
 * /jobs/
 *
 * and on admin we have:
 * /admin/dashboard/accounts/:accountId/places/:placeId/jobs/
 *
 * This allows us to navigate within the components ignoring the prefix before the shared
 * common path.
 *
 * @param {string} url - Current URL.
 * @param {string} endOfCommonPath - Path shared between web and admin (e.g. /jobs)
 * @param {string} newEndPath - Path to navigate to added to the end of the common path.
 */
export const formatUrlFromCommonPath = (
  url: string,
  endOfCommonPath: string,
  newEndPath: string,
): string => {
  const splitUrl = url.split(endOfCommonPath);
  if (!splitUrl.length) {
    return url;
  }

  return `${splitUrl[0]}${endOfCommonPath}${newEndPath}`;
};
