import { select } from 'redux-saga/effects';

import type { SelectPubnubPayload } from '@numbox/react';

import { getWatchActivelyTyping } from '@numbox/react';
import { getUserId } from '../modules/auth';
import { getUsers } from '../modules/users';
import getPubnubClient from '../selectors/pubnub';

function* selectPubnubPayload(): Generator<any, SelectPubnubPayload, any> {
  const pubnubClient = yield select(getPubnubClient);
  // We want to listen on the active channel, which is currently the place id
  const activeUserId = yield select(getUserId);
  const users = yield select(getUsers);
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'u' implicitly has an 'any' type.
  const user = users.find(u => u.id === activeUserId);

  return { pubnubClient, activeUserId, user };
}
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => Generator<any, SelectPubnubPayload, any>' is not assignable to parameter of type 'Generator<any, SelectPubnubPayload, any>'.
export default getWatchActivelyTyping(selectPubnubPayload);
