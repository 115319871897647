import { gql } from '@apollo/client';

export const UPDATE_BUSINESS_IDENTITY = gql`
  mutation UpdateBusinessIdentityMutation(
    $input: BusinessIdentityInput!
    $trustProductId: String!
  ) {
    updateBusinessIdentity(input: $input, trustProductId: $trustProductId) {
      tenDlcProcess {
        twilioTrustProduct {
          friendlyName
          id
          status
        }
        errors {
          friendlyName
          objectField
          failureReason
        }
      }
    }
  }
`;
