import { gql } from '@apollo/client';

export const GET_PLACE_DISRUPTION_SETTINGS = gql`
  query getPlaceDisruptionSettings($placeId: String!) {
    place(id: $placeId) {
      id
      answeringMode
      answeringModeUntil
      disruptions {
        id
        disruptionDepartmentVoiceScriptId
        draftDisruptionDepartmentVoiceScriptId
        disruptionStaffVoiceScriptId
        draftDisruptionStaffVoiceScriptId
        holidayDepartmentVoiceScriptId
        draftHolidayDepartmentVoiceScriptId
        holidayStaffVoiceScriptId
        draftHolidayStaffVoiceScriptId
      }
    }
  }
`;
