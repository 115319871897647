import { gql } from '@apollo/client';
import { VoiceStepFieldsFragment } from '../../fragments/gql/VoiceStepFieldsFragment.gql';

export const UPLOAD_CUSTOM_AUDIO = gql`
  mutation UploadCustomAudio($input: UploadCustomAudioInput!) {
    uploadCustomAudio(input: $input) {
      step {
        ...VoiceStepFields
      }
      script {
        id
        dirty
      }
    }
  }
  ${VoiceStepFieldsFragment}
`;
