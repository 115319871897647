import { gql } from '@apollo/client';

export const DELETE_TEAM = gql`
  mutation deleteTeam($input: DeleteTeamInput!) {
    deleteTeam(input: $input) {
      team {
        id
      }
    }
  }
`;
