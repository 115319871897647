import { gql } from '@apollo/client';

// TODO: Update Place to fragment after https://github.com/NumberAI/numbox-clients/pull/4219 lands

export const UPDATE_NEW_CONVERSATIONS_PER_DAY = gql`
  mutation updateNewConversationsPerDay(
    $input: UpdateNewConversationsPerDayInput!
  ) {
    updateNewConversationsPerDay(input: $input) {
      place {
        id
        name
        newConversationsPerDay
      }
    }
  }
`;
