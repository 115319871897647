import { gql } from '@apollo/client';

import { OutboundConferenceCallFragment } from '../../fragments/gql/outboundConferenceCall.gql';

export const END_OUTBOUND_CONFERENCE_CALL = gql`
  mutation EndOutboundConferenceCall($input: EndOutboundConferenceCallInput!) {
    endOutboundConferenceCall(input: $input) {
      outboundConferenceCall {
        ...OutboundConferenceCallFields
      }
    }
  }
  ${OutboundConferenceCallFragment}
`;
