import { gql } from '@apollo/client';

export const UPDATE_PLACE_STATUS = gql`
  mutation UpdatePlaceStatus($input: UpdatePlaceStatusInput!) {
    updatePlaceStatus(input: $input) {
      place {
        id
        status
      }
    }
  }
`;
