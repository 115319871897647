import type { ApolloContext } from '../../types';
import { safeRead } from '../../util';
import { GET_LABELS_FOR_PLACE } from '../../queries/gql/getLabelsForPlace.gql';

export const convertLabel = (
  label: PubNubLabelModel,
): getLabelsForPlace$labels => {
  return {
    __typename: 'LabelQL',
    id: label.id,
    placeId: label.place_id,
    category: label.category,
    name: label.name,
    color: label.color,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    icon: label.icon,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
    iconType: label.icon_type,
    hidden: label.hidden,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
    expirationHours: label.expiration_hours,
    archived: label.archived,
  };
};

export const addLabelToCache = (
  { client }: ApolloContext,
  label: PubNubLabelModel,
) => {
  const newLabel = convertLabel(label);

  const data = safeRead(client, {
    query: GET_LABELS_FOR_PLACE,
    variables: { placeId: newLabel.placeId },
  });

  const existingLabels = data?.labels ?? [];

  client.writeQuery({
    query: GET_LABELS_FOR_PLACE,
    variables: { placeId: newLabel.placeId },
    data: {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'l' implicitly has an 'any' type.
      labels: [newLabel, ...existingLabels.filter(l => l.id !== newLabel.id)],
    },
  });
};
