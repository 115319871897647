import { gql } from '@apollo/client';

import { OutboundConferenceCallFragment } from '../../fragments/gql/outboundConferenceCall.gql';

export const GET_OUTBOUND_CONFERENCE_CALL_BY_USER = gql`
  query getOutboundConferenceCallByUser($userId: String!) {
    outboundConferenceCalls(userId: $userId) @client {
      ...OutboundConferenceCallFields
    }
  }
  ${OutboundConferenceCallFragment}
`;
