import { MuiButton } from './MuiButton';
import { MuiButtonBase } from './MuiButtonBase';
import { MuiDialog } from './MuiDialog';
import { MuiDialogActions } from './MuiDialogActions';
import { MuiDialogContent } from './MuiDialogContent';
import { MuiDialogTitle } from './MuiDialogTitle';
import { MuiFormControl } from './MuiFormControl';
import { MuiList } from './MuiList';
import { MuiListItem } from './MuiListItem';
import { MuiListItemText } from './MuiListItemText';
import { MuiListSubheader } from './MuiListSubheader';
import { MuiOutlinedInput } from './MuiOutlinedInput';
import { MuiSelect } from './MuiSelect';
import { MuiToggleButton } from './MuiToggleButton';
import { MuiFormHelperText } from './MuiFormHelperText';
import { MuiIconButton } from './MuiIconButton';
import { MuiAppBar } from './MuiAppBar';
import { MuiToolbar } from './MuiToolbar';
import { MuiRating } from './MuiRating';
import { MuiTooltip } from './MuiTooltip';
import { MuiPaper } from './MuiPaper';
import { MuiIcon } from './MuiIcon';
import { MuiAccordion } from './MuiAccordion';
import { MuiAccordionSummary } from './MuiAccordionSummary';
import { MuiCard } from './MuiCard';

export const components = {
  MuiButton,
  MuiButtonBase,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiFormControl,
  MuiIconButton,
  MuiList,
  MuiListItem,
  MuiListItemText,
  MuiListSubheader,
  MuiOutlinedInput,
  MuiSelect,
  MuiToggleButton,
  MuiTooltip,
  MuiFormHelperText,
  MuiAppBar,
  MuiToolbar,
  MuiRating,
  MuiPaper,
  MuiIcon,
  MuiAccordion,
  MuiAccordionSummary,
  MuiCard,
};
