import { gql } from '@apollo/client';
import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';
import { RepairOrderFragment } from '../../fragments/gql/repairOrderFragment.gql';

export const GET_CONVERSATION_BY_ID = gql`
  query getConversationById(
    $conversationId: String!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    conversation(conversationId: $conversationId) {
      ...ConversationItemFields
    }
  }
  ${ConversationItemFragment}
  ${RepairOrderFragment}
`;
