import { gql } from '@apollo/client';

export const GET_ACTIVE_PAYMENTS_COLLECTION = gql`
  query getActivePaymentsCollection {
    activePaymentsCollection @client {
      status
      collectionId
    }
  }
`;
