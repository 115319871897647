import { gql } from '@apollo/client';

import { ChannelFieldsFragment } from './channelFields.gql';

export const AssignmentOptionsFragment = gql`
  fragment AssignmentOptionsFields on PhoneLineQL {
    id
    smsChannel {
      ...ChannelFields
    }
  }
  ${ChannelFieldsFragment}
`;
