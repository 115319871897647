import type { ApolloContext } from '../../types';
import { safeRead } from '../../util';
import { GET_OUTBOUND_PROXY_CALL_STATUS } from '../../queries/gql/getOutboundProxyCallStatus.gql';

/* eslint-disable react/destructuring-assignment */

export const PubNubOutboundProxyCallStatusUpdated = (
  obj: any,
  args: {
    id: string;
    call_status: string;
    start_time: string | null;
    is_being_recorded: boolean;
  },
  { client }: ApolloContext,
) => {
  const data = safeRead(client, {
    query: GET_OUTBOUND_PROXY_CALL_STATUS,
    variables: { id: args.id },
  });

  const outboundProxyCall: getOutboundProxyCallStatus$outboundProxyCall | null =
    data?.outboundProxyCall;
  client.writeQuery<getOutboundProxyCallStatus>({
    query: GET_OUTBOUND_PROXY_CALL_STATUS,
    variables: { id: args.id },
    data: {
      outboundProxyCall: {
        __typename: 'OutboundProxyCallQL',
        ...outboundProxyCall,
        id: args.id,
        callStatus: args.call_status,
        isBeingRecorded: args.is_being_recorded,
        startTime: args.start_time,
      },
    },
  });

  return null;
};
/* eslint-enable react/destructuring-assignment */
