import { gql } from '@apollo/client';

export const CREATE_PLACE = gql`
  mutation CreatePlace($input: CreatePlaceInput!) {
    createPlace(input: $input) {
      place {
        id
        name
      }
    }
  }
`;
