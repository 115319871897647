import axios from 'axios';
import type { AxiosPromise } from 'axios';
import { Service } from '../Service';
import type {
  ApiCreatePhoneNumberRequest,
  ApiCreatePhoneNumberResponse,
  ApiFetchAvailablePhoneNumbersRequest,
  ApiFetchAvailablePhoneNumbersResponse,
} from './types';

class PhoneNumberService implements Service {
  static fetchAvailablePhoneNumbers(
    params: ApiFetchAvailablePhoneNumbersRequest,
  ): AxiosPromise<ApiFetchAvailablePhoneNumbersResponse> {
    const query = Object.keys(params)
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      .map(key => (params[key] ? `${key}=${params[key]}` : ''))
      .filter(term => term)
      .join('&');

    const path = `/api/phone_number${query ? `?${query}` : ''}`;
    return axios.get(path);
  }

  static createPhoneNumber(
    params: ApiCreatePhoneNumberRequest,
  ): AxiosPromise<ApiCreatePhoneNumberResponse> {
    const path = params.link;
    return axios.post(path, {
      mdn: params.mdn,
    });
  }
}

export default PhoneNumberService;
