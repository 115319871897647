import { gql } from '@apollo/client';
import { StatusItemDetails } from '../../fragments/gql/StatusItem.gql';

export const REOPEN_STATUS_ITEM = gql`
  mutation reopenStatusItem($input: ReopenStatusItemInput) {
    reopenStatusItem(input: $input) {
      statusItem {
        ...StatusItemDetails
      }
    }
  }
  ${StatusItemDetails}
`;
