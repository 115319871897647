import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const ADD_VOICE_STEP = gql`
  mutation addVoiceStep($input: AddVoiceStepInput!) {
    addVoiceStep(input: $input) {
      voiceScript {
        ...VoiceScriptFields
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
