import { gql } from '@apollo/client';
import { TeamWithUsersFragment } from '../../../fragments/gql/TeamFragment.gql';

export const CREATE_TEAM = gql`
  mutation CreateTeam(
    $input: CreateTeamInput!
    $userCount: Int
    $afterUser: String
  ) {
    createTeam(input: $input) {
      team {
        ...TeamWithUsers
      }
    }
  }
  ${TeamWithUsersFragment}
`;
