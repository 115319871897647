import { gql } from '@apollo/client';

export const AttachmentItemFragment = gql`
  fragment AttachmentItemFields on Attachment {
    __typename
    ... on Attachment {
      id
      attachmentType
    }
    ... on FileAttachment {
      filename
      sizeBytes
      url
      words
    }
    ... on OpenVoiceQuestionAttachment {
      words
      url
      shorthandPrompt
    }
    ... on ImageAttachment {
      width
      height
      url
    }
    ... on VCardAttachment {
      filename
      sizeBytes
      url
      message {
        place {
          name
          externalName
        }
      }
    }
    ... on TranslatedMessage {
      text
      language
    }
    ... on YelpAttachment {
      url
    }
    ... on ReviewAttachment {
      review {
        reviewMode
        feedback {
          id
          opened
        }
      }
    }
    ... on SmartTopicAttachment {
      prompt
      transcript
      rationale
      outcome
    }
    ... on VideoUploadLinkAttachment {
      videoUploadLinkId
      videoMicrositeLinkId
    }
  }
`;
