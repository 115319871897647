import { gql } from '@apollo/client';
import { AwayModeFragment } from '../../fragments/gql/AwayModeFragment.gql';

export const UPDATE_IS_SCHEDULE_ENABLED = gql`
  mutation updateIsScheduleEnabled($input: UpdateIsScheduleEnabledInput!) {
    updateIsScheduleEnabled(input: $input) {
      user {
        ...AwayModeFields
        schedule
        isScheduleEnabled
      }
    }
  }
  ${AwayModeFragment}
`;
