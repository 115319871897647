import { gql } from '@apollo/client';

export const SystemMessageSettingsFragment = gql`
  fragment SystemMessageSettingsFields on SystemMessageSettings {
    __typename
    showSystemMessages
  }
`;

export const UserViewPreferencesFragment = gql`
  fragment UserViewPreferencesFields on UserViewPreferences {
    __typename
    systemMessageSettings {
      ...SystemMessageSettingsFields
    }
  }
  ${SystemMessageSettingsFragment}
`;
