import { gql } from '@apollo/client';

import { OutboundConferenceCallFragment } from '../../fragments/gql/outboundConferenceCall.gql';

export const UPDATE_OUTBOUND_CONFERENCE_CALL = gql`
  mutation UpdateOutboundConferenceCall(
    $input: UpdateOutboundConferenceCallInput!
  ) {
    updateOutboundConferenceCall(input: $input) {
      outboundConferenceCall {
        ...OutboundConferenceCallFields
      }
    }
  }
  ${OutboundConferenceCallFragment}
`;
