import type { ApolloContext } from '../../../types';
import { upsertTeamInCache } from './utils';

export const PubNubTeamCreated = async (
  obj: any,
  args: { team: PubNubTeamSettingsModel },
  { client }: ApolloContext,
) => {
  upsertTeamInCache(client, args.team);
};
