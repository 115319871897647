import { gql } from '@apollo/client';

export const UPDATE_USER_PERSONALIZATION = gql`
  mutation updateUserPersonalization($input: UpdateUserPersonalizationInput!) {
    updateUserPersonalization(input: $input) {
      user {
        id
        firstName
        namePronunciation
        namePronunciationUrl
        pronouns
      }
    }
  }
`;
