import { createSelector } from 'reselect';

import type { ExtractReturn } from '@numbox/modules';
import {
  AdminModule,
  BillingIdentityModule,
  AuthModule,
} from '@numbox/modules';
import type { ApiDemoUser, ApiUserStatus } from '@numbox/services';
import '@numbox/services';
import type { WebReducerState } from '../reducers/types';

export type DemoUserStatus = ApiUserStatus;

export type DemoUser = {
  id: string;
  lastLoginAt: Date;
  email: string;
  fullName: string;
  status: DemoUserStatus;
  demoMdn: string;
};

const fromAdminApi = (user: ApiDemoUser): DemoUser => ({
  id: user.id,
  lastLoginAt: new Date(user.last_login_at),
  email: user.email,
  fullName: user.full_name,
  status: user.status,
  demoMdn: user.demo_mdn,
});

export type AdminReducerState = {
  billingOrderId: string;
  skus: Array<string>;
  demoUsers: Array<DemoUser>;
  demoReset?: {
    resetInProgress: boolean;
    lastBizName: string;
    lastResetDate: string;
    bizName: string;
    mdn: string;
    wasSuccessful: boolean;
  };
};

export const ADMIN_INITIAL_STATE = {
  billingOrderId: '',
  skus: [],
  demoUsers: [],
};

export const clearResetAttempt = () => ({
  type: 'CLEAR_RESET_ATTEMPT',
});

export const demoTimedOut = () => ({
  type: 'DEMO_RESET_TIMED_OUT',
});

type AdminActions =
  | {
      type: 'CLEAR_RESET_ATTEMPT';
    }
  | {
      type: 'DEMO_RESET_TIMED_OUT';
    }
  | ExtractReturn<typeof AuthModule.auth.success>
  | ExtractReturn<typeof AdminModule.createDemoUser.success>
  | ExtractReturn<typeof AdminModule.deleteDemoUser.success>
  | ExtractReturn<typeof AdminModule.fetchDemoUsers.success>
  | ExtractReturn<typeof AdminModule.demoStatus.success>
  | ExtractReturn<typeof AdminModule.demoStatus.error>
  | ExtractReturn<typeof AdminModule.demoReset.success>
  | ExtractReturn<typeof AdminModule.demoReset.request>
  | ExtractReturn<typeof BillingIdentityModule.createBillingIdentity.success>;

const AdminReducer = (
  state: AdminReducerState = ADMIN_INITIAL_STATE,
  action: AdminActions,
) => {
  switch (action.type) {
    case 'CLEAR_RESET_ATTEMPT': {
      return {
        ...state,
        demoReset: {
          ...state.demoReset,
          wasSuccessful: null,
        },
      };
    }
    case 'DEMO_RESET.REQUEST': {
      return {
        ...state,
        demoReset: {
          ...state.demoReset,
          resetInProgress: true,
        },
      };
    }
    case 'DEMO_RESET_TIMED_OUT': {
      return {
        ...state,
        demoReset: {
          ...state.demoReset,
          resetInProgress: false,
          wasSuccessful: false,
        },
      };
    }
    case 'DEMO_STATUS.ERROR': {
      if (action.payload.response.status === 401) {
        return state;
      }
      return {
        ...state,
        demoReset: {
          ...state.demoReset,
          resetInProgress: false,
          wasSuccessful: false,
        },
      };
    }
    case 'DEMO_STATUS.SUCCESS': {
      if (action.payload.status === 'SUCCESS') {
        const {
          channels,
          business_name: businessName,
          success,
        } = action.payload.result;
        const twilioChannel = channels.find(
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
          channel => channel.type === 'twilio',
        );

        return {
          ...state,
          demoReset: {
            ...state.demoReset,
            resetInProgress: false,
            bizName: businessName,
            mdn: twilioChannel && twilioChannel.mdn ? twilioChannel.mdn : null,
            wasSuccessful: success,
            lastBizName: businessName,
            lastResetDate: new Date().toString(),
          },
        };
      }
      return state;
    }
    case 'CREATE_DEMO_USER.SUCCESS': {
      return {
        ...state,
        demoUsers: [...state.demoUsers, fromAdminApi(action.payload)],
      };
    }
    case 'DELETE_DEMO_USER.SUCCESS': {
      const userId = action.payload.user_id;
      return {
        ...state,
        demoUsers: state.demoUsers.filter(user => user.id !== userId),
      };
    }
    case 'FETCH_DEMO_USERS.SUCCESS': {
      return {
        ...state,
        demoUsers: action.payload.map(fromAdminApi),
      };
    }
    case 'CREATE_BILLING_IDENTITY.SUCCESS': {
      return {
        ...state,
        billingOrderId: action.payload.billing_identity.billing_order_id,
      };
    }
    case 'STORE_AUTH0_TOKEN':
    case 'AUTH_USER.SUCCESS': {
      if (!action.payload.user.demo_settings) {
        return state;
      }
      const demoSettings = action.payload.user.demo_settings;
      const twilioChannel = demoSettings.channels.find(
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
        channel => channel.type === 'twilio',
      );

      return {
        ...state,
        demoReset: {
          ...state.demoReset,
          lastBizName: demoSettings.last_business_name,
          lastResetDate: demoSettings.last_demo_reset
            ? demoSettings.last_demo_reset
            : null,
          mdn: twilioChannel && twilioChannel.mdn ? twilioChannel.mdn : null,
        },
      };
    }
    default:
      return state;
  }
};

export default AdminReducer;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'userA' implicitly has an 'any' type.
const sortUsers = (userA, userB) => {
  if (userA.status !== userB.status) {
    return userA.status === 'active' ? -1 : 1;
  }

  return userA.lastLoginAt <= userB.lastLoginAt ? 1 : -1;
};

export const getIsNumberAIEmployee = (state: WebReducerState) =>
  state.auth.isNumberAIEmployee;

export const getDemoUsers = (state: WebReducerState) => state.admin.demoUsers;
export const getSortedDemoUsers = createSelector([getDemoUsers], users =>
  [...users].sort(sortUsers),
);

export const getBillingOrderId = (state: WebReducerState) =>
  state.admin.billingOrderId;
export const getAvailableSkus = (state: WebReducerState) => state.admin.skus;

export const getDemoReset = (state: WebReducerState) => state.admin.demoReset;
export const getDemoBizName = createSelector([getDemoReset], demoReset =>
  demoReset ? demoReset.bizName : null,
);

export const getDemoMdn = createSelector([getDemoReset], demoReset =>
  demoReset ? demoReset.mdn : null,
);

export const getDemoResetInProgress = createSelector(
  [getDemoReset],
  demoReset => (demoReset ? demoReset.resetInProgress : false),
);

export const wasResetSuccessful = createSelector([getDemoReset], demoReset =>
  demoReset ? demoReset.wasSuccessful : null,
);

export const getLastBizName = createSelector([getDemoReset], demoReset =>
  demoReset ? demoReset.lastBizName : null,
);

export const getLastResetDate = createSelector([getDemoReset], demoReset =>
  demoReset ? demoReset.lastResetDate : null,
);
