import { gql } from '@apollo/client';

export const GET_PLACE_MFA_SETTINGS = gql`
  query getPlaceMfaSettings($id: String!) {
    place(id: $id) {
      id
      enforceMfa
    }
  }
`;
