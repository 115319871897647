import { gql } from '@apollo/client';

export const EVALUATE_BUSINESS_IDENTITY = gql`
  mutation EvaluateBusinessIdentityMutation(
    $input: BusinessIdentityInput!
    $accountId: String!
  ) {
    evaluateBusinessIdentity(input: $input, accountId: $accountId) {
      tenDlcProcess {
        id
        twilioTrustProduct {
          friendlyName
          id
          status
        }
        errors {
          objectField
          failureReason
        }
      }
    }
  }
`;
