/* eslint-disable max-len */

import { TopBanner } from './TopBanner';

export const BrowserUnsupportedBanner = () => (
  <TopBanner>
    {`You're using a browser that is no longer supported. We recommend switching to the latest version of `}
    <a href="https://www.google.com/chrome/">Chrome</a>,
    <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>
    <span style={{ marginLeft: '5px' }}>or</span>
    <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Edge</a>.
  </TopBanner>
);
