import { gql } from '@apollo/client';

export const BULK_UPDATE_SUPPORTED_VEHICLES = gql`
  mutation bulkUpdateSupportedVehicles(
    $input: BulkUpdateSupportedVehiclesInput!
  ) {
    bulkUpdateSupportedVehicles(input: $input) {
      supportedVehicles {
        id
        useOverrides
        outcome
        repairService {
          id
          name
          enabledSupportedVehiclesCount
          disabledSupportedVehiclesCount
        }
        firstEnabledOpCode {
          id
          isEnabled
          opCode {
            id
            dmsId
            description
          }
        }
        opCodes {
          id
          isEnabled
          opCodeId
        }
      }
    }
  }
`;
