import type { ApolloContext } from '../../types';
import { addPaymentRequestToCache } from './convertPaymentRequest';

export const PubNubPaymentRequestUpdated = (
  obj: any,
  args: { payment_request: PubNubPaymentRequestModel },
  { client }: ApolloContext,
) => {
  addPaymentRequestToCache(client, args.payment_request);
};
