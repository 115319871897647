import type { ApolloClient } from '@apollo/client';
import { safeRead } from './apollo';
import { GET_MY_INBOXES } from '../queries/gql/getMyInboxes.gql';
import { GET_MENTIONED_CONVERSATIONS } from '../queries/gql/getMentionedConversations.gql';
import { updateActiveInbox } from './activeInbox';

export const markMentionedConversationsAsRead = (
  client: ApolloClient<any>,
  conversationIds?: Array<string>,
) => {
  const myInboxesCache = safeRead(client, {
    query: GET_MY_INBOXES,
  });

  const mentionedConversationsCache = safeRead(client, {
    query: GET_MENTIONED_CONVERSATIONS,
  });

  let conversationMentions =
    myInboxesCache?.myInboxes?.conversationMentions || 0;

  if (
    conversationMentions === 0 ||
    !mentionedConversationsCache?.mentionedConversations?.edges
  ) {
    return;
  }

  const mentionedConversationsIdsToBeMarkedAsRead =
    conversationIds ||
    mentionedConversationsCache?.mentionedConversations?.edges.map(
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'edge' implicitly has an 'any' type.
      edge => edge.node.id,
    ) ||
    [];

  const edges = mentionedConversationsCache?.mentionedConversations?.edges.map(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'edge' implicitly has an 'any' type.
    edge => {
      if (
        edge?.node?.amIMentioned &&
        mentionedConversationsIdsToBeMarkedAsRead.includes(edge?.node.id)
      ) {
        conversationMentions -= 1;
        return {
          ...edge,
          node: {
            ...edge?.node,
            amIMentioned: false,
          },
        };
      }

      return edge;
    },
  );

  client.writeQuery({
    query: GET_MY_INBOXES,
    data: {
      ...myInboxesCache,
      myInboxes: {
        ...myInboxesCache?.myInboxes,
        conversationMentions,
      },
    },
  });

  client.writeQuery({
    query: GET_MENTIONED_CONVERSATIONS,
    data: {
      ...mentionedConversationsCache,
      mentionedConversations: {
        ...mentionedConversationsCache?.mentionedConversations,
        edges,
      },
    },
  });

  updateActiveInbox(
    client,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    mentionedConversationsIdsToBeMarkedAsRead.map(id => ({
      id,
      isMentionedConversation: true,
      amIMentioned: false,
    })) || [],
  );
};
