import { gql } from '@apollo/client';

export const CREATE_CONVERSATION_SUMMARY = gql`
  mutation createConversationSummary($input: CreateConversationSummaryInput!) {
    createConversationSummary(input: $input) {
      textSummary {
        responses
        messages {
          role
          content
        }
        data
      }
    }
  }
`;
