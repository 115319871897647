import { gql } from '@apollo/client';

export const GET_STRIPE_ACCOUNT = gql`
  query getStripeAccount($placeId: String!) {
    stripeIntegration(placeId: $placeId) {
      id
      placeId
      stripeAccountType
      stripeAccountId
      stripeAccountStatus
    }
  }
`;
