import axios from 'axios';
import type { AxiosPromise } from 'axios';

import { Service } from '../Service';
import type {
  ApiFetchPlacesResponse,
  ApiUpdatePlaceHoursRequest,
  ApiUpdatePlaceHoursResponse,
} from './types';

class PlaceService implements Service {
  static fetchPlaces(): AxiosPromise<ApiFetchPlacesResponse> {
    const path = `/api/places`;
    return axios.get(path);
  }

  static updatePlaceHours(
    params: ApiUpdatePlaceHoursRequest,
  ): AxiosPromise<ApiUpdatePlaceHoursResponse> {
    const { placeId, ...payload } = params;
    const path = `/api/places/${placeId}`;
    return axios.patch(path, payload);
  }
}

export default PlaceService;
