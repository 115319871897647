import { gql } from '@apollo/client';
import { VideoUploadLinkFragment } from '../../fragments/gql/videoUploadLinkFragment.gql';

export const CREATE_VIDEO_UPLOAD_LINK = gql`
  mutation createVideoUploadLink($input: CreateVideoUploadLinkInput!) {
    createVideoUploadLink(input: $input) {
      videoUploadLink {
        ...VideoUploadLinkFields
      }
    }
  }
  ${VideoUploadLinkFragment}
`;
