import { gql } from '@apollo/client';

export const DELETE_NOTE_TAKER = gql`
  mutation deleteNoteTaker($input: DeleteNoteTakerInput!) {
    deleteNoteTaker(input: $input) {
      id
      message
    }
  }
`;
