import { gql } from '@apollo/client';

export const PUBNUB_APPOINTMENT_ENRICHED = gql`
  mutation PubNubAppointmentEnriched(
    $appointment: PubNubAppointmentModel!
    $conversation_id: String!
  ) {
    PubNubAppointmentEnriched(
      appointment: $appointment
      conversation_id: $conversation_id
    ) @client
  }
`;
