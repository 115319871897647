import { gql } from '@apollo/client';

import { ConversationItemStub } from './conversationItem.gql';
import { ConversationLabelStub } from './labelFragment.gql';

export const StatusItemStub = gql`
  fragment StatusItemStub on StatusItemQL {
    id
    eta
    orderNumber
    tag
    color
    status
    description
    createdOn
    lastUpdateTime
    heldSince
    participant {
      id
      displayName
      displayNameConfidence
      firstName
      lastName
      address
    }
    conversation {
      ...ConversationItemStub
      escalated
      conversationLabels {
        ...ConversationLabelStubFields
      }
    }
  }
  ${ConversationItemStub}
  ${ConversationLabelStub}
`;

export const StatusItemDetails = gql`
  fragment StatusItemDetails on StatusItemQL {
    id
    eta
    orderNumber
    tag
    status
    description
    createdOn
    color
    lastUpdateTime
    heldSince
    participant {
      id
      displayName
      displayNameConfidence
      firstName
      lastName
      address
    }
    conversation {
      ...ConversationItemStub
      escalated
      conversationLabels {
        ...ConversationLabelStubFields
      }
    }
    stage {
      id
      name
      placeId
    }
    assignee {
      id
      displayName
    }
  }
  ${ConversationItemStub}
  ${ConversationLabelStub}
`;
