import { gql } from '@apollo/client';

export const CREATE_CHAT_EXPANSION = gql`
  mutation createChatExpansion($input: CreateChatExpansionInput!) {
    createChatExpansion(input: $input) {
      textExpansion {
        responses
        messages {
          role
          content
        }
        data
      }
    }
  }
`;
