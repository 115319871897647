import { gql } from '@apollo/client';
import { PaymentRequestFragment } from '../../fragments/gql/paymentRequestFragment.gql';

export const GET_PAYMENT_REQUEST = gql`
  query getPaymentRequest($id: String!) {
    paymentRequest(id: $id) {
      ...PaymentRequestFields
    }
  }
  ${PaymentRequestFragment}
`;
