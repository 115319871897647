import { gql } from '@apollo/client';
import { StatusItemDetails } from '../../fragments/gql/StatusItem.gql';

export const UPDATE_STATUS_ITEM = gql`
  mutation updateStatusItem($input: UpdateStatusItemInput) {
    updateStatusItem(input: $input) {
      statusItem {
        ...StatusItemDetails
      }
    }
  }
  ${StatusItemDetails}
`;
