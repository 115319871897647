import { gql } from '@apollo/client';
import { AdminPlaceFragment } from '../../fragments/gql/AdminPlace.gql';
import { UserDetailAdminFragment } from '../../fragments/gql/userItem.gql';

export const GET_ADMIN_PLACE_DETAILS = gql`
  query getAdminPlaceDetails($id: String!) {
    place(id: $id) {
      billingMetadata {
        id
        hubspotDealId
        hubspotCompanyId
        chargebeeSubscriptionId
        chargebeeCustomerId
      }
      ...AdminPlaceFields
      placesUsers {
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          node {
            user {
              ...UserDetailFields
            }
          }
        }
      }
    }
  }
  ${AdminPlaceFragment}
  ${UserDetailAdminFragment}
`;
