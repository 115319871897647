import { gql } from '@apollo/client';

export const GET_PLACES_ADMIN_QUERY = gql`
  query placesAdmin(
    $first: Int
    $after: String
    $search: String
    $sort: [PlaceModelSortEnum]
  ) {
    places(first: $first, after: $after, search: $search, sort: $sort) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          account {
            id
            name
          }
        }
      }
    }
  }
`;
