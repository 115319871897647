import { gql } from '@apollo/client';

export const GET_ACTIVE_INTEGRATION = gql`
  query getActiveIntegration($integrationId: String!) {
    activeIntegration(integrationId: $integrationId) {
      id
      placeId
      pendingOpcodeSync
      integration {
        id
        name
        configurationSchema
        description
        internalId
        categories
      }
      status
      configuration
      appointmentAgentSettings {
        id
        placeId
        name
        settings
      }
    }
  }
`;
