import { gql } from '@apollo/client';
import { LabelFragment } from '../../fragments/gql/labelFragment.gql';

export const UPDATE_LABEL = gql`
  mutation updateLabel($input: UpdateLabelInput!) {
    updateLabel(input: $input) {
      label {
        ...LabelFields
      }
    }
  }
  ${LabelFragment}
`;
