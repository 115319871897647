import { gql } from '@apollo/client';

import { StatusItemStub } from '../../fragments/gql/StatusItem.gql';

export const SEND_STATUS_ITEM_MESSAGE = gql`
  mutation sendStatusItemMessage($input: SendStatusItemMessageInput!) {
    sendStatusItemMessage(input: $input) {
      statusItem {
        ...StatusItemStub
      }
    }
  }
  ${StatusItemStub}
`;
