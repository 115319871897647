import { gql } from '@apollo/client';

export const GET_GOOGLE_MESSAGING_AGENTS = gql`
  query getGoogleMessagingAgents($accountId: String) {
    googleMessagingAgents(accountId: $accountId) {
      id
      accountId
      agentId
      brandId
      contactEmail
      websiteUrl
      status
      account {
        name
      }
    }
    logoUrls(accountId: $accountId)
  }
`;
