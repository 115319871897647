import { gql } from '@apollo/client';

export const PUBNUB_INBOUND_CALL_STATUS_UPDATED = gql`
  mutation PubNubInboundCallStatusUpdated(
    $call: PubNubInboundPhoneCallModel!
    $conversation_id: String!
  ) {
    PubNubInboundCallStatusUpdated(
      call: $call
      conversation_id: $conversation_id
    ) @client
  }
`;
