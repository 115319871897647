import { gql } from '@apollo/client';

import { UserItemFragment } from './userItem.gql';
import { ChannelFieldsFragment } from './channelFields.gql';

export const PhoneLineFieldsFragment = gql`
  fragment PhoneLineFields on PhoneLineQL {
    id
    mdn
    name
    carrierName
    category
    lineType
    createHostedSmsUrl
    kind
    returnCallsMode
    returnCallsRoutingHours
    returnCallsForwardingMode
    returnCallsMdn
    canForwardReturnCalls
    nryForwardingMdn
    policy {
      id
      category
    }
    user {
      ...UserItemFields
    }
    hostedSmsPort {
      id
      url
      status
      verificationAttempts
      verificationCode
    }
  }
  ${UserItemFragment}
`;

export const PhoneLineWithChannelsFieldsFragment = gql`
  fragment PhoneLineWithChannelsFields on PhoneLineQL {
    ...PhoneLineFields
    voiceChannel @include(if: $withVoiceChannel) {
      ...ChannelFields
    }
    smsChannel @include(if: $withSMSChannel) {
      ...ChannelFields
    }
  }
  ${PhoneLineFieldsFragment}
  ${ChannelFieldsFragment}
`;
