import { gql } from '@apollo/client';

export const GET_SUPPORTED_VEHICLES = gql`
  query getSupportedVehicles(
    $repairServiceId: String
    $ids: [String!]
    $searchTerm: String
    $first: Int
    $after: String
  ) {
    supportedVehicles(
      repairServiceId: $repairServiceId
      ids: $ids
      search: $searchTerm
      first: $first
      after: $after
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          supportedYears
          make
          model
          outcome
          useOverrides
          firstEnabledOpCode {
            id
            isEnabled
            opCode {
              id
              dmsId
              description
            }
          }
        }
      }
    }
  }
`;
