import { gql } from '@apollo/client';

import { OutboundConferenceCallFragment } from '../../fragments/gql/outboundConferenceCall.gql';

export const GET_OUTBOUND_CONFERENCE_CALL = gql`
  query getOutboundConferenceCall($id: String!) {
    outboundConferenceCall(id: $id) {
      ...OutboundConferenceCallFields
    }
  }
  ${OutboundConferenceCallFragment}
`;
