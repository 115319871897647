import { gql } from '@apollo/client';

export const UPDATE_USER_PHONE = gql`
  mutation updateUserPhone($input: UpdateUserPhoneInput!) {
    updateUserPhone(input: $input) {
      userPhone {
        id
        mdn
        type
        enabledDuringBusinessHours
        enabledAfterBusinessHours
        enabledWhenAway
      }
    }
  }
`;
