import { gql } from '@apollo/client';

import { OutboundConferenceCallFragment } from '../../fragments/gql/outboundConferenceCall.gql';

export const CREATE_OUTBOUND_CONFERENCE_CALL = gql`
  mutation CreateOutboundConferenceCall(
    $input: CreateOutboundConferenceCallInput!
  ) {
    createOutboundConferenceCall(input: $input) {
      outboundConferenceCall {
        ...OutboundConferenceCallFields
      }
    }
  }
  ${OutboundConferenceCallFragment}
`;
