import * as React from 'react';

import type { ApolloQueryResult, ApolloError } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import { AUTO_COMPLETE_SHORTCUTS } from './gql/autoCompleteShortcuts.gql';
import { concatEdges, collapseEdges } from '../util';
import { GraphQLClient } from '../client/GraphQLClient';

export type AutoCompleteShortcutsParams = {
  searchResults?: Array<searchShortcuts$autocompleteShortcuts$edges$node>;
  error: ApolloError | null | undefined;
  loading: boolean;
  fetchMore: () => Promise<any>;
  hasMore: boolean;
};

type Props = {
  placeId: string;
  search: string;
  children: (params: AutoCompleteShortcutsParams) => React.ReactElement | null;
};

const defaultChildProps = {
  error: undefined,
  loading: false,
  // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
  fetchMore: () => new Promise(resolve => resolve()),
  hasMore: false,
  searchResults: [],
};

export const autoCompleteShortcuts = ({
  search,
  searchTag,
  placeId,
}: {
  search?: string;
  searchTag?: string;
  placeId?: string;
}) => {
  return GraphQLClient.query({
    query: AUTO_COMPLETE_SHORTCUTS,
    fetchPolicy: 'network-only',
    // Occasionally we get a flow error here...restarting flow seems to fix it
    // but there's this https://github.com/facebook/flow/issues/6321
    variables: { search, searchTag, placeId },
  }).then((results: ApolloQueryResult<searchShortcuts>) => {
    const { errors, data } = results;
    if (errors) {
      console.error('failed to search for users', errors);
      return [];
    }

    if (
      !data ||
      !data.autocompleteShortcuts ||
      !data.autocompleteShortcuts.edges
    ) {
      console.error('No data in user search response', data);
      return [];
    }

    const { autocompleteShortcuts } = data;

    const nodes = collapseEdges<
      searchShortcuts$autocompleteShortcuts,
      searchShortcuts$autocompleteShortcuts$edges$node
    >(autocompleteShortcuts);

    return nodes;
  });
};

export const AutoCompleteShortcuts = React.memo<Props>(
  ({ search, placeId, children }: Props) => {
    return (
      <Query<searchShortcuts, searchShortcutsVariables>
        query={AUTO_COMPLETE_SHORTCUTS}
        variables={{ search, placeId }}
        fetchPolicy="cache-and-network"
        notifyOnNetworkStatusChange
      >
        {({ data, error, loading, fetchMore: fetchNextPage }) => {
          const errorResult = {
            ...defaultChildProps,
            error,
            loading,
          };

          if (error) {
            return children(errorResult);
          }
          if (
            !data ||
            !data.autocompleteShortcuts ||
            !data.autocompleteShortcuts.edges
          ) {
            return children(errorResult);
          }

          const { autocompleteShortcuts } = data;
          const { pageInfo } = autocompleteShortcuts;

          const nodes = collapseEdges<
            searchShortcuts$autocompleteShortcuts,
            searchShortcuts$autocompleteShortcuts$edges$node
          >(autocompleteShortcuts);

          const fetchMore = async () => {
            if (!pageInfo.hasNextPage) {
              return;
            }
            try {
              await fetchNextPage({
                variables: {
                  after: pageInfo.endCursor,
                },
                // @ts-expect-error FIXME: apollo upgrade signature change
                updateQuery: (prev, { fetchMoreResult }) => ({
                  autocompleteShortcuts: concatEdges<
                    searchShortcuts$autocompleteShortcuts,
                    'autocompleteShortcuts'
                  >(prev, fetchMoreResult, 'autocompleteShortcuts'),
                }),
              });
            } catch (e) {
              // likely component unmounted
              // https://github.com/apollographql/apollo-client/issues/4114
            }
          };

          return children({
            searchResults: nodes,
            fetchMore,
            loading,
            error,
            hasMore: pageInfo.hasNextPage,
          });
        }}
      </Query>
    );
  },
);
