import {
  Collapse,
  Icon,
  Box as MuiBox,
  BoxProps as MuiBoxProps,
} from '@mui/material';

import { BaseComponentProps } from './types';

type BannerSize = 'inherit' | 'small' | 'medium' | 'large';
export interface BannerProps
  extends BaseComponentProps,
    Pick<MuiBoxProps, 'color' | 'children'> {
  size?: BannerSize;
  animated?: boolean;
  visible?: boolean;
  variant?: 'default' | 'overlay';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  sticky?: boolean;
  includeIcon?: boolean;
  iconName?: string;
  onClose?: () => void;
}

const getFontSize = (size: BannerSize) => {
  switch (size) {
    case 'small':
      return 'caption.fontSize';
    case 'medium':
      return 'body2.fontSize';
    default:
      return 'inherit';
  }
};

type Padding = {
  paddingX: number;
  paddingY: number;
};

const PaddingBySize: Record<BannerSize, Padding> = {
  small: {
    paddingX: 1.5,
    paddingY: 1,
  },
  medium: {
    paddingX: 2,
    paddingY: 1,
  },
  large: {
    paddingX: 2,
    paddingY: 1.5,
  },
  inherit: {
    paddingX: 2,
    paddingY: 1,
  },
};

export const Banner = ({
  children,
  color = 'primary',
  size = 'inherit',
  visible = true,
  animated = true,
  variant = 'default',
  justifyContent = 'center',
  iconName = 'info_outline',
  sticky,
  includeIcon,
  onClose,
  ...rest
}: BannerProps) => {
  if (!children) return null;
  const child = (
    <MuiBox
      sx={{
        display: 'flex',
        gap: 1,
        justifyContent,
        alignItems: 'center',
        color:
          variant === 'default' ? `${color}.contrastText` : `${color}.main`,
        backgroundColor:
          variant === 'default' ? `${color}.main` : `${color}.background`,
        paddingX: PaddingBySize[size].paddingX,
        paddingY: PaddingBySize[size].paddingY,
        fontSize: getFontSize(size),

        zIndex: 1,
        top: 0,
        left: 0,
        right: 0,
        position: sticky ? 'sticky' : 'static',

        overflowY: 'hidden',
        overflowX: 'auto',
        '& a': {
          color: 'inherit',
        },
      }}
      {...rest}
    >
      {includeIcon && <Icon>{iconName}</Icon>}
      {children}
      <MuiBox
        sx={{
          position: 'absolute',
          right: theme => theme.spacing(PaddingBySize[size].paddingX),
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {onClose && <Icon onClick={onClose}>close</Icon>}
      </MuiBox>
    </MuiBox>
  );

  return animated ? <Collapse in={visible}>{child}</Collapse> : child;
};
