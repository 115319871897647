import { gql } from '@apollo/client';

export const TOGGLE_CUSTOM_PHONE_LINE_POLICY = gql`
  mutation toggleCustomPhoneLinePolicy(
    $input: ToggleCustomPhoneLinePolicyInput!
  ) {
    toggleCustomPhoneLinePolicy(input: $input) {
      phoneline {
        id
        policy {
          id
          category
        }
      }
    }
  }
`;
