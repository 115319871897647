import { gql } from '@apollo/client';

export const GET_PLACE_BY_ID = gql`
  query getPlaceById($id: String!) {
    place(id: $id) {
      id
      name
      externalName
      hours
      messagingHours
      useMessagingHours
      businessTz
      businessProfileId
      voice
    }
  }
`;
