import { gql } from '@apollo/client';

export const PUBNUB_AWAY_MODE_CHANGED = gql`
  mutation PubNubAwayModeChanged(
    $user_id: String!
    $is_away: Boolean!
    $is_schedule_enabled: Boolean!
    $status_board_view: Boolean!
    $next_shift_start: String
    $next_shift_end: String
    $can_manage_own_away_mode_settings: Boolean!
    $is_unassigning_inbound_messages: Boolean!
    $show_away_mode_banner_after: String
  ) {
    PubNubAwayModeChanged(
      user_id: $user_id
      is_away: $is_away
      is_schedule_enabled: $is_schedule_enabled
      status_board_view: $status_board_view
      next_shift_start: $next_shift_start
      next_shift_end: $next_shift_end
      can_manage_own_away_mode_settings: $can_manage_own_away_mode_settings
      is_unassigning_inbound_messages: $is_unassigning_inbound_messages
      show_away_mode_banner_after: $show_away_mode_banner_after
    ) @client
  }
`;
