import { gql } from '@apollo/client';

export const GET_APPOINTMENT_AGENTS = gql`
  query getAppointmentAgents($placeId: String!) {
    appointmentAgents(placeId: $placeId) {
      id
      name
      lastUpdated
      placeId
      activeIntegration {
        id
        pendingOpcodeSync
        integration {
          id
          internalId
        }
      }
    }
  }
`;
