import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { storeAuth0Login } from '~/actions/auth0';

const AUTH0_API_AUDIENCE = import.meta.env.VITE_AUTH0_API_AUDIENCE;
const AUTH_SCOPES = 'openid profile email read:current_user';

export const useNumaAuth0 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getAccessTokenSilently, logout } = useAuth0();

  const auth0Logout = useCallback(
    (options?: { redirectTo?: string }) => {
      logout({
        openUrl: (url: string) => {
          window.location.href = `${url}&returnTo=${encodeURIComponent(
            `${window.location.origin}${options?.redirectTo || ''}`,
          )}`;
        },
      });
    },
    [logout],
  );

  const attemptSilentAuth0Login = useCallback(
    async ({
      onSuccess,
      onFailure,
      storeToken = true,
      cacheMode = 'on',
      authorizationParams = {},
    }: {
      onSuccess?: (
        accessToken: string,
        refreshToken: string | undefined,
        userData: any,
      ) => void;
      onFailure?: () => void;
      storeToken?: boolean;
      cacheMode?: 'on' | 'off' | 'cache-only';
      authorizationParams?: {
        redirect_uri?: string;
      };
    }) => {
      const authParams = {
        cacheMode,
        authorizationParams: {
          audience: AUTH0_API_AUDIENCE,
          scope: AUTH_SCOPES,
          ...authorizationParams,
        },
      };

      let accessToken;
      let userData;
      try {
        accessToken = await getAccessTokenSilently(authParams);
      } catch (e) {
        if (e instanceof Error) {
          console.warn(e.message);
        }
      }

      if (accessToken) {
        // This data is currently returned by the backend when we get our existing tokens
        try {
          const userDataResponse = await fetch('/auth0/user_data', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          userData = await userDataResponse.json();
        } catch (e) {
          if (e instanceof Error) {
            console.warn(e.message);
          }
        }
      }

      if (accessToken && userData && userData.auth) {
        if (storeToken) {
          dispatch({
            type: 'STORE_AUTH0_TOKEN',
            payload: {
              ...userData,
              auth: {
                ...userData.auth,
                access_token: accessToken,
                refresh_token: accessToken,
              },
            },
          });
        }

        if (userData.user.status === 'pending') {
          history.push('/invite');
        } else if (onSuccess) {
          onSuccess(accessToken, accessToken, userData);
        }
      } else {
        if (onFailure) {
          onFailure();
        }
        // In certain failures we receive a token without all the necessary data (user claims),
        // so we should make sure to clear the auth0 token
        auth0Logout();
      }
    },
    [dispatch, history, getAccessTokenSilently, auth0Logout],
  );

  useEffect(() => {
    dispatch(
      storeAuth0Login({ login: attemptSilentAuth0Login, logout: auth0Logout }),
    );
  }, [dispatch, attemptSilentAuth0Login, auth0Logout]);

  return { attemptSilentAuth0Login, auth0Logout };
};
