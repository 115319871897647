import { gql } from '@apollo/client';
import { StatusItemDetails } from '../../fragments/gql/StatusItem.gql';

export const ADD_STATUS_ITEM = gql`
  mutation addStatusItem($input: AddStatusItemInput) {
    addStatusItem(input: $input) {
      statusItem {
        ...StatusItemDetails
      }
    }
  }
  ${StatusItemDetails}
`;
