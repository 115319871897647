import { gql } from '@apollo/client';

import { ConversationItemFragment } from '../../fragments/gql/conversationItem.gql';
import { ConversationLabelFragment } from '../../fragments/gql/labelFragment.gql';

export const ADD_CONVERSATION_LABEL = gql`
  mutation AddLabelToConversation(
    $input: AddLabelToConversationInput!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    addLabelToConversation(input: $input) {
      conversationLabel {
        ...ConversationLabelFields
      }
      conversation {
        ...ConversationItemFields
      }
    }
  }
  ${ConversationLabelFragment}
  ${ConversationItemFragment}
`;
