type NamedSpacing = 'narrow' | 'mid' | 'normal' | 'thick' | 'xThick';

const namedSpacing: Record<NamedSpacing, number> = {
  narrow: 0.5,
  mid: 0.75,
  normal: 1.25,
  thick: 1.75,
  xThick: 2,
};

export const ptToRem = (ptValue: number, baseFontSize = 16): string =>
  `${ptValue / baseFontSize}rem`;

export const getSpacingByName = (spacing: NamedSpacing): string | undefined => {
  const match = namedSpacing[spacing];
  return match ? `${match * 10}px` : undefined;
};

const customValues = {
  navBarHeight: '64px',
};

export const util = {
  getSpacingByName,
  ptToRem,
  customValues,
};

declare module '@mui/material/styles' {
  interface Theme {
    util?: typeof util;
  }
  interface ThemeOptions {
    util?: typeof util;
  }
}
