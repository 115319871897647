import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import type {
  ApiCreatePlaceInfoRequest,
  ApiCreatePlaceInfoResponse,
} from '@numbox/services';
import { PlaceInfoService } from '@numbox/services';

import type { Meta, ExtractReturn, ApiActionCreators } from './sagas/callApi';
import { serviceCall } from './sagas/callApi';

export const createPlaceInfo: ApiActionCreators<
  'CREATE_PLACE_INFO.REQUEST',
  ApiCreatePlaceInfoRequest,
  'CREATE_PLACE_INFO.SUCCESS',
  ApiCreatePlaceInfoResponse,
  Meta
> = {
  request: (params: ApiCreatePlaceInfoRequest, meta) => ({
    type: 'CREATE_PLACE_INFO.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'CREATE_PLACE_INFO.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'CREATE_PHONE_NUMBER.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onCreatePlaceInfoRequest(
  action: ExtractReturn<typeof createPlaceInfo.request>,
): Generator<any, void, void> {
  yield serviceCall(
    PlaceInfoService.createPlaceInfo,
    createPlaceInfo,
    action.payload,
    action.meta,
  );
}

export function* PlaceInfoSagas(): Saga<any> {
  yield all([takeEvery('CREATE_PLACE_INFO.REQUEST', onCreatePlaceInfoRequest)]);
}
