/* eslint-disable no-restricted-syntax */
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import { $Keys } from 'utility-types';

import type { ExtractReturn } from '@numbox/modules';
import { WidgetModule } from '@numbox/modules';
import type { WebReducerState } from '../reducers/types';

export const START_CUSTOMIZE = 'numbox-web/widget/START_CUSTOMIZE';
export const CANCEL_CUSTOMIZE = 'numbox-web/widget/CANCEL_CUSTOMIZE';
export const SAVE_CUSTOMIZE = 'numbox-web/widget/SAVE_CUSTOMIZE';
export const UPDATE_CUSTOM_FIELD = 'numbox-web/widget/UPDATE_CUSTOM_FIELD';

export type WidgetFields = {
  brand_color: string;
  description_copy: string;
  number_label: string;
  number_placeholder: string;
  nospam_copy: string;
  message_label: string;
  message_placeholder: string;
  close_text: string;
  send_text: string;
  form_title: string;
  widget_text: string;
  channel_id: string;
};

export type WidgetFieldNames = $Keys<WidgetFields>;

export type UpdateCustomFieldAction = {
  type: 'numbox-web/widget/UPDATE_CUSTOM_FIELD';
  payload: {
    field: WidgetFieldNames;
    value: string;
  };
};

export type StartCustomizeAction = {
  type: 'numbox-web/widget/START_CUSTOMIZE';
};

export type CancelCustomizeAction = {
  type: 'numbox-web/widget/CANCEL_CUSTOMIZE';
};

type Actions =
  | UpdateCustomFieldAction
  | StartCustomizeAction
  | CancelCustomizeAction
  | ExtractReturn<typeof WidgetModule.fetchWidget.request>
  | ExtractReturn<typeof WidgetModule.fetchWidget.success>
  | ExtractReturn<typeof WidgetModule.updateWidget.request>
  | ExtractReturn<typeof WidgetModule.updateWidget.success>
  | ExtractReturn<typeof WidgetModule.updateWidget.error>;

export const updateCustomField = (
  field: WidgetFieldNames,
  value: string,
): UpdateCustomFieldAction => ({
  type: UPDATE_CUSTOM_FIELD,
  payload: { field, value },
});

export const startCustomize = (): StartCustomizeAction => ({
  type: START_CUSTOMIZE,
});

export const cancelCustomize = (): CancelCustomizeAction => ({
  type: CANCEL_CUSTOMIZE,
});

// ******  IMPORTANT *****
// THESE KEYS MUST MATCH WHAT IS IN https://github.com/NumberAI/text-widget
// The default values are provided here for consistency
// **************************

export const INITIAL_TEXT_WIDGET_FIELDS = {
  brand_color: '#888',
  description_copy:
    'Enter your mobile phone number below and we will get back to you right away.',
  number_label: 'Your Mobile Number',
  number_placeholder: '555-555-0123',
  nospam_copy:
    "Don't worry.  We will only respond to your questions and you can always text STOP.",
  message_label: 'What can we do for you?',
  message_placeholder: 'Your message or question',
  close_text: 'Close',
  send_text: 'Send',
  form_title: 'Message Us',
  widget_text: 'Text Us',
  channel_id: '',
};

export const WIDGET_REDUCER_INITIAL_STATE = {
  customizing: false,
  loading: false,
  snippet: '',
  firstLoad: true,
  actual: { ...INITIAL_TEXT_WIDGET_FIELDS },
  custom: { ...INITIAL_TEXT_WIDGET_FIELDS },
};

export type WidgetReducerState = {
  snippet: string;
  customizing: boolean;
  loading: boolean;
  actual: WidgetFields;
  custom: WidgetFields;
  backup?: WidgetFields;
  firstLoad: boolean; // We only show the loading bar the first time the Widget View loads.
};

export const getIsCustomizing = (state: WebReducerState) =>
  state.widget.customizing;

export const getActualWidgetFields = (state: WebReducerState) =>
  state.widget.actual;

export const getCustomWidgetFields = (state: WebReducerState) =>
  state.widget.custom;

export const getIsDirty = (state: WebReducerState) => {
  const { actual, custom } = state.widget;
  return !isEqual(actual, custom);
};

export const getSnippet = (state: WebReducerState) => state.widget.snippet;

export const getCorrectFieldsForView = createSelector(
  [getIsCustomizing, getActualWidgetFields, getCustomWidgetFields],
  (isCustomizing, actual, custom) => (isCustomizing ? custom : actual),
);

export const getMailToLink = createSelector([getSnippet], snippet =>
  encodeURI(
    `mailto:?subject=Please install this on website&body=We are \
adding a new widget for our website that helps make \
it easy for customers to reach us via our texting system.\


Can you install the following on our site within the <body> tag?\


${snippet}\


Thanks, let me know as soon as it's live!`,
  ),
);

const WidgetReducer = (
  state: WidgetReducerState = WIDGET_REDUCER_INITIAL_STATE,
  action: Actions,
) => {
  switch (action.type) {
    case START_CUSTOMIZE: {
      const newState = cloneDeep(state);
      newState.custom = { ...newState.actual };
      newState.customizing = true;
      return newState;
    }
    case CANCEL_CUSTOMIZE: {
      const newState = cloneDeep(state);
      newState.customizing = false;
      return newState;
    }
    case UPDATE_CUSTOM_FIELD: {
      const newState = cloneDeep(state);
      const { field, value } = action.payload;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newState.custom[field] = value;
      return newState;
    }
    case 'UPDATE_WIDGET.REQUEST': {
      const newState = cloneDeep(state);
      newState.backup = { ...newState.actual };
      newState.actual = { ...newState.custom };
      return newState;
    }
    case 'UPDATE_WIDGET.FAILURE': {
      const newState = cloneDeep(state);
      if (newState.backup) {
        newState.actual = { ...newState.backup };
      }
      return newState;
    }
    case 'FETCH_WIDGET.REQUEST': {
      const newState = cloneDeep(state);
      newState.loading = true;
      return newState;
    }
    case 'UPDATE_WIDGET.SUCCESS':
    // just fall through here for now, its the same response
    case 'FETCH_WIDGET.SUCCESS': {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.firstLoad = false;
      const { div, script, ...actual } = action.payload;
      if (actual) {
        newState.actual = { ...actual };
        if (!state.customizing) {
          newState.custom = { ...actual };
        }
        newState.snippet = `${div}${script}`;
      }
      return newState;
    }
    default:
      return state;
  }
};

export default WidgetReducer;
