import get from 'lodash/get';
// @ts-expect-error ts-migrate(2724) FIXME: '"reselect"' has no exported member named 'InputSe... Remove this comment to see the full error message
import type { InputSelector } from 'reselect';
import { createSelector } from 'reselect';
import { all, takeEvery } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import type {
  ApiAuthRequest,
  ApiAuthResponse,
  ApiLogoutRequest,
  ApiLogoutResponse,
  ApiPasswordResetRequest,
  ApiPasswordResetResponse,
  ApiUpdatePasswordRequest,
  ApiUpdatePasswordResponse,
  ApiExchangeTokenRequest,
  ApiExchangeTokenResponse,
  ApiMagicLinkRequest,
  ApiMagicLinkResponse,
  ApiSignupCodeRequest,
  ApiSignupCodeResponse,
} from '@numbox/services';

import { AuthService } from '@numbox/services';
import { serviceCall } from './sagas/callApi';
import type { ApiActionCreators, Meta, ExtractReturn } from './sagas/callApi';
import { refreshTokenActionCreators } from './refreshToken';

const _ = { get };

type Auth = {
  accessToken: string;
};

type AuthState = {
  entities: {
    auth: Auth | null | undefined;
  };
};

// Selectors
export const getAuth: InputSelector<
  AuthState,
  void,
  Auth | null | undefined
> = (state: AuthState): Auth | null | undefined =>
  _.get(state, ['entities', 'auth']);

// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
export const hasAuthed = createSelector<
  AuthState,
  void,
  boolean,
  Auth | null | undefined
>([getAuth], (auth: Auth | null | undefined): boolean => auth != null);

export const getAccessToken = createSelector<
  AuthState,
  void,
  string | null | undefined,
  Auth | null | undefined,
  boolean
>(
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  [getAuth, hasAuthed],
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'authData' implicitly has an 'any' type.
  (authData, isAuthenticated) =>
    isAuthenticated && authData ? authData.accessToken : null,
);

export type AuthMeta = {
  email: string;
} & Meta;

export const auth: ApiActionCreators<
  'AUTH_USER.REQUEST',
  ApiAuthRequest,
  'AUTH_USER.SUCCESS',
  ApiAuthResponse,
  AuthMeta
> = {
  request: (params: ApiAuthRequest, meta) => ({
    type: 'AUTH_USER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'AUTH_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'AUTH_USER.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onAuthRequest(
  action: ExtractReturn<typeof auth.request>,
): Generator<any, void, void> {
  yield serviceCall(AuthService.auth, auth, action.payload, action.meta);
}

function* onRefreshRequest(
  action: ExtractReturn<typeof refreshTokenActionCreators.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AuthService.refresh,
    refreshTokenActionCreators,
    action.payload,
    action.meta,
  );
}

export const logoutUser: ApiActionCreators<
  'LOGOUT_USER.REQUEST',
  ApiLogoutRequest,
  'LOGOUT_USER.SUCCESS',
  ApiLogoutResponse,
  Meta
> = {
  request: (params: ApiLogoutRequest, meta) => ({
    type: 'LOGOUT_USER.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'LOGOUT_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'LOGOUT_USER.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onLogoutUserRequest(
  action: ExtractReturn<typeof logoutUser.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AuthService.logout,
    logoutUser,
    action.payload,
    action.meta,
  );
}

export const requestPasswordReset: ApiActionCreators<
  'REQUEST_PASSWORD_RESET.REQUEST',
  ApiPasswordResetRequest,
  'REQUEST_PASSWORD_RESET.SUCCESS',
  ApiPasswordResetResponse,
  Meta
> = {
  request: (params: ApiPasswordResetRequest, meta) => ({
    type: 'REQUEST_PASSWORD_RESET.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'REQUEST_PASSWORD_RESET.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'REQUEST_PASSWORD_RESET.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onRequestPasswordReset(
  action: ExtractReturn<typeof requestPasswordReset.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AuthService.requestPasswordReset,
    requestPasswordReset,
    action.payload,
    action.meta,
  );
}

export const updatePassword: ApiActionCreators<
  'UPDATE_PASSWORD.REQUEST',
  ApiUpdatePasswordRequest,
  'UPDATE_PASSWORD.SUCCESS',
  ApiUpdatePasswordResponse,
  Meta
> = {
  request: (params: ApiUpdatePasswordRequest, meta) => ({
    type: 'UPDATE_PASSWORD.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'UPDATE_PASSWORD.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'UPDATE_PASSWORD.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onUpdatePasswordRequest(
  action: ExtractReturn<typeof updatePassword.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AuthService.updatePassword,
    updatePassword,
    action.payload,
    action.meta,
  );
}

export const exchangeToken: ApiActionCreators<
  'EXCHANGE_TOKEN.REQUEST',
  ApiExchangeTokenRequest,
  'AUTH_USER.SUCCESS',
  // Use AUTH_USER.SUCCESS out of convenience, see below
  ApiExchangeTokenResponse,
  Meta & { tokenType?: string }
> = {
  request: (params: ApiExchangeTokenRequest, meta) => ({
    type: 'EXCHANGE_TOKEN.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    // Abuse the fact that the payload here is identical to what
    // AUTH_USER.SUCCESS expects.  We want the *exact* same thing
    // to happen in these two cases
    type: 'AUTH_USER.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'EXCHANGE_TOKEN.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onExchangeTokenRequest(
  action: ExtractReturn<typeof exchangeToken.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AuthService.exchangeToken,
    exchangeToken,
    action.payload,
    action.meta,
  );
}

export const requestMagicLink: ApiActionCreators<
  'REQUEST_MAGIC_LINK.REQUEST',
  ApiMagicLinkRequest,
  'REQUEST_MAGIC_LINK.SUCCESS',
  ApiMagicLinkResponse,
  Meta
> = {
  request: (params: ApiMagicLinkRequest, meta) => ({
    type: 'REQUEST_MAGIC_LINK.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'REQUEST_MAGIC_LINK.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'REQUEST_MAGIC_LINK.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onMagicLinkRequest(
  action: ExtractReturn<typeof requestMagicLink.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AuthService.requestMagicLink,
    requestMagicLink,
    action.payload,
    action.meta,
  );
}

export const requestSignupCode: ApiActionCreators<
  'REQUEST_SIGNUP_CODE.REQUEST',
  ApiSignupCodeRequest,
  'REQUEST_SIGNUP_CODE.SUCCESS',
  ApiSignupCodeResponse,
  Meta
> = {
  request: (params: ApiSignupCodeRequest, meta) => ({
    type: 'REQUEST_SIGNUP_CODE.REQUEST',
    payload: params,
    meta,
  }),

  success: (data, meta) => ({
    type: 'REQUEST_SIGNUP_CODE.SUCCESS',
    payload: data,
    meta,
  }),

  error: (error: any, meta) => ({
    type: 'REQUEST_SIGNUP_CODE.FAILURE',
    payload: error,
    error: true,
    meta,
  }),
};

function* onSignupCodeRequest(
  action: ExtractReturn<typeof requestSignupCode.request>,
): Generator<any, void, void> {
  yield serviceCall(
    AuthService.requestSignupCode,
    requestSignupCode,
    action.payload,
    action.meta,
  );
}

export function* AuthSagas(): Saga<any> {
  yield all([
    takeEvery('AUTH_USER.REQUEST', onAuthRequest),
    takeEvery('LOGOUT_USER.REQUEST', onLogoutUserRequest),
    takeEvery('REQUEST_PASSWORD_RESET.REQUEST', onRequestPasswordReset),
    takeEvery('REFRESH_TOKEN.REQUEST', onRefreshRequest),
    takeEvery('UPDATE_PASSWORD.REQUEST', onUpdatePasswordRequest),
    takeEvery('EXCHANGE_TOKEN.REQUEST', onExchangeTokenRequest),
    takeEvery('REQUEST_MAGIC_LINK.REQUEST', onMagicLinkRequest),
    takeEvery('REQUEST_SIGNUP_CODE.REQUEST', onSignupCodeRequest),
  ]);
}

export {
  getRefreshToken,
  refreshTokenActionCreators as refreshToken,
} from './refreshToken';
