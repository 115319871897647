import type { ExtractReturn } from '@numbox/modules';
import { HostedSmsModule, PhoneLineModule } from '@numbox/modules';

// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { goBack, go, push } from 'connected-react-router';

import { createSelector } from 'reselect';

import isEmpty from 'lodash/isEmpty';

import type {
  ApiHostedSmsPort,
  ApiPhoneLine,
  ApiPhoneLineType,
} from '@numbox/services';

import type { WebReducerState } from '../reducers/types';
import '../reducers/types';
import { getLastPath } from './modal';

export type HostedSmsPort = {
  phonelineId: string;
  status: string;
  url: string;
  verificationCode: string;
  verificationAttempts: number;
};

export type PhoneLineType = ApiPhoneLineType;
export type PhoneLine = {
  id: string;
  name: string;
  mdn: string;
  lineType: PhoneLineType | null | undefined;
  carrier: string | null | undefined;
  placeId: string;
  smsChannel?: {
    id: string;
    mdn: string;
  };

  voiceChannel?: {
    id: string;
    mdn: string;
  };

  hostedSmsPort: HostedSmsPort | null | undefined;
  createHostedSmsUrl: string;
  delayAnswerDuration: number | null | undefined;
  answerWithKeys?: string;
  autoOptin: boolean | null | undefined;
  autoOptinMessage: string | null | undefined;
  assistantPhrase: string;
};

const fromHostedSmsApi = (hostedSmsPort: ApiHostedSmsPort): HostedSmsPort => ({
  phonelineId: hostedSmsPort.phoneline_id,
  status: hostedSmsPort.status,
  url: hostedSmsPort._links.self,
  verificationCode: hostedSmsPort.verification_code,
  verificationAttempts: hostedSmsPort.verification_attempts,
});

const fromApi = (phoneLine: ApiPhoneLine): PhoneLine => ({
  id: phoneLine.id,
  name: phoneLine.name,
  mdn: phoneLine.mdn,
  lineType: phoneLine.line_type,
  carrier: phoneLine.carrier_name,
  placeId: phoneLine.place_id,
  smsChannel: phoneLine.sms_channel
    ? {
        id: phoneLine.sms_channel.id,
        mdn: phoneLine.sms_channel.mdn,
      }
    : undefined,
  voiceChannel: phoneLine.voice_channel
    ? {
        id: phoneLine.voice_channel.id,
        mdn: phoneLine.voice_channel.mdn,
      }
    : undefined,
  hostedSmsPort: phoneLine.hosted_sms_port
    ? {
        phonelineId: phoneLine.hosted_sms_port.phoneline_id,
        status: phoneLine.hosted_sms_port.status,
        url: phoneLine.hosted_sms_port._links.self,
        verificationCode: phoneLine.hosted_sms_port.verification_code,
        verificationAttempts: phoneLine.hosted_sms_port.verification_attempts,
      }
    : null,
  createHostedSmsUrl: phoneLine.create_hosted_sms_url,
  delayAnswerDuration: phoneLine.delay_answer_duration,
  answerWithKeys: phoneLine.answer_with_keys,
  autoOptin: phoneLine.auto_optin,
  autoOptinMessage: phoneLine.auto_optin_message,
  assistantPhrase: phoneLine.assistant_phrase,
});

export type PhoneLineReducerState = {
  lines: Array<PhoneLine>;
  backupLines: Record<string, PhoneLine>;
};

export const PHONELINE_INITIAL_STATE: PhoneLineReducerState = {
  lines: [],
  backupLines: {},
};

const updateNumaInfo =
  (
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'phonelineId' implicitly has an 'any' ty... Remove this comment to see the full error message
    phonelineId,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'autoOptin' implicitly has an 'any' type... Remove this comment to see the full error message
    autoOptin,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'delayAnswerDuration' implicitly has an ... Remove this comment to see the full error message
    delayAnswerDuration,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'answerWithKeys' implicitly has an 'any'... Remove this comment to see the full error message
    answerWithKeys,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'assistantPhrase' implicitly has an 'any... Remove this comment to see the full error message
    assistantPhrase,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'autoOptinMessage' implicitly has an 'an... Remove this comment to see the full error message
    autoOptinMessage,
  ) =>
  (p: PhoneLine): PhoneLine => {
    if (p.id === phonelineId) {
      return {
        ...p,
        answerWithKeys:
          typeof answerWithKeys === 'string'
            ? answerWithKeys
            : p.answerWithKeys,
        autoOptin: typeof autoOptin === 'boolean' ? autoOptin : p.autoOptin,
        autoOptinMessage:
          typeof autoOptinMessage === 'string'
            ? autoOptinMessage
            : p.autoOptinMessage,
        delayAnswerDuration:
          typeof delayAnswerDuration === 'number'
            ? delayAnswerDuration
            : p.delayAnswerDuration,
        assistantPhrase:
          typeof assistantPhrase === 'string'
            ? assistantPhrase
            : p.assistantPhrase,
      };
    }
    return p;
  };

type PhoneLineActions =
  | ExtractReturn<typeof PhoneLineModule.updatePhoneLine.request>
  | ExtractReturn<typeof PhoneLineModule.updatePhoneLine.error>
  | ExtractReturn<typeof PhoneLineModule.fetchPhoneLines.success>
  | ExtractReturn<typeof PhoneLineModule.createPhoneLine.success>
  | ExtractReturn<typeof HostedSmsModule.createHostedSmsPort.success>
  | ExtractReturn<typeof HostedSmsModule.fetchHostedSmsPort.success>
  | ExtractReturn<typeof HostedSmsModule.updateHostedSmsPort.success>;

const PhonelineReducer = (
  state: PhoneLineReducerState = PHONELINE_INITIAL_STATE,
  action: PhoneLineActions,
) => {
  switch (action.type) {
    case 'FETCH_PHONE_LINES.SUCCESS': {
      return {
        ...state,
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'line' implicitly has an 'any' type.
        lines: action.payload.phonelines.map(line => fromApi(line)),
      };
    }
    case 'CREATE_NEW_PHONE_LINE.SUCCESS': {
      return {
        ...state,
        lines: [...state.lines, fromApi(action.payload)],
      };
    }
    case 'UPDATE_PHONE_LINE.REQUEST': {
      const {
        phone_line_id: phonelineId,
        auto_optin: autoOptin,
        auto_optin_message: autoOptinMessage,
        delay_answer_duration: delayAnswerDuration,
        answer_with_keys: answerWithKeys,
        assistant_phrase: assistantPhrase,
      } = action.payload;
      const backupLines = { ...state.backupLines };
      const backupLine = state.lines.find(p => p.id === phonelineId);
      if (backupLine) {
        backupLines[phonelineId] = backupLine;
      }
      const newLines = state.lines.map<PhoneLine>(
        updateNumaInfo(
          phonelineId,
          autoOptin,
          delayAnswerDuration,
          answerWithKeys,
          assistantPhrase,
          autoOptinMessage,
        ),
      );

      return {
        ...state,
        lines: newLines,
        backupLines,
      };
    }

    case 'UPDATE_PHONE_LINE.FAILURE': {
      const newLines = [...state.lines];
      const { phonelineId } = action.meta;
      const index = newLines.findIndex(p => p.id === phonelineId);
      if (state.backupLines[phonelineId] && index > -1) {
        newLines[index] = { ...state.backupLines[phonelineId] };
      }
      return {
        ...state,
        lines: newLines,
      };
    }

    case 'FETCH_HOSTED_SMS_PORT.SUCCESS':
    case 'UPDATE_HOSTED_SMS_PORT.SUCCESS':
    case 'CREATE_HOSTED_SMS_PORT.SUCCESS': {
      const { payload } = action;

      const lines = state.lines.map(line => {
        if (line.id !== payload.phoneline_id) {
          return line;
        }
        return {
          ...line,
          hostedSmsPort: fromHostedSmsApi(payload),
        };
      }) as Array<PhoneLine>;

      return {
        ...state,
        lines,
      };
    }
    default:
      return state;
  }
};

export default PhonelineReducer;

export type CarrierType =
  | 'Verizon'
  | 'AT&T'
  | 'Sprint'
  | 'T-Mobile'
  | 'Other'
  | 'Comcast'
  | 'Comcast SMS-Enabled'
  | 'Rogers'
  | 'Telus';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'phoneline' implicitly has an 'any' type... Remove this comment to see the full error message
const determineCarrier = (phoneline): CarrierType => {
  if (phoneline && phoneline.carrier) {
    const carrier = phoneline.carrier.toLowerCase();
    if (carrier.includes('verizon')) {
      return 'Verizon';
    }
    if (carrier.includes('at&t')) {
      return 'AT&T';
    }
    if (carrier.includes('sprint')) {
      return 'Sprint';
    }
    if (carrier.includes('t-mobile')) {
      return 'T-Mobile';
    }

    if (carrier.includes('telus')) {
      return 'Telus';
    }

    if (carrier.includes('rogers')) {
      return 'Rogers';
    }

    if (carrier.includes('comcast')) {
      return carrier.includes('sms') ? 'Comcast SMS-Enabled' : 'Comcast';
    }
  }
  return 'Other';
};

export const getPhoneLines = (state: WebReducerState): Array<PhoneLine> =>
  state.phonelines.lines;
export const getExternalPhoneLines = (
  state: WebReducerState,
): Array<PhoneLine> =>
  state.phonelines.lines.filter(p => p.lineType !== 'NUMA');
export const getNumaNumber = (
  state: WebReducerState,
): PhoneLine | null | undefined =>
  state.phonelines.lines.find(p => p.lineType === 'NUMA');
export const getMobilePhoneline = createSelector(
  [getPhoneLines],
  (phonelines: Array<PhoneLine>): PhoneLine | null | undefined =>
    phonelines.find(p => p.lineType === 'MOBILE'),
);

export const getFirstValidPhoneline = createSelector(
  [getPhoneLines],
  (phonelines: Array<PhoneLine>): PhoneLine | null | undefined =>
    phonelines.find(p => p.lineType === 'LANDLINE') ||
    phonelines.find(p => p.lineType === 'MOBILE'),
);

export const getMobilePhonelineCarrier = createSelector(
  [getMobilePhoneline],
  (p: PhoneLine | null | undefined): CarrierType => determineCarrier(p),
);

export const getIsFirstLandline = createSelector(
  [getFirstValidPhoneline],
  p => p && p.lineType === 'LANDLINE',
);

export const getFirstPhonelineCarrier = createSelector(
  [getFirstValidPhoneline],
  (p: PhoneLine | null | undefined): CarrierType => determineCarrier(p),
);

export const getPhoneLineById = (
  state: WebReducerState,
  phonelineId: string,
): PhoneLine => {
  const phoneline = state.phonelines.lines.find(p => p.id === phonelineId);
  if (!phoneline) {
    throw Error(`Phone line of id ${phonelineId} does not exist`);
  }
  return phoneline;
};

export const getHasSingleForwardingCode = createSelector(
  [getMobilePhonelineCarrier],
  (carrier: CarrierType): boolean => {
    return carrier === 'Verizon' || carrier === 'AT&T';
  },
);

export const getTrimmedNumaNumber = createSelector(
  [getNumaNumber],
  (numaNumber: PhoneLine | null | undefined) => {
    if (!numaNumber) {
      throw Error('Need a Numa #');
    }

    return numaNumber.mdn.substring(2);
  },
);

export const getFirstForwardingCode = createSelector(
  [getTrimmedNumaNumber, getMobilePhonelineCarrier],
  (numaNumber: string, carrier: CarrierType): string => {
    const mdnWithoutPlusOne = numaNumber;
    if (carrier === 'Verizon') {
      return `*71${mdnWithoutPlusOne}`;
    }
    if (carrier === 'AT&T') {
      return `*004*${mdnWithoutPlusOne}*11#`;
    }
    if (carrier === 'Sprint') {
      return `*73${mdnWithoutPlusOne}`;
    }
    if (carrier === 'T-Mobile') {
      return `**61*1${mdnWithoutPlusOne}#`;
    }
    if (carrier === 'Rogers') {
      return `*61*${mdnWithoutPlusOne}#`;
    }
    if (carrier === 'Telus') {
      return `*61*${mdnWithoutPlusOne}#`;
    }

    // This state is impossible
    throw Error('Something went wrong, please contact support');
  },
);

export const getSecondForwardingCode = createSelector(
  [getTrimmedNumaNumber, getMobilePhonelineCarrier],
  (numaNumber: string, carrier: CarrierType): string => {
    const mdnWithoutPlusOne = numaNumber;
    if (carrier === 'Sprint') {
      return `*74${mdnWithoutPlusOne}`;
    }
    if (carrier === 'T-Mobile') {
      return `**67*1${mdnWithoutPlusOne}#`;
    }
    if (carrier === 'Rogers') {
      return `*67*${mdnWithoutPlusOne}#`;
    }
    if (carrier === 'Telus') {
      return `*67*${mdnWithoutPlusOne}#`;
    }

    // This state is impossible
    throw Error('Something went wrong, please contact support');
  },
);

export const getNumaAnswerWithKeysById = createSelector(
  [getPhoneLineById],
  phoneline => phoneline.answerWithKeys,
);

export const getNumaAssistantPhrase = createSelector(
  [getPhoneLineById],
  phoneline => phoneline.assistantPhrase,
);

export const getNumaNumberDelayById = createSelector(
  [getPhoneLineById],
  phoneline => {
    if (
      phoneline.delayAnswerDuration === null ||
      typeof phoneline.delayAnswerDuration === 'undefined'
    ) {
      throw Error('Phone line is not a numanumber');
    }
    return phoneline.delayAnswerDuration;
  },
);

export const getNumaNumberAutoOptinById = createSelector(
  [getPhoneLineById],
  phoneline => {
    if (
      phoneline.autoOptin === null ||
      typeof phoneline.autoOptin === 'undefined'
    ) {
      throw Error('Phone line is not a numanumber');
    }
    return phoneline.autoOptin;
  },
);

export const getNumaNumberAutoOptinMessageById = createSelector(
  [getPhoneLineById],
  phoneline => {
    return phoneline.autoOptinMessage;
  },
);

function* navigateToPhoneInfo(
  action: ExtractReturn<typeof PhoneLineModule.createPhoneLine.success>,
): Generator<any, void, void> {
  const { id } = action.payload;
  yield put(push(`/jobs/phonenumbers/number/${id}`));
}

export const returnToPhoneList = () => ({
  type: 'RETURN_TO_PHONE_LIST',
});

function* onReturnToPhoneList(): Generator<any, void, any> {
  const lastPath = yield select(getLastPath);
  if (lastPath && lastPath.includes('/phonenumbers/add')) {
    /*
      User came through the Provision new/existing number flow.
      Stack will be:
        => /jobs/phonenumbers/number/<id>
        => /jobs/phonenumbers/add/(new|existing)
        => /jobs/phonenumbers/add
        => /jobs/phonenumbers
    */
    yield put(go(-3));
  } else {
    yield put(goBack());
  }
}

export function* watchPhoneLineNavigation(): Saga<any> {
  yield all([
    takeEvery('CREATE_NEW_PHONE_LINE.SUCCESS', navigateToPhoneInfo),
    takeEvery('RETURN_TO_PHONE_LIST', onReturnToPhoneList),
  ]);
}

export function isBandwidthNumber(lineType: string, carrierName: string) {
  return lineType === 'VOIP' && carrierName.toLowerCase().includes('bandwidth');
}

export function isSybaseNumber(lineType: string, carrierName: string) {
  return (
    lineType === 'VOIP' && carrierName.toLowerCase().includes('sms - sybase365')
  );
}

export function canTextEnablePhoneline(
  lineType: string | null | undefined,
  carrierName: string | null | undefined,
) {
  if (isEmpty(lineType) || isEmpty(carrierName)) {
    return false;
  }

  if (lineType === 'NUMA') {
    return true;
  }

  return (
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
    ['LANDLINE', 'VOIP'].includes(lineType) &&
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
    !isBandwidthNumber(lineType, carrierName) && // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
    !isSybaseNumber(lineType, carrierName)
  );
}
