import { gql } from '@apollo/client';

export const RENAME_ACCOUNT = gql`
  mutation renameAccount($input: RenameAccountInput) {
    renameAccount(input: $input) {
      account {
        id
        name
      }
    }
  }
`;
