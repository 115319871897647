import type {
  Auth0LoginFunction,
  Auth0LogoutFunction,
  StoreAuth0LoginAction,
} from '~/actions/auth0';

export const AUTH0_INITIAL_STATE = {
  login: () => {},
  logout: () => {},
};

export type Auth0ReducerState = {
  login: Auth0LoginFunction;
  logout: Auth0LogoutFunction;
};

export default function Auth0Reducer(
  state: Auth0ReducerState = AUTH0_INITIAL_STATE,
  action: StoreAuth0LoginAction,
) {
  switch (action.type) {
    case 'SET_AUTH0_AUTH_FUNCTIONS': {
      const { login, logout } = action.payload;

      return {
        ...state,
        login,
        logout,
      };
    }
    default:
      return state;
  }
}
