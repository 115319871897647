import { gql } from '@apollo/client';

export const GET_QUESTION_AGENTS = gql`
  query getQuestionAgents($placeId: String!) {
    questionAgents(placeId: $placeId) {
      id
      name
      placeId
    }
  }
`;
