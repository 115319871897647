import { gql } from '@apollo/client';
import { BrandThemeFragment } from '../../fragments/gql/BrandTheme.gql';

export const GET_PLACE_BRAND_THEME = gql`
  query getPlaceBrandTheme($placeId: String!) {
    place(id: $placeId) {
      id
      externalName
      inboxType
    }
    brandTheme(placeId: $placeId) {
      ...BrandThemeFields
    }
  }
  ${BrandThemeFragment}
`;
