import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  centered: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  centered?: boolean;
  size?: number;
  minSeconds?: number;
  maxSeconds?: number;
  placeholderElement?: React.ReactNode;
};

export const DelayedLoadingIndicator = ({
  centered,
  size = 40,
  minSeconds = 1,
  maxSeconds = 3,
  placeholderElement = null,
}: Props): React.ReactElement => {
  const classes = useStyles();
  const [timeElapsed, setTimeElapsed] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeElapsed(true);
      // Anywhere from minSeconds - maxSeconds second initial delay
    }, ((maxSeconds - minSeconds) * Math.random() + minSeconds) * 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  });

  if (!timeElapsed) {
    return <>{placeholderElement}</>;
  }

  return centered ? (
    <div className={classes.centered}>
      <CircularProgress size={size} />
    </div>
  ) : (
    <CircularProgress size={size} />
  );
};
