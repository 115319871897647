import { gql } from '@apollo/client';
import { DialogFlowBotDataFragment } from './dialogFlowBotDataFragment.gql';

export const AppointmentFragment = gql`
  fragment AppointmentFields on AppointmentQL {
    id
    state
    createdOn
    expiresAt
    appointmentDate
    appointmentId
    externalServiceAdvisorName
    externalServiceAdvisorId
    enrichedFrom
    dialogFlowBotData {
      ...DialogFlowBotDataFields
    }
  }
  ${DialogFlowBotDataFragment}
`;
