import { gql } from '@apollo/client';
import { PaymentRequestFragment } from '../../fragments/gql/paymentRequestFragment.gql';

export const UPDATE_PAYMENT_REQUEST_ATTACHMENT = gql`
  mutation updatePaymentRequestAttachment(
    $input: UpdatePaymentRequestAttachmentInput!
  ) {
    updatePaymentRequestAttachment(input: $input) {
      paymentRequest {
        ...PaymentRequestFields
      }
    }
  }
  ${PaymentRequestFragment}
`;
