import { put, takeEvery, select, takeLatest, all } from 'redux-saga/effects';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';

import { resetTypingIndicator } from '@numbox/react';
import { IntentsModule } from '@numbox/modules';

import type { WebReducerState } from '../reducers/types';

import { resetGreetings } from './greetings';
import { resetAnswers, getAppliedIntents } from './answers';
import { getIsAuthenticated, getIsAuthenticatedAdmin } from './auth';

const _ = { cloneDeep };

// Selectors
export const getPlaces = (state: WebReducerState) =>
  state.places ? state.places.list : [];

export const getPlaceById = (state: WebReducerState, id: string) => {
  const places = getPlaces(state);
  return places.find(t => t.id === id);
};

export const getSelectedPlace = (state: WebReducerState) =>
  state.places.selected ? state.places.selected : null;

export const getSelectedPlaceTimezone = (state: WebReducerState) =>
  state.places.selected ? state.places.selected.business_tz : undefined;

export const getSelectedPlaceId = (state: WebReducerState) => {
  const place = getSelectedPlace(state);
  return place ? place.id : null;
};

export const getIsMultiPlace = (state: WebReducerState) =>
  getPlaces(state).length > 1;

export const getActivePlaceId: (arg0: WebReducerState) => string =
  createSelector(
    [getIsAuthenticated, getIsAuthenticatedAdmin, getSelectedPlaceId],
    (isAuthed, isAuthedAdmin, placeId) => {
      if (!isAuthed && !isAuthedAdmin) {
        throw Error(
          'Cannot call getActivePlaceId for an unauthenticated user.',
        );
      }

      if (!placeId) {
        throw Error('No active place available.');
      }

      return placeId;
    },
  );

export const getPlaceHours = (state: WebReducerState, id: string) => {
  const place = getPlaceById(state, id);
  return place && place.hours
    ? _.cloneDeep(place.hours)
    : {
        regular: [],
        exceptions: [],
      };
};

// Action Creators
export const resetUserList = () => ({
  type: 'numbox-web/users/RESET_USER_LIST',
});

// Sagas
export function* onSelectPlace(): Generator<any, void, void> {
  yield put(resetGreetings());
  yield put(resetTypingIndicator());
  yield put(resetUserList());
  yield put(resetAnswers());
}

function* onPlaceHoursUpdated(): Generator<any, void, any> {
  const getAppliedIntentAction = yield select<WebReducerState>(state => {
    const placeId = getSelectedPlaceId(state);
    if (!placeId) {
      return null;
    }
    const hoursIntent = getAppliedIntents(state, placeId).find(
      i => i.name === 'core.hours',
    );

    if (!hoursIntent || !hoursIntent.placeId) {
      return null;
    }
    return IntentsModule.getAppliedIntent.request(
      {
        placeId: hoursIntent.placeId,
        id: hoursIntent.id,
      },
      undefined,
    );
  });
  if (getAppliedIntentAction) {
    yield put(getAppliedIntentAction);
  }
}

// Sagas
export function* watchSelectPlace(): Saga<any> {
  yield all([
    takeEvery('SELECT_PLACE', onSelectPlace),
    takeLatest('UPDATE_PLACE_HOURS.SUCCESS', onPlaceHoursUpdated),
  ]);
}
